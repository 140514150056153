import React, { Component } from 'react';
import Colours from './../../../../assets/colours.js';
import Fetching from './../../../../functions/fetching.js';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import "./../../../../assets/css/datepicker.css";
import { connect } from 'react-redux';
import { Link} from 'react-router-dom'

class PastTransactionInput extends Component {

  constructor(props){
    super(props);
    this.state = {
      transactionData:[
        // {
        //   id:-1,
        //   type:'contribution',
        //   age:22,
        //   value:10000
        // },
        // {
        //   id:-1,
        //   type:'savings',
        //   age:25,
        //   value:12000
        // },
        // {
        //   id:-1,
        //   type:'withdrawal',
        //   age:26,
        //   value:1000
        // },
        // {
        //   id:-1,
        //   type:'withdrawal',
        //   age:26,
        //   value:1000
        // }
      ],
      transactionsToDelete:[],
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
    }

    this.addNewTransaction = this.addNewTransaction.bind(this);
    this.changeAmount = this.changeAmount.bind(this);
    this.saveHistoricalInput = this.saveHistoricalInput.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  componentDidMount() {
    this.getTokens();
    this.loadHistoricalData();
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  addNewTransaction(){

    let date = new Date();
    let newTransaction = {
      id:-1,
      type:'withdrawal',
      date:date,
      value:1000
    };

    let newTransactionData = this.state.transactionData.slice();
    newTransactionData.push(newTransaction);

    this.setState({
      transactionData:newTransactionData
    });
  }

  changeTransactionType(e,i){

    let newTransactionData = this.state.transactionData.slice();
    newTransactionData[i].type = e.target.value

    this.setState({
      transactionData:newTransactionData
    });

  }

  changeAmount(e,i){

    let newTransactionData = this.state.transactionData.slice();
    newTransactionData[i].value = e.floatValue;

    this.setState({
      transactionData:newTransactionData
    });

  }

  changeDate(e,i){


    let newTransactionData = this.state.transactionData.slice();
    newTransactionData[i].date = e;

    this.setState({
      transactionData:newTransactionData
    });

  }

  deleteTransaction(e,i){

    let doDelete = window.confirm('Do you wish to delete this record?');

    if(doDelete){
      let transactionData = this.state.transactionData.slice();
      let transactionsToDelete = this.state.transactionsToDelete.slice();
      let transactionToDelete = transactionData.splice(i,1);

      transactionsToDelete.push(transactionToDelete[0].id);
      this.setState({
        transactionData,transactionsToDelete
      })
    }


  }

  loadHistoricalData(){

		let thus = this;

		let customerRef = this.props.match.params.custRef;

		const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/loadHistoricalData/"+customerRef;

		// Run the login request
    fetch(apiRoute, {
      method: 'get',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include"
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        let transactionData = data.data;
        let preparedTransactionData =[];
        transactionData.forEach((transaction) => {
          transaction.date = new Date(transaction.date)
          preparedTransactionData.push(transaction);
        })

        thus.setState({
          transactionData:preparedTransactionData
        });

      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

  saveHistoricalInput(e){

		let thus = this;

		let customerRef = this.props.match.params.custRef;

		const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/saveHistoricalData/"+customerRef;

    let saveData = {
      transactionData:this.state.transactionData,
      transactionsToDelete:this.state.transactionsToDelete
    }

		// Run the login request
    fetch(apiRoute, {
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include",
      body:'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        let transactionData = thus.state.transactionData.slice();
        for(let i =0; i< transactionData.length;i++){
          transactionData[i].id = data.data[i];
        }


        thus.setState({
          transactionData:transactionData,
          transactionsToDelete:[]
        })

        alert('Historical Data Saved');
				//thus.setState({similarProducts:data.data})
      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }



  render() {

    let tableRows = this.state.transactionData.map( (transaction,i) => {
       return (
         <tr key={'tr-'+i}>
            <td>
              <select  onChange={(e) => this.changeTransactionType(e,i)} value={transaction.type} >
                <option value='contribution'>Contribution</option>
                <option value='withdrawal'>Withdrawal</option>
                <option value='savings'>Current Value</option>
              </select>
            </td>
            <td><NumberFormat onValueChange={(e) => this.changeAmount(e,i)} prefix={'£'} value={transaction.value}/></td>
            <td>
            <DatePicker
                selected={transaction.date}
                onChange={(e) => this.changeDate(e,i)}
                dateFormat="d/M/yyyy"
                style={ styles.input }
            />

            </td>
            <td> <span onClick={(e) => this.deleteTransaction(e,i) } style={{verticalAlign:'top',cursor:'pointer'}}>x</span></td>
          </tr>
        )
    });


    return (
      <div className='width-12 centred historical-data'>
        <h1>Historical Savings/Contribution/Withdrawals</h1>
        <Link to={"/admin/clients/planning/"+this.props.match.params.custRef}>
          <button className='btn' style={ styles.greyBtn }>Back</button>
        </Link>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Amount</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
        <button className='btn' onClick={this.saveHistoricalInput}  style={styles.greyBtn}>Save</button>
        <button className='btn' onClick={this.addNewTransaction} style={styles.greyBtn}>Add</button>

      </div>
    );
  }
}

const styles = {
	greyBtn: {
		background: Colours.txtGrey,
		marginTop: 22,
		fontSize: '90%',
		padding: '5px 12px',
    float:'right'
	},
  input: {
    display: 'inline-block',
    padding: "5px 12px",
    border: 0,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.15)',
    fontSize: '14px',
    fontFamily: "'Mukta', sans-serif",
    width: 155,
    marginLeft: 5
  },
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(PastTransactionInput);
