import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import FlexiInput from './flexi-input.js';
import Fetching from './../../functions/fetching.js';

import Colours from './../../assets/colours.js';
import addIcon from './../../assets/img/add-btn.png'
import removeIcon from './../../assets/img/remove-btn.png'
import expandIcon from './../../assets/img/expand-contract.png'

class QuestionGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      repeaters: []
    };

    this.removeEntry = this.removeEntry.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  handleAddition (e, repType) {

    if (typeof repType == "undefined") {
      repType = "img";
    }

    e.preventDefault();

    var currentReps = this.state.repeaters;
    var i = currentReps.length;
    i = i + 1;

    var groupDetail = this.props.group;
    if (groupDetail.style.indexOf("fpi") > -1 ) {
      if ( repType === "text" ) {
        groupDetail.isText = true;
      } else {
        groupDetail.isText = false;
      }
    }

    let newRepeater = this.generateGroupArray(groupDetail, i);
    currentReps.push(newRepeater);

    this.setState({ repeaters: currentReps });

  }

	getTokens () {
		fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
    	.then(Fetching.statusCheck)
    	.then(Fetching.jsonExtract)
    	.then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	    .catch(function (error) {
	    	console.log('Request failed', error);
	    });
  }

  removeEntry (e, repKey) {
    e.preventDefault();

    // Get the full details of the removal
    var repeaters = this.state.repeaters;
    for (var i in repeaters) {
      if (repeaters[i].repKey === repKey) {
        var rep = repeaters[i];
        var finalI = i;
      }
    }
// console.log(repeaters);
// console.log(rep);
    // If no repeater then throw an error
    if (typeof rep == 'undefined') {
      alert('Sorry but there was an error removing that data. Please try again.');
    } else {

// console.log(rep);
// return;

      var saveData = {
        gid: this.props.group.gid,
        repNo: rep.repKey
      };

      // Run a fetch to "remove the element"
      var fetchUrl;
      if (typeof this.props.custRef != 'undefined') {
        fetchUrl = process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/deletion/' + this.props.custRef;
      } else {
        fetchUrl = process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/remove/' + this.props.customerToken + '/' + this.props.customerMash + '/' + this.props.customerTokenKey;
      }
      var thus = this;
      fetch(fetchUrl, {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        credentials:"include",
        body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
      })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);

        if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
          // Upon success update the state to reflect the removed element
          delete repeaters[finalI];
          thus.setState({ repeaters: repeaters });
        } else {
          // Handle the failure by showing a failure message
          alert('Sorry but there was an error deleting these entries. Please try again.');
        }
        thus.getTokens();
      })
      .catch(function (error) {
        thus.getTokens();
        alert('Sorry but there was an error deleting these entries. Please try again.');
      });

    }


  }

  generateGroupArray (group, i, subtitles) {

    var newRepeater = {
      repKey: i,
      closedClass: (i !== 1)?"closed":"",
      subtitle: (typeof subtitles == 'undefined')?group.subtitle + " " + i:subtitles[i - 1],
      group: group
    };

    if (this.props.names !== false && typeof this.props.names.client1 != 'undefined' && newRepeater.subtitle === "Client 1") {
      newRepeater.subtitle = this.props.names.client1;
    }
    if (this.props.names !== false && typeof this.props.names.client2 != 'undefined' && newRepeater.subtitle === "Client 2") {
      newRepeater.subtitle = this.props.names.client2;
    }

    return newRepeater;

  }

  toggleOpenness (e, repKey) {

    e.preventDefault();

    // Get the current status of the repeaters
    var repeaters = this.state.repeaters;

    // Loop through them and update all to closed (except this one, which is open - unless already open)
    for ( var i in repeaters ) {
      if (repeaters[i].repKey === repKey) {
        repeaters[i].closedClass = (repeaters[i].closedClass === "closed")?"":"closed";
      } else {
        repeaters[i].closedClass = "closed";
      }
    }

    this.setState({ repeaters: repeaters });

  }

	hideToggle (e, groupId, repeatNo, hideType = 'customer',count = 0) {
    
    e.persist();

		// Set up the save data
		var saveData = {
			custRef: this.props.custRef,
			gid: groupId,
			repNo: repeatNo,
			isHidden: e.target.checked,
			hideType: hideType
		}

		// Trigger the fetch
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/hide_group/', {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
      console.log('Request succeeded with JSON response', data);
      
      if(data.rslt === 'secure-fail' && count < 1){
        //fix security tokens when clicking on customer page then toggling checkbox on the back end.
        thus.getTokens();
        setTimeout(() => {
          thus.hideToggle (e, groupId, repeatNo, hideType ,1);
        },1000)
        
      } else {
        
        if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
          // Upon success update the state to reflect the removed element
          console.log('Successful save');
        }  else {
          // Handle the failure by showing a failure message
          alert('Sorry but there was an saving this setting. Please try again.');
        }
        thus.getTokens();
      }

		})
		.catch(function (error) {
			thus.getTokens();
			alert('Sorry but there was an error saving this entry. Please try again.');
		});
	}

  componentDidMount () {

    var repNo = 1;
    var repeats = [];
    if (this.props.group.type === "repeat-fxd") {
      var subtitles = this.props.group.subtitle.split('--');
      repNo = subtitles.length;
      for (var i = 1; i <= repNo; i++) {
        repeats.push(this.generateGroupArray(this.props.group, i, subtitles));
      }
    } else {
      // None fixed repeaters
      if (this.props.group.repeaters === 0) {
        repNo = 1;
        for (var j = 1; j <= repNo; j++) {
          repeats.push(this.generateGroupArray(this.props.group, j));
        }
      } else {
        for ( var k in this.props.group.repeaterKeys ) {
          repeats.push(this.generateGroupArray(this.props.group, this.props.group.repeaterKeys[k]));
        }
      }
    }

    //this.getTokens();
    this.setState({ repeaters: repeats });
  }

	inArray (needle, haystack) {

		for ( var i in haystack ) {
      if(i === 'customer'){
        console.log(haystack[i]);
      }
			if (Number(haystack[i]) === Number(needle)) {
				return true;
			}
		}
		return false;
	}

  render () {

    const { repeaters } = this.state;

    if (this.props.group.style === 'accordian' || this.props.group.style === 'accordian-wide') {

      return (

        <div className={ 'question-group ' + this.props.group.style }>
          {this.props.group.title.indexOf("HIDE-") > -1 ? "" : (
            <h2 style={styles.head2}>{this.props.group.title}</h2>
          )}

          { repeaters.map( repeater =>
            <div key={ "repeater-" + repeater.repKey + "-" + this.props.group.gid } className={ "acc-wrap " + repeater.closedClass }>
              <h4 className={ "acc-head " + repeater.closedClass } style={ styles.expandBg }><a href="#toggle" onClick={ (e) => this.toggleOpenness(e, repeater.repKey) }>{ repeater.subtitle }</a></h4>
              <div className={ "acc-sxn " + repeater.closedClass + " " + this.props.group.style }>

                { repeater.group.questions.map( question =>

                  <FlexiInput
                    key={ question.qid }
                    hideTabs={ this.props.hideTabs }
                    repeatNo={ repeater.repKey }
                    responses={ question.responses }
                    qId={ question.qid }
                    inputName={ "question-" + question.qid + "-" + repeater.repKey }
                    inputType={ question.type }
                    isRequired={ question.isRequired }
                    qContent={ question.question }
                    optionList={ (typeof question.options != 'undefined') ? question.options : [] } errorFields={ this.props.errorFields }
                    clearError={ this.props.clearError }
                    custRef={ this.props.custRef }
                    family={ this.props.family }
                    isSummary={ question.isSummary }
                    readOnly={ this.props.readOnly }
                    qGroup={this.props.group}
                    canTemplate={ question.canTemplate }
                    {...this.props}
                  />

                )}

              </div>
            </div>
          )}

          { this.props.group.type === 'repeat' ? (
            <a href="#another" onClick={ (e) => this.handleAddition(e) } style={{ ...styles.lnkBtn,...styles.addBtn }}>Add another</a>
          ): (
            <span></span>
          )}
        </div>

      );

    } else {

			var useAllBtns = (typeof this.props.sxnSlug == 'undefined' || this.props.sxnSlug !== 'protection' ) ? true : false ;

      // //Hide entire group: hidden repeater count equals repeater count for this group
      if(typeof this.props.custRef == 'undefined'){
         if(typeof this.props.group.hiddenRepeaters.customer !== 'undefined'  && repeaters.length === this.props.group.hiddenRepeaters.customer.length){
          return null;
        }
      }
     

      return (

        
        <div className={ 'question-group inline ' + this.props.group.style }>
          { this.props.group.title.indexOf("HIDE-") > -1 ? "" : (
            <h2 style={styles.head2}>{this.props.group.title}</h2>
          )}

          { repeaters.map( repeater =>
            <div key={ "repeater-" + repeater.repKey + "-" + this.props.group.gid }>

							{ typeof this.props.custRef == 'undefined' && this.inArray(repeater.repKey, this.props.group.hiddenRepeaters.customer) ? "" : (
								<div className={ this.props.group.type === 'repeat' ? "acc-sxn-plain rep-sxn" : "acc-sxn-plain" }>
									{ Number(repeater.group.canHide) === 1 && typeof this.props.custRef != 'undefined' ? (
										<Fragment>
											{ useAllBtns ? (
												<div style={ styles.rghtCb }>
													<label htmlFor={ "isHiddenCustomer" + repeater.group.gid + '-' + repeater.repKey } style={ styles.rghtCbLabel }>Hide from customer?</label>
													<input type="checkbox" id={ "isHiddenCustomer" + repeater.group.gid + '-' + repeater.repKey } defaultChecked={ this.inArray(repeater.repKey, this.props.group.hiddenRepeaters.customer) } style={ styles.rghtCbCb } onChange={ (e) => this.hideToggle(e, repeater.group.gid, repeater.repKey, "customer") } />
												</div>
											) : "" }

											<div style={ styles.rghtCb }>
												<label htmlFor={ "isHiddenPR" + repeater.group.gid + '-' + repeater.repKey } style={ styles.rghtCbLabel }>Hide from Progress Review</label>
												<input type="checkbox" id={ "isHiddenPR" + repeater.group.gid + '-' + repeater.repKey } defaultChecked={ this.inArray(repeater.repKey, this.props.group.hiddenRepeaters['progress-review']) } style={ styles.rghtCbCb } onChange={ (e) => this.hideToggle(e, repeater.group.gid, repeater.repKey, "progress-review") } />
											</div>

											{ useAllBtns ? (
												<div style={ styles.rghtCb }>
													<label htmlFor={ "isHiddenCalculator" + repeater.group.gid + '-' + repeater.repKey } style={ styles.rghtCbLabel }>Hide from Calculator</label>
													<input type="checkbox" id={ "isHiddenCalculator" + repeater.group.gid + '-' + repeater.repKey } defaultChecked={ this.inArray(repeater.repKey, this.props.group.hiddenRepeaters.calculator) } style={ styles.rghtCbCb } onChange={ (e) => this.hideToggle(e, repeater.group.gid, repeater.repKey, "calculator") } />
												</div>
											) : "" }

										</Fragment>
									) : "" }

								  { repeater.group.questions.map( question =>
		                <FlexiInput
											key={ question.qid }
											repeatNo={ repeater.repKey }
											hideTabs={ this.props.hideTabs }
											responses={ question.responses }
											qId={ question.qid }
											inputName={ "question-" + question.qid + "-" + repeater.repKey }
											inputType={ question.type }
											isRequired={ question.isRequired }
											qContent={ question.question }
											optionList={ (typeof question.options != 'undefined') ? question.options : [] }
											errorFields={ this.props.errorFields }
											clearError={ this.props.clearError }
											custRef={ this.props.custRef }
											names={ this.props.names }
											isSummary={ question.isSummary }
											family={ this.props.family }
											readOnly={ this.props.readOnly }
											qGroup={ this.props.group }
											sxnId={repeater.group.sxnId}
                      canTemplate={question.canTemplate}
											{...this.props}
										/>
		              )}
		              { this.props.group.type === 'repeat' && !this.props.readOnly ? (
		                <a href="#remove" className="remove-btn" onClick={ (e) => this.removeEntry(e, repeater.repKey) } style={{ ...styles.lnkBtn,...styles.removeBtn }}>Remove</a>
		              ): ("")}
								</div>
							) }
            </div>
          )}

          { this.props.group.type === 'repeat' && !this.props.readOnly ? (
            <a href="#another" onClick={ (e) => this.handleAddition(e) } style={{ ...styles.lnkBtn,...styles.addBtn }}>Add another</a>
          ): (
            <span></span>
          )}
          {this.props.group.type === 'repeat' && !this.props.readOnly && this.props.group.style.indexOf("fpi") > -1 ? (
            <a href="#another" onClick={(e) => this.handleAddition(e, "text")} style={{ ...styles.lnkBtn, ...styles.addBtn, ...styles.mrgRght }}>Add a text page</a>
          ) : ""}
        </div>
      );
    }

  }

}

const styles = {
  head2: {
    color: Colours.grn,
    border: 'none',
    fontVariant: 'small-caps',
    fontSize: 28,
    margin: "0 0 22px 0"
  },
  lnkBtn: {
    color: Colours.txtBlack,
    fontSize: 14,
    float: 'right',
    padding: "3px 0 3px 20px"
  },
  addBtn: {
    background: 'url(' + addIcon + ') left center / 12px 12px no-repeat',
  },
  expandBg: {
    backgroundImage: 'url(' + expandIcon + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: "20px auto",
    width: '100%',
    boxSizing: 'border-box'
  },
  removeBtn: {
    position: "absolute",
    bottom: 12,
    right: 15,
    background: 'url(' + removeIcon + ') left center / 12px 12px no-repeat',
  },
	rghtCb: {
		float: 'right',
		width: 80,
		minHeight: 100,
		marginRight: 12
	},
	rghtCbLabel: {
		display: 'block',
		textAlign: 'center',
		fontSize: 12,
		opacity: 0.5
	},
	rghtCbCb: {
		margin: '10px auto',
		display: "block",
  },
  mrgRght: {
    marginRight: 22
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash
  };
}

export default connect(mapStateToProps)(QuestionGroup);
