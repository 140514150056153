import React, { Component } from 'react';

class ForecastDatatable extends Component {

  constructor(props){
    super(props);
    this.renderTableRow = this.renderTableRow.bind(this);
  }

  renderTableRow(content){
    return content.map((contentEntry,i) => {
      return <td key={'contentEntry-'+i}>{contentEntry}</td>
    })
  }

  render(){
    let data = this.props.data.slice();
    let titleArray = data.shift();

    const tableHeadingContent = titleArray.map((heading,i) =>{
      return <th key={'heading-'+i}>{heading}</th>
    } )

    const tableRowContent = data.map((content,i) => {
      return <tr key={'row-content-'+i}>{this.renderTableRow(content)}</tr>
    })
    return (
      <table style={ styles.greyed }>
      <thead>
        <tr>
          { tableHeadingContent}
        </tr>
        </thead>
        <tbody>
          {tableRowContent}
        </tbody>
      </table>
    )
  }
}

const styles = {
	greyed: {
		opacity: 0.3,
		marginTop: 32,
		fontSize: 13
	}
};

export default ForecastDatatable;
