import React, { Component } from 'react';

import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';
import ExpContBlack from './../../../assets/img/expand-contract-black.png';

import { connect } from 'react-redux';

class EmailGroupsPanel extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      email_groups:[],
	  is_collapsed:true,
	  is_saving_data:false
    };
	this.toggleEmailGroupPanelVisibility = this.toggleEmailGroupPanelVisibility.bind(this);
    this.loadContent = this.loadContent.bind(this);
	this.saveContent = this.saveContent.bind(this);
	this.handleEmailGroupChange = this.handleEmailGroupChange.bind(this);
  }

  componentWillMount () {
    this.loadContent();
  }

  toggleEmailGroupPanelVisibility(){
	  this.setState(prevState => ({
		  is_collapsed: !prevState.is_collapsed
	  }));
  }

  getTokens () {
   fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	 .then(Fetching.statusCheck)
	 .then(Fetching.jsonExtract)
	 .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	 .catch(function (error) {
	   console.log('Request failed', error);
	 });
  }

  handleEmailGroupChange(e){

	  let email_groups = [...this.state.email_groups];

	  for(let i = 0;i< email_groups.length;i++){
		  if(email_groups[i].name === e.target.name){
			  email_groups[i].is_member = e.target.checked;
		  }
	  }

	  this.setState({email_groups}, () => {
		  this.saveContent();
	  })
  }

  // Check the tokens
  loadContent () {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/email_groups/getEmailGroups/' + this.props.custRef;
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          thus.setState({ email_groups: data.data });

        }
      );

  }

  // Check the tokens
  saveContent () {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/email_groups/saveEmailGroups/' + this.props.custRef;
    var thus = this;

	let saveData = this.state.email_groups;

    fetch(fetchUrl, {
		credentials:"include",
		method: 'post',
		headers: {
			"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
		},
		body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
	})
      .then(response => response.json())
      .then(
        function (data) {
			thus.getTokens();
        }
      ).catch(function (error) {
 		  thus.getTokens();
 		  thus.setState({ success: "", error: "There was an error sending your message. Please check the content and try again." });
 	  });

  }


  render() {

    const email_groups  = this.state.email_groups;


	const emailGroups = email_groups.map((email_group,i) => {
		return (
			<div className="question" key={'qn_'+i}>
			  <input style={{marginRight:5}} data-index={i} className="" id={email_group.name} name={email_group.name} onChange={this.handleEmailGroupChange} type="checkbox" defaultChecked={email_group.is_member} value={email_group.id} />
			  <label htmlFor={email_group.name}>{email_group.name}</label>

			</div>
		)
	});

	const visibilityCss = this.state.is_collapsed ? {display:'none'} : {display:'block'};
	const filterState = this.state.is_collapsed ? 'closed' : 'open';
    return (

	  <>
      <div style={ {...styles.cfBlock} } className={"cf-block cf-fltrs width-8 " + filterState}>

	  <h2 style={ styles.mSml } className={ filterState }>
		<a style={styles.blackText} href="#toggle" onClick={ (e) => this.toggleEmailGroupPanelVisibility(e) }>
		  Email Group Membership
		</a>
	  </h2>
	  <div style={visibilityCss}>
		  {emailGroups}
	  </div>
      </div>
	  </>
    );
  }
}

var styles = {
  cfBlock: {
	  marginBottom: 25,
      background: Colours.bgGrey,
      padding: "12px 20px",
      boxSizing: "border-box",
      overflow: "auto"
  },

  mSml: {
    marginBottom: 15,
    borderBottomColor: Colours.txtGrey,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px top -38px",
    backgroundSize: "24px auto",
    backgroundImage: 'url(' + ExpContBlack + ')',
   },
  save_btn:{
	  background: "rgb(78, 144, 67)",
	  color: "rgb(255, 255, 255)",
	  borderRadius: "20px",
	  textAlign: "center",
	  textDecoration: "none",
	  fontSize: '14px',
	  display: 'block',
	  padding: '5px 12px',
	  float:'right',
	  marginTop:0,
	  border:0,
	  cursor:'pointer',
	  marginRight:12
  },
  blackText:{
	  color:Colours.txtBlack
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(EmailGroupsPanel);
