import React, { Component } from 'react';

import Colours from './../../../assets/colours.js';

class ProgressSml extends Component {

  render() {

    let sxns = false;
    if (typeof this.props.customer.completeness != 'undefined') {
      sxns = this.props.customer.completeness;
    }
    if ( sxns === false || sxns.length === 0 ) {
      return (
        <div></div>
      );
    }

    return (
      <div style={ styles.mainMenu }>
        <ul style={styles.progressBar}>
          { sxns.map( sxn =>
            <span key={ sxn.sxnId } title={ typeof sxn.sxnName != "undefined" ? sxn.sxnName : "" }>
              { sxn.isAddressed === "1" ? (
                <li style={{ ...styles.progressBlock,...styles.grnProgress }}></li>
              ) : (
                <li style={ styles.progressBlock }></li>
              )}
            </span>
          )}
        </ul>
      </div>
    );
  }
}

var styles = {
  mainMenu: {
    marginTop: 2
  },
  progressBlock: {
    display: 'block',
    float: 'left',
    height: 4,
    width: 8,
    background: Colours.lightGrey,
    marginLeft: 2
  },
  grnProgress: {
    background: Colours.grn
  }
};


export default ProgressSml;
