import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'

import Heading from './../heading.js';

import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';

class Delete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uid: this.props.match.params.fieldId,
      field: false,
      rdr: false
    };
  }

  // Fetch the custom field data
  componentDidMount () {

    var thus = this;
    if (typeof this.props.match.params.fieldId != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/custom_fields/get/' + this.props.match.params.fieldId, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            console.log(data);
            // Check whether we have a failure
            if (typeof data.errors != 'undefined') {

              // Redirect to the error page
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but we could not find the custom field to delete.' });

            } else {

              if ( data.cf.type === "default" ) {
                thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but you cannot delete this core user field.' });
                thus.setState({ rdr: true });
              } else {
                // Process the data for display of the tabs
                thus.setState({ field: data.cf, usrName: data.name });
              }
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the custom field.' });
        thus.setState({ rdr: true });
      }

  }

  handleDeletion () {

    var thus = this;
    if (typeof this.props.match.params.fieldId != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/custom_fields/delete/' + this.props.match.params.fieldId, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            console.log(data);
            // Check whether we have a failure
            if (typeof data.errors != 'undefined') {

              // Redirect to the error page
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the custom field.' });
              thus.setState({ rdr: true });

            } else if ( typeof data.rslt != 'undefined' && data.rslt === "error" ) {

              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: data.msg });
              thus.setState({ rdr: true });

            } else {
              // Process the data for display of the tabs
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: 'The client has been successfully custom field.' });
              return data;
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the custom field.' });
        thus.setState({ rdr: true });
      }

  }


  render() {

    const { field, rdr } = this.state;

    if (rdr) {
      return (
        <Redirect to='/admin/custom-fields' />
      );
    }

    if (field) {

      return (
        <div className="width-6 centred">
          <Link style={{ ...styles.lnk, ...styles.lnkRght }} to={ `/admin/custom-fields` }>Back</Link>
          <Heading mainTitle="Delete Custom field" subTitle={ field.label } />

          <p>Are you sure you wish to delete &quot;{ field.label }&quot;?</p>

          <Link style={{ ...styles.lnk, ...styles.lnkConfirm }} to={ `/admin/custom-fields` } onClick={ (e) => this.handleDeletion(e) }>Yes, delete this custom field</Link>
          <Link style={{ ...styles.lnk, ...styles.lnkDeny }} to={ `/admin/custom-fields` }>No, keep this custom field</Link>

        </div>
      );

    }

    return <div className='width-1 centred'>
            <div className="loader whiteBg">Loading...</div>
          </div>;
  }
}

var styles = {
  lnk: {
    display: "block",
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50
  },
  lnkDeny: {
    textAlign: 'center',
    maxWidth: 200,
    margin: "15px auto",
    background: Colours.red,
    border: '1px solid ' + Colours.red,
  },
  lnkConfirm: {
    textAlign: 'center',
    maxWidth: 200,
    margin: "30px auto 15px auto"
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Delete);
