import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Fetching from './../../../functions/fetching.js';

class GenerateLink extends Component {

    // Constructor to set up data and set init state
    constructor(props) {
        super(props);

        this.state = {
            hasLnk: false,
            lnkDate: false,
            genLoading: false,
            displayLnk: false
        };

        // this.loadContent = this.loadContent.bind(this);
    }

    componentWillMount () {
        this.checkForLinks(this.props.custRef);
    }

    getTokens() {
        fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials: "include" })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }

    checkForLinks ( custRef ) {
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/admin/customer_submissions/check_links/' + custRef, { method: 'get', credentials: "include" })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function(data){
                thus.setState({ hasLnk: data.lnkUrl, lnkDate: data.lnkDate });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }

    displayLnk () {
        this.setState({ displayLnk: true });
    }
    hideLnk(e) {
        e.preventDefault();
        this.setState({ displayLnk: false });
    }

    cancelLnk(e, usrRef) {
        e.preventDefault();

        // Confirm the cancellation
        if (window.confirm("This will cancel the link that has been sent to the customer early. Do you want to proceed?")) {

            // Run a fetch to cancel the link
            let thus = this;
            fetch(process.env.REACT_APP_API_BASE + '/admin/customer_submissions/cancel/' + usrRef + "/1", { credentials: "include" })
                .then(Fetching.statusCheck)
                .then(Fetching.jsonExtract)
                .then(
                    function (data) {
                        // Check whether we have a failure
                        if (typeof data.errors !== 'undefined' && data.errors === 'auth') {

                            // Redirect to the error page
                            thus.setState({ rslt: data.errors, rsltType: "error" });

                        } else {
                            // Process the data for display of the users
                            thus.setState({ hasLnk: false, lnkDate: false, genLoading: false });

                        }
                    }
                );
        }
    }

    generateLnk(e, usrRef) {

        e.preventDefault();

        this.setState({ genLoading: true, displayLnk: true });

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/admin/customer_submissions/create', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            credentials: "include",
            body: 'data=' + JSON.stringify({ pid: usrRef, sxns: 'all' }) + '&' + this.props.tokenKey + '=' + this.props.token
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                console.log('Request succeeded with JSON response', data);

                if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
                    // Set the updated state
                    thus.setState({ genLoading: false, hasLnk: data.slug, lnkDate: data.created });
                } else {
                    // Handle the failure by showing a failure message
                    thus.setState({ rslt: "Sorry but there was an error generating the link. Please try again.", rsltType: 'error' });
                }
                thus.getTokens();
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }

    render () {

        let { hasLnk, lnkDate, genLoading, displayLnk } = this.state;

        var coverContent = "";
        if (displayLnk) {
            if (genLoading !== false) {
                coverContent = <div className="cover">
                    <div className='width-1 centred' styles={styles.topMrgn}>
                        <div className="loader whiteBg">Loading...</div>
                    </div>
                </div>
            }
            if (hasLnk !== "") {
                coverContent = <div className="cover">
                    <a href="#close" onClick={(e) => this.hideLnk(e)}> </a>
                    <div className="box">
                        <h5>Questionnaire Link</h5>
                        <p className="smlr">Please copy the link below and send it to the client(s).</p>
                        <p>{ process.env.REACT_APP_SITE_BASE }/welcome/{ hasLnk }</p>
                    </div>
                </div>
            }
        }

        if (!hasLnk) {
       
            return <Fragment>
                { coverContent }
                <Link style={ this.props.lightStyles } to={`/admin/clients/generate/` + this.props.custRef}>Sections link</Link>
                <button
                    style={ this.props.styles } 
                    onClick={(e) => this.generateLnk(e, this.props.custRef)}
                >Generate questionnaire link</button>
            </Fragment>

        } else {

            return <Fragment>
                { coverContent }
                <button style={this.props.styles} href="#view" onClick={(e) => this.displayLnk(e, this.props.custRef)}>View link (generated: {lnkDate})</button>
                <button style={this.props.styles} href="#view" onClick={(e) => this.cancelLnk(e, this.props.custRef)}>Cancel</button>
            </Fragment>

        }
  
    }
}

const styles = {
    topMrgn: {
        marginTop: 125
    }
};

function mapStateToProps(state) {
    return {
        token: state.token,
        tokenKey: state.tokenKey,
    };
}

export default connect(mapStateToProps)(GenerateLink);