import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'

import Btn from './../common/btn.js';
import FlashMsg from './../common/flash-msg.js';

import Colours from './../../assets/colours.js';
import Fetching from './../../functions/fetching.js';

class ResetPassword extends Component {
  // Set the initial state for the form entry
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPw: '',
      slug: '',
      mash: '',
      isNew: 0,
      error: '',
      rdr: false
    }

    this.handleEntry = this.handleEntry.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
  }

  handleEntry ( event ) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // Handle the login form submission
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/users/password_set/' + this.state.slug + "/" + this.state.mash, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ password: this.state.password, confirm_password: this.state.confirmPw }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Redirect to the dashboard
        thus.setState({ rdr: '/admin' });
        thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: "Your password has been successfully updated. You can now sign in." })
      } else {
        // Handle the failure by showing a failure message
        // TODO - Handle the errors
        var msg = "<p>" + data.msg + "</p><ul>";
        for (var i in data.errors) {
          for (var j in data.errors[i]) {
            msg += "<li>" + data.errors[i][j].message + "</li>";
          }
        }
        msg += "</ul>";
        thus.setState({ error: msg });
      }

      thus.getTokens();

    })
    .catch(function (error) {
      thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: "Sorry but we could not reset your password. Please try again." })
      thus.setState({ rdr: '/admin' });
      console.log('Request failed', error);
    });

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Upon loading up check that the slug and tokens etc are in place
  checkAuth () {
    // Get the slug and the mash
    if (typeof this.props.match.params.slug == 'undefined' || typeof this.props.match.params.mash == 'undefined') {
      console.log('REALLY?!');
      this.setState({ rdr: '/forgotten-password' });
    } else {

      // Check that the slug and mash return a reasonable result
      var thus = this;
      fetch(process.env.REACT_APP_API_BASE + '/users/password_set/' + this.props.match.params.slug + '/' + this.props.match.params.mash)
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);

        if (typeof data.rslt !== 'undefined' && data.rslt === 'display-form') {
          // Redirect to the dashboard
          thus.setState({ slug: thus.props.match.params.slug, mash: thus.props.match.params.mash, isNew: data.isSetup });
        } else {
          // Handle the failure by showing a failure message
          thus.setState({ rdr: '/forgotten-password' });
        }

        thus.getTokens();
        return true;

      })
      .catch(function (error) {
        console.log(error);
        thus.setState({ rdr: '/forgotten-password' });
      });

    }

  }

  // Before load of the login component run a check to see if we are already logged in
  componentWillMount () {
    // Check that the tokens are in place for a reset
    this.checkAuth();
    // Get the form tokens for submission
    this.getTokens();
  }

  render() {
    if (this.state.rdr !== false) {
      return <Redirect to={ this.state.rdr } />
    }

    const { isNew } = this.state;

    var introTxt = "Thanks for requesting a password reset.";
    var addnTxt = "To reset your password, please enter your new password in the form below.";
    if (isNew === "1") {
      introTxt = "Your account has been activated.";
      addnTxt = "Please create a password using the form below.";
    }

    return (
      <div className='content'>
        <div className='width-1 spacer'>&nbsp;</div>
        <div className='width-4 ' style={ styles.container }>
          <p className='intro'>{ introTxt }</p>
          <p>{ addnTxt }</p>

          <FlashMsg />
          <div className='error mbtm' dangerouslySetInnerHTML={{ __html: this.state.error }}></div>

          <form style={styles.form}>
            <input name='password' placeholder='Password' type='password' id='password' required='required' value={this.state.password} onChange={this.handleEntry} />
            <input name='confirmPw' placeholder='Confirm password' type='password' id='confirmPw' required='required' value={this.state.confirmPw} onChange={this.handleEntry} />
            <Btn title="Set password" lnk="/questionnaire" type="submit" handleSubmission={ this.handleSubmission } />
          </form>
          <p className='smlrRght'><Link to='/admin'>Return to login</Link></p>
        </div>
      </div>
    );
  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 145,
    marginBottom: 125,
    padding: 25
  },
  form: {
    marginTop: 32
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
    authError: state.errors.auth,
  };
}

export default connect(mapStateToProps)(ResetPassword);
