import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import DatePicker from "react-datepicker";
import "./../../../assets/css/datepicker.css";
import Draggable from 'react-draggable';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';

import FpiTemplates from './../questionnaire/fpi-templates';

import Btn from './../../common/btn.js';

class AgendaView extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      meetingDate: false,
      meetingDateFormatted: new Date(),
      notes: "",
      custRef: "",
      aid: false,
      createNew: false,
      updateDate: false,
      newMeetingDate: "",
      updateMeetingDate: "",
      minimised: true,
	  hasMeetingPassed:false
    };

    this.loadContent = this.loadContent.bind(this);
    this.createAgendaForm = this.createAgendaForm.bind(this);
    this.createAgendaDate = this.createAgendaDate.bind(this);
    this.createAgenda = this.createAgenda.bind(this);
    this.updateMeetingDateForm = this.updateMeetingDateForm.bind(this);
    this.updateMeetingDateState = this.updateMeetingDateState.bind(this);
    this.updateMeetingDate = this.updateMeetingDate.bind(this);
    this.saveUpdate = this.saveUpdate.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.getTokens = this.getTokens.bind(this);

  }

  componentWillMount () {
    this.loadContent();
    this.getTokens();
  }

  // Display the create agenda form
  createAgendaForm (  ) {
    this.setState({ createNew: true });
  }

  // Update the meeting date in the state with input
  createAgendaDate ( agendaDate ) {
    agendaDate = this.formatDate(agendaDate);
    this.setState({ newMeetingDate: agendaDate, meetingDateFormatted: agendaDate });
  }

  // Save the
  createAgenda ( ) {
    let { newMeetingDate } = this.state;

    if (newMeetingDate === "") {
      // alert("Please select a date for the meeting to which this agenda relates.");
			var todayDate = new Date();
			todayDate = this.formatDate(todayDate);
    }

    let saveData = {
      date: (newMeetingDate === "") ? todayDate : newMeetingDate,
      custRef: this.props.custRef
    };

    let thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/admin/agendas/create', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if (typeof data.aid != "undefined") {
        console.log('Request succeeded with JSON response', data);
        thus.setState({
          aid: data.aid,
          createNew: false,
          meetingDate: newMeetingDate,
          notes: ""
        });
        thus.loadContent();
      } else {
        alert("There was an error updating your agenda. Please check the content and try again.");
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.getTokens();
      alert("There was an error updating your agenda. Please check the content and try again.");
      console.log('Request failed', error);
    });
  }

  // Update the meeting date
  updateMeetingDateForm () {
    let { meetingDate } = this.state;
    this.setState({ updateDate: true, updateMeetingDate: meetingDate });
  }

  updateMeetingDateState ( selectedDate ) {
    selectedDate = this.formatDate(selectedDate);
    this.setState({ updateMeetingDate: selectedDate, meetingDateFormatted: selectedDate });
  }

  updateMeetingDate () {

    let { updateMeetingDate, aid,notes } = this.state;

    let saveData = {
      aid: aid,
      date: updateMeetingDate,
      custRef: this.props.custRef,
	  notes:notes
    };

    let thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/admin/agendas/update', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if (typeof data.aid != "undefined") {
        console.log('Request succeeded with JSON response', data);
        thus.getTokens();
        thus.setState({
          aid: data.aid,
          updateDate: false,
          updateMeetingDate: "",
          meetingDate: updateMeetingDate
        });
        thus.loadContent();
      } else {
        alert("There was an error updating your agenda. Please check the content and try again.");
      }
    })
    .catch(function (error) {
      thus.getTokens();
      console.log('Request failed', error);
    });

  }

  // Update state based on the content
  handleEditorChange (content, doSave) {
    this.setState({ notes: content });

    // If desired then save the agenda 
    var thus = this;
    if ( typeof doSave != 'undefined' && doSave === true ) {
      setTimeout(function(){
        thus.saveUpdate();
      }, 100);
    } else {
      clearTimeout(this.saveTimer);
      this.saveTimer = setTimeout(function () {
        thus.saveUpdate();
      }, 2000);
    }
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // On bur of the text editor save the notes content to the database
  saveUpdate ( e ) {

    let {meetingDateFormatted, notes, aid,hasMeetingPassed } = this.state;

	// let todayObj = new Date();
	// let todaysDate = ("0" + todayObj.getDate()).slice(-2) + '/' + ("0"+(todayObj.getMonth()+1)).slice(-2) +'/' + todayObj.getFullYear();

    let saveData = {
      aid: aid,
      notes: notes,
      custRef: this.props.custRef,
      date:meetingDateFormatted,
      hasMeetingPassed:hasMeetingPassed
    };

    let thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/admin/agendas/update', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if (typeof data.aid != "undefined") {
        console.log('Request succeeded with JSON response', data);
		    thus.setState({hasMeetingPassed:false,aid: data.aid})
      } else {
        alert("There was an error updating your agenda. Please check the content and try again.");
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.getTokens();
      console.log('Request failed', error);
    });

  }

  // Load up the agenda content
  loadContent () {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/agendas/get_by_customer/' + this.props.custRef;
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          if (typeof data.aid != "undefined") {

			let meetingDate = data.meetingDate;
			let meetingDateFormatted = data.meetingDateFormatted;

			let meetingDateObj = new Date(data.meetingDateFormatted);
			let todayObj = new Date();

			let difference = todayObj.getTime() - meetingDateObj.getTime();

			let differenceInDays = Math.ceil((difference)/(24*3600*1000)) - 1;

			let hasMeetingPassed = false;

			if(differenceInDays >= 1){
				meetingDateFormatted = todayObj.getFullYear() + '-'+("0"+(todayObj.getMonth()+1)).slice(-2) +'-'+ ("0" + todayObj.getDate()).slice(-2);
				meetingDate = ("0" + todayObj.getDate()).slice(-2) + '/' + ("0"+(todayObj.getMonth()+1)).slice(-2) +'/' + todayObj.getFullYear();
				hasMeetingPassed = true;
			}



            thus.setState({
              meetingDateFormatted: meetingDateFormatted,
              meetingDate: meetingDate,
              notes: data.notes,
              aid: data.aid,
              createNew: false,
			  hasMeetingPassed:hasMeetingPassed
            });
          } else {
            thus.setState({
              meetingDate: "",
              aid: "",
              createNew: true
            });
          }

        }
      );

  }

  formatDate ( date ) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  toggleAgenda (e,notifyParentExpandedState) {

    var { minimised } = this.state;
    minimised = minimised ? false : true ;

    if(typeof notifyParentExpandedState !== "undefined"){
      notifyParentExpandedState(minimised)
    }

    this.setState({ minimised: minimised });
  }

  render() {

    const { meetingDate, meetingDateFormatted, notes, createNew, updateDate, minimised } = this.state;



    let notifyParentExpandedState  = this.props.notifyParentExpandedState;

    if (meetingDate === false) {
      if (!minimised) {
        return (
          <Draggable      >
            <div style={ styles.agendaBlock } className="agenda-block width-12 ">
              <h2>Agenda</h2>
              <div className='cf-content width-1 centred'>
                <div className="loader sml">Loading...</div>
              </div>
            </div>
          </Draggable>
        );
      } else {

        if(this.props.fixedLayout){
          return (
              <div style={ styles.agendaBlock } className="agenda-fixed-side agenda-fixed-side-closed closed width-12 ">
                <h2 className="no-border">
                  Agenda
                  <a href="#agenda" onClick={ (e) => this.toggleAgenda(e,notifyParentExpandedState) }>Open / close</a>
                </h2>
              </div>
          );
        }
        return (
          <Draggable      >
            <div style={ styles.agendaBlock } className="agenda-block closed width-12 ">
              <h2 className="no-border">
                Agenda
                <a href="#agenda" onClick={ (e) => this.toggleAgenda(e) }>Open / close</a>
              </h2>
            </div>
          </Draggable>
        );
      }
    }

    if ( (meetingDate === "" || createNew === true) && !minimised) {

      if(this.props.fixedLayout){
        return (
            <div style={ styles.agendaBlock } className="agenda-fixed-side width-12 ">
              <h2 className="no-border">Create Agenda<a href="#agenda" onClick={ (e) => this.toggleAgenda(e,notifyParentExpandedState) }>Open / close</a> </h2>
              <p>Please enter the date of the meeting below
                and select &quot;Create Agenda&quot;.</p>
              <DatePicker
                selected={ new Date(meetingDateFormatted) }
                onChange={ this.createAgendaDate }
                dateFormat="d/M/yyyy"
              />
              <div className="centre">
                <Btn type='button' title="Create Agenda" handleSubmission={ (e) => this.createAgenda(e) } btnStyle={ styles.btnNoFloat } btnStyleInner={ styles.btnInner } />
              </div>
            </div>
        );
      }

      return (
        <Draggable >
          <div style={ styles.agendaBlock } className="agenda-block width-12 ">
            <h2 className="">Create Agenda</h2>
            <p>Please enter the date of the meeting below
              and select &quot;Create Agenda&quot;.</p>
            <DatePicker
              selected={ new Date(meetingDateFormatted) }
              onChange={ this.createAgendaDate }
              dateFormat="d/M/yyyy"
            />
            <div className="centre">
              <Btn type='button' title="Create Agenda" handleSubmission={ (e) => this.createAgenda(e) } btnStyle={ styles.btnNoFloat } btnStyleInner={ styles.btnInner } />
            </div>
          </div>
        </Draggable>
      );
    }

    if ( (meetingDate === "" || createNew === true) && minimised && this.props.fixedLayout) {
      return (
        <div style={ styles.agendaBlock } className="rotate agenda-fixed-side agenda-fixed-side-closed closed width-12 ">
          <h2 className="no-border">
            Agenda
            <a href="#agenda" onClick={ (e) => this.toggleAgenda(e,notifyParentExpandedState) }>Open / close</a>
          </h2>
        </div>
      );
    }

    if (updateDate === true) {

      if(this.props.fixedLayout){
        return (
            <div style={ styles.agendaBlock } className="agenda-fixed-side width-12 ">
              <h2>Update date</h2>
              <p>Update the meeting date below:</p>
              <DatePicker
                selected={ new Date(meetingDateFormatted) }
                onChange={ this.updateMeetingDateState }
                dateFormat="d/M/yyyy"
              />
              <div className="centre">
                <Btn type='button' title="Update Date" handleSubmission={ (e) => this.updateMeetingDate(e) } btnStyle={ styles.btnNoFloat } btnStyleInner={ styles.btnInner } />
              </div>
            </div>
        );
      }

      return (
        <Draggable  >
          <div style={ styles.agendaBlock } className="agenda-block width-12 ">
            <h2>Update date</h2>
            <p>Update the meeting date below:</p>
            <DatePicker
              selected={ new Date(meetingDateFormatted) }
              onChange={ this.updateMeetingDateState }
              dateFormat="d/M/yyyy"
            />
            <div className="centre">
              <Btn type='button' title="Update Date" handleSubmission={ (e) => this.updateMeetingDate(e) } btnStyle={ styles.btnNoFloat } btnStyleInner={ styles.btnInner } />
            </div>
          </div>
        </Draggable>
      );
    }

    if (minimised) {

      if(this.props.fixedLayout){
        return (
          <div style={ styles.agendaBlock } className=" rotate agenda-fixed-side agenda-fixed-side-closed closed width-12 ">
            <h2 className="no-border">
              Agenda
              <a href="#agenda" onClick={ (e) => this.toggleAgenda(e,notifyParentExpandedState) }>Open / close</a>
            </h2>
          </div>
        );
      }

      return (
        <Draggable  >
          <div style={ styles.agendaBlock } className="agenda-block closed width-12 ">
            <h2 className="no-border">
              Agenda
              <a href="#agenda" onClick={ (e) => this.toggleAgenda(e) }>Open / close</a>
            </h2>
          </div>
        </Draggable>
      );

    } else {

      if(this.props.fixedLayout){
        return (
          <div className="agenda-fixed-side width-3 ">

            <div id="handle">

              <h2 className="no-border">
                Agenda
                <a href="#agenda" onClick={ (e) => this.toggleAgenda(e,notifyParentExpandedState) }>Open / close</a>
              </h2>
              
              <FpiTemplates
                qid="-"
                content={notes}
                updateContent={this.handleEditorChange}
                templateType="agenda"
                templateClass="agenda-sml"
              />
              <h3>Meeting</h3>
            </div>

            <ReactQuill value={ notes } onChange={this.handleEditorChange} onBlur={this.saveUpdate} modules={ modules } />

          </div>
        );
      }


      return (
        <Draggable handle="#handle">
          <div className="agenda-block width-12 ">

						<div id="handle">

	            <h2 className="no-border">
	              Agenda
	              <a href="#agenda" onClick={ (e) => this.toggleAgenda(e) }>Open / close</a>
              </h2>
              <FpiTemplates
                qid="-"
                content={notes}
                updateContent={this.handleEditorChange}
                templateType="agenda"
                templateClass="agenda-sml"
              />
	            <h3>Meeting</h3>
						</div>

            <ReactQuill value={ notes } onChange={this.handleEditorChange} onBlur={this.saveUpdate} modules={ modules } />

					</div>
        </Draggable>
      );

    }
  }
}

const styles = {
  btn: {
    float: 'right',
    marginTop: 5
  },
  btnNoFloat: {
    marginTop: 25
  },
  btnInner: {
    borderRadius: "20px",
    marginTop: 0,
    padding: "3px 12px",
    fontSize: 13,
    cursor: "pointer"
  },
  btnSml: {
    float: 'right',
    marginTop: 6,
    clear: "right"
  },
  btnInnerGrey: {
    borderRadius: "20px",
    marginTop: 0,
    padding: "3px 12px",
    fontSize: 11,
    cursor: "pointer",
    background: Colours.txtGrey
  }
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'strike'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
  ],
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(AgendaView);
