import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Colours from './../../assets/colours.js';

class Btn extends Component {


  render() {
    if (this.props.type === 'submit') {
      return (
        <div style={ styles.container }>
          <input style={{ ...styles.btn, ...this.props.btnStyle }} type='submit' onClick={ this.props.handleSubmission } value={ this.props.title } />
        </div>
      );
    } else if (this.props.type === 'button') {
      return (
        <div style={{ ...styles.container, ...this.props.btnStyle }}>
          <button style={{ ...styles.btn, ...this.props.btnStyleInner }} onClick={ this.props.handleSubmission }>{ this.props.title }</button>
        </div>
      );
    } else if (this.props.type === 'hidden') {
      return '';
    } else {
			let wrapStyle = (typeof this.props.wrapStyle != 'undefined') ? { ...styles.container, ...this.props.wrapStyle } : styles.container ;
      return (
        <div style={ wrapStyle }>
          <Link style={{ ...styles.btn, ...this.props.btnStyle }} to={ this.props.lnk }>{ this.props.title }</Link>
        </div>
      );
    }
  }
}

var styles = {
  container: {
    marginTop: 20,
    marginBottom: 15
  },
  btn: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    background: Colours.grn,
    color: Colours.txtWhite,
    maxWidth: 200,
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 22,
    paddingRight: 22,
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 16,
    border: 0,
    cursor: 'pointer',
    outline: 'none',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.1)'
  }
};

export default Btn;
