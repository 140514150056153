import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

import queryString from 'query-string';

import Btn from './../common/btn.js';
import Heading from './heading.js';
import Colours from './../../assets/colours.js';

import Fetching from './../../functions/fetching.js';

class Intro extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      canAccess: false,
      initName: "",
      loginRdr: false
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: 'MAXIMISE_HEADER' });
    this.checkValidity();
    this.checkForRdrError();
  }

  checkForRdrError () {
    // Get the query string from the URL
    var qsParams = this.props.location.search;
    qsParams = queryString.parse(qsParams);
    console.log(qsParams);
    // If we have the appropriare params then handle the login redirect
    if (typeof qsParams.code != 'undefined') {
      this.setState({ loginRdr: qsParams.code });
    }
  }

  checkValidity () {
    var { token, mash, tokenKey } = this.props.match.params;

    // If we have a token mash and key then store them to redux + possibly to cookie?!
    if (typeof token !== 'undefined' && typeof mash !== 'undefined' && typeof tokenKey !== 'undefined' && token !== '' && mash !== '' && tokenKey !== '') {

      this.props.dispatch({ type: 'SET_CUSTOMER_TOKENS', token: token, tokenKey: tokenKey, mash: mash });
      // Store the data to a cookie
      const { cookies } = this.props;
      var tokenData = { token: token, tokenKey: tokenKey, mash: mash };
      cookies.set('AshCli', tokenData, { path: '/'});
    } else if (this.props.customerToken === '' || this.props.customerTokenKey === '' || this.props.customerMash === '') {

      // We do not have anything from the URL and there is nothing in the state
      // So check for cookies
      const { cookies } = this.props;
      var cookieData = cookies.get('AshCli');
      console.log(cookieData);
      if (typeof cookieData !== 'undefined' && typeof cookieData.token !== 'undefined' && typeof cookieData.mash !== 'undefined' && typeof cookieData.tokenKey !== 'undefined' && cookieData.token !== '' && cookieData.mash !== '' && cookieData.tokenKey !== '') {
        this.props.dispatch({ type: 'SET_CUSTOMER_TOKENS', token: cookieData.token, tokenKey: cookieData.tokenKey, mash: cookieData.mash });
        token = cookieData.token;
        tokenKey = cookieData.tokenKey;
        mash = cookieData.mash;
      } else {
        this.setState({ isLoading: false });
      }
    }

    // Now that we have the tokens run a check to make sure it is a valid submission
    const thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/customer_submissions/check/' + token + '/' + mash + '/' + tokenKey, { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function(data) {
        if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
          thus.setState({ canAccess: true, initName: data.initName, isLoading: false });
          thus.props.dispatch({ type: 'SET_NAME', name: data.initName });
        } else {
          thus.setState({ isLoading: false });
        }
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });

  }

  render() {

    const { canAccess, initName, isLoading, loginRdr } = this.state;

    if (loginRdr) {
      return <Redirect to={"/admin?code=" + loginRdr} />
    }

    if (isLoading) {
      return (
        <div className='content'>
          <Heading mainTitle="Update Your Details" />
          <div className='width-6' style={{ ...styles.container, ...styles.pbtm }}>
            <div className='width-1 centred'>
              <div className="loader whiteBg">Loading...</div>
            </div>
          </div>
        </div>
      );
    }

    // Check whether we should display the standard intro
    if (canAccess) {

      return (
        <div className='content'>
          <Heading mainTitle="Update Your Details" subTitle={ initName } />
          <div className='width-6' style={ styles.container }>
            <p className='intro'>In order to advise you appropriately we we want
            to make sure that we have sufficient information regarding you, your
            family and your financial situation. Could you please follow the link
            below which will take you to an online questionnaire. It is important
            that we maintain accurate information, therefore please amend the details
            by either correcting any errors or adding further information where
            appropriate.</p>
            <p>The questionnaire is broken down into a number of sections. Please
            click &quot;save &amp; continue&quot; to work your way through the form,
            completing each section as you go.</p>
            <p>When you&rsquo;ve finished please click the &quot;Submit&quot; button.</p>
            <p>If you have any questions please do not hesitate to contact us on 01206 632006.</p>
            <p>Thank you</p>

            <Btn title="Get started" lnk={ "/questionnaire" } />
          </div>
        </div>
      );

    } else {
      return (
        <div className='content'>
          <Heading mainTitle="Update Your Details" />
          <div className='width-6' style={ styles.container }>
            <p className='intro'>Welcome to the Ashworth FP client portal. Unfortunately
            to access your data you will need to be granted access.</p>
            <p>If you are already an Ashworth FP client, then please request access
            via your account manager.</p>
            <p>If you wish to find out more about becoming an Ashworth FP client, please do not hesitate to contact us on 01206 632006.</p>
            <p>Thank you</p>

          </div>
        </div>
      );
    }
  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 35,
    marginBottom: 125,
    padding: 25
  },
  pbtm: {
    paddingBottom: 80
  }
};

function mapStateToProps(state) {
  return {
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash,
    initName: state.customer.initName,
    minimalHeader: state.minimalHeader
  };
}

export default withCookies(connect(mapStateToProps)(Intro));
