import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Heading from './../heading.js';
import Btn from './../../common/btn.js';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';
import CustomerCommunicationDropdown from './customer-communication-dropdown.js';

class CustomerCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uid: this.props.match.params.userRef,
      initName: "",
      existingCustomer:{},
      canShowDropdown:true
    };

    this.handleEntry = this.handleEntry.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
    this.handleExternalClientSelected = this.handleExternalClientSelected.bind(this);
  }

  handleEntry ( event ) {
    let canShowEntry = (event.target.value.length > 0) ? true : false ;
    this.setState({ [event.target.name]: event.target.value, existingCustomer: {}, canShowDropdown: canShowEntry });
  }

  // handleExistingClientNameChange(){

  // }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Handle the submission of a user
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/create';
    var initName = encodeURIComponent(this.state.initName);
    var existingCustomer = this.state.existingCustomer;
    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ initial_name: initName,existingCustomer:existingCustomer}) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Set the updated state
        thus.setState({ rslt: 'The client account has been successfully created', rsltType: 'success' });
      } else {
        // Handle the failure by showing a failure message
        var msg = "<p>Sorry but there was an error creating the client's details. Please check the errors below and try again.</p><ul>";
        for ( var i in data.errors) {
          for (var j in data.errors[i]) {
            msg += "<li>" + data.errors[i][j].message + "</li>";
          }
        }
        msg += "</ul>";
        thus.setState({ rslt: msg, rsltType: 'error' });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  // Fetch the user data
  componentDidMount () {
    this.getTokens();
  }

  handleExternalClientSelected(existingCustomer){

    this.setState({existingCustomer,initName:existingCustomer.initial_name,canShowDropdown:false});
  }


  render() {

    return (
      <div className='width-9 centred'>
        <Link style={ styles.lnkRght } to={ `/admin/clients` }>Back</Link>
        <Heading mainTitle="Create Client" subTitle="Create a new client account" />

        <div className={ "mbtm " + this.state.rsltType } style={ styles.full } dangerouslySetInnerHTML={{ __html: this.state.rslt }}></div>

        <form>
          <div className='full-input'>
            <label htmlFor="first" style={ styles.full }>Client name(s)</label>
            <input type="text" name="initName" id="first" value={ this.state.initName } onChange={ this.handleEntry } style={ styles.full } />
          </div>
          <CustomerCommunicationDropdown 
            usrFirst={ this.state.initName } 
            existingCustomer={this.state.existingCustomer} 
            handleExternalClientSelected={this.handleExternalClientSelected}
            canShowDropdown={this.state.canShowDropdown}
          />
          <Btn title="Create" lnk="/questionnaire" type="submit" handleSubmission={ this.handleSubmission } />
        </form>

        

      </div>
    );
  }
}

var styles = {
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  full: {
    marginLeft: '0.5%',
    marginRight: '0.5%',
    width: '99%'
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(CustomerCreate);
