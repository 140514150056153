import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';
import ProgressSml from './progress-bar.js';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';
import Paginate from './../../common/paginate.js';

import CFFilter from './cf-filter.js';
import EmailGroupsToggle from './email-groups-toggle.js';

class CustomersList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      isLoading: true,
      rslt: "",
      rsltType: "",
      paramList: {},
      email_groups:[],
      filterList: {"status":['active','prospect']},
      rdr: false
    };

    this.getTokens = this.getTokens.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.loadEmailGroups = this.loadEmailGroups.bind(this);
  }

  initialiseComponent (pageNo, useProps, isParamsUpdate) {

    if (typeof isParamsUpdate == 'undefined') {
      isParamsUpdate = false;
    }

    var thus = this;

    if (typeof pageNo == 'undefined' || pageNo === "" || isParamsUpdate) pageNo = 1;
    var orderStr = this.returnParam(useProps.match.params.filterParams, "order");
    if (orderStr !== "") orderStr = "&order=" + orderStr;

    // Check whether we have any parameters set
    let filterList = this.state.filterList;


// TODO - upon load of a new param reset the pageNo in URL etc to be page 1... how?!?!

    if (Object.keys(filterList).length === 0) {

      fetch(process.env.REACT_APP_API_BASE + '/admin/customers?page=' + pageNo + orderStr, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            // Check whether we have a failure
            if (typeof data.error !== 'undefined' && data.error === 'auth') {

              // Redirect to the error page
              thus.setState({ error: true });
              return false;

            } else {
              // Process the data for display of the users
              thus.setState({ customers: data, isLoading: false });
              return data;
            }
          }
        );

    } else {

      fetch(process.env.REACT_APP_API_BASE + '/admin/customers?page=' + pageNo + orderStr, {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        credentials:"include",
        body: 'data=' + JSON.stringify(filterList) + '&' + this.props.tokenKey + '=' + this.props.token
      })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            // Check whether we have a failure
            thus.getTokens();
            if (typeof data.error !== 'undefined' && data.error === 'auth') {

              // Redirect to the error page
              thus.setState({ error: true });
              return false;

            } else {
              // Process the data for display of the users
              thus.setState({ customers: data, isLoading: false });
              return data;
            }
          }
        );

    }
  }

  updateFilter(e, cfId, fieldType) {

    // Work out the filter value
    // N.B. This switch statement is pretty pointless at the moment, but would be useful if there was a checkbox style of field
    var fieldVal;
    switch (fieldType) {
      case "radio":
        fieldVal = e.target.value;
        break;
      case "select":
        fieldVal = e.target.value;
        break;
      case "multi-select":
        fieldVal =  [...e.target.options].filter(o => o.selected).map(o => o.value) ;
        break;
      case "cb":
        fieldVal = e.target.checked;
        break;
      default:
        fieldVal = e.target.value;
        break;
    }

    // Update the filtered list - by adding hte params to POST request
    var filterList = this.state.filterList;

    if (fieldVal !== "") {
      filterList[cfId] = fieldVal;
    } else {
      if (typeof filterList[cfId] != 'undefined') {
        delete filterList[cfId];
      }
    }

    this.setState({ filterList: filterList });

    var pageNo = this.returnParam(this.props.match.params.filterParams, "page");
    if (typeof pageNo == 'undefined') {
      pageNo = 1;
    }
    this.initialiseComponent(pageNo, this.props, true);

  }

  extractParams ( paramList ) {

    var retLst = {};

    if (typeof paramList != 'undefined') {
      paramList = paramList.split('&');

      var curParam;
      for (var i in paramList) {
        curParam = paramList[i].split(':');
        retLst[curParam[0]] = {
          'pname': curParam[0],
          'value': curParam[1]
        };
      }
    }

    this.setState({ paramList: retLst });

    return retLst;
  }

  returnParam (filterParams, desired) {
    let paramsLst = this.extractParams(filterParams);
    return (typeof paramsLst[desired] != 'undefined' && typeof paramsLst[desired].value != 'undefined') ? paramsLst[desired].value : "" ;
  }

	archiveToggle (e, custRef) {
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/customers/archive_toggle/' + custRef, { 
      method: 'post', 
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({'status' :e.target.value}) 
    })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
				if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
					// Success
					thus.initialiseComponent("", thus.props, false);
				} else {
					// Error
					alert(data.msg);
				}
			})
      .catch(function (error) {
        console.log('Request failed', error);
      });
	}

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  loadEmailGroups(){
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/email_groups';
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          thus.setState({ email_groups: data.data.email_groups });

        }
      );
  }

  componentWillReceiveProps(nextProps){
     //call your api and update state with new props
     if(nextProps.location.pathname !== this.props.location.pathname){
      this.initialiseComponent(this.returnParam(nextProps.match.params.filterParams, "page"), nextProps);
     }
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {
    this.loadEmailGroups();
    // Check for pagination
    var pageNo = this.returnParam(this.props.match.params.filterParams, "page");
    if (typeof pageNo == 'undefined') {
      pageNo = 1;
    }

    let newFilterListState;

    if(typeof this.props.location.review_month !== 'undefined' || typeof this.props.location.interim_review_month !== 'undefined'){
      newFilterListState = {...this.state.filterList};
      newFilterListState[this.props.location.filter_key] = this.props.location.filter_value;
      this.setState({filterList:newFilterListState});
    }

    this.initialiseComponent(pageNo, this.props);

    this.getTokens();

  }

  

  render() {

    const { customers, isLoading, rslt, rsltType, paramList,email_groups } = this.state;

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if (!isLoading) {

      let theBase = "/admin/clients/";
      var baseUrl = { "standard": theBase };
      for ( var key in paramList ) {
        baseUrl["minus-" + key] = theBase;
        for ( var dkey in paramList ) {
          baseUrl["standard"] += dkey + ":" + paramList[dkey].value + "&";
          if (key !== dkey) {
            baseUrl["minus-" + key] += dkey + ":" + paramList[dkey].value + "&";
          }
        }
        baseUrl["minus-" + key] += key + ":";
      }

      if (typeof customers.rslt !== 'undefined' && customers.rslt === "empty") {

        content = <div style={ styles.centred }>
                    <p style={ styles.empty }>Sorry but there are no clients meeting your current criteria.</p>
                  </div>

      } else {

        content = <div className="mbtm-lrg">
                    <div className={ "mbtm mtop " + rsltType } dangerouslySetInnerHTML={{ __html: rslt }}></div>
                    <table>
                      <thead>
                        <tr>
                          { customers.defaultCfs.indexOf("Client name") > -1 ?
                            (
                              <th>
                                <Link to={ typeof baseUrl['minus-order'] != 'undefined' ? baseUrl['minus-order'] + 'client' : baseUrl['standard'] + "order:client" } style={ styles.thLnk }>Client name</Link>
                              </th>
                            ) : ( null )
                          }
                          { customers.defaultCfs.indexOf("Reference") > -1 ?
                            (
                              <th className="hide1">
                                <Link to={ typeof baseUrl['minus-order'] != 'undefined' ? baseUrl['minus-order'] + 'reference' : baseUrl['standard'] + "order:reference" } style={ styles.thLnk }>Reference</Link>
                              </th>
                            ) : ( null )
                          }
                          { customers.cfs.map( cf =>
                            <th key={ cf.id } className="hide2">{ cf.label }</th>
                          )}
                          { customers.defaultCfs.indexOf("Created") > -1 ?
                            (
                              <th className="hide1">
                                <Link to={ typeof baseUrl['minus-order'] != 'undefined' ? baseUrl['minus-order'] + 'created' : baseUrl['standard'] + "order:created" } style={ styles.thLnk }>Created</Link>
                              </th>
                            ) : ( null )
                          }
                          { customers.defaultCfs.indexOf("Progress") > -1 ?
                            (
                              <th className="hide3">Progress</th>
                            ) : ( null )
                          }
                          <th>&nbsp;</th>
                          <th className="hide3">&nbsp;</th>
                          <th className="hide3">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        { customers.customers.map( customer =>
                          <tr key={ customer.public_id }>
                            { customers.defaultCfs.indexOf("Client name") > -1 ?
                              (
                                <td>{ customer.initial_name }</td>
                              ) : ( null )
                            }
                            { customers.defaultCfs.indexOf("Reference") > -1 ?
                              (
                                <td className="hide1">{ customer.public_id }</td>
                              ) : ( null )
                            }
                            { customers.cfs.map( cf =>
                              <td key={ "cftd-" + cf.id + "-" + customer.public_id } className="hide2">{ typeof customer.cfs != 'undefined' && typeof customer.cfs[Number(cf.id)] != 'undefined' ? customer.cfs[cf.id] : "" }</td>
                            )}
                            { customers.defaultCfs.indexOf("Created") > -1 ?
                              (
                                <td className="hide1">{ customer.created }</td>
                              ) : ( null )
                            }
                            { customers.defaultCfs.indexOf("Progress") > -1 ?
                              (
                                <td className="hide3">
                                  <ProgressSml customer={ customer } />
                                </td>
                              ) : ( null )
                            }
                            <td>
                              <Link style={ styles.fauxBtn } to={ `/admin/clients/details/` + customer.public_id }>View</Link>
                              <Link style={ {...styles.fauxBtn, ...styles.export} } to={ `/admin/clients/transact/` + customer.public_id }>Investments</Link>
                              <a style={{ ...styles.fauxBtn, ...styles.export }} href={ process.env.REACT_APP_API_BASE + '/admin/questionnaire_Sections/generate_pdf/' + customer.public_id + '/0/--' } target='_blank' rel="noopener noreferrer">Export</a>
                            </td>
                            <td>
                              <EmailGroupsToggle custRef={customer.public_id } email_groups={email_groups} customer_email_groups={customer.email_groups}/>
                              {/* <Link style={ {...styles.fauxBtn, ...styles.export} } to={ `/`}>Email Groups</Link> */}
                              
                            </td>
                            <td className="hide3">
                              <select  id={ 'qStatus-'+customer.public_id } defaultValue={ customer.status } style={ styles.archiveStatus } onChange={ (e) => this.archiveToggle(e, customer.public_id) }>
                                <option value="active">Active</option>
                                <option value="archived">Archived</option>
                                <option value="prospect">Prospect</option>
                              </select>
                              <Link style={ styles.lnk } to={ `/admin/clients/delete/` + customer.public_id }>Delete</Link>
                            </td>
                            
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Paginate urlBase={ typeof baseUrl['minus-page'] != 'undefined' ? baseUrl['minus-page'] : baseUrl.standard + "page:" } totalPages={ customers.totalPages } currentPage={ customers.currentPage }  nextPage={ customers.nextPage } prevPage={ customers.prevPage } />
                  </div>

      }
    }

    return (
      <div className='width-12 centred'>
        <Link style={{ ...styles.lnkRght, ...styles.grey }} to={ `/admin/investment-import` }>Transact Import</Link>
        <a style={{ ...styles.lnkRght, ...styles.grey }} href={ process.env.REACT_APP_API_BASE +`/admin/files/csv_export` }>CSV Export</a>
        <Link style={{ ...styles.lnkRght, ...styles.grey }} to={ `/admin/custom-fields` }>Custom fields</Link>
        
        <Link style={ styles.lnkRght } to={ `/admin/clients/create` }>Create Client</Link>
        <Heading mainTitle="Manage Clients" subTitle="View, create and edit the client accounts" />

        <CFFilter updateFilter={ this.updateFilter } location={this.props.location} email_groups={email_groups}/>

        <FlashMsg />

        { content }

      </div>
    );
  }
}

var styles = {
  lnk: {
    color: Colours.txtBlack,
    display: 'inline-block',
    marginLeft: 12
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  thLnk: {
    color: Colours.txtBlack,
    textDecoration: 'none'
  },
  grey: {
    background: Colours.txtGrey,
    borderColor: Colours.txtGrey,
  },
  fauxBtn: {
    background: Colours.grn,
    color: Colours.txtWhite,
    borderRadius: 20,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5
  },
  topMrgn: {
    marginTop: 125
  },
  centred: {
    textAlign: 'center'
  },
  empty: {
    color: Colours.red,
    marginBottom: 25,
    fontStyle: 'italic'
  },
  export: {
    color: Colours.txtWhite,
    background: Colours.txtGrey,
    marginTop: 2
  },
  archiveStatus:{
    width:'50%',
    display:'inline-block',
    fontSize:14,
    marginLeft:16,
    marginRight:4
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(CustomersList);
