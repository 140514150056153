import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';
import Autocomplete from './../common/autocomplete.js';

import Heading from './../heading.js';

import Btn from './../../common/btn.js';

class EmailCompose extends Component {

	constructor(props) {
    super(props);

    this.state = {
			emailId: false,
			editorReady: false,
			subject: "",
			sender: false,
			recipients: [],
			attachments: [],
			defaultAttachments: [],
			msg: "",
			rdr: false,
			loading: false,
			fileOptions: [],
			senderSuggestions: [],
			recipientSuggestions: [],
			error: "",
			success: ''
    };

		this.selectRecipient = this.selectRecipient.bind(this);
		this.selectSender = this.selectSender.bind(this);
		this.removeRecipient = this.removeRecipient.bind(this);
		this.removeSender = this.removeSender.bind(this);
		this.sendToAll = this.sendToAll.bind(this);
		this.saveDraft = this.saveDraft.bind(this);
		this.sendEmail = this.sendEmail.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
	}

	// On component mount load up the users, which triggers email loading
	componentWillMount () {
		this.getTokens();
		this.loadDraft();
		this.getAdmins();
		this.getCustomers();
		this.getDocuments();
	}

	getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

	// Get a list of potential senders + use it to populate the senders autocomplete
	getAdmins () {
		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/users/email_usrs/';
		var thus = this;
		fetch(fetchUrl, { credentials:"include" })
			.then(response => response.json())
			.then(
				function (data) {

					if (typeof data.rslt != "undefined" && data.rslt === 'success') {
						thus.setState({
							senderSuggestions: data.data
						});
					}

				}
			);
	}

	// Get a list of potential recipients + use it to populate the recipients autocomplete
	getCustomers () {
		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/emails_list/1';
		var thus = this;
		fetch(fetchUrl, { credentials:"include" })
			.then(response => response.json())
			.then(
				function (data) {

					if (typeof data.rslt != "undefined" && data.rslt === 'success') {
						thus.setState({
							recipientSuggestions: data.data
						});
					}

				}
			);
	}

	// Get a list of potential attachment documents
	getDocuments () {
		var thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/admin/files/list/-/-/-', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          // Check whether we have a failure
          if (typeof data.error == 'undefined') {
            // Redirect to the error page
            thus.setState({ fileOptions: data });

          }
        }
      );
	}

	// Select the senders from list
	selectSender ( id, name ) {
		this.setState({ sender: [{ id: id, name: name }] });
	}

	removeSender ( id, name ) {
		this.setState({ sender: false });
	}

	selectRecipient ( id, name ) {

		// Get the existing recipients
		let { recipients,recipientSuggestions } = this.state;
		var newRecipients = [];
		var isIncluded = false;
		let i;


		var match = /\g-/.exec(id); // eslint-disable-line
		if(match && match.index === 0){
			//Dealing with an email group,
			let customerIds = [];
			// so find the ids
    		for ( i in recipientSuggestions ) {
    			if ( recipientSuggestions[i].id === id ) {
    				customerIds = recipientSuggestions[i]['customer_ids'];
    			}
    		}
			//then get the
			for ( i in recipientSuggestions ) {
				for(var j = 0;j<customerIds.length;j++){
					if(customerIds[j] === recipientSuggestions[i].id){
						newRecipients.push({ id:recipientSuggestions[i].id, name: recipientSuggestions[i].name });
					}
				}
    		}
		} else {

			//Single Email address selected

			// Check whether the existing is already in the recipients array
			for ( i in recipients ) {
				newRecipients.push(recipients[i]);
				if ( recipients[i].id === id ) {
					isIncluded = true;
				}
			}

			// If the recipient is not in the recipients array then add it
			if (!isIncluded) {
				newRecipients.push({ id: id, name: name });
			}
		}



		this.setState({ recipients: newRecipients });
	}

	sendToAll () {

		let { recipientSuggestions } = this.state;

		let singleEmailRecipients = [];

		for(let i = 0; i<  recipientSuggestions.length;i++){
			if(!recipientSuggestions[i].is_email_group){
				singleEmailRecipients.push(recipientSuggestions[i]);
			}
		}
		this.setState({ recipients: singleEmailRecipients});

	}

	removeRecipient ( e ) {

		e.preventDefault();

		// Get the recipient ID
		let custId = e.target.getAttribute('data-id');

		// Get the recipients list
		let { recipients } = this.state;
		var newRecipients = [];

		// Check whether the existing is already in the recipients array
		for ( var i in recipients ) {
			if ( custId !== recipients[i].id ) {
				newRecipients.push(recipients[i]);
			}
		}

		this.setState({ recipients: newRecipients });
	}

	// Handle the update of the editor
	handleEditorChange ( content ) {
		this.setState({ msg: content });
	}

	// Add attachments
	addAttachment ( e ) {

		// Check whether it is checked
		let isChecked = e.target.checked;

		// Get the list of current attachments
		let { attachments } = this.state;
		var newAttachments = [];
		var isUnset = false;

		// Loop through the attachments
		for ( var i in attachments ) {
			if (attachments[i].id === e.target.value) {
				isUnset = true;
			} else {
				newAttachments.push(attachments[i]);
			}
		}

		// Check whether this should be added in
		if (!isUnset && isChecked) {
			newAttachments.push({ id: e.target.value });
		}

		this.setState({ attachments: newAttachments });
	}

	// Update subject in state
	updateSubject (e) {
		this.setState({ subject: e.target.value });
	}

	formatForFetch ( usrList ) {

		var newLst = [];
		for ( var i in usrList ) {
			newLst.push({
				id: usrList[i].id,
				name: encodeURIComponent(usrList[i].name)
			});
		}

		return newLst;

	}

	// Save as draft
	saveDraft (e) {

		e.preventDefault();

		// Set up the data for the submission
		let { emailId, subject, msg, sender, recipients, attachments } = this.state;

		let newRecipients = this.formatForFetch(recipients);
		let newSender = this.formatForFetch(sender);

		var saveData = {
			emailId: emailId,
			sender: newSender,
			recipients: newRecipients,
			subject: subject,
			msg: msg,
			attachments: attachments
		}

		// Run the save enquiry
		let thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/user_emails/save', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if (typeof data.eid != "undefined") {
        thus.setState({ success: "Your email has been saved as a draft.", error: "", emailId: data.eid });
      } else {
				thus.setState({ success: "", error: "There was an error saving your message. Please check the content and try again." });
      }
			thus.getTokens();
			window.scrollTo(0, 0);
    })
    .catch(function (error) {
      thus.getTokens();
			thus.setState({ success: "", error: "There was an error storing your draft. Please check the content and try again." });
			window.scrollTo(0, 0);
	  });

	}

	// Load up draft on page load (if necessary)
	loadDraft () {

		// Check whether we are viewing a draft
		if ( typeof this.props.match.params.emailId != 'undefined' ) {

			// Set the email ID in the state
			this.setState({ emailId: this.props.match.params.emailId });

			// Fetch the email details
			var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/user_emails/get/' + this.props.match.params.emailId;
			var thus = this;
			fetch(fetchUrl, { credentials:"include" })
				.then(response => response.json())
				.then(
					function (data) {

						if (typeof data.rslt != "undefined" && data.rslt === 'success') {

							// Set the state
							thus.setState({
								emailId: thus.props.match.params.emailId,
								subject: data.data.subject,
								sender: data.data.sender,
								recipients: data.data.recipients,
								attachments: data.data.attachmentsRaw,
								defaultAttachments: data.data.attachments,
								msg: data.data.body,
								editorReady: true
							});
						} else {
							thus.setState({ emailId: false, editorReady: true });
						}

					}
				);

		} else {
			this.setState({ editorReady: true });
		}

	}

	// Send the email
	sendEmail ( e ) {
		e.preventDefault();

		// Set up the data for the submission
		let { emailId, subject, msg, sender, recipients, attachments } = this.state;
		var saveData = {
			emailId: emailId,
			sender: this.formatForFetch(sender),
			recipients: this.formatForFetch(recipients),
			subject: subject,
			msg: encodeURIComponent(msg),
			attachments: attachments
		}

		// Run the save enquiry
		let thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/user_emails/send', {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			if (typeof data.eid != "undefined") {
				thus.setState({ success: "Your email has been sent successfully.", rdr: "/admin/emails" });
			} else {
				thus.setState({ success: "", error: "There was an error sending your message. Please check the content and try again." });
				window.scrollTo(0, 0);
			}
			thus.getTokens();
		})
		.catch(function (error) {
			thus.getTokens();
			thus.setState({ success: "", error: "There was an error sending your message. Please check the content and try again." });
			window.scrollTo(0, 0);
		});
	}

	// Render the view
  render() {

		let { rdr, loading, msg, senderSuggestions, sender, recipientSuggestions, recipients, fileOptions, error, success, subject, defaultAttachments, editorReady } = this.state;

		if ( rdr ) {
			return (
				<Redirect to={ rdr } />
			);
		}

		if ( loading ) {
			return (
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
		}

		var editor = "";
		if ( editorReady ) {
			editor = <CKEditor
						editor={ClassicEditor}
						data={msg}
						onChange={(event, editor) => {
						const data = editor.getData();
							this.handleEditorChange(data)
						}}
						config={{ toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'Indent', 'Outdent', '|', 'undo', 'redo'] }}
					/>
		}

		var flashContent = "";
		if ( success !== "" ) {
			flashContent = <div className='success mbtm'>{ success }</div>
		}
		if ( error !== "" ) {
			flashContent = <div className='error mbtm'>{ error }</div>
		}

		recipientSuggestions = recipientSuggestions.map((recipient) => {
			if(recipient.is_email_group === true){
				recipient.style = {fontWeight:'bold',fontStyle:'italic'}
			}

			return recipient;
		})

    return (
      <div className='content'>

				<div className="width-6 centred email-compose lrg-txt">
					<Heading mainTitle="Compose Bulk Email" />

					{ flashContent }

					<Autocomplete
						id="1"
						label="Sender"
						suggestions={ senderSuggestions }
						selection={ this.selectSender }
						chosen={ sender }
						removal={ this.removeSender }
					/>

					<Btn
						type='button'
						title="Select All"
						handleSubmission={ this.sendToAll }
						btnStyleInner={{ ...styles.greyBtn, ...styles.rounded, ...styles.smlBtn }}
						btnStyle={ styles.wrap }
					/>
					<Autocomplete
						id="2"
						label="Recipient(s)"
						suggestions={ recipientSuggestions }
						selection={ this.selectRecipient }
						chosen={ recipients }
						removal={ this.removeRecipient }
					/>

					<div style={ styles.mrgn }>
						<label htmlFor="subject">Email subject</label>
						<input type="text" id='subject' defaultValue={ subject } onChange={ (e) => this.updateSubject(e) } style={ styles.pad } />
					</div>

					<div style={ styles.mrgn } className='bulk-email-container'>
						<label>Message content</label>
						{ editor }
					</div>

					<div className="attachments">
						<h6>Attachments (sent as unique links)</h6>

						{ fileOptions.map ( (opt, i) =>
							<div className="checkbox half" key={ "opt-" + i }>
								<input type="checkbox" value={ opt.id } id={ "cbopt-" + opt.id } onChange={ (e) => this.addAttachment(e) } defaultChecked={ defaultAttachments.indexOf(opt.id) > -1 || defaultAttachments.indexOf(opt.id.toString()) > -1 ? true : false } />
								<label htmlFor={ "cbopt-" + opt.id }>{ opt.file_name } <span>(Uploaded: { opt.createdFormatted })</span></label>
							</div>
						)}
					</div>

					<Btn
						type='button'
						title="Send message"
						handleSubmission={ (e) => this.sendEmail(e) }
						btnStyleInner={{ ...styles.rounded }}
						btnStyle={ styles.wrap }
					/>
					<Btn
						type='button'
						title="Save draft"
						handleSubmission={ (e) => this.saveDraft(e) }
						btnStyleInner={{ ...styles.greyBtn, ...styles.rounded }}
						btnStyle={ styles.wrap }
					/>

				</div>

      </div>
    );
  }
}



const styles = {
	mrgn: {
		marginLeft: "1%",
		marginRight: "1%",
		width: '98%'
	},
	pad: {
		padding: '5px 8px',
		fontSize: '0.9em'
	},
	wrap: {
		float: 'right',
		marginLeft: 12,
		marginTop: 0
	},
	greyBtn: {
		backgroundColor: Colours.txtGrey
	},
	smlBtn: {
		fontSize: 12,
		float: 'right',
		padding: '2px 12px',
		marginTop: 12
	},
	rounded: {
		borderRadius: 25,
	}
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(EmailCompose);
