import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cfs: [],
      isLoading: true
    };

  }

  initialiseComponent () {
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/custom_fields', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {
            // Process the data for display of the users
            thus.setState({ cfs: data.cfs, isLoading: false });
            return data;
          }
        }
      );
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {

    this.initialiseComponent();

  }

  render() {

    const { cfs, isLoading } = this.state;

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if (!isLoading) {

      content = <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Custom Field</th>
                        <th className="hide2">Field type</th>
                        <th className="hide2">Compulsory?</th>
                        <th className="hide1">Display in table?</th>
                        <th className="hide1">Created</th>
                        <th className="hide3">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      { cfs.map( cf =>
                        <tr key={ cf.id }>
                          <td>{ cf.label }</td>
                          <td>{ cf.type }</td>
                          <td>{ cf.is_required === "1" ? "Yes" : "No" }</td>
                          <td>{ cf.in_table === "1" ? "Yes" : "No" }</td>
                          <td>{ cf.created }</td>
                          <td>
                            <Link style={ styles.lnk } to={ `/admin/custom-fields/edit/` + cf.id }>Edit</Link>
                            { cf.type !== "default" ? (
                              <Link style={ styles.lnk } to={ `/admin/custom-fields/delete/` + cf.id }>Delete</Link>
                            ) : ( "" )
                            }
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
    }


    return (
      <div className='width-9 centred'>

        <Link style={{ ...styles.lnkRght, ...styles.grey }} to={ `/admin/clients` }>Back</Link>
        <Link style={ styles.lnkRght } to={ `/admin/custom-fields/create` }>Create field</Link>
        <Heading mainTitle="Manage Custom fields" subTitle="View, create and edit the custom fields" />

        <FlashMsg />

        { content }

      </div>
    );
  }
}

var styles = {
  lnk: {
    color: Colours.txtBlack,
    display: 'inline-block',
    marginLeft: 12
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  grey: {
    background: Colours.txtGrey,
    borderColor: Colours.txtGrey,
  }
};

export default List;
