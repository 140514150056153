import React, { Component } from 'react';

import Heading from './heading.js';
import Colours from './../../assets/colours.js';

class Thanks extends Component {

  render() {

    return (
      <div className='content'>
        <Heading mainTitle="Thanks for your submission" />
        <div className='width-6' style={ styles.container }>
          <p className='intro'>Thanks for submitting your questionnaire.</p>
          <p>We have sent it to the team at Ashworth Financial Planning.</p>
          <p>Thank you</p>

        </div>
      </div>
    );

  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 35,
    marginBottom: 125,
    padding: 25
  }
};


export default Thanks;
