import React, { Component } from 'react';
import { connect } from 'react-redux';

class FlashMsg extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adminSuccess: "",
      adminError: "",
    }
  }

  componentDidMount() {
    this.initialise();
  }

  componentWillReceiveProps(nextProps){
    this.initialise();
  }

  initialise () {

    if (this.props.adminSuccess !== '') {
      this.setState({ adminSuccess: this.props.adminSuccess });
      this.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: '' });
    }

    if (this.props.adminError !== '') {
      this.setState({ adminError: this.props.adminError });
      this.props.dispatch({ type: 'SET_ADMIN_ERROR', error: '' });
    }

  }

  render() {
    return (
      <div>
        <div className='error mbtm' style={ styles.smlr }>{ this.state.adminError }</div>
        <div className='success mbtm' style={ styles.smlr }>{ this.props.adminSuccess }</div>
      </div>
    );
  }
}

var styles = {
  smlr: {
    width: '99%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
  }
};

function mapStateToProps(state) {
  return {
    adminError: state.errors.adminError,
    adminSuccess: state.errors.adminSuccess,
  };
}

export default connect(mapStateToProps)(FlashMsg);
