import React, { Component, Fragment } from 'react';
import Colours from '../../../assets/colours.js';
import DisplayComposeDropdown from './display-compose-dropdown.js'
import Chevron from './../../../assets/img/chevron-down.svg';

class DisplayComposeOptionDropdown extends Component {

  constructor(props){
    super(props);
    this.state = {
      isDropdownOpen:false
    }
    this.focusRef = React.createRef();
    this.handleDropDownBlur  = this.handleDropDownBlur.bind(this);
  }

  handleDropDownBlur(e){
   if(e.relatedTarget == null){
    this.setState({
      isDropdownOpen:false
    })
   }
  }

  toggleDropdownDisplay(e){
    let { isDropdownOpen } = this.state;
    let newState = (!isDropdownOpen) ? true : false;
    this.setState({
      isDropdownOpen: newState
    })
  }


  render() {

    const dropdown = this.state.isDropdownOpen ? <DisplayComposeDropdown handleDropDownBlur={this.handleDropDownBlur} {...this.props} /> : null;

    return (
      <Fragment  >
        <div style={styles.container} className='right '>
        <button
          className='btn grn'
          style={{ ...styles.round, ...styles.displayOptions}}
          onClick={(e) => this.toggleDropdownDisplay()}
        >
          Create 
        </button>

      {dropdown}
      </div>
      </Fragment>
    );
  }
}

export default DisplayComposeOptionDropdown;

var styles = {
  container:{
    position:"relative",
    overflowY:"visible",
    display:'inline-block',
    zIndex:'999',
    paddingTop:'0',
    marginTop:48,
  },
  round: {
    borderRadius: 25,
    display: 'inline-block',
    margin: "0 0 0 18px",
    padding: "5px 12px",
    fontSize: 16,
		border: 'none'
  },
	displayOptions: {
    marginLeft: 10,
    width:140,
    color: Colours.txtWhite,
    textDecoration: 'none',
    paddingRight: 15,
    paddingLeft: 15,
    cursor: 'pointer',
    fontWeight:300,
    background: Colours.grn + " url('" + Chevron + "') right 12px center / 16px auto no-repeat"
  }
};
