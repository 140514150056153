import React, { Component } from 'react';
import { connect } from 'react-redux';

import Colours from './../../assets/colours.js';

class Footer extends Component {
  render() {

    return (
      <footer style={ styles.footer } className={ this.props.minimise ? "minimised" : "" }>
        <div className='content'>
          <div className='width-6'>
            <p style={ styles.para }>Content and design copyright &copy; Ashworth FP 2018</p>
          </div>
          <div className='width-6 right'>
            <p style={ styles.para }>Need help? <a href={'mailto:' + process.env.REACT_APP_ADMIN_EMAIL}>Get in touch</a></p>
          </div>
        </div>
      </footer>
    );
  }
}

var styles = {
  footer: {
    background: Colours.bgGrey,
    zIndex: 3
  },
  para: {
    margin: 0,
    padding: 0
  }
};

function mapStateToProps(state) {
  return {
    minimise: state.minimalHeader
  };
}

export default connect(mapStateToProps)(Footer);
