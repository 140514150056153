import React, { Component } from 'react';

class DisplayDropdown extends Component {

  constructor() {
    super();
    this.focusRef = React.createRef();
  }

  componentDidMount(){
    this.focusRef.current.focus();
  }



  render(){

    return (

      <div id='dropdown' className='dropdown' tabIndex={1} ref={this.focusRef} onBlur={this.props.handleDropDownBlur}  >

	      <div style={{display:'none'}}>
	        <input id="toggleCurrentSavings" name='toggleCurrentSavings' type='checkbox' onChange ={this.props.toggleCurrentSavings} checked={this.props.planDisplayDetails.displayCurrentSavings}/>
	        <label htmlFor="toggleCurrentSavings" >Show Current Value Progress</label>
	      </div>

	      <div style={{display:'none'}}>
	        <input id="togglePotentialValueSavings" name='togglePotentialValueSavings' type='checkbox' onChange ={this.props.togglePotentialValueSavings} checked={this.props.planDisplayDetails.displayPotentialValue}/>
	        <label htmlFor="togglePotentialValueSavings">Show Potential Value of Savings</label>
	      </div>

        <div>
          <select defaultValue={this.props.planDisplayDetails.graphToggle} onChange={this.props.handleGraphToggle}>
            <option value='both'>Both</option>
            <option value='progress'>Current Progress</option>
            <option value='projections'>Future Projections</option>
          </select>
        </div>

        <div>
          <input id="handleGlobalInflationToggle" name='handleGlobalInflationToggle' type='checkbox' onChange ={this.props.handleGlobalInflationToggle} checked={this.props.planDisplayDetails.enable_inflation}/>
          <label htmlFor="handleGlobalInflationToggle">Show as Real-terms</label>
        </div>

        <div>
          <input id="handleShowContributionsToggle" name='handleShowContributionsToggle' type='checkbox' onChange ={this.props.toggleContributionDisplay} checked={this.props.planDisplayDetails.showContributions}/>
          <label htmlFor="handleShowContributionsToggle">Show Contributions</label>
        </div>

        <div>
          <input id="handleShowWithdrawalsToggle" name='handleShowWithdrawalsToggle' type='checkbox' onChange ={this.props.toggleWithdrawalDisplay} checked={this.props.planDisplayDetails.showWithdrawals}/>
          <label htmlFor="handleShowWithdrawalsToggle">Show Withdrawals</label>
        </div>

        <div>
          <input id="toggleUpperForecastDisplay" name='toggleUpperForecastDisplay' type='checkbox' onChange ={this.props.toggleUpperReturnDisplay} checked={this.props.planDisplayDetails.showUpperReturn}/>
          <label htmlFor="toggleUpperForecastDisplay">Show Upper Forecast</label>
        </div>

        <div>
          <input id="toggleExpectedForecastDisplay" name='toggleOriginalForecastDisplay' type='checkbox' onChange ={this.props.toggleExpectedReturnDisplay} checked={this.props.planDisplayDetails.showExpectedReturn}/>
          <label htmlFor="toggleExpectedForecastDisplay">Show Expected Forecast</label>
        </div>


        <div>
          <input id="toggleLowerdForecastDisplay" name='toggleOriginalForecastDisplay' type='checkbox' onChange ={this.props.toggleLowerReturnDisplay} checked={this.props.planDisplayDetails.showLowerReturn}/>
          <label htmlFor="toggleLowerdForecastDisplay">Show Lower Forecast</label>
        </div>

        <div>
          <input id="toggleOriginalForecastDisplay" name='toggleOriginalForecastDisplay' type='checkbox' onChange ={this.props.toggleOriginalForecastDisplay} checked={this.props.planDisplayDetails.displayOriginalForecast}/>
          <label htmlFor="toggleOriginalForecastDisplay">Show Original Forecast</label>
        </div>

        <div>
          <input id="toggleTargetDisplay" name='toggleTargetDisplay' type='checkbox' onChange ={this.props.toggleTargetDisplay} checked={this.props.planDisplayDetails.showTarget}/>
          <label htmlFor="toggleTargetDisplay">Show Target</label>
        </div>
        <hr/>
        <div>
          <b>Adjust Age Range</b>
        </div>
        <div className='ageCrop' >
          <div>
            <label htmlFor="startAgeCrop">Start Age</label>
            <input id="startAgeCrop" name='startAgeCrop' type='number' onChange ={this.props.toggleStartAgeCrop} min={this.props.initialSavingsAge} max ={100} value={this.props.planDisplayDetails.holdingStartAgeCrop}/>
          </div>
          <div>
            <label htmlFor="endAgeCrop">End Age</label>
            <input id="endAgeCrop" name='endAgeCrop' type='number' onChange ={this.props.toggleEndAgeCrop} min={this.props.initialSavingsAge} max ={100} value={this.props.planDisplayDetails.holdingEndAgeCrop}/>
          </div>
        </div>
      </div>

   );
  }

}

export default DisplayDropdown;
