import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PublicTemplate from './views/questionnaire/public-tmplt.js';
import AdminTemplate from './views/admin/admin-tmplt.js';
import './assets/css/structure.css';
import './assets/css/core.css';
import CallLogTemplate from './views/admin/call-log-tmplt.js';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={ PublicTemplate }/>
          <Route exact path='/forgotten-password' component={ PublicTemplate }/>
		      <Route path='/download' component={ PublicTemplate }/>
          <Route path='/password-reset' component={ PublicTemplate }/>
          <Route path='/questionnaire' component={ PublicTemplate }/>
          <Route path='/welcome' component={ PublicTemplate }/>
          <Route path='/call-log/:slug/:hash' component={ CallLogTemplate }/>
          <Route exact path='/thanks' component={ PublicTemplate }/>
          <Route path='/error' component={ PublicTemplate }/>
          <Route path='/download/fail' component={ PublicTemplate }/>
          <Route exact path='/admin' component={ PublicTemplate }/>
          <Route path='/admin/:actionId'  render={(props) => <AdminTemplate cookies={this.props.cookies} {...props} />}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
