import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'

import Fetching from './../../functions/fetching.js';
import QuestionGroup from './question-group.js';
import Btn from './../common/btn.js';
import Confirm from './confirm.js';

import Colours from './../../assets/colours.js';

class TabContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      userConfirm: false,
      isLoading: false,
      errorFields: [],
      rdr: false,
      readOnly: false,
      names: false,
			family: [],
			performSaveCheck:false,
    };

    this.clearError = this.clearError.bind(this);
		this.savePendingChanges = this.savePendingChanges.bind(this);

  }

  componentDidMount() {

    this.loadContent(this.props);

    if (this.props.showConfirm === "true") {
      this.setState({ userConfirm: true });
    } else {
      this.setState({ userConfirm: false });
    }

    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentWillReceiveProps ( newProps ) {
    console.log(newProps);
   this.loadContent( newProps );
  }

  loadContent ( latestProps ) {

    this.setState({ isLoading: true })

    // Check that we have the relevant questionnaire tokens
    if ((typeof this.props.isAdmin == 'undefined' || this.props.isAdmin === false) && (this.props.customerToken === '' || this.props.customerTokenKey === '' || this.props.customerMash === '')) {

      this.setState({ error: true })

    } else {

      // If we have the tokens then get the tabs for this
      var sxnSlug;
      var fetchUrl;
      if (typeof this.props.isAdmin == 'undefined' || this.props.isAdmin === false) {
        sxnSlug = (typeof this.props.match.params != 'undefined' && typeof this.props.match.params.tabno != 'undefined')?this.props.match.params.tabno:this.props.firstSxn;
        fetchUrl = process.env.REACT_APP_API_BASE + '/questionnaire_sections/details/' + sxnSlug + '/' + this.props.customerToken + '/' + this.props.customerMash + '/' + this.props.customerTokenKey + '/';
      } else {
        sxnSlug = latestProps.sxnSlug;
        fetchUrl = process.env.REACT_APP_API_BASE + '/questionnaire_sections/edit/' + sxnSlug + "/" + this.props.custRef + "/" + latestProps.timeMachine;
      }

      var thus = this;
      fetch(fetchUrl, { credentials: 'include' })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {

            // Check whether we have a failure
            if (typeof data.error !== 'undefined' && data.error === 'auth') {

              // Redirect to the error page
              thus.setState({ error: true });

            } else {
              // Check whether it is marked as read only
              var readOnly = false;
              if (typeof data.readOnly != 'undefined') {
                readOnly = true;
              }
              // Process the data for display of the tabs
              if ( thus._ismounted ) {
                thus.setState({ groups: data.groups, isLoading: false, names: data.names, family: data.family, readOnly: readOnly});
                // thus.props.tabLoaded();
              }

            }
          }
        );

    }

    this.getTokens();

  }

  getTokens () {
		if (this.props.tokenIsUsed === true && this.props.tokenIsPending === false) {
			this.props.dispatch({ type: 'MARK_TOKENS_PENDING' });
			fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	      .then(Fetching.statusCheck)
	      .then(Fetching.jsonExtract)
	      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	      .catch(function (error) {
	        console.log('Request failed', error);
	      });
		}
  }

  markErrors ( errorList ) {
    this.setState({ errorFields: errorList });
  }

  clearError (repNo, qid) {
    // Get the existing state
    var errorFields = this.state.errorFields;
    // Reset the state
    if (typeof errorFields[repNo + '-' + qid] != 'undefined') {
      delete errorFields[repNo + '-' + qid];
    }
    this.setState({ errorFields: errorFields });
  }


  handleSubmit (e) {
	  console.log('handleSubmit');
    // Run a fetch to submit the section
    var thus = this;
    var fetchUrl;
    if (typeof this.props.custRef != 'undefined') {
      fetchUrl = process.env.REACT_APP_API_BASE + '/customer_submissions/publish/' + this.props.custRef;
    } else {
      var sxnSlug = (typeof this.props.match.params != 'undefined' && typeof this.props.match.params.tabno != 'undefined')?this.props.match.params.tabno:this.props.firstSxn;
      fetchUrl = process.env.REACT_APP_API_BASE + '/questionnaire_responses/complete/' + sxnSlug + '/' + this.props.customerToken + '/' + this.props.customerMash + '/' + this.props.customerTokenKey + '/';
    }
    fetch(fetchUrl, { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function(data){
        // If it is a success
        if (typeof data.rslt != 'undefined' && (data.rslt === 'success' || data.rslt === 'no-req')) {

          // Update the sections data
          var sxns = thus.props.sxns;
          console.log(sxns);
          for ( var i in sxns ) {
            if (sxns[i].slug === sxnSlug) {
              sxns[i].isAddressed = "1";
            }
          }
          thus.props.dispatch({ type: 'SET_SXN_DATA', sxns: sxns });

          // Forward on to the next section
          if (typeof data.nxtSlug != 'undefined') {
            if (data.nxtSlug === "") data.nxtSlug = "confirm";
            thus.setState({ rdr: '/questionnaire/' + data.nxtSlug });
            thus.props.handleChange(data.nxtSlug, sxnSlug);
            thus.props.checkCompleteness();
          } else {
            alert('Your changes have been saved successfully.');
          }

        } else if (typeof data.errors != 'undefined' && data.errors === 'unanswered') {

          // If the error is there are unanswered
          // Display the unanswered as errors
          thus.markErrors(data.toComplete);
          alert('Sorry but we cannot mark this section as complete until you have answered all the mandatory questions (marked by an asterisk).')

        } else {
          // For other errors show an alert
          alert('Sorry but there was an error marking this section as complete. Please try again.')
        }
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });

  }

	blockDefault (e) {
		e.preventDefault();
	}

	savePendingChanges(){
		this.setState({performSaveCheck:true});
	}

  render() {

    const { groups, isLoading, errorFields, rdr, readOnly, userConfirm, names, family } = this.state;

    if (rdr !== false) {
      return (
        <Redirect to={ rdr } />
      );
    }

    if (isLoading) {
      return (
        <div className='content'>
          <div className='width-1 centred'>
            <div className="loader">Loading...</div>
          </div>
        </div>
      );
    }

    // If they should be shown the confirmation page then simply display that component
    var pathname = (typeof this.props.location != 'undefined' && typeof this.props.location.pathname != 'undefined')?this.props.location.pathname:"";
    if (userConfirm && pathname === "/questionnaire/confirm") {

      return (
        <Confirm  />
      );

    }

    var btnTxt = "Save & Continue";
    if (typeof this.props.custRef != 'undefined') {
      btnTxt = "Save updates";
    }

		// Do we need to hide the save button for this section
		var sxnHideBtn = (typeof this.props.sxnSlug != 'undefined' && this.props.sxnSlug === "progress_review_content")?true:false;

    var btnComp = <Btn title={ btnTxt } lnk={ "/questionnaire" } type="button" handleSubmission={ (e) => this.handleSubmit(e) } />
    if ((typeof this.props.isAdmin != 'undefined' && this.props.isAdmin === "true") || sxnHideBtn) {
      btnComp = "";
    }
		// Finally - this is a bit of a bodgey buttong, but it saves time
		// Effectively it isn't meant to do anything, but it makes sure that the user clicks to save the changes
		if (sxnHideBtn) {
			btnComp = <div onClick={this.savePendingChanges} style={ styles.divBtn }>Save Updates</div>;
		}

    var mmBtn = "";
    if (this.props.canMindmap) {
      mmBtn = <Link style={ styles.fauxBtn } to={ `/admin/clients/mindmap/` + this.props.custRef + "/" + this.props.sxnSlug }>View mindmap</Link>
    }

    var planningCalculatorsBtn = "";
		if (typeof this.props.isAdmin != 'undefined' && this.props.isAdmin) {
    	planningCalculatorsBtn = <Link style={ styles.fauxBtn1 } to={ `/admin/clients/planning/` + this.props.custRef  }>Planning Calculator</Link>
		}

    var presentationBtn = <Link style={ styles.fauxBtn2 } to={ `/admin/clients/presentation/` + this.props.custRef+'/agenda'  }>Presentation</Link>

    return (
      <div className="width-9 tab-body">
        <div style={ styles.body }>

          { readOnly === true ? (
            <div className="error">PLEASE NOTE: This client is currently working on their submission, so it is read only to administrators.</div>
          ) : (
            <div className="error"></div>
          )}

          { mmBtn }
          {planningCalculatorsBtn}
            {presentationBtn}
          { groups.map( group =>

            <QuestionGroup
							performSaveCheck={this.state.performSaveCheck}
							responseChanges={this.state.responseChanges}
							resolveEditsSaved ={this.resolveEditsSaved}
							handleEditorChange={this.handleEditorChange}
							key={group.group.title}
							group={group.group}
							hideTabs={ this.props.hideTabs }
							errorFields={ errorFields }
							clearError={ this.clearError }
							custRef={ this.props.custRef }
							names={ names }
							family={ family }
							readOnly={ readOnly }
							sxnSlug={ this.props.sxnSlug }
						/>

          )}

          { btnComp }

        </div>
      </div>
    );
  }
}

const styles = {
  body: {
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20
  },
  fauxBtn: {
    background: Colours.grn,
    color: Colours.txtWhite,
    borderRadius: 20,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 18,
    paddingRight: 18,
    top: 24,
    right: 22,
    position: 'absolute'
  },
  fauxBtn1: {
    background: Colours.grn,
    color: Colours.txtWhite,
    borderRadius: 20,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 18,
    paddingRight: 18,
    top: 24,
    right: 150,
    position: 'absolute'
  },
  fauxBtn2: {
    background: Colours.grn,
    color: Colours.txtWhite,
    borderRadius: 20,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 18,
    paddingRight: 18,
    top: 24,
    right: 303,
    position: 'absolute'
  },
	divBtn: {
		background: Colours.grn,
    color: Colours.txtWhite,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 18,
    paddingRight: 18,
		maxWidth: 100,
		margin: "22px auto 5px auto",
		boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",
		cursor: "pointer"
	}
};

function mapStateToProps(state) {
  return {
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash,
		tokenIsUsed: state.tokenIsUsed,
		tokenIsPending: state.tokenIsPending,
    sxns: state.sxns
  };
}

export default connect(mapStateToProps)(TabContent);
