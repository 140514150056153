import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import Fetching from './../../../functions/fetching.js';

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

class EmailDetails extends Component {

	constructor(props) {
    super(props);

    this.state = {
			core: [],
			subject: "",
      emails: [],
			mailbox: "",
			stats: [],
			statsUsrs: [],
			suType: false,
			emailId: this.props.match.params.emailId,
			rdr: false,
			loading: true
    };

	}

	// On component mount load up the users, which triggers email loading
	componentWillMount () {
		// Check that we have the email ID
		if ( typeof this.props.match.params.emailId == 'undefined' ) {
			this.setState({ rdr: "/admin/emails" });
		}
		// Load up the email details
		this.loadEmail( this.props.match.params.emailId );
	}

	// Load up the email details
	loadEmail ( emailId ) {

		let thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/sso/email_details/' + emailId, { credentials:"include" })
			.then(Fetching.statusCheck)
			.then(Fetching.jsonExtract)
			.then(
				function (data) {
					// Check whether we have a failure
					if (typeof data.core != 'undefined') {

						// Redirect to the error page
						thus.setState({
							core: data.core,
							subject: data.core.subject,
							emails: data.emails,
							mailbox: data.mailbox,
							loading: false,
							stats: data.core.stats,
							statsUsrs: data.core.statsUsrs
						});

					}
				}
			);

	}


	// Toggle the email status
	// Opens or closes the emails within the trail
	toggleEmail ( e ) {

		e.preventDefault();

		// Figure out the ID for the clicked header
		let emailId = e.target.getAttribute('data-emailid');

		// Loop through the emails + set the status for the appropriate email
		let { emails } = this.state;
		var newEmails = [];
		for (var i in emails) {
			if ( typeof emails[i].id != 'undefined' && emails[i].id === emailId ) {
				emails[i].isOpen = ( typeof emails[i].isOpen == "undefined" || !emails[i].isOpen ) ? true : false ;
			}
			newEmails.push(emails[i]);
		}

		this.setState({ emails: newEmails });
	}

	// Open up the details lightbox to display the users that performed an action on an email
	openDetails (e) {

		e.preventDefault();

		// Work out the type
		let type = e.target.getAttribute('data-type');

		this.setState({ suType: { type: type, title: typeTitles[type], th: typeThs[type] } });

	}

	// Close up the details
	closeDetails (e) {
		this.setState({ suType: false });
	}

	// Render the view
  render() {

		let { emails, mailbox, core, stats, statsUsrs, suType, rdr, loading } = this.state;

		if ( rdr ) {
			return (
				<Redirect to={ rdr } />
			);
		}

		if ( loading ) {
			return (
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
		}

		var usrStats = "";
		if ( statsUsrs && suType ) {
			usrStats = <div className="cover">
				<a href="#close" onClick={ (e) => this.closeDetails(e) }>&nbsp;</a>
				<div className="box big">
					<h5>{ suType.title }</h5>
					<table>
						<thead>
							<tr>
								<th>User</th>
								<th>Email</th>
								<th>{ suType.th }</th>
							</tr>
						</thead>
						<tbody>
							{ statsUsrs[suType.type].map ( (usr, j) =>
								<tr key={ "usr-" + j }>
									<td>{ usr.name }</td>
									<td>{ usr.email }</td>
									<td>{ usr.time }</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		}

    return (
      <div className='content'>

				{ usrStats }

				<div className="width-8 centred">
					<Heading mainTitle={ core.subject } subTitle={ mailbox } />

					<FlashMsg />

					<div style={{ overflow: "auto" }}>
						<div className={ stats !== false && stats.total > 0 ? "core-data two-thirds" : "core-data" }>
							<div>
								<p className="lft">From:</p>
								<p>{ core.sender.name } &lt;{ core.sender.address }&gt;</p>
							</div>
							<div>
								<p className="lft">To:</p>
								{ core.recipients.to.map( (recipient, i) =>
									<Fragment key={ "emw-" + i }>
										{ core.type === 'sent-local' ? (
											<p key={ "em-" + i }>{ recipient.name } &lt;{ recipient.address }&gt;</p>
										) : (
											<p key={ "em-" + i }>{ recipient.emailAddress.name } &lt;{ recipient.emailAddress.address }&gt;</p>
										)}
									</Fragment>
								)}
								{ typeof core.recipients.cc != 'undefined' ? (
									<Fragment>
										{ core.recipients.cc.map( (recipient, j) =>
											<Fragment key={ "emw-" + j }>
												{ core.type === 'sent-local' ? (
													<p key={ "em-" + j }>{ recipient.name } &lt;{ recipient.address }&gt;</p>
												) : (
													<p key={ "em-" + j }>{ recipient.emailAddress.name } &lt;{ recipient.emailAddress.address }&gt;</p>
												)}
											</Fragment>
										)}
									</Fragment>
								) : "" }
								{ typeof core.recipients.bcc != 'undefined' ? (
									<Fragment>
										{ core.recipients.bcc.map( (recipient, k) =>
											<p key={ "emcc-" + k }>{ recipient.emailAddress.name } &lt;{ recipient.emailAddress.address }&gt;</p>
										)}
									</Fragment>
								) : "" }
							</div>
							<div>
								<p className="lft">Subject:</p>
								<p>{ core.subject }</p>
							</div>
						</div>

						{ stats !== false && stats.total > 0 ? (
							<div className="core-data one-third email-stats">
								<h4>Statistics</h4>
								<ul>
									{ stats.open > 0 ? (
										<li><span>Opened:</span> <strong><a onClick={ (e) => this.openDetails(e) } data-type="open" href="#detail">{ stats.open } / { stats.total }</a></strong></li>
									) : "" }
									{ stats.click > 0 ? (
										<li><span>Link clicked:</span> <strong><a onClick={ (e) => this.openDetails(e) } data-type="click" href="#detail">{ stats.click } / { stats.total }</a></strong></li>
									) : "" }
									{ stats.bounce > 0 ? (
										<li><span>Bounced:</span> <strong><a onClick={ (e) => this.openDetails(e) } data-type="bounce" href="#detail">{ stats.bounce } / { stats.total }</a></strong></li>
									) : "" }
									{ stats.spam > 0 ? (
										<li><span>Spam:</span> <strong><a onClick={ (e) => this.openDetails(e) } data-type="spam" href="#detail">{ stats.spam } / { stats.total }</a></strong></li>
									) : "" }
									{ stats.blocked > 0 ? (
										<li><span>Blocked:</span> <strong><a onClick={ (e) => this.openDetails(e) } data-type="blocked" href="#detail">{ stats.open } / { stats.blocked }</a></strong></li>
									) : "" }
									{ stats.unsub > 0 ? (
										<li><span>Unsubscribed:</span> <strong><a onClick={ (e) => this.openDetails(e) } data-type="unsub" href="#detail">{ stats.unsub } / { stats.total }</a></strong></li>
									) : "" }
								</ul>
							</div>
						) : "" }
					</div>

					<div className="emails">
						{ emails.map ( (email, l) =>
							<div key={ "email-" + l } className="individual">
								<div className="email-header">
									<a href="#open" data-emailid={ email.id } onClick={ (e) => this.toggleEmail(e) }>&nbsp;</a>
									<div>
										<span className="lft">From:</span>
										<span className="email">{ core.sender.name } &lt;{ core.sender.address }&gt;</span>
									</div>
									<div>
										<span className="lft">To:</span>
										{ core.recipients.to.map( (recipient, i) =>
											<Fragment key={ "em-" + i }>
												{ core.type === 'sent-local' ? (
													<span className="email">{ recipient.name } &lt;{ recipient.address }&gt;</span>
												) : (
													<span className="email">{ recipient.emailAddress.name } &lt;{ recipient.emailAddress.address }&gt;</span>
												)}
											</Fragment>
										)}
										{ typeof core.recipients.cc != 'undefined' ? (
											<Fragment>
												{ core.recipients.cc.map( (recipient, j) =>
													<Fragment key={ "emccw-" + j }>
														{ core.type === 'sent-local' ? (
															<p key={ "emcc-" + j }>{ recipient.name } &lt;{ recipient.address }&gt;</p>
														) : (
															<p key={ "emcc-" + j }>{ recipient.emailAddress.name } &lt;{ recipient.emailAddress.address }&gt;</p>
														)}
													</Fragment>
												)}
											</Fragment>
										) : "" }
										{ typeof core.recipients.bcc != 'undefined' ? (
											<Fragment>
												{ core.recipients.bcc.map( (recipient, k) =>
													<span className="email" key={ "emcc-" + k }>{ recipient.emailAddress.name } &lt;{ recipient.emailAddress.address }&gt;</span>
												)}
											</Fragment>
										) : "" }
									</div>
									<div>
										<span className="lft">Date:</span>
										<span>{ email.date }</span>
									</div>
								</div>
								<div className={ typeof email.isOpen != "undefined" && email.isOpen ? "body open" : "body" } dangerouslySetInnerHTML={{ __html: email.stripBody }}></div>
							</div>
						)}
					</div>

				</div>

      </div>
    );
  }
}

const typeTitles = {
	open: "Opened by:",
	click: "Links clicked by:",
	bounce: "Bounced:",
	unsub: "Unsubscribed:",
	spam: "Identified as spam by:",
	blocked: "Blocked for:",
}

const typeThs = {
	open: "Opened on:",
	click: "Links clicked at:",
	bounce: "Bounced on:",
	unsub: "Unsubscribed on:",
	spam: "Identified as spam on:",
	blocked: "Blocked on:",
}

export default EmailDetails;
