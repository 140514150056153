import React, { Component, Fragment } from 'react';
import Calculator from './calculator.js'
import Colours from './../../../../assets/colours.js';
import { Link} from 'react-router-dom'

class Accordion extends Component {
  render() {

    const calculatorInputCallbacks= this.props.calculatorInputCallbacks
    const saveCalculator = this.props.saveCalculator;
    const customerAge = this.props.customerAge;
    const softDeleteCalculator = this.props.softDeleteCalculator;
    const downloadAsPDF = this.props.downloadAsPDF;
    const isFullScreen = this.props.isFullScreen;

    const calculators = this.props.calculators.map((calculator,i) => {
      const isExpanded = this.props.openCalculator === i;
      const displayValue = this.props.displayValues[i];
      const handleExpandToggle = this.props.handleExpandToggle;
      const handleCalculatorTitleEdit = this.props.handleCalculatorTitleEdit
      return (<Calculator
                key={'calculator-'+i}
                displayValue={displayValue}
                index={i}
                calculator={calculator}
                isExpanded={isExpanded}
                handleExpandToggle={handleExpandToggle}
                calculatorInputCallbacks={calculatorInputCallbacks}
                saveCalculator={saveCalculator}
                softDeleteCalculator={softDeleteCalculator}
                customerAge={customerAge}

                handleCalculatorTitleEdit={handleCalculatorTitleEdit}
                downloadAsPDF={downloadAsPDF}
								{ ...this.props }
              />);
    });

    const calculatorContainer = (calculators.length > 0) ? (
      <div style={{marginTop: isFullScreen ? '150px':'0'}}className="side-bar">
      	{ calculators }
      </div>) : null;

    return (
      <Fragment>
        {calculatorContainer}
        <button onClick={this.props.createOption} style={ styles.greyBtn }>+ Create New Calculator</button>
        <Link to={"/admin/clients/planningTransactions/"+this.props.custRef}>
          <button style={ styles.greyBtn }>Historical Data</button>
        </Link>
      </Fragment>
    );
  }
}

const styles = {
	greyBtn: {
		background: Colours.txtGrey,
		marginTop: 22,
		fontSize: '90%',
		padding: '5px 12px'
	}
};

export default Accordion;
