import { Chart } from "react-google-charts";
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONFIGURATION from './series-configuration.js'
import {DISPLAY_BOTH,DISPLAY_SAVINGS,DISPLAY_PREDICTIONS,DISPLAY_TARGET_ONLY} from './constants/constants.js'
import {createDataEntries,getTargetValue} from './data-generator.js'
import ForecastDatatable from './forecast-datatable.js'
import ProgressReview from './../progress-review.js'
import ValuationSummary from './../valuation-summary.js'
import DisplayOptionDropdown from './display-option-dropdown.js'

class Graph extends Component {

  constructor(props){
    super(props);
    this.state = {
       lastUpdate:'',
       isDropdownOpen:false
    }
    this.toggleDropdownDisplay = this.toggleDropdownDisplay.bind(this);
    this.handleDropDownBlur = this.handleDropDownBlur.bind(this);
  }

  toggleDropdownDisplay(){

    this.setState((prevState) => {

      return {isDropdownOpen: ! prevState.isDropdownOpen}
    })
  }

  handleDropDownBlur(e){

    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout( () => this.setState(prevState => ({
        isDropdownOpen: false,
      })), 200);
    }
  }

  componentWillReceiveProps(nextProps){

    if(nextProps.lastUpdate !== this.state.lastUpdate){
      this.setState({
        lastUpdate:nextProps.lastUpdate
      },() => this.forceUpdate())

    }
  }

  removeOriginalForecastColumn(data){
    for(let i =0 ; i< data.length;i++){
      data[i].splice(4,1);
    }

    return data;
  }

  getIndicesToRemove(props,data){
    let indicesToSplice = [];

    let titleRow = data[0];
    for(let i = 0; i< titleRow.length;i++){
      if(!props.showWithdrawals && titleRow[i] === 'Withdrawals'){
        indicesToSplice.push(i);
      }

      if(!props.showContributions && titleRow[i] === 'Contributions'){
        indicesToSplice.push(i);
      }

      if(!props.displayOriginalForecast && titleRow[i] === 'Original'){
        indicesToSplice.push(i);
      }

      if(!props.showUpperReturn && titleRow[i] === 'Upper'){
        indicesToSplice.push(i);
      }

      if(!props.showExpectedReturn && titleRow[i] === 'Expected Return'){
        indicesToSplice.push(i);
      }

      if(!props.showLowerReturn && titleRow[i] === 'Lower'){
        indicesToSplice.push(i);
      }

      if(!props.showTarget && titleRow[i] === 'Target'){
        indicesToSplice.push(i);
      }
    }

    return indicesToSplice;
  }

  removeIndicesFromDataSet(indicesToRemove,data){

    for(var i =0; i<data.length;i++){
      let newDataEntry = data[i];
      for(var j = indicesToRemove.length -1 ; j >=0 ; j--){
        newDataEntry.splice(indicesToRemove[j],1);

      }
      data[i] = newDataEntry;
    }

    return data;
  }

  updateSeriesConfig(indicesToRemove,props,data,seriesOptions){

    for(var i = indicesToRemove.length -1 ; i >=0 ; i--){
      delete seriesOptions[indicesToRemove[i] - 1];
    }

    let newSeriesOptions = {};
    let counter = 0;
    for(var key in seriesOptions){
      newSeriesOptions[counter] = seriesOptions[key];
      counter++;
    }

    return newSeriesOptions;
  }

  cropDataByAge(data,startAge,endAge){
    let startRow = 0
    for(let a = data.length - 1; a > startRow ;a--){

      if(data[a][0] < startAge || data[a][0] > endAge){
        data.splice(a,1);
      }

    }

    return data;
  }

  //doesColumnHaveNullsOnly - Array of length column count with all values initialised to true
  //data - our created dataset
  checkForColumnsWithEntireNullValues(doesColumnHaveNullsOnly,data){
    //We assume every column is set to null throughout
    for(let i = 1; i < data.length;i++){
      for(let j=0;j<data[i].length;j++){
        //So for every row check that it's non-null
        if(data[i][j] !== null){
          //Found a non-null value so set to false
          doesColumnHaveNullsOnly[j] = false;
        }
      }
    }

    return doesColumnHaveNullsOnly;
  }

  addColumnIndicesWithNullValues(indicesToRemove,doesColumnHaveNullsOnly){
    for(var i = 0 ; i< doesColumnHaveNullsOnly.length;i++){
      if(doesColumnHaveNullsOnly[i]){
        indicesToRemove.push(i);
      }
    }

    return indicesToRemove;
  }

  removeNullColumns(data,doesColumnHaveNullsOnly){
    for(var i = doesColumnHaveNullsOnly.length-1 ; i >= 0 ; i--){
      if(doesColumnHaveNullsOnly[i]){
        for(var j = 0 ; j< data.length;j++){
          data[j].splice(i,1);
        }
      }
    }

    return data;
  }


  render() {

    if(this.props.calculators.length === 0) {
      return null;
    }
    let isFullscreen = this.props.isFullScreen;

    let fsBtn = <button style={{fontSize:'13px'}} onClick={ (e) => this.props.triggerFullScreen(e) }>Fullscreen</button>
    if (isFullscreen) {
      fsBtn = <button style={{fontSize:'13px'}} onClick={ (e) => this.props.exitFullscreen(e) }>Exit Fullscreen</button>
    }

    let progressReviewBtn = <ProgressReview lastUpdate={this.props.lastUpdate} saveCalculator={this.props.saveCalculator} openCalculator={this.props.openCalculator} onCalculatorPage={true} custRef={this.props.custRef} downloadAsPDF={true} {...this.props}/>
	let vsBtn = (
		<ValuationSummary custRef={this.props.custRef} />
	)
    const activeCalculator = this.props.calculators[this.props.openCalculator]

    const target = getTargetValue(activeCalculator);

    let data = [];
    let seriesOptions = {}
    let savings = this.props.savings;
    let indicesToRemove = [];

  ;
    const presentAge = parseInt(activeCalculator.age);

    let initialSavingsAge =presentAge

    if(typeof Object.keys(savings)[0] !== 'undefined'){
      initialSavingsAge =  parseInt(Object.keys(savings)[0]);
    }

    const retirementAge = parseInt(activeCalculator.retirement_age);

    let dataSet = [];

    let dataOptions = {
      hideOriginalForecast:true,
      enableInflation:true
    }

    if(this.props.planDisplayDetails.displayOriginalForecast){
      dataOptions.hideOriginalForecast = false
    }

    if(!this.props.planDisplayDetails.enable_inflation){
      dataOptions.enableInflation = false
    }

    let startAgeCrop = this.props.planDisplayDetails.startAgeCrop;
    let endAgeCrop = this.props.planDisplayDetails.endAgeCrop;

    if(this.props.planDisplayDetails.displayCurrentSavings && this.props.planDisplayDetails.displayPotentialValue) {

      data.push(['Age', 'Current Value','Contributions', 'Withdrawals','Original', 'Lower', 'Expected Return','Upper','Target']);
      indicesToRemove = this.getIndicesToRemove(this.props.planDisplayDetails,data);
      data = this.removeIndicesFromDataSet(indicesToRemove,data);

      dataSet = createDataEntries(  DISPLAY_BOTH,savings,initialSavingsAge,retirementAge,presentAge,activeCalculator,target,dataOptions,indicesToRemove);
      data = data.concat(dataSet);



      data = this.cropDataByAge(data,startAgeCrop,endAgeCrop);

      let doesColumnHaveNullsOnly = new Array(data[0].length).fill(true);

      doesColumnHaveNullsOnly = this.checkForColumnsWithEntireNullValues(doesColumnHaveNullsOnly,data);
      indicesToRemove = this.addColumnIndicesWithNullValues(indicesToRemove,doesColumnHaveNullsOnly);
      data = this.removeNullColumns(data,doesColumnHaveNullsOnly);

      seriesOptions = {...CONFIGURATION.series.both};
      seriesOptions = this.updateSeriesConfig(indicesToRemove,this.props,data,seriesOptions);
    }

    if(this.props.planDisplayDetails.displayCurrentSavings && !this.props.planDisplayDetails.displayPotentialValue){

      data.push(['Age', 'Current Savings','Contributions', 'Withdrawals','Target']);
      dataSet = createDataEntries(  DISPLAY_SAVINGS,savings,initialSavingsAge,retirementAge,presentAge,activeCalculator,target,dataOptions);
      data = data.concat(dataSet);
      seriesOptions = CONFIGURATION.series.savings;
    }

    if(!this.props.planDisplayDetails.displayCurrentSavings && this.props.planDisplayDetails.displayPotentialValue){
      data.push(['Age', 'Current Savings','Contributions', 'Withdrawals','Original', 'Lower', 'Expected Return','Upper','Target']);

      dataSet = createDataEntries(  DISPLAY_PREDICTIONS,savings,initialSavingsAge,retirementAge,presentAge,activeCalculator,target,dataOptions);

      data = data.concat(dataSet);

      if(dataOptions.hideOriginalForecast){
        seriesOptions = CONFIGURATION.series.both_without_original;
      } else {
        seriesOptions = CONFIGURATION.series.both;
      }

      if(dataOptions.hideOriginalForecast){
        data = this.removeOriginalForecastColumn(data);
      }

    }

    if(!this.props.planDisplayDetails.displayCurrentSavings && !this.props.planDisplayDetails.displayPotentialValue){
      data.push(['Age','Target']);

      dataSet = createDataEntries(  DISPLAY_TARGET_ONLY,savings,initialSavingsAge,retirementAge,presentAge,activeCalculator,target,dataOptions);
      data = data.concat(dataSet);

      seriesOptions = CONFIGURATION.series.target_only;
    }



    let transparentPanel = (this.state.isDropdownOpen) ? <div style={{backgroundColor:'transparent',width:'100%',height:'600px',position:'absolute','zIndex':2}}></div> : null;

    let thus = this;



    return (

    <Fragment>
        <div className='display-options' >
          {fsBtn}
		  {vsBtn}
          {progressReviewBtn}

          <DisplayOptionDropdown
            toggleStartAgeCrop={this.props.toggleStartAgeCrop}
            toggleEndAgeCrop={this.props.toggleEndAgeCrop}
            toggleCurrentSavings = {this.props.toggleCurrentSavings}
            togglePotentialValueSavings = {this.props.togglePotentialValueSavings}
            toggleOriginalForecastDisplay = {this.props.toggleOriginalForecastDisplay}
            handleGlobalInflationToggle = {this.props.handleGlobalInflationToggle}
            toggleTargetDisplay = {this.props.toggleTargetDisplay}
            toggleWithdrawalDisplay ={this.props.toggleWithdrawalDisplay}
            toggleContributionDisplay ={this.props.toggleContributionDisplay}
            toggleExpectedReturnDisplay={this.props.toggleExpectedReturnDisplay}
            toggleUpperReturnDisplay={this.props.toggleUpperReturnDisplay}
            toggleLowerReturnDisplay={this.props.toggleLowerReturnDisplay}
            isDropdownOpen = {this.state.isDropdownOpen}
            toggleDropdownDisplay = {this.toggleDropdownDisplay}
            handleDropDownBlur = {this.handleDropDownBlur}
            graphToggle={this.props.graphToggle}
            handleGraphToggle={this.props.handleGraphToggle}
            planDisplayDetails={this.props.planDisplayDetails}
            initialSavingsAge={initialSavingsAge}
          />


        </div>

    <div >
    {transparentPanel}
      <Chart
        width={'100%'}
        height={'500px'}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        chartEvents={[{
          eventName: "ready",
          callback({ chartWrapper }) {
            thus.props.handleSnapshot(data,thus.props.openCalculator);
            thus.props.saveImageURI(chartWrapper.getChart().getImageURI())
          }
        }]}
        data={data}
        options={{
          vAxis: {
            title: 'Investment Value' ,
            gridlines: {
                color: 'transparent'
            }
          },
          hAxis: {
            title: 'Age' ,
            baseline: 1,
            baselineColor: 'black',
            viewWindow: {
              min: startAgeCrop,
              max: endAgeCrop
            },
            gridlines: {
              color: 'transparent'
            },
          },
          seriesType: 'bars',
          interpolateNulls :true,
          legend: { position: 'bottom',textStyle: {fontSize:11}},
          chartArea: {'width': '80%', 'height': '70%'},
          series:seriesOptions,
          explorer: {
            keepInBounds: true,
            maxZoomIn: 4.0,
            maxZoomOut:1
          }
        }}
        rootProps={{ 'data-testid': '1' }}
      />

      {<ForecastDatatable data={data}/>}

      </div>
    </Fragment>

    );
  }

};



function mapStateToProps(state,ownProps) {

  return {
    minimise: state.minimalHeader,
    calculator:ownProps.calculators,
    openCalculator:ownProps.openCalculator,
    lastUpdate:ownProps.lastUpdate,
    custRef:ownProps.custRef
  };
}

export default connect(mapStateToProps)(Graph);
