import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'
import Email from './../../../assets/img/email.svg';

import {Chart } from "react-google-charts";

import Heading from '../heading.js';
import FlashMsg from '../../common/flash-msg.js';


import Fetching from '../../../functions/fetching.js';
import Colours from '../../../assets/colours.js';
import NumberFormat from 'react-number-format';

class TransactInvestments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      custRef: typeof this.props.custRef !== 'undefined' ? this.props.custRef : this.props.match.params.custRef,
			investments:[],
      latestInvestmentData:[],
      investmentWrapperMap:{},
      investmentWrapperExpandMap:{},
      rdr: false,
			isLoading: true,
      error: "",
      embedded: typeof this.props.embedded !== 'undefined' && this.props.embedded ? true : false
    };

    this.getTokens = this.getTokens.bind(this);
    this.closeCover = this.closeCover.bind(this);
    this.getCustomerInvestments = this.getCustomerInvestments.bind(this);
    this.toggleCustomerInvestment = this.toggleCustomerInvestment.bind(this);
    this.cancelLnk = this.cancelLnk.bind(this);
    this.toggleExpandedInvestment = this.toggleExpandedInvestment.bind(this);
  }

	getCustomerInvestments () {

    // Check that we have a reference
    if (typeof this.state.custRef == 'undefined' || this.state.custRef === "") {
      this.setState({ rdr: true });
    }

    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customers/transact/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.data != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });
      } else {

        let investmentWrapperMap = {"Overall" :true}
        let investmentWrapperExpandMap = {}
        data.investments.forEach(element => {
          let name = element["local_wrapper_name"] ;
          if(name == null){
            name = element['wrapper_name'];
          }
          investmentWrapperMap[name ] = false
          investmentWrapperExpandMap[name] = false;
        });

        thus.setState({
          investments:data.data,
          latestInvestmentData:data.investments,
          investmentWrapperMap:investmentWrapperMap,
          investmentWrapperExpandMap:investmentWrapperExpandMap,
          isLoading:false
        })
        // thus.setState({ links: data.customer_links,isLoadingCustomerLinks:false});
      }

    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  toggleExpandedInvestment(e,investmentKey){
    let newInvestmentWrapperExpandMap = {...this.state.investmentWrapperExpandMap}
    newInvestmentWrapperExpandMap[investmentKey] = !this.state.investmentWrapperExpandMap[investmentKey]
    this.setState({investmentWrapperExpandMap:newInvestmentWrapperExpandMap})
  }

  toggleCustomerInvestment(e,investmentKey){

    let newInvestmentWrapperMap = {...this.state.investmentWrapperMap}
    newInvestmentWrapperMap[investmentKey] = !this.state.investmentWrapperMap[investmentKey]
    this.setState({investmentWrapperMap:newInvestmentWrapperMap})
  }

	cancelLnk (e,url) {

		e.preventDefault();

    var doCancel = window.confirm('This will cancel the link. Do you wish to continue?');

    if(!doCancel){
      return false;
    }

		// // Get the selected Files
		// var { selectedFiles, custRef } = this.state;

		// Set the generating state (to display the loader)
		this.setState({ genLoading: true });

		// Run a query to generate the link and return it
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/customer_sends/cancel/'+url, {
      method: 'get',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

			// Upon success display the link and clear the selected fields
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
        // Set the updated state
        thus.getCustomerLinks()
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ error: "Sorry but there was an error cancelling the link. Please try again.", genLoading: false });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.setState({ error: "Sorry but there was an error cancelling the link. Please try again.", genLoading: false });
      thus.getTokens();
    });

	}

	inArray (needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] === needle) return true;
    }
    return false;
	}

  closeCover (e) {
    e.preventDefault();
    this.setState({ genLnk: "", genLoading: false, rdr: true });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  componentDidMount () {
		this.getCustomerInvestments();
    this.getTokens();
  }

  render() {

    const { isLoading, investments, custRef,  error, customerName,latestInvestmentData,investmentWrapperMap,investmentWrapperExpandMap,embedded } = this.state;

    if (this.state.rdr) {
      return <Redirect to={"/admin/clients/details/" + custRef} />
    }

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if(!isLoading){

      if(investments.length > 0){
        const options = {
          title: "Investment Value",
          curveType: "function",
          legend: {position: 'none'},
          chartArea:{top:80,height:'70%'},
          vAxis: { format:'£###,###'} 
        };
  
        let newInvestments = [];
  
        let referencesToCheck = investments[0];

        newInvestments[0] = ['Date'];
        for(var j = 1; j< referencesToCheck.length;j++){
          if( investmentWrapperMap[referencesToCheck[j]]){
            newInvestments[0].push(referencesToCheck[j]);
          }
        }

        for(var i = 1; i<investments.length;i++){

          let date = investments[i][0]
          newInvestments[i] = [date]; //initialise new investment row with date column

          for(j = 1; j< investments[i].length;j++){
            if( investmentWrapperMap[referencesToCheck[j]]){
              newInvestments[i].push(investments[i][j]);
            }
          }
          
        }
   
        let series = {}
  
        for(var l = 0; l< newInvestments[0].length - 1 ;l++){
          series[l] = {
            lineWidth:1,
            color:'#9d7349'
          }
        }
       
        if(investmentWrapperMap['Overall']){
          series[newInvestments[0].length -2] = {
            lineWidth:2,
            color:'#4e9043'
         }
        }
        

        options.series = series;
        let overallTotal = 0;


        for(let i =0; i<=latestInvestmentData.length;i++){
          if(typeof latestInvestmentData[i] !== 'undefined'){
            overallTotal += parseFloat(latestInvestmentData[i].total_value)
          }
          
        }
        content = 
        <Fragment>
          <Chart
            chartType="LineChart"
            width="100%"
            height="650px"
            format="currency"
            data={newInvestments}
            options={options}
          />
          <table className='investments'>
          <tbody>
            <tr>
              <th>&nbsp;</th>
              {/* <th>Investment Key</th> */}
              <th>Investment Name</th>
              <th>Owner</th>
              {/* <th>Wrapper Key</th>
              <th>Wrapper Name</th>
              <th>Number of Units</th>
              <th>Price</th> */}
              <th>Value</th>
              <th></th>
            </tr>
            
            {latestInvestmentData.map((invt,i) => {

              let wrapperName = invt.local_wrapper_name;

              if(wrapperName == null){
                wrapperName = invt.wrapper_name
              }

              let wrapperDetails = wrapperName.split('-');

              let wrapperNameText = wrapperDetails[0];

              let owner = typeof wrapperDetails[1] !== 'undefined' ? wrapperDetails[1] : '-';

              let expandedData = null;

              if(investmentWrapperExpandMap[wrapperName]) {
                expandedData = invt.investment_breakdown.map((brkdown,i) => {
                  return (
                  <tr className='comm-row comm-row-sub' key={'investment-row-brkdown'+i} >
                  <td></td>
                  {/* <td>{invt.transact_investment_key}</td> */}
                  <td  >{brkdown.investment_name}</td>
                  <td></td>
                  {/* <td>{invt.wrapper_key}</td>
                  <td>{invt.wrapper_name}</td>
                  <td>{invt.number_of_units}</td>
                  <td>{invt.price}</td> */}
                  <td><NumberFormat
                    value={ brkdown.investment_value }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '£' }
                    decimalSeparator={ '.' }
                    decimalScale={ 2 }
                    fixedDecimalScale={ true }
                  /></td>
                  <td></td>
                </tr>
                  )
                })
              }
              return (
                <Fragment key={'investment-row-'+i}>
                <tr className='comm-row' >
                  <td> 
                    
                    <input style={ styles.cbInput } defaultChecked={ investmentWrapperMap[wrapperName] === true ? "checked":"" } type="checkbox" onChange={ (e) => this.toggleCustomerInvestment(e,wrapperName) }/></td>
                  {/* <td>{invt.transact_investment_key}</td> */}
                  <td>{wrapperNameText}</td>
                  <td>{owner }</td>
                  {/* <td>{invt.wrapper_key}</td>
                  <td>{invt.wrapper_name}</td>
                  <td>{invt.number_of_units}</td>
                  <td>{invt.price}</td> */}
                  <td><NumberFormat
                    value={ invt.total_value }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '£' }
                    decimalSeparator={ '.' }
                    decimalScale={ 2 }
                    fixedDecimalScale={ true }
                  />
                  </td>
                  <td><span className={investmentWrapperExpandMap[wrapperName]  ? 'expand-icon collapse' : 'expand-icon'  } onClick={(e)=> this.toggleExpandedInvestment(e,wrapperName) }></span></td>
                </tr>
                {expandedData}
               
                
                </Fragment>
              )
            })}
             <tr className='comm-row'>
                  <td><input style={ styles.cbInput }  defaultChecked={ investmentWrapperMap['Overall'] === true ? "checked":"" } type="checkbox" onChange={ (e) => this.toggleCustomerInvestment(e,'Overall') }/></td>
                  <td><b>Total:</b></td>
                  <td>&nbsp;</td>
                  {/* <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td> */}
                  {/* <td>&nbsp;</td>
                  <td>&nbsp;</td> */}
                  <td><b><NumberFormat
                    value={ overallTotal }
                    displayType={ 'text' }
                    thousandSeparator={ true }
                    prefix={ '£' }
                    decimalSeparator={ '.' }
                    decimalScale={ 2 }
                    fixedDecimalScale={ true }
                  /></b></td>
                  <td></td>
                </tr>
            </tbody>
          </table>
        </Fragment>
      } else {
        content = <p>Sorry but no investment data has been found for this customer</p>
      }
      }

    
    

    var errorContent = "";
    if (error !== "") {
      errorContent = <div className='error mbtm' style={ styles.full }>
                      { error }
                    </div>
    }

    return (
      <div className={!embedded  ? 'width-12 centred' :'width-11 centred'} >

        {!embedded ? (
        <Fragment>
          <Link style={{ ...styles.lnkRght, ...styles.grey }} to={ "/admin/clients/details/" + custRef }>Back</Link>
          <Link to={ "/admin/emails/1/" + custRef } style={{ ...styles.lnkRght, ...styles.grey,  ...styles.email }}>Communications</Link>
        
          <Heading mainTitle="Transact Investments" subTitle={ customerName } />
        </Fragment>
        ) : null }
        <FlashMsg />

        { errorContent }
        { content }

      </div>
    );
  }
}

var styles = {
  full: {
    width: '99%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
	grey: {
		background: Colours.txtGrey,
		fontSize: 14
	},
  form: {
    overflow: 'auto',
    display: 'block'
  },
  half: {
    width: "49%",
    float: "left",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: "0.5%",
    marginRight: "0.5%"
  },
  label: {
    marginLeft: 8
  },
	bgGrey: {
		background: Colours.bgGrey,
		padding: "12px 20px",
		boxSizing: "border-box"
	},
	lighten: {
		opacity: "0.6",
		fontSize: 14
	},
	emptyMsg: {
		fontStyle: "italic",
		opacity: "0.6",
		marginTop: 12
	},
  fileList:{
    fontSize:14,
    marginLeft:14,
    marginBottom:14
  },
  file:{
    fontSize:14,
    marginLeft:14
  },
  fileLink:{
    marginBottom:0
  },
  fileTitle:{
    marginBottom:0,
    fontSize:14,
  },
  cb: {
    textAlign: 'center',
    width: '7%',
    float: 'left',
    marginTop: 5,
    marginRight: '2.5%'
  },
  cbInput: {
    margin: "15px auto",
    display: 'inline-block',
    width: 'auto'
  },
  email: {
		marginLeft: 10,
    background: Colours.txtGrey + " url('" + Email + "') left 13px center / 17px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
		paddingRight: 15,
    paddingLeft: 38,
    fontWeight:400
	},
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(TransactInvestments);
