import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Fetching from './../../../functions/fetching.js';
import MicroThumb from './micro-thumb.js';

class ObjectiveTemplates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tid: false,
            options: [],
            openDd: false
        };

        this.updateField = this.updateField.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
    }

    // Run a fetch to get the options for this item
    getOptions ( openDd ) {

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/admin/template_responses/get_list/' + this.props.qid + '/' + this.props.type, { method: 'get', credentials: "include" })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                if (openDd) {
                    thus.setState({ options: data.rslts, openDd: true });
                } else {
                    thus.setState({ options: data.rslts });
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    toggleOptions () {
        var { openDd } = this.state;
        openDd = (!openDd) ? true : false ;
        if ( openDd ) {
            this.getOptions( true );
        } else {
            this.setState({ openDd: openDd });
        }
    }

    getTokens(resubmit = false, qId, repeatNo) {
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials: "include" })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                thus.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });
    }

    saveTemplate () {

        // Figure out what type of save it is + hence the URL
        let { tid, openDd } = this.state;
        var url = process.env.REACT_APP_API_BASE + "/admin/template_responses/create/";
        if (tid) {
            url = process.env.REACT_APP_API_BASE + "/admin/template_responses/update/" + tid + "/" + this.props.type
        } 

        // Set up the save data 
        var txtContent = this.props.content;
        let saveData = {
            qid: this.props.qid,
            content: encodeURIComponent(txtContent.replace(/"/g, '\\"')),
            type: this.props.type
        };

        // Run the fetch request to save
        var thus = this;
        fetch(url, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            credentials: "include",
            body: 'data=' + JSON.stringify(saveData) + '&' + thus.props.tokenKey + '=' + thus.props.token
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt != "undefined" && data.rslt === 'success') {
                if ( openDd ) {
                    console.log('BOOM');
                    thus.getOptions();
                }
                alert("The template has been saved successfully and will be made available in the templates list.");
            } else {
                alert(data.msg);
            }
            thus.getTokens();
        })
        .catch(function (error) {
            thus.getTokens();
        });

    }

    updateField (e, tid, content) {
        e.preventDefault();
        this.props.updateField(content);
        this.setState({ tid: tid, openDd: false });
    }

    updateImgField(e, tid, imgId, tmbUrl) {
        e.preventDefault();
        this.props.updateImgField(imgId, tmbUrl);
        this.setState({ tid: tid, openDd: false });
    }

    deleteTemplate (e, tid) {
        e.preventDefault();

        var doDelete = window.confirm('This will delete this template.  Do you wish to continue?');
        // Run the deletion
        if(doDelete){
            var thus = this;
            fetch(process.env.REACT_APP_API_BASE + "/admin/template_responses/delete/" + tid, {
                method: 'get',
                credentials: "include",
            })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                if (typeof data.rslt != "undefined" && data.rslt === 'success') {
                    alert("The template has been deleted successfully.");
                    thus.getOptions(true);
                } else {
                    alert(data.msg);
                }
                thus.getTokens();
            })
            .catch(function (error) {
                thus.getTokens();
            });
        }
        
    }

    render () {

        let { options, openDd } = this.state;

        var ddClass = "dd-area";
        if ( openDd ) {
            ddClass += " dd-open";
        }

        return <div className="btn-bar">
            { this.props.content ? (
                <button className="save-btn" onClick={ (e) => this.saveTemplate(e) }>Save as template</button>
            ) : "" }
            <div className="dd-btn-area">
                <button className="select-btn" onClick={ (e) => this.toggleOptions(e) }>Select template</button>
                <div className={ ddClass }>
                    { options.length > 0 ? (
                        <ul>
                            { this.props.type.indexOf("img") > -1 ? (
                                <Fragment>
                                    { options.map(opt =>
                                            <li key={opt.id}>
                                                <MicroThumb 
                                                    url={opt.microTmb}
                                                    tmbUrl={opt.tmb}
                                                    imgId={ opt.imgId } 
                                                    updateImgField={(e) => this.updateImgField( e, opt.id, opt.imgId, opt.tmb) }
                                                    deleteTemplate={ (e) => this.deleteTemplate(e, opt.id) }
                                                    tid={ opt.id }
                                                />
                                            </li>
                                        )
                                    }
                                </Fragment>
                            ) : (
                                <Fragment>
                                    { options.map( opt =>
                                        <li key={opt.id}>
                                            <a href="#select" onClick={(e) => this.updateField(e, opt.id, opt.content)}>{opt.truncated}</a>
                                            <a href="#delete" onClick={(e) => this.deleteTemplate(e, opt.id)} className="sml">(Delete template)</a>
                                        </li>
                                    )}
                                </Fragment>
                            )}
                            
                        </ul>
                    ) : (
                        <p className="negative">Sorry but there are no templates available.</p>
                    )}
                </div>
            </div>
        </div>

    }

}

function mapStateToProps(state) {
    return {
        token: state.token,
        tokenKey: state.tokenKey
    };
}

export default connect(mapStateToProps)(ObjectiveTemplates);
