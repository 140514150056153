import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Colours from './../../assets/colours.js';

import Tick from './../../assets/img/tick.png';
import Cross from './../../assets/img/cross.png';

class Tab extends Component {
  handleChangeLocal = ( ) => {
    var curTab = this.props.lnk;
    this.props.handleChange(curTab);
  }

  render() {
    let lnkBase = (typeof this.props.lnkBase != 'undefined')?this.props.lnkBase:"/questionnaire/";
    let pathname = window.location.href;

    // Check whether we are in the admin side of things
    if (pathname.indexOf("/admin/") > -1 || (typeof this.props.isConfirm != "undefined" && this.props.isConfirm === true)) {

      var liClass = this.props.activeClass;
      liClass += " admin";
      if ( this.props.isHidden === "1" ) {
        liClass += " private";
      }

      return (
        <li style={ styles.li } className={ liClass } id={ `lnk-${this.props.lnk}` }>
          <Link style={ styles.tab } to={ lnkBase + this.props.lnk } onClick={this.handleChangeLocal}>{ this.props.title }</Link>
        </li>
      );

    } else {
      return (
        <li style={ styles.li } className={ `${this.props.activeClass} ${this.props.disabledClass} ${this.props.isComplete === "1" ? "complete":"" }` } id={ `lnk-${this.props.lnk}` }>
          { this.props.isComplete === "1" || this.props.isComplete === 1 ? (
            <Link style={{ ...styles.tab, ...styles.complete }} to={ lnkBase + this.props.lnk } onClick={this.handleChangeLocal}>{ this.props.title }</Link>
          ) : (
            <Link style={{ ...styles.tab, ...styles.withCross }} to={ lnkBase + this.props.lnk } onClick={this.handleChangeLocal}>{ this.props.title }</Link>
          ) }
        </li>
      );
    }
  }
}

var styles = {
  li: {
    listStyle: 'none',
    marginBottom: 8
  },
  tab: {
    display: 'block',
    border: '1px solid ' + Colours.lightGrey,
    borderRight: 'none',
    marginRight: 1,
    color: Colours.txtBlack,
    background: Colours.bgWhite,
    textAlign: 'right',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontWeight: 300,
    fontSize: 18,
    fontVariant: 'small-caps'
  },
  withCross: {
    background: Colours.bgWhite + " url(" + Cross + ") left 12px center / 22px auto no-repeat"
  },
  complete: {
    backgroundImage: "url(" + Tick + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "22px auto",
    backgroundPosition: "left 12px center"
  }
};

export default Tab;
