import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Colours from './../../assets/colours.js';

class AdminMenu extends Component {

  render() {

    return (
      <nav style={ styles.nav } className="admin-menu">
        <ul style={ styles.menu }>
          <li style={ styles.menuItem }>
            <Link style={ styles.lnk } to={ `/admin/clients` }>Client Details</Link>
          </li>
          <li style={ styles.menuItem }>
            <Link style={ styles.lnk } to={ `/admin/users` }>Manage Admins</Link>
          </li>
          <li style={ styles.menuItem }>
            <Link style={ styles.lnk } to={ `/admin/questions` }>Questionnaire Content</Link>
          </li>
					<li style={ styles.menuItem }>
            <Link style={ styles.lnk } to={ `/admin/files` }>Files</Link>
          </li>
					<li style={ styles.menuItem }>
            <Link style={ styles.lnk } to={ `/admin/emails` }>Communications</Link>
          </li>
          <li style={ styles.menuItem }>
            <Link style={ styles.lnk } to={ `/admin/management-summary` }>Management Info</Link>
          </li>
          <li style={{ ...styles.bordered, ...styles.menuItem }} className="sign-out">
            <Link style={ styles.lnk } to={ `/admin/logout` }>Sign Out</Link>
          </li>
        </ul>
      </nav>
    );
  }
}

var styles = {
  nav: {
    marginTop: 32
  },
  menu: {
    listStyle: 'none'
  },
  menuItem: {
    display: 'inline-block'
  },
  lnk: {
    textDecoration: 'none',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontVariant: 'small-caps',
    color: Colours.txtBlack
  },
  bordered: {
    paddingLeft: 20,
    marginLeft: 20,
    borderLeft: '1px solid ' + Colours.txtGrey
  }
};

export default AdminMenu;
