import React, { Component } from 'react';
import Colours from './../../../assets/colours.js';
//import ProgressReviewIcon from './../../../assets/img/doc-icon.svg';
import Fetching from './../../../functions/fetching.js';

class ValuationSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isGeneratingValuationSummary:false,
	  showValuationPercentageReturn:false
    };


	this.generateValuationSummary = this.generateValuationSummary.bind(this);
	this.toggleShowValuationPercentageReturn = this.toggleShowValuationPercentageReturn.bind(this);
  }

  generateValuationSummary () {

	  var thus = this;
	  this.props.handleDownloadPDFSubMenuButtonClick();

	  this.setState({isGeneratingValuationSummary:true});

	  let postData = {
		method: 'post',
		headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
		credentials:"include"
	  };

	  if(this.state.showValuationPercentageReturn){
		postData.body = "data="+JSON.stringify({showPercentageReturn:1});
	  }
	  

	  fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/generateValuationSummary/' + this.props.custRef,postData)
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(function (data) {
		  console.log(data);
		thus.props.updateFiles();
		window.open(process.env.REACT_APP_API_BASE+"/admin/files/download/"+data.file,'_blank');
		let isGeneratingValuationSummary = false;
		thus.setState({isGeneratingValuationSummary})


	  })
	  .catch(function (error) {
		console.log('Request failed', error);
		alert('Sorry but there was an error generating and saving the Progress Review. Please try again.');
	  });
  }

  toggleShowValuationPercentageReturn(){
    this.setState((state ) => ({
      showValuationPercentageReturn: !state.showValuationPercentageReturn
    }))
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

 render(){
	 return (
		 <div>
		 <span  className='download-title' >Valuation Summary</span>
 		<button
 			onClick={this.generateValuationSummary}
 			style={{...styles.progressReview,opacity:this.state.isGeneratingValuationSummary ? 0.5: 1  }}
 			disabled={this.state.isGeneratingValuationSummary ? true: false }
 		 >
 		 Download
 		</button>
		 <ul className="spaced-list download-panel-progress-review">
           <li style={styles.dashedBorder}>
           <input type='checkbox' value={1} id='showValuationPercentageReturn' onChange={this.toggleShowValuationPercentageReturn} checked={this.state.showValuationPercentageReturn} />
            <label for='showValuationPercentageReturn' style={styles.percentageReturnText}>
              Show Percentage Return
            </label>
          </li>
          {this.props.dropDownContent}
          
        </ul>
		</div>
 	)


 }

}


var styles = {
	progressReview: {
		marginLeft: 0,
		fontSize:'12px',
		background: Colours.txtGrey ,
		color: Colours.txtWhite,
		textDecoration: 'none',
		padding: "3px 8px",
		cursor: 'pointer',
		border:'none',
		marginTop:0,
		borderRadius:25,
		float:'right',
		
	},
	percentageReturnText:{
		fontStyle:'italic',
		paddingLeft:"5px"
	  }
}

export default ValuationSummary;
