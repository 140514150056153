import React, { Component } from 'react';

import Colours from './../../../../assets/colours.js';
import pound from './../../../../assets/img/pound.svg'

class Contributions extends Component {


  renderLumpSum (contribution,calculatorIndex,i,callbacks,contributionDisplay) {

    const handleContributionFieldInputChange = (e) =>{
      callbacks.handleContributionFieldInputChange(e,calculatorIndex,i)
    }

    return (
      <div key={'cnd-'+calculatorIndex+'-'+i} className="adjustments">
        <div className="inf-container">
          <label htmlFor={ "inflation-" + i }>Inf</label>
          <input id={ "inflation-" + i } type='checkbox' defaultChecked={parseInt(contributionDisplay.has_inflation_applied) === 1} name="inflation" onChange={(e) => callbacks.handleContributionInflationChange(e,calculatorIndex,i)}  />
        </div>
				<div className="type">
					<label htmlFor={ "amount-" + i }>Type</label>
	        <select id={ "amount-" + i } value='lump_sum' onChange={(e) => callbacks.handleContributionTypeChange(e,calculatorIndex,i)}>
	          <option value='lump_sum' >Lump Sum</option>
	          <option value='amount' >Monthly Amount (£)</option>
	        </select>
				</div>

				<div className="amt">
					<label htmlFor={ "val-" + i }>Value</label>
					<input id={ "val-" + i } type='text' value={contributionDisplay.value} name="value"  onChange={ handleContributionFieldInputChange } style={ styles.money } />
				</div>

				<div className="age-container">
					<label htmlFor={ "age-" + i }>Age</label>
					<input htmlFor={ "age-" + i } type='text' className='narrow' value={contributionDisplay.start_age} name="start_age" onChange={handleContributionFieldInputChange} />
				</div>

        <span onClick={(e) => callbacks.handleContributionFieldRemove(e,calculatorIndex,i)} className='close-btn'>x</span>
      </div>
    );
  }

  renderAmount(contribution,calculatorIndex,i,callbacks,contributionDisplay){

    const handleContributionFieldInputChange = (e) =>{
      callbacks.handleContributionFieldInputChange(e,calculatorIndex,i)
    }


    return (
      <div key={'cn-'+calculatorIndex+'-'+i} className="adjustments">
        <div className="inf-container">
          <label htmlFor={ "inflation-" + i }>Inf</label>
          <input id={ "inflation-" + i } type='checkbox' defaultChecked={parseInt(contributionDisplay.has_inflation_applied) === 1} name="inflation"  onChange={(e) => callbacks.handleContributionInflationChange(e,calculatorIndex,i)} />
        </div>
				<div className="type">
					<label htmlFor={ "amount-" + i }>Type</label>
	        <select value='amount' onChange={(e) => callbacks.handleContributionTypeChange(e,calculatorIndex,i)}>
						<option value='lump_sum'>Lump Sum</option>
						<option value='amount'>Monthly Amount (£)</option>
	        </select>
				</div>

				<div className="amt">
					<label htmlFor={ "val-" + i }>Value</label>
					<input id={ "val-" + i } type='text' value={ contributionDisplay.value }  name="value"  onChange={ handleContributionFieldInputChange } style={ styles.money } />
				</div>

				<div className="age-container">
					<label htmlFor={ "age-" + i }>Start</label>
        	<input id={ "age-" + i } type='text' className='narrow'  value={contributionDisplay.start_age} name="start_age" onChange={ handleContributionFieldInputChange } />
				</div>

				<div className="age-container">
					<label htmlFor={ "endage-" + i }>End</label>
					<input id={ "endage-" + i } type='text' className='narrow'  value={contributionDisplay.end_age} name="end_age" onChange={ handleContributionFieldInputChange } />
				</div>

        <span onClick={(e) => callbacks.handleContributionFieldRemove(e,calculatorIndex,i)} className='close-btn'>x</span>
      </div>
    );
  }

  render() {

   const calculatorIndex = this.props.index;
   const callbacks = this.props.contributionCallbacks;

   const contributions = this.props.contributions.map((contribution,i) => {

     if(contribution.can_edit === 1){
       const contributionDisplay = this.props.contribution_displays[i];

       switch(contribution.type){
         case 'lump_sum':
         default:
          return this.renderLumpSum(contribution,calculatorIndex,i,callbacks,contributionDisplay);
        case 'amount':
          return this.renderAmount(contribution,calculatorIndex,i,callbacks,contributionDisplay);
       }
     } else {
       return null;
     }


   })

    return (
      <div>
      <p className='note' >
      Start Value:
      <span className='start-value'>
      {'£' + this.props.startValue}
      </span>
      </p>
        { contributions }
        <button onClick = {(e) => callbacks.handleContributionFieldAdd(e,calculatorIndex) } className="addn" >Add</button>
      </div>
    );
  }
}

const styles = {
	money: {
    paddingLeft: 14,
    background: Colours.bgWhite + " url(" + pound + ") left 6px center / 5px auto no-repeat",
  }
};

export default Contributions;
