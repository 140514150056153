import {DISPLAY_BOTH,DISPLAY_SAVINGS,DISPLAY_PREDICTIONS,DISPLAY_TARGET_ONLY} from './constants/constants.js'

//Blue/Black Line Prediction

//Investment return 2%
//inflation 1%

//Year 35
//Day 1: forecastForYear - £15000, contributionValue - £100

//Total for this year: £15100

//Year 36
//Day 1: forecastForYear - £15100 * 1.02 , contributionValue - £101.00, (£100 with 1 year inflation) investmentReturn 2%

//Total for this year:  forecastForYear + contributionValue

//Year 37
//totalForLastYear (£15100 * 1.02  + 101)
//forecastForYear- totalForLastYear * 1.02, contributionValue - £102.1 (£100 with 2 years inflation)
//
//Total for the year = forecastForYear + contributionValue

//************************************************************************//

//Green Prediction

//Investment return 3%
//inflation 1%

//Year 35
//Day 1: forecastForYear - £15000, contributionValue - £100

//Total for this year: £15100

//Year 36
//totalBlackForLastYear (£15100 * 1.02  + 101)
//Day 1: forecastForYear - £15100 * 1.03 , contributionValue - £101.00, (£100 with 1 year inflation) investmentReturn 2%

//Total for this year:  forecastForYear + contributionValue

//Year 37
//totalBlackForLastYear (£15100 * 1.02  + 101)
//forecastForYear- totalForLastYear * 1.03, contributionValue - £102.1 (£100 with 2 years inflation)
//
//Total for the year = forecastForYear + contributionValue

//************************************************************************//

//Red Prediction

//Investment return -1%
//inflation 1%

//Year 35
//Day 1: forecastForYear - £15000, contributionValue - £100

//Total for this year: £15100

//Year 36
//totalBlackForLastYear (£15100 * 1.02  + 101)
//Day 1: forecastForYear - totalBlackForLastYear * 0.99 , contributionValue - £101.00, (£100 with 1 year inflation) investmentReturn 2%

//Total for this year:  forecastForYear + contributionValue

//Year 37
//totalBlackForLastYear (£15100 * 1.02  + 101)
//forecastForYear- totalBlackForLastYear * 0.99, contributionValue - £102.1 (£100 with 2 years inflation)
//
//Total for the year = forecastForYear + contributionValue

//helper function to round float to set place count
export function _doRound(number,places){
  return +(Math.round(number + "e+" + places)  + "e-" + places);
}

//if a contribution has been applied in this year, add to the contribution dataset for year
function _addContributionData(contributionArray,contribution){

  let contributionValue = 0;
  switch(contribution.type){
    case 'lump_sum':
    default:
      contributionValue = parseFloat(contribution.value);
      break;
    case 'amount':
      contributionValue = parseFloat((12 * contribution.value) );
      break;
  }

  let applyContributionInflation = (contribution.can_edit === 1 && contribution.has_inflation_applied === 1) ? true: false;
  let isHistorical = (contribution.is_historical === 1) ? true: false;

  let contributionObj = {
    contributionValue,
    applyContributionInflation,
	isHistorical
  };

  contributionArray.push(contributionObj);
  return contributionArray;

}

//if a withdrawal has been applied in this year, add to the withdrawals dataset for year
function _addWithdrawalData(withdrawalArray,withdrawal,originalForecastForYear = false){

  let applyWithdrawalInflation;
  let withdrawalValue = 0;

  switch(withdrawal.type){

    case 'starting_amount':
      withdrawalValue = parseFloat(withdrawal.value);
      applyWithdrawalInflation = true;
      break;
    case 'lump_sum':
    default:
      withdrawalValue = parseFloat(withdrawal.value);
      applyWithdrawalInflation = (withdrawal.can_edit === 1 && withdrawal.has_inflation_applied === 1) ? true: false;
      break;
    case 'amount':
      withdrawalValue = parseFloat((12 * withdrawal.value) );
      applyWithdrawalInflation = (withdrawal.can_edit === 1 && withdrawal.has_inflation_applied === 1) ? true: false;
      break;
    case 'percentage':
      withdrawalValue = originalForecastForYear * (1 - (parseFloat(12 * withdrawal.value) / 100)) ;
      applyWithdrawalInflation = (withdrawal.can_edit === 1 && withdrawal.has_inflation_applied === 1) ? true: false;
      break;
  }

  let isHistorical = (withdrawal.is_historical === 1) ? true: false;

  let withdrawalObj = {
    withdrawalValue,
    applyWithdrawalInflation,
	isHistorical
  };

  withdrawalArray.push(withdrawalObj);
  return withdrawalArray;

}

//sum the contributions made for a year, applying inflation if set
function _assembleContributionEntry(contributionData,inflationRateAmount ){
  let contributionEntry = contributionData.reduce((val,contribution,index) => {
    let contributionValue =  (contribution.applyContributionInflation === true) ? contribution.contributionValue * inflationRateAmount : contribution.contributionValue;
    return val + contributionValue;
  },0) ;

  return contributionEntry;
}

//sum the withdrawals made for a year, applying inflation if set
function _assembleWithdrawalEntry(withdrawalData,inflationRateAmount ){
  let withdrawalEntry = withdrawalData.reduce((val,withdrawal) => {
    let withdrawalValue = (withdrawal.applyWithdrawalInflation === true) ? withdrawal.withdrawalValue * inflationRateAmount : withdrawal.withdrawalValue;
    return val +  withdrawalValue;
  },0) ;

  return withdrawalEntry;
}

//handle display of contribution bars depending on Display Options toggle
function _cumulativeContributionGraphEntry(cumulativeContributionGraphEntry,contributionEntry,displayType,isPredictedInvestment){
  if(displayType === DISPLAY_PREDICTIONS && !isPredictedInvestment){
    cumulativeContributionGraphEntry = 0
  } else if(displayType === DISPLAY_SAVINGS && isPredictedInvestment ){
    cumulativeContributionGraphEntry = 0
  } else {
    cumulativeContributionGraphEntry += contributionEntry;
  }

  return cumulativeContributionGraphEntry;
}

//handle display of withdrawal bars depending on Display Options toggle
function _cumulativeWithdrawalGraphEntry(cumulativeWithdrawalGraphEntry,withdrawalEntry,displayType,isPredictedWithdrawal){
  if(displayType === DISPLAY_PREDICTIONS && !isPredictedWithdrawal){
    cumulativeWithdrawalGraphEntry = 0
  } else if(displayType === DISPLAY_SAVINGS && isPredictedWithdrawal ){
    cumulativeWithdrawalGraphEntry = 0
  } else {
    cumulativeWithdrawalGraphEntry +=withdrawalEntry;
  }

  return cumulativeWithdrawalGraphEntry;
}

//add on investment to forecast, adding contributions and withdrawals for year
function _getOriginalForecastForYearEntry(originalForecastForYear,investmentReturn,withdrawalEntry,contributionEntry){
  originalForecastForYear *= (1 + investmentReturn);
  originalForecastForYear = originalForecastForYear - withdrawalEntry + contributionEntry;

  return originalForecastForYear;
}

function removeIndicesFromDataSet(dataRow,indicesToRemove){

  let newDataRow = [...dataRow];

    for(var j = indicesToRemove.length -1 ; j >=0 ; j--){
     newDataRow.splice(indicesToRemove[j],1);

    }

  return newDataRow ;
}

//get inflation rate amount
export function getInflationRateAmount(dataOptions,activeCalculator,yearIncrement){
  let inflationRateAmount = 1;
  if(dataOptions.enableInflation === true){
    inflationRateAmount = Math.pow( 1 + (activeCalculator.inflation_rate / 100), yearIncrement);
  }

  return inflationRateAmount;
}

function getStartingAgeToRenderGraphFrom(initialSavingsAge,savings,contributions,withdrawals){

  //check for historical savings data
  for(let age in savings){
    if(age < initialSavingsAge){
      initialSavingsAge = age;
    }
  }

  //check historical contribution data
  for(let i in contributions){
    if(contributions[i].start_age < initialSavingsAge){
      initialSavingsAge = contributions[i].start_age;
    }
  }

  //check historical contribution data
  for(let i in withdrawals){
    if(withdrawals[i].start_age < initialSavingsAge){
      initialSavingsAge = withdrawals[i].start_age;
    }
  }

  return initialSavingsAge;
}

export function getTargetValue(calculatorData){
  let target = 0;

  if( calculatorData.is_specific_amount === 1){
    target = calculatorData.target
  } else {
    target = (calculatorData.withdrawal_rate > 0)  ? (calculatorData.target  ) / (calculatorData.withdrawal_rate / 100) : calculatorData.target ;
  }

  return target;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//main function for generating dataset to populate Google Chart grph
export function createDataEntries(displayType,savings,initialSavingsAge,retirementAge,presentAge,activeCalculator,target,dataOptions,indicesToRemove)
{



  const DECIMAL_POINT_COUNT = 2;
  const contributions = activeCalculator.contributions;
  const withdrawals = activeCalculator.withdrawals;

  initialSavingsAge =  getStartingAgeToRenderGraphFrom(initialSavingsAge,savings,contributions,withdrawals)

  let originalForecastForYear = savings[initialSavingsAge];
  let currentForecastForYear = null;
  let upperForecastForYear = null;
  let lowerForecastForYear = null;

  let presentDaySavings = 0;

  if(typeof Object.values(savings).pop() !== 'undefined'){
    presentDaySavings = Object.values(savings).pop();
  }

  let data = [];

  let targetYearDifference = retirementAge - presentAge;

  let targetInflationRateAmount =  getInflationRateAmount(dataOptions,activeCalculator,targetYearDifference);

  //apply inflation to target and make it constant
  let targetEntry = target * targetInflationRateAmount;
  targetEntry = _doRound(targetEntry,DECIMAL_POINT_COUNT);

  let i,j;

  let cumulativeContributionGraphEntry = 0;
  let cumulativeWithdrawalGraphEntry = 0;

  let investmentReturn = (activeCalculator.annual_investment_return / 100);
  let higherRateReturn =  (activeCalculator.assumed_rate_high / 100);
  let lowerRateReturn =(activeCalculator.assumed_rate_low / 100);

  const investmentReturnWithoutInflation = ((activeCalculator.annual_investment_return - activeCalculator.inflation_rate) / 100);
  const higherRateReturnWithoutInflation =  ((activeCalculator.assumed_rate_high - activeCalculator.inflation_rate)/ 100);
  const lowerRateReturnWithoutInflation = ((activeCalculator.assumed_rate_low -activeCalculator.inflation_rate )/ 100);

  let isPreviousInvestmentHistorical = false;
  let isPreviousWithdrawalHistorical = false;

  let counter = 0;
  //Deal with investments prior to retirement
  for(i = initialSavingsAge; i< retirementAge; i++){

    let investmentAge = i;
    let knownSavingsEntry = null;
    let knownSavingsGraphEntry = null;
    let originalForecastGraphEntry = null;

    const yearIncrement = investmentAge - presentAge;
    let inflationRateAmount = dataOptions.enableInflation === true ?  Math.pow( 1 + (activeCalculator.inflation_rate / 100), yearIncrement) : 1;

    //Set value for area graph if found
    if(typeof savings[investmentAge] !== "undefined"){
      knownSavingsEntry = savings[investmentAge];
    }

    if(investmentAge === presentAge){
      //initialise from most recent savings
      currentForecastForYear = presentDaySavings;
      lowerForecastForYear = presentDaySavings;
      upperForecastForYear = presentDaySavings;
    } else if (investmentAge < presentAge ){
      //no line shown
      currentForecastForYear = null;
      lowerForecastForYear = null;
      upperForecastForYear = null;
    }


    let contributionData =[];
    let withdrawalData =[];

    let isPredictedInvestment,isPredictedWithdrawal

	let contributionEntry = 0;
	let withdrawalEntry = 0;

    //find calculations
    for( j = 0; j < contributions.length;j++){

      contributionEntry = 0;
      isPredictedInvestment =false;
      //lump sums
      if(contributions[j].type === 'lump_sum' && parseInt(contributions[j].start_age) === investmentAge){
        let contribution= contributions[j];
        contributionData = _addContributionData(contributionData,contribution);
        isPredictedInvestment = (contribution.can_edit === 1) ? true : false;
      }

      //monthly amounts
      if(contributions[j].type === 'amount' && ( contributions[j].start_age <= investmentAge && investmentAge <= contributions[j].end_age)){
        let contribution= contributions[j];
        contributionData = _addContributionData(contributionData,contribution);
        isPredictedInvestment = (contribution.can_edit === 1) ? true : false;
      }

    }

    //find withdrawals
    for(j = 0; j < withdrawals.length;j++){
      isPredictedWithdrawal =false;
      withdrawalEntry = 0;

      if(withdrawals[j].type === 'lump_sum' && parseInt(withdrawals[j].start_age) === investmentAge){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }

      if(withdrawals[j].type === 'amount' && ( withdrawals[j].start_age <= investmentAge && investmentAge <= withdrawals[j].end_age)){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }

      if( withdrawals[j].type === 'percentage' && (  withdrawals[j].start_age <= investmentAge && investmentAge <=  withdrawals[j].end_age)){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal,originalForecastForYear);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }
    }



    if(contributionData.length >0){
      contributionEntry = _doRound(_assembleContributionEntry(contributionData,inflationRateAmount),DECIMAL_POINT_COUNT);
	  if(contributionData[0].isHistorical){
		  //historical withdrawal records
		  //don't sum past entries
		 cumulativeContributionGraphEntry = contributionEntry;
		 isPreviousInvestmentHistorical = true;
	 } else if(!contributionData[0].isHistorical && isPreviousInvestmentHistorical){
		 //first record of non-historical data so don't sum to previous data
		  //don't sum past entries
		 cumulativeContributionGraphEntry = contributionEntry;
		 isPreviousInvestmentHistorical = false;
     } else {
		 //start accumulating the data
		 cumulativeContributionGraphEntry = _doRound(_cumulativeContributionGraphEntry(cumulativeContributionGraphEntry,contributionEntry,displayType,isPredictedInvestment),DECIMAL_POINT_COUNT);;
		 isPreviousInvestmentHistorical = false;
	  }

    }

	if(withdrawalData.length >0){
      withdrawalEntry = _doRound(_assembleWithdrawalEntry(withdrawalData,inflationRateAmount),DECIMAL_POINT_COUNT);
	  if(withdrawalData[0].isHistorical){
		  //historical withdrawal records
		   //don't sum past entries
		 cumulativeWithdrawalGraphEntry = withdrawalEntry;
		 isPreviousWithdrawalHistorical = true;
	 } else if(!withdrawalData[0].isHistorical && isPreviousWithdrawalHistorical){
		 //first record of non-historical data so don't sum to previous data
		  //don't sum past entries
		 cumulativeWithdrawalGraphEntry = withdrawalEntry;
		 isPreviousWithdrawalHistorical = false;
     } else {
		 //start accumulating the data
		 cumulativeWithdrawalGraphEntry = _doRound(_cumulativeWithdrawalGraphEntry(cumulativeWithdrawalGraphEntry,withdrawalEntry,displayType,isPredictedWithdrawal),DECIMAL_POINT_COUNT);;
		 isPreviousWithdrawalHistorical = false;
	  }

    }

    knownSavingsGraphEntry =  ( displayType === DISPLAY_PREDICTIONS   ) ? 0 : knownSavingsEntry;
    originalForecastGraphEntry = (dataOptions.hideOriginalForecast) ? null : originalForecastForYear;

	if(investmentAge - presentAge === 1){
	  //Dealing with the initial value of questionaire data


	  let lastYearsContributions = 0;//Default to 0 in case any undefined issues arise

	  if(data[counter-1] !== undefined  && data[counter-1][2] !== undefined){
		   //So get last years contributions if present(these will be historical entries entered from the graph page)
		  lastYearsContributions = data[counter-1][2];
	  }

	  let customContributionInput = 0;
	  let customWithdrawalInput = 0;

	  //get the custom contributions from the calculator to add back in
	  if(contributionData.length > 0){
		  for (let e = 0; e< contributionData.length;e++){
			  customContributionInput += contributionData[e].contributionValue;
		  }
	  }

	  //get the custom withdrawals from the calculator to add back in
	  if(withdrawalData.length > 0){
		  for (let e = 0; e< withdrawalData.length;e++){
			  customWithdrawalInput += withdrawalData[e].withdrawalValue;
		  }
	  }

	  //remove last years contributions (inital setup) from the
	  currentForecastForYear = (currentForecastForYear - lastYearsContributions) + customContributionInput-customWithdrawalInput;
	  lowerForecastForYear = (lowerForecastForYear - lastYearsContributions) + customContributionInput - customWithdrawalInput;
	  upperForecastForYear = (upperForecastForYear - lastYearsContributions) + customContributionInput - customWithdrawalInput;


   }



    let dataRow = [investmentAge,knownSavingsGraphEntry,cumulativeContributionGraphEntry,cumulativeWithdrawalGraphEntry,originalForecastGraphEntry ,lowerForecastForYear,currentForecastForYear,upperForecastForYear,targetEntry]

    dataRow = removeIndicesFromDataSet(dataRow,indicesToRemove)
    data.push(dataRow);

    //apply investment return original line graph entry
    originalForecastForYear  = _doRound(_getOriginalForecastForYearEntry(originalForecastForYear,investmentReturn,withdrawalEntry,contributionEntry),DECIMAL_POINT_COUNT);

    //apply investment returns to predictions from current savings if investment year equal or greater to current age
    if(investmentAge >= presentAge){
      let forecastForLastYear = currentForecastForYear;

      investmentReturn = (dataOptions.enableInflation === true) ? investmentReturn : investmentReturnWithoutInflation;
      higherRateReturn = (dataOptions.enableInflation === true) ? higherRateReturn :  higherRateReturnWithoutInflation ;
      lowerRateReturn = (dataOptions.enableInflation === true) ? lowerRateReturn : lowerRateReturnWithoutInflation;

      lowerForecastForYear = (forecastForLastYear *  lowerRateReturn) + forecastForLastYear
      currentForecastForYear = (forecastForLastYear * investmentReturn) + forecastForLastYear
      upperForecastForYear = (forecastForLastYear * higherRateReturn) + forecastForLastYear

      currentForecastForYear += contributionEntry  - withdrawalEntry;
      lowerForecastForYear +=contributionEntry - withdrawalEntry;
      upperForecastForYear += contributionEntry  - withdrawalEntry;

      currentForecastForYear = _doRound(currentForecastForYear,DECIMAL_POINT_COUNT);
      lowerForecastForYear = _doRound(lowerForecastForYear,DECIMAL_POINT_COUNT);
      upperForecastForYear = _doRound(upperForecastForYear,DECIMAL_POINT_COUNT);

    }

	counter++;

  }


  //handle retirement
  for( i = retirementAge; i<= 100; i++) {

    let pensionerAge = i;
    let contributionGraphEntry = 0;
    let withdrawalGraphEntry = 0;
    let knownSavingsEntry = null;
    let knownSavingsGraphEntry;
    let originalForecastGraphEntry;

    let contributionData =[];
    let withdrawalData =[];


    //Check for savings
    if(typeof savings[pensionerAge] !== "undefined"){
      knownSavingsEntry = savings[pensionerAge];
    }

    if(pensionerAge === presentAge){
      currentForecastForYear = presentDaySavings;
    } else if (pensionerAge < presentAge ){
      currentForecastForYear = null;
    }

    let yearIncrement = pensionerAge - presentAge;
    let inflationRateAmount = dataOptions.enableInflation ?  Math.pow( 1 + (activeCalculator.inflation_rate / 100), yearIncrement) : 1;

    let isPredictedInvestment =false;
    let isPredictedWithdrawal =false;

    let contributionEntry = 0;
    let withdrawalEntry = 0;

    //allow contributions into retirement.  We keep the same format for prior retirement to ensure model doesn't break for silly values
    for(j = 0; j < contributions.length;j++){

      contributionEntry = 0;
      //lump sums
      if(contributions[j].type === 'lump_sum' && parseInt(contributions[j].start_age) === pensionerAge){

        let contribution= contributions[j];
        contributionData = _addContributionData(contributionData,contribution);
        isPredictedInvestment = (contribution.can_edit === 1) ? true : false;
      }

      //monthly amounts
      if(contributions[j].type === 'amount' && ( contributions[j].start_age <= pensionerAge && pensionerAge <= contributions[j].end_age)){
        let contribution= contributions[j];
        contributionData = _addContributionData(contributionData,contribution);
        isPredictedInvestment = (contribution.can_edit === 1) ? true : false;
      }
    }

    //subtract withdrawals for retirement
    for( j = 0; j < withdrawals.length;j++){

      withdrawalEntry = 0;

      if(withdrawals[j].type === 'lump_sum' && parseInt(withdrawals[j].start_age) === pensionerAge){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }

      if(withdrawals[j].type === 'amount' && ( withdrawals[j].start_age <= pensionerAge && pensionerAge <= withdrawals[j].end_age)){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }

      if( withdrawals[j].type === 'starting_amount' && (  withdrawals[j].start_age <= pensionerAge && pensionerAge <=  withdrawals[j].end_age)){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }

      if( withdrawals[j].type === 'percentage' && (  withdrawals[j].start_age <= pensionerAge && pensionerAge <=  withdrawals[j].end_age)){
        let withdrawal= withdrawals[j];
        withdrawalData = _addWithdrawalData(withdrawalData,withdrawal,originalForecastForYear);
        isPredictedWithdrawal = (withdrawal.can_edit === 1) ? true : false;
      }
    }

    if(contributionData.length >0){
      contributionEntry = _doRound(_assembleContributionEntry(contributionData,inflationRateAmount),DECIMAL_POINT_COUNT);
      cumulativeContributionGraphEntry = _doRound(_cumulativeContributionGraphEntry(cumulativeContributionGraphEntry,contributionEntry,displayType,isPredictedInvestment),DECIMAL_POINT_COUNT);;
    }


    if(withdrawalData.length >0){
      withdrawalEntry = _doRound(_assembleWithdrawalEntry(withdrawalData,inflationRateAmount ),DECIMAL_POINT_COUNT);
      cumulativeWithdrawalGraphEntry = _doRound(_cumulativeWithdrawalGraphEntry(cumulativeWithdrawalGraphEntry,withdrawalEntry,displayType,isPredictedWithdrawal),DECIMAL_POINT_COUNT);
    }

    knownSavingsGraphEntry =  ( displayType === DISPLAY_PREDICTIONS ) ? 0 : knownSavingsEntry;

    originalForecastGraphEntry = (dataOptions.hideOriginalForecast) ? null : originalForecastForYear;

    cumulativeContributionGraphEntry += contributionGraphEntry;
    cumulativeWithdrawalGraphEntry += withdrawalGraphEntry;

    if( displayType === DISPLAY_SAVINGS   ) {
      cumulativeContributionGraphEntry =0;
      cumulativeWithdrawalGraphEntry =0;
    }

    let dataRow = [pensionerAge,knownSavingsGraphEntry,cumulativeContributionGraphEntry,cumulativeWithdrawalGraphEntry,originalForecastGraphEntry ,lowerForecastForYear,currentForecastForYear,upperForecastForYear,targetEntry]

    dataRow = removeIndicesFromDataSet(dataRow,indicesToRemove)

    data.push(  dataRow);

    //apply investment return
    originalForecastForYear = _doRound(_getOriginalForecastForYearEntry(originalForecastForYear,investmentReturn,withdrawalEntry,contributionEntry),DECIMAL_POINT_COUNT);

    //add contributions and subtract withdrawals to year investment for next year
    if(pensionerAge >= presentAge){
      let forecastForLastYear = currentForecastForYear;

      investmentReturn = (dataOptions.enableInflation === true) ? investmentReturn : investmentReturnWithoutInflation;
      higherRateReturn = (dataOptions.enableInflation === true) ? higherRateReturn :  higherRateReturnWithoutInflation ;
      lowerRateReturn = (dataOptions.enableInflation === true) ? lowerRateReturn : lowerRateReturnWithoutInflation;

      lowerForecastForYear = (forecastForLastYear *  lowerRateReturn) + forecastForLastYear
      currentForecastForYear = (forecastForLastYear * investmentReturn) + forecastForLastYear
      upperForecastForYear = (forecastForLastYear * higherRateReturn) + forecastForLastYear

	  currentForecastForYear += contributionEntry - withdrawalEntry;
      lowerForecastForYear +=contributionEntry - withdrawalEntry;
      upperForecastForYear += contributionEntry - withdrawalEntry

      currentForecastForYear = _doRound(currentForecastForYear,DECIMAL_POINT_COUNT);
      lowerForecastForYear = _doRound(lowerForecastForYear,DECIMAL_POINT_COUNT);
      upperForecastForYear = _doRound(upperForecastForYear,DECIMAL_POINT_COUNT);

    }
  }

  data = stripUnneededColumns(data,displayType)

  return data;
}

//remove unwanted columns depending on the view set
export function stripUnneededColumns(data,displayType){

  let indicesToRemove = []

  let i = 0,j;
  switch ( displayType){
    case DISPLAY_BOTH:
    default:
      break;
    case DISPLAY_SAVINGS:
      indicesToRemove = [4,5,6,7];
      for( i =0 ; i< data.length;i++){
        for( j = indicesToRemove.length -1; j>= 0 ; j--){
          data[i].splice(indicesToRemove[j],1);
        }
      }
      break;
    case DISPLAY_TARGET_ONLY:
      indicesToRemove = [1,2,3,4,5,6,7];
      for( i =0 ; i< data.length;i++){
        for( j = indicesToRemove.length -1; j>= 0 ; j--){
          data[i].splice(indicesToRemove[j],1);
        }
      }
      break;
  }


  return data;
}
