import React, { Component } from 'react';
import Accordion from './accordion.js'

class SideBar extends Component {
  render() {

    const handleExpandToggle = this.props.handleExpandToggle;
    const calculators = this.props.calculators;
    const displayValues = this.props.displayValues;
    const calculatorInputCallbacks= this.props.calculatorInputCallbacks
    const createOption = this.props.createOption
    const saveCalculator = this.props.saveCalculator;
    const handleCalculatorTitleEdit = this.props.handleCalculatorTitleEdit;
    const customerAge = this.props.customerAge;
    const softDeleteCalculator = this.props.softDeleteCalculator;
    const downloadAsPDF = this.props.downloadAsPDF;
    const isFullScreen=this.props.isFullScreen;
    const custRef =this.props.custRef;

    return (
      <>
      <Accordion
        saveCalculator = {saveCalculator}
        displayValues={displayValues}
        createOption={createOption}
        openCalculator={this.props.openCalculator}
        handleCalculatorTitleEdit={handleCalculatorTitleEdit}
        handleExpandToggle={handleExpandToggle}
        calculators={calculators}
        customerAge = {customerAge}
        softDeleteCalculator={softDeleteCalculator}
        calculatorInputCallbacks={calculatorInputCallbacks}
        downloadAsPDF={downloadAsPDF}
        isFullScreen={isFullScreen}
        custRef={custRef}
				{ ...this.props }
      />

      </>
    );
  }
}

export default SideBar;
