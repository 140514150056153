import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import Fetching from './../../functions/fetching.js';


class Logout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      auth: true
    }

  }

  componentDidMount() {

    // Call the logout function to clear the session
    let thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/users/logout', {
      method: 'get',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include"
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Redirect to the dashboard
        thus.setState({ auth: false });
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ error: data.msg });
        thus.getTokens();
      }

    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }


  // Redirect to the login page
  render() {

    if (this.state.auth === false) {
      return <Redirect to='/admin' />
    }
    return (
      <div className='content'>
        <div className='width-1 centred'>
          <div className="loader whiteBg">Loading...</div>
        </div>
      </div>
    );
  }
}

export default Logout;
