import React, { Component } from 'react';

import Heading from './heading.js';
import Colours from '../../assets/colours.js';

class ErrorLinkPage extends Component {

  render() {
    return (
      <div className='content'>
        <Heading mainTitle="Sorry there has been an error" subTitle="Please get in touch to request access" />
        <div className='width-6' style={ styles.container }>
          <p>We are sorry but the link you clicked on is not valid. If you believe this has happened in error, please email steve@ashworthfp.co.uk or call the office on 01206 632006 to request a new link.</p>
          <p>Thank you</p>
        </div>
      </div>
    );
  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 35,
    marginBottom: 125,
    padding: 25
  }
};

export default ErrorLinkPage;
