import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

import Fetching from './../../../functions/fetching.js';

import { connect } from 'react-redux';

class EmailGroupList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email_groups: [],
      isLoading:true
    };

  }

  initialiseComponent () {
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/email_groups', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {

          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {

            let email_groups = [...data.data.email_groups];
            let isLoading = false;
            // Process the data for display of the users
            thus.setState({ isLoading, email_groups });
            return data;
          }
        }
      );
  }

  getTokens () {
	fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	  .catch(function (error) {
		console.log('Request failed', error);
	  });
  }

  componentDidMount () {
	  this.initialiseComponent();
  }

  // Render the view
  render() {

    const { isLoading,email_groups } = this.state;


    let content = <div className='width-2 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;


    if (!isLoading && email_groups.length > 0) {

      content = <div>

                  <table >
                    <thead>
											<tr>
												<th>Existing Email Groups</th>
											</tr>
                    </thead>
                    <tbody>
                      { email_groups.map((email_group,i) => {
                        return (
                          <tr key={'month-'+i}>
                            <td><Link className="link" style={{color:'#000000'}} to={'/admin/email_group/edit/'+email_group.slug} dangerouslySetInnerHTML={{ __html: email_group.name }}></Link></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  
				  				<Link style={style.add_btn} to={'/admin/email_group/create'}>Create</Link>
                  
                </div>
    } else if (!isLoading) {
			content = <div>
				<table>
					<thead>
						<tr>
							<th>Existing Email Groups</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={ style.italic }>There are no email groups set up at the moment</td>
						</tr>
					</tbody>
				</table>
				<Link style={style.add_btn} to={'/admin/email_group/create'}>Create</Link>
			</div>
		}



    return (


      <div className='width-8 centred'>

        <Heading mainTitle="Email Groups"  />

        <FlashMsg />

        { content }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

const style = {
	add_btn:{
		background: "rgb(78, 144, 67)",
		color: "rgb(255, 255, 255)",
		borderRadius: "20px",
 		textAlign: "center",
		textDecoration: "none",
		fontSize: '14px',
		display: 'block',
		padding: '5px 12px',
		float:'right',
		marginTop:'10px'
	},
	italic: {
		fontStyle: "italic",
		color: "#777",
		textAlign: "center"
	}
}


export default connect(mapStateToProps)(EmailGroupList);
