import React, { Component, Fragment } from 'react';


import Heading from '../heading.js';
import FlashMsg from '../../common/flash-msg.js';

import Fetching from '../../../functions/fetching.js';

import { connect } from 'react-redux';
import Moment from 'moment';
import CsvUpload from '../files/csv-upload.js';
import Colours from '../../../assets/colours.js';
import { Link } from 'react-router-dom';

class ManualImport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading:true,
      investmentImports: [],
      importProgress:null,
      importData:{},
      newUpload:false,
      csvKey:Date.now(),
      dotIndex:0
    };

    this.getLastImportStatus = this.getLastImportStatus.bind(this)
    this.loadManualInvestments = this.loadManualInvestments.bind(this);
  }

  loadManualInvestments(firstLoad){
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/investments/manual', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {

          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {

            let newData = {};
            newData.isLoading = false;

            if(firstLoad){
              if(data.data.length > 0){
                thus.getLastImportStatus(data.data[data.data.length - 1]['slug'],firstLoad)
              } 
              thus.setState({importProgress:'complete'})
            } else {
              thus.setState({importProgress:'complete'})
            }

			      newData.investmentImports = data.data
            thus.setState(newData);
            return data;
          }
        }
      );
  }

  initialiseComponent () {
    const firstLoad = true
    this.loadManualInvestments(firstLoad);
    this.interval = setInterval(this.cycleDots, 500);
  }

  cycleDots = () => {
		const { dotIndex } = this.state;
		const dots = ['.', '..', '...'];
		const nextIndex = (dotIndex + 1) % dots.length;
		this.setState({ dotIndex: nextIndex })
	}

  componentWillUnmount(){
		clearInterval(this.interval)
	}


  getLastImportStatus (slug,firstLoad) {
    var thus = this;
    setTimeout(
      function(){
    fetch(process.env.REACT_APP_API_BASE + '/admin/investments/latestImportCheck/'+slug, { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {

          if(typeof data.data !== 'undefined'){
            thus.setState({importData:data.data})

            if(firstLoad){
              //get current uploads if they exist and continue to show progress
              if(data.data.status === 'importing'){
                thus.getTokens ()
                thus.getLastImportStatus(slug)
                thus.setState({importProgress:'importing'})
              } else {
                //show upload form otherwise
                thus.setState({importProgress:'complete'})
                thus.setState({csvKey:Date.now()})
              }
            } else {
              
              if(data.data.status === 'importing'){
                //show progress of new upload
                thus.getTokens ()
                thus.getLastImportStatus(slug)
                

              } else if(data.data.status === 'complete'){
                //show csv upload form
                thus.getTokens ()
                thus.loadManualInvestments();
                thus.setState({csvKey:Date.now()})
              }
              thus.setState({importProgress:data.data.status})
            }
            
          }

        }
  )},3000);
  }


  getTokens () {
	fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	  .catch(function (error) {
		console.log('Request failed', error);
	  });
  }

 
  componentDidMount () {
    this.initialiseComponent();
	  this.getTokens();

  }

  // Render the view
  render() {

    const { isLoading,investmentImports,importProgress,importData,dotIndex,csvKey} = this.state;

	var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

  var importDisplay = <div className='width-1 centred'>
  <div className="loader whiteBg">Loading...</div>
</div>;


  switch( importProgress){
    case 'complete':
      importDisplay = <CsvUpload key={csvKey} callback={this.getLastImportStatus} />
      break;
    case 'importing':
      const dots = ['.','..','...'];
      importDisplay = <div className='transact-investment-import-panel'>
        <p>Currently importing Row {importData.investment_count}{dots[dotIndex]}</p>
        <p>Import started at {Moment(importData.created).format('h:mma DD/MM/YYYY')}</p>
        <p>Last import: {importData.customer}</p>
      </div>
      break;
    case 'failed-investment-save':
      importDisplay =<div>
        <p>Error: Failed to save investment data</p>
      </div>
      break;
      case 'failed-save-customer':
        importDisplay = <div>
        <p>Error: Failed to save investment data to customer record</p>
      </div>
      break;
      case 'failed-file':
        importDisplay =<div>
        <p>Error: Failed to failed to parse file</p>
      </div>
      break;
      case 'failed-file-type':
        importDisplay =<div>
        <p>Error: Please import the correct file type</p>
      </div>
      break;
  }

    if (!isLoading ) {

      content = <Fragment><div>
                  <table className='management-summary-table'>
                    <thead>
                      <tr>
                        <th>Import Id</th>
                        <th>Status</th>
                        <th >Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {investmentImports.length > 0 && investmentImports.map( (importData ,i) => {


                        return (
                          <tr key={'import-'+i}>
                            <td>{importData.slug}</td>
                            <td>{importData.status.charAt(0).toUpperCase() + importData.status.slice(1)}</td>
                            <td>{Moment(importData.created).format('h:mma DD/MM/YYYY')}</td>
                          </tr>
                        )
                      })}
                      {investmentImports.length === 0 && 
                        <tr><td colspan="3" style={{textAlign:"center",fontStyle:'italic',color:'grey'}}>No Imports Found</td></tr>
                      }
                    </tbody>
                  </table>

                </div>
				<h3 >Upload Transact File (CSV)</h3>
				{importDisplay}
				</Fragment>
    }



   

    return (


      <div className='width-8 centred' style={{paddingBottom:'50px'}}>
        <Link style={{ ...styles.lnkRght, ...styles.fauxBtn }} to={ `/admin/clients` }>Back</Link>
        <Heading mainTitle="Transact Investment Import"  />
        
        <FlashMsg />

       {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

var styles = {
  lnk: {
    color: Colours.txtBlack,
    display: 'inline-block',
    marginLeft: 12
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 54,
    display: 'block',
    borderRadius: 20,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  thLnk: {
    color: Colours.txtBlack,
    textDecoration: 'none'
  },
  grey: {
    background: Colours.txtGrey,
    borderColor: Colours.txtGrey,
  },
  fauxBtn: {
    background: Colours.grn,
    color: Colours.txtWhite,
    borderRadius: 20,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5
  },
  topMrgn: {
    marginTop: 125
  },
  centred: {
    textAlign: 'center'
  },
  empty: {
    color: Colours.red,
    marginBottom: 25,
    fontStyle: 'italic'
  },
  export: {
    color: Colours.txtWhite,
    background: Colours.txtGrey,
    marginTop: 2
  },
  archiveStatus:{
    width:'50%',
    display:'inline-block',
    fontSize:14,
    marginLeft:16,
    marginRight:4
  }
};

export default connect(mapStateToProps)(ManualImport);
