import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Heading from './../heading.js';
import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';
import Btn from './../../common/btn.js';

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: this.props.match.params.userRef,
      usrFirst: "",
      usrLast: "",
      usrEmail: "",
      is_archived: "",
      rslt: "",
      rsltType: "error",
      title: "Create a New Admin",
      isLoading: true,
      isEditing: false
    };

    this.handleEntry = this.handleEntry.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  handleEntry ( event ) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Fetch the user data
  componentDidMount () {

    var thus = this;
    if (typeof this.props.match.params.userRef != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/users/get/' + this.props.match.params.userRef, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            // Check whether we have a failure
            if (typeof data.error !== 'undefined' && data.error === 'auth') {

              // Redirect to the error page
              thus.setState({ error: true });
              return false;

            } else {
              // Process the data for display of the tabs
              thus.setState({ title: "Edit Admin", uid: data.id, isEditing: true, usrFirst: data.first_name, usrLast: data.last_name, usrEmail: data.email, is_archived: data.is_archived, isLoading: false });
              return data;
            }
          }
        );

      } else {
        this.setState({ isLoading: false });
      }

      this.getTokens();

  }

  // Handle the submission of a user
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;
    var fetchUrl = (this.state.isEditing === true)?process.env.REACT_APP_API_BASE + '/admin/users/update':process.env.REACT_APP_API_BASE + '/admin/users/create';
    var usrEmail = encodeURIComponent(this.state.usrEmail);
    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ id: this.state.uid, first_name: this.state.usrFirst, last_name: this.state.usrLast, email: usrEmail }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Set the updated state
        if (typeof data.uid !== 'undefined') {
          thus.setState({ uid: data.uid, isEditing: true, title: "Edit Admin", rslt: "The admin account has been successfully created. An email has been sent to them with instructions on how to activate the account.", rsltType: 'success' });
        } else {
          thus.setState({ rslt: 'The admin\'s details have been updated successfully', rsltType: 'success' });
        }
      } else {
        // Handle the failure by showing a failure message
        var msg = "<p>Sorry but there was an error updating the admin's details. Please check the errors below and try again.</p><ul>";
        for ( var i in data.errors) {
          for (var j in data.errors[i]) {
            msg += "<li>" + data.errors[i][j].message + "</li>";
          }
        }
        msg += "</ul>";
        thus.setState({ rslt: msg, rsltType: 'error' });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  render() {

    const { isLoading, isEditing, uid } = this.state;

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if (!isLoading) {
      content = <form>
                  <div className='full-input'>
                    <label htmlFor="first" style={ styles.full }>First name</label>
                    <input type="text" name="usrFirst" id="first" value={ this.state.usrFirst } onChange={ this.handleEntry } style={ styles.full } />
                  </div>
                  <div className='full-input'>
                    <label htmlFor="last" style={ styles.full }>Last name</label>
                    <input type="text" name="usrLast" id="last" value={ this.state.usrLast } onChange={ this.handleEntry } style={ styles.full } />
                  </div>
                  <div className='full-input'>
                    <label htmlFor="email" style={ styles.full }>Email address</label>
                    <input type="email" name="usrEmail" id="email" value={ this.state.usrEmail } onChange={ this.handleEntry } style={ styles.full } />
                  </div>
                  <Btn title="Save" lnk="/questionnaire" type="submit" handleSubmission={ this.handleSubmission } />
                </form>
    }

    var delBtn = "";
    var arcBtn = "";
    if (isEditing === true) {
      delBtn = <Link style={ styles.lnkRght } to={ `/admin/users/delete/${ uid }` }>Delete</Link>
      arcBtn = <Link style={ styles.lnkRght } to={ `/admin/users/archive/${ uid }` }>{ this.state.is_archived === "1" ? "Unarchive" : "Archive" }</Link>
    }

    return (
      <div className='width-9 centred'>

        { delBtn }
        { arcBtn }
        <Link style={ styles.lnkRght } to={ `/admin/users` }>Back</Link>
        <Heading mainTitle={ this.state.title } subTitle={ this.state.usrFirst + ' ' + this.state.usrLast } />

        <div className={ "mbtm " + this.state.rsltType } style={ styles.full } dangerouslySetInnerHTML={{ __html: this.state.rslt }}></div>
        { content }

      </div>
    );
  }
}

var styles = {
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  full: {
    marginLeft: '0.5%',
    marginRight: '0.5%',
    width: '99%'
  }
};

function mapStateToProps(state) {
  return {
    sessionId: state.sessionId,
    token: state.token,
    tokenKey: state.tokenKey,
    authError: state.errors.auth,
  };
}

export default connect(mapStateToProps)(UserDetails);
