import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie';

import Customers from './customers/customers.js';
import FilesIndex from './files/index.js';
import EmailsIndex from './emails/index.js';
import EmailDetails from './emails/details.js';
import EmailCompose from './emails/compose.js';
import EmailGroupList from './emails/group-list.js';
import EmailGroupEdit from './emails/group-edit.js';
import Users from './users/users.js';
import Content from './content/content.js';
import Logout from './../common/logout.js';

import Header from './../common/header.js';
import Colours from './../../assets/colours.js';
import Footer from './../common/footer.js';

import Fetching from './../../functions/fetching.js';
import ManagementSummary from './users/management-summary.js'
import CommunicationCompose from './emails/communication.js';
import ManualImport from './investments/manual-import.js';

class AdminTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      auth: true,
      adminSuccess: "",
      adminError: "",
      pendingAuth: true
    }
  }

  checkStatus () {
    // TODO - Fix this one up...
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/login', { method: 'get', credentials: "include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        if (typeof data.status == 'undefined' || data.status !== 'authenticated') {
          thus.setState({ auth: false });
        } else {
          thus.setState({ pendingAuth: false });
        }
      })
      .catch(function (error) {
        thus.setState({ auth: false });
        console.log('Request failed', error);
      });

  }

  componentDidMount() {

    // Before doing anything in the admin template check the logged in status
    // Check for session cookies
    if (typeof this.props.sessionId == 'undefined') {
      this.setState({ auth: false });
    }

    // DCheck that the session is actually logged in
    this.checkStatus();

  }

  render() {

    if (this.state.auth === false) {
      return <Redirect to='/admin' />
    }

    if (this.state.pendingAuth === true) {
      return (
        <div className='content'>
          <div className='width-1 centred'>
            <div className="loader whiteBg">Loading...</div>
          </div>
        </div>
      );
    }

    return (
      <div style={ styles.main }>
        <Header isAdmin={ true } />

        <Switch>
			<Route path='/admin/files' component={ FilesIndex }/>
			<Route path='/admin/emails/:pageNo/:custRef' component={ EmailsIndex }/>
			<Route path='/admin/emails/:pageNo' component={ EmailsIndex }/>
			<Route exact path='/admin/emails' component={ EmailsIndex }/>
			<Route exact path='/admin/email/compose' component={ EmailCompose }/>
			<Route path='/admin/email/compose/:emailId' component={ EmailCompose }/>
      <Route exact path='/admin/record_call/compose' component={ CommunicationCompose } />
			<Route path='/admin/record_call/compose/:communicationId' component={ CommunicationCompose }/>
      <Route exact path='/admin/meeting_notes/compose' component={ CommunicationCompose }/>
			<Route path='/admin/meeting_notes/compose/:communicationId'  component={ CommunicationCompose } />
			<Route exact path='/admin/email/details' component={ EmailDetails }/>
  			<Route path='/admin/email/details/:emailId' component={ EmailDetails }/>
			<Route exact  path='/admin/email_group' component={ EmailGroupList }/>
			<Route exact  path='/admin/email_group/edit/:slug' component={ EmailGroupEdit }/>
			<Route exact  path='/admin/email_group/create' render = {() => <EmailGroupEdit isCreate={true} /> } />
  			<Route path='/admin/clients' component={ Customers }/>
  			<Route path='/admin/users' component={ Users }/>
  			<Route path='/admin/custom-fields' component={ Customers }/>
  			<Route path='/admin/questions' component={ Content }/>
			<Route path='/admin/sections' component={ Content }/>
  			<Route path='/admin/contents' component={ Content }/>
			<Route exact path='/admin/management-summary' component={ ManagementSummary }/>
  			<Route exact path='/admin/management-summary/:filterParams' component={ ManagementSummary }/>
  			<Route exact path='/admin/investment-import' component={ ManualImport }/>
        <Route exact path='/admin/logout' component={ Logout }/>
        </Switch>

        <Footer />
      </div>
    );
  }
}

var styles = {
  main: {
    background: Colours.bgWhite
  }
};

function mapStateToProps(state) {
  return {
    sessionId: state.sessionId
  };
}


export default withCookies(connect(mapStateToProps)(AdminTemplate));
