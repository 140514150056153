import React, { Component } from 'react';

import Colours from './../../../assets/colours.js';
import FilterInput from './filter-input.js';
import ExpCont from './../../../assets/img/expand-contract.png';

class CFFilter extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      cfs: [],
      filterState: "closed"
    };

    this.loadContent = this.loadContent.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.initialiseDefaultSearch = this.initialiseDefaultSearch.bind(this);
    // this.loadEmailGroups = this.loadEmailGroups.bind(this);
  }

  componentWillMount () {
    // alert('here')
    this.loadContent();
  }

 

  // Check the tokens
  loadContent () {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/custom_fields/get_filter';
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          thus.setState({ cfs: data.cfs });

        }
      );

  }

  updateFilter(e, cfId, fieldType) {

    // Pass it up the chain to the filter
    this.props.updateFilter(e, cfId, fieldType);

  }

  // Toiggle the open / closed status of the filter
  toggleFilter (e) {

    e.preventDefault();

    var fltrState = this.state.filterState;
    fltrState = (fltrState === "closed") ? "open" : "closed";
    this.setState({ filterState: fltrState });

  }

  //if props.location have specific filter settings attached
  initialiseDefaultSearch(cfs,propsLocation){
    for(var i = 0 ; i <cfs.length;i++){

      //like review month....
      if(propsLocation.hasOwnProperty('review_month') && propsLocation.filter_key === cfs[i].id){
        cfs[i].entry = propsLocation.filter_value;
      }

      //..or interim review month
      if(propsLocation.hasOwnProperty('interim_review_month') && propsLocation.filter_key === cfs[i].id){
        cfs[i].entry = propsLocation.filter_value;
      }
    }

    return cfs;
  }


  render() {

    let thus = this;
    let { cfs, filterState } = this.state;

    cfs = this.initialiseDefaultSearch(cfs,this.props.location);

    if (cfs.length === 0) {
      return (
        <div style={ styles.cfBlock } className={ "cf-block " + filterState }>

          <h2 className={ filterState }>Filter Clients</h2>

          <div className='cf-content width-1 centred'>
            <div className="loader sml">Loading...</div>
          </div>

        </div>

      );
    }

    return (
      <div style={ styles.cfBlock } className={ "cf-block cf-fltrs " + filterState }>

        <h2 style={ styles.mSml } className={ filterState }>
          <a href="#toggle" onClick={ (e) => this.toggleFilter(e) }>
            Filter Clients
          </a>
        </h2>

        <div className='cf-content'>
          { cfs.map( cf =>
            <FilterInput cf={ cf } key={ cf.id } updateFilter={ this.updateFilter } />
          )}

        <div class="question">
          <label class="" for="cf-11">Only include clients who have savings or pensions that are:</label>
          <select name="cf-11" id="cf-11" style={{marginBottom: "25px;"}} onChange={ (e) => this.updateFilter(e, "invest", "select") } >
            <option selected=""></option>
            <option value="jointly-held">Jointly Held</option>
            <option value="held-in-trust">Held in Trust</option>
            <option value="individually-held">Individually Held</option>
          </select>
        </div>

        <div className="question">
          <label htmlFor="arcCb" >Client Status</label>
            <select  id={ 'c-status' }   multiple='multiple' onChange={ (e) => this.updateFilter(e, "status", "multi-select") }>
              <option value="active" selected="selected">Active</option>
              <option value="archived">Archived</option>
              <option value="prospect" selected="selected">Prospect</option>
            </select>
            </div>

            <div className="question">
          <label htmlFor="arcCb" >Email Groups</label>
            <select  id={ 'c-email' }   onChange={ (e) => this.updateFilter(e, "email-group", "select") }>
              <option value="" selected="selected"></option>
              {
                thus.props.email_groups.map((eg,i) => {
                  return <option key={'email-group-'+i} value={eg.slug}  dangerouslySetInnerHTML={{ __html: eg.name }}></option>
                })
              }
            </select>
      
        </div>
      </div>

      </div>
    );
  }
}

var styles = {
  cfBlock: {
    marginBottom: 35,
    background: Colours.bgGrey,
    padding: "12px 20px",
    boxSizing: "border-box",
    overflow: "auto"
  },
  mSml: {
    marginBottom: 15,
    borderBottomColor: Colours.grn,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px top -38px",
    backgroundSize: "24px auto",
    backgroundImage: 'url(' + ExpCont + ')',
  },
	label: {
		marginLeft: 15
	}
};

export default CFFilter;
