import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import Fetching from './../../../functions/fetching.js';

class UserDelete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: this.props.match.params.userRef,
      rdr: false
    };

  }

  // Fetch the user data
  componentDidMount () {

    var thus = this;
    if (typeof this.props.match.params.userRef != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/users/delete/' + this.props.match.params.userRef, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            // Check whether we have a failure
            if (typeof data.error !== 'undefined' && data.error === 'auth') {

              // Redirect to the error page
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the admin.' });
              return false;

            } else {
              // Process the data for display of the tabs
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: 'The admin has been successfully deleted.' });
              return data;
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the admin.' });
        thus.setState({ rdr: true });
      }

  }

  render() {

    if (this.state.rdr === true) {
      return (
        <Redirect to='/admin/users' />
      );
    }

    return <div></div>;
  }
}


function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UserDelete);
