import React, { Component } from 'react';

import Heading from './heading.js';
import Colours from './../../assets/colours.js';

class ErrorPage extends Component {

  render() {
    return (
      <div className='content'>
        <Heading mainTitle="Sorry there has been an error" subTitle="Please get in touch to request access" />
        <div className='width-6' style={ styles.container }>
          <p className='intro'>Sorry, but in order to take the questionnaire you
          need to be an Ashworth FP client.</p>
          <p>If you are already an Ashworth FP client, then please request access
          via your account manager.</p>
          <p>If you wish to find out more about becoming an Ashworth FP client, please do not hesitate to contact us on 01206 632006.</p>
          <p>Thank you</p>
        </div>
      </div>
    );
  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 35,
    marginBottom: 125,
    padding: 25
  }
};

export default ErrorPage;
