import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'

import Btn from './../common/btn.js';
import FlashMsg from './../common/flash-msg.js';

import Colours from './../../assets/colours.js';
import Fetching from './../../functions/fetching.js';

class ForgottenPassword extends Component {
  // Set the initial state for the form entry
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
      rdr: false
    }

    this.handleEntry = this.handleEntry.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  handleEntry ( event ) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // Handle the login form submission
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/users/forgotten', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ email: this.state.email, password: this.state.password }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Redirect to the dashboard
        thus.setState({ rdr: '/admin' });
        thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: "An email has been sent to your registered email address with instructions on how to reset your password." })
      } else {
        // Handle the failure by showing a failure message
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: data.msg })
        thus.setState({ rdr: '/admin' });
      }

      thus.getTokens();

    })
    .catch(function (error) {
      thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: "Sorry but we could not reset your password. Please try again." })
      thus.setState({ rdr: '/admin' });
      console.log('Request failed', error);
    });

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Before load of the login component run a check to see if we are already logged in
  componentWillMount () {
    // Get the form tokens for submission
    this.getTokens();
  }

  render() {
    if (this.state.rdr !== false) {
      return <Redirect to={ this.state.rdr } />
    }


    return (
      <div className='content'>
        <div className='width-1 spacer'>&nbsp;</div>
        <div className='width-4 ' style={ styles.container }>
          <p className='intro'>Forgotten your password to the Ashworth FP portal?</p>
          <p>Request a reset link to update your password using the form below.</p>

          <FlashMsg />

          <form style={styles.form}>
            <input name='email' placeholder='Email address' type='email' id='email' required='required' value={this.state.email} onChange={this.handleEntry} />
            <Btn title="Request password reset" lnk="/questionnaire" type="submit" handleSubmission={ this.handleSubmission } />
          </form>
          <p className='smlrRght'><Link to='/admin'>Return to login</Link></p>
        </div>
      </div>
    );
  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 145,
    marginBottom: 125,
    padding: 25
  },
  form: {
    marginTop: 32
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
    authError: state.errors.auth,
  };
}

export default connect(mapStateToProps)(ForgottenPassword);
