import React, { Component } from 'react';

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

import Files from './files.js';

class FilesIndex extends Component {

  render() {

    return (
      <div className='width-4 centred'>

        <Heading mainTitle="Manage File Uploads" subTitle="Upload generic files for use by admins" />

        <FlashMsg />

				<Files genLnk={ false } />

      </div>
    );
  }
}

export default FilesIndex;
