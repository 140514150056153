import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import UserList from './user-list.js';
import UserDetails from './user-details.js';
import UserArchive from './user-archive.js';
import UserDelete from './user-delete.js';


class Users extends Component {
  render() {



    return (
      <div className='content'>

        <Switch>
					<Route path='/admin/users/link/:ssoStatus' component={ UserList }/>
          <Route path='/admin/users/page/:pageNo' component={ UserList }/>
          <Route exact path='/admin/users' component={ UserList }/>
          <Route exact path='/admin/users/create' component={ UserDetails }/>
          <Route path='/admin/users/edit/:userRef' component={ UserDetails }/>
          <Route path='/admin/users/archive/:userRef' component={ UserArchive }/>
          <Route path='/admin/users/delete/:userRef' component={ UserDelete }/>

        </Switch>

      </div>
    );
  }
}


export default Users;
