import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import DatePicker from "react-datepicker";
import "./../../assets/css/datepicker.css";

import FullPageImg from './full-page-img.js';
import FpiTemplates from './../admin/questionnaire/fpi-templates';

import Colours from './../../assets/colours.js';
import Fetching from './../../functions/fetching.js';

import ObjectiveTemplates from './../admin/questionnaire/objective-templates.js';

import historyImg from './../../assets/img/history-icon.png'
import pound from './../../assets/img/pound.svg'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FlexiInput extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
      loadingHistory: false,
      history: [],
      dateHolder: "",
      textContent:"",
      showHistoryLnk: false,
      hasDbUpdated:true,
      isFetching:false,
      imageUploading:false,
      imgId: 0,
      tmbUrl: false,
      storeTemplate: false
    };

    this.saveField = this.saveField.bind(this);
    this.saveSortOrder = this.saveSortOrder.bind(this);
    this.getTokens = this.getTokens.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatDateForDisplay = this.formatDateForDisplay.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.saveTextareaUpdate = this.saveTextareaUpdate.bind(this);
		this.savelocalUpdatesToDB = this.savelocalUpdatesToDB.bind(this);
		this.saveTimer = null;
		this.getFormDetails = this.getFormDetails.bind(this);
		this.confirmUpload = this.confirmUpload.bind(this);
		this.setImageDataToState  = this.setImageDataToState.bind(this);
    this.doFetchSubmit = this.doFetchSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateImgField = this.updateImgField.bind(this);
  }

  componentWillReceiveProps ( newProps ) {

    if ( typeof newProps != 'undefined' && typeof newProps.errorFields != 'undefined' && typeof newProps.errorFields[newProps.repeatNo + '-' + newProps.qId] != 'undefined' ) {
      this.setState({ hasError: true });

    }

    if(typeof newProps.performSaveCheck !== 'undefined' && newProps.performSaveCheck){
      this.savelocalUpdatesToDB(newProps);
    }

    this.setHistoryLnk(newProps);

    if(this.props.inputType === 'objective'  && (typeof this.state.imgId === 'undefined' || this.state.imgId === 0) ){
      this.setImageDataToState ();
    }
  }

	componentWillMount () {
		this.getTextResponse();
		this.setHistoryLnk(this.props);

		if(this.props.inputType === 'objective' && (typeof this.state.imgId === 'undefined' || this.state.imgId === 0) ){
			this.setImageDataToState ();
		}
	}

	getTextResponse () {
		var response = "";
	    if (typeof this.props.responses != 'undefined') {
	      for ( var i in this.props.responses ) {
	        if (Number(this.props.responses[i].repeatNo) === Number(this.props.repeatNo)) {
	          response = this.props.responses[i].content;
						this.setState({ textContent: response });
	        }
	      }
	    }
	}

	setImageDataToState () {
		let imgId = 0;
		let imgTmbUrl = "";
	    if (typeof this.props.responses != 'undefined') {
	      for ( var i in this.props.responses ) {
	        if (Number(this.props.responses[i].repeatNo) === Number(this.props.repeatNo)) {
	          imgId = this.props.responses[i].imgId;
            imgTmbUrl = this.props.responses[i].imgTmbUrl;
						this.setState({  imgId: imgId , imgTmbUrl:imgTmbUrl});
	        }
	      }
	    }
	}

  getTokens ( resubmit = false, qId, repeatNo,callback ) {
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then( function (data) {
				thus.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session });
				// Should this be a resubmission?
				if (resubmit && resubmit < 2) {
					console.log('REATTEMPT');
					thus.saveTextareaUpdate(qId, repeatNo, resubmit);
				}

				if(typeof callback !== "undefined"){
					callback();
				}
			})
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Add in commas to the numeric values (if required)
  handleCommas (e) {

    var enteredVal = e.target.value;

    enteredVal = enteredVal.replace(/[^0-9,.]/g, "");

    // Strip out commas
    enteredVal = enteredVal.replace(/,/g, "");

    // Work out how many charaters before any decimal points
    enteredVal = enteredVal.split(".");
    let length = enteredVal[0].length;

    if (length > 3) {
      var mod = length % 3;
      if (mod === 0) mod = 3;
      enteredVal[0] = enteredVal[0].slice(0,mod) + "," + enteredVal[0].slice(mod) ;

      let commaNo = length / 3 - 1;

      // loop through and add in the commas
      for ( var i = 1; i < commaNo; i++ ) {
        enteredVal[0] = enteredVal[0].slice(0, ((Number(i)*3) + Number(i) + mod)) + "," + enteredVal[0].slice(((Number(i)*3) + Number(i) + mod));
      }
    }

    // Bring the string back together
    enteredVal = enteredVal.join(".");

    e.target.value = enteredVal;

  }

  savelocalUpdatesToDB(props,isUnmountAction = false){
	  if(!this.state.hasDbUpdated){

	  	  let inputType = props.inputType;

        let isSelect= false;
	  	  let isRadio = false;
	  	  let isDate = false
	  	  if(inputType === 'select'){
	  		  isSelect = true;
	  	  } else if (inputType === 'radio'){
	  		  isRadio = true;
	  	  } else {
	  		  isDate = true;
	  	  }

	  	  let textContent = this.state.textContent;
	  	  this.saveField(false, props.qId, props.repeatNo, isSelect, isRadio, isDate, textContent,isUnmountAction);
	   }
  }

  componentWillUnmount(){
	  this.savelocalUpdatesToDB(this.props,true);
  }

	// Update state based on the content
  handleEditorChange (content, skipDelay) {
    this.setState({ textContent: content,hasDbUpdated:false });
    clearTimeout(this.saveTimer);
    // Check what the delay is 
		var thus = this;
		this.saveTimer = setTimeout(function(){
			thus.saveTextareaUpdate (thus.props.qId, thus.props.repeatNo, 0);
		}, 1500);
  }

	saveTextareaUpdate (qId, repeatNo, attNo) {
		var thus = this;
		setTimeout(function(){
			let { textContent } = thus.state;
			if (textContent === "") textContent = " ";
			thus.saveField(false, qId, repeatNo, false, false, false, textContent, false, attNo);
		}, 150);
  }

  saveSortOrder ( e, qId, repeatNo ) {

    e.preventDefault();

    // Set up the save data
    var saveData = {
      qid: qId,
      repeatNo: repeatNo,
      sortOrder: e.target.value
    }

    // Submit the data to save
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/update_so/' + this.props.custRef, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials: "include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);

        if ( typeof data.rslt != 'undefined' && data.rslt !== "success" ) {
          alert(data.msg);
          thus.setState({ isFetching: false });
        }
        thus.getTokens();

      })
      .catch(function (error) {
        thus.getTokens();
        alert('Sorry but there was an error saving your sort order. Please try again.');
        console.log('Request failed', error);
      });
  }


  saveField (e, qId, repeatNo, isSelect, isRadio, isDate, textContent = false,isUnmountAction = false, attNo = 0) {

		if (!isRadio && !isDate && !textContent && e !== false) {
			e.preventDefault();
		}

    if (this.props.readOnly) {
			return;
		}

		var response = "";
		if (textContent) {
			response = textContent;
		} else {
    	response = (!isDate) ? encodeURIComponent(e.target.value.replace(/(?:\r\n|\r|\n)/g, "<br />")) : this.formatDate(e) ;
		}

    // Figure out the response object
    var saveData = {
      qid: qId,
      repeatNo: repeatNo,
      response: response
    }

    if(this.props.inputType === 'objective' && this.state.imgId !== undefined && this.state.imgId > 0){
      saveData.imgId = this.state.imgId;
    }


    if (isSelect) {
      saveData.aid = saveData.response;
    }

		let { isFetching } = this.state;
		if (isFetching){
			return;
		}

		//so tokens retreived aren't overriden by almost simultaneous calls
		this.setState({isFetching:true});

    // Run the save
    var thus = this;
    var fetchUrl;
    if (typeof this.props.custRef != 'undefined') {
      fetchUrl = process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/update/' + this.props.custRef;
    } else {
      fetchUrl = process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/respond/' + this.props.customerToken + '/' + this.props.customerMash + '/' + this.props.customerTokenKey;
    }
    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + encodeURIComponent(JSON.stringify(saveData)) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

			if ( typeof data.rslt != 'undefined' && data.rslt === "secure-fail" &&  attNo < 2 && textContent) {
				thus.setState({ isFetching: false });
				thus.getTokens(attNo, qId, repeatNo);
      } else if (typeof data.rslt != 'undefined' && (data.rslt === 'success' || data.rslt === 'empty-success')) {
				thus.getTokens();

				if (!isUnmountAction) {
		        if (!isDate) {
				   thus.setState({ hasError: false,hasDbUpdated:true,isFetching:false});
		        } else {
		          thus.setState({ hasError: false, dateHolder: saveData.response,hasDbUpdated:true,isFetching:false});
		        }

		        thus.props.clearError(saveData.repeatNo, saveData.qid);
					}

      } else {
				thus.getTokens();
        // Handle the failure by showing a failure message
				if(!isUnmountAction){
            thus.setState({ hasError: true,isFetching:false });
            if ( textContent && typeof data.errors.response[0].message != "undefined" ) {
              alert(data.errors.response[0].message);
            }
				} else {
					alert('Sorry but there was an error saving your entries. Please try again.');
				}


      }

    })
    .catch(function (error) {
    	thus.getTokens();
		thus.setState({ hasError: true,isFetching:false });
		  alert('Sorry but there was an error saving your entries. Please try again.');
      	console.log('Request failed', error);
    });
  }

  displayHistory (e) {

    e.preventDefault();

    // Check that we have a custRef (i.e. this is an admin)
    if (typeof this.props.custRef == 'undefined') {
      return;
    }
    this.setState({ loadingHistory: true });

    // Run a fetch to get the history
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/history/' + this.props.custRef + "/" + this.props.qId + "/" + this.props.repeatNo + "/1", { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function(data){
        // console.log(data);
        thus.setState({ loadingHistory: false, history: data });
        thus.props.hideTabs(true);
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });

  }

  closeCover (e) {
    e.preventDefault();
    this.setState({ history: [], loadingHistory: false });
    this.props.hideTabs(false);
  }

  formatDate ( date ) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  formatDateForDisplay ( date ) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

	setHistoryLnk ( useProps ) {

		if (typeof this.props.custRef == 'undefined') {
			this.setState({ showHistoryLnk: false });
			return;
		} else if (useProps.qGroup.title === 'Progress Review Content' && (useProps.qContent === "Services Provided Content" || useProps.qContent === "Services Provided content")) {
			this.setState({ showHistoryLnk: false });
			return;
		} else if (useProps.qGroup.title === 'Next Agenda' && (useProps.qContent === "Content" || useProps.qContent === "Date")) {
			this.setState({ showHistoryLnk: false, storeTemplate: "agenda" });
			return;
		} else {
			this.setState({ showHistoryLnk: true });
		}
	}

	getFormDetails (e) {

		if(typeof this.props.toggleIsUploadingImage != 'undefined'){
			this.props.toggleIsUploadingImage();
		}
		// Disable the input submit while processing the update
		this.setState({ formDisabled: true,imageUploading:true });

		// Get the file name
		var fullPath = e.target.value;
		if (fullPath) {
			var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
			var filename = fullPath.substring(startIndex);
			if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
				filename = filename.substring(1);
			}
		}

		var postData = { filename: filename };

		if(typeof this.state.imgId !== 'undefined' && this.state.imgId > 0){
			postData.imgId = this.state.imgId;
		}

		var fileData = { filename: filename, fileContent: e.target.files[0] };
		var thus = this;

		fetch(process.env.REACT_APP_API_BASE + '/admin/images/post_image/objectives/' + this.props.custRef, {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body: 'data=' + JSON.stringify(postData) + '&' + thus.props.tokenKey + '=' + thus.props.token
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			if ( typeof data.rslt != "undefined" && data.rslt === 'success' ) {

				console.log('Request succeeded with JSON response', data);
				thus.setState({
					imgId: data.data.imgId,
					formAction: data.data.attributes.action,
					filename: data.data.subName, // TODO - remove this!!!
					acl: data.data.inputs.acl,
					cred: data.data.inputs['X-Amz-Credential'],
					algo: data.data.inputs['X-Amz-Algorithm'],
					lnkExpiry: data.data.inputs['X-Amz-Date'],
					policy: data.data.inputs['Policy'],
					sig: data.data.inputs['X-Amz-Signature'],
					formDisabled: false
				});
				thus.getTokens();
        thus.doFetchSubmit(fileData, data.data.imgId);

			} else {
				alert("There was an error uploading your image. Please check the content and try again.");
			}
		})
		.catch(function (error) {
			thus.getTokens();
		});
	}

	doFetchSubmit ( fileData, imgId ) {

		// Assemble the core data for form submission
		let { formAction, filename, acl, cred, algo, lnkExpiry, policy, sig } = this.state;

		var submitData = new FormData();
		submitData.append("key", filename);
		submitData.append("acl", acl);
		submitData.append("X-Amz-Credential", cred);
		submitData.append("X-Amz-Algorithm", algo);
		submitData.append("X-Amz-Date", lnkExpiry);
		submitData.append("Policy", policy);
		submitData.append("X-Amz-Signature", sig);
		submitData.append("file", fileData.fileContent);

		var thus = this;

		fetch(formAction, {
			method: 'post',
			body: submitData,
			mode: "no-cors"
		})
		.then(Fetching.statusCheck)
		.then(function (data) {
			console.log('Upload succeeded. Handle confirmation.');
			thus.confirmUpload(1, imgId);
		})
		.catch(function (error) {
			console.log('Request failed', error);
		});
	}

	confirmUpload ( performResize, imgId, tmbUrl,counter = 0,isImageTemplateToggle = false ) {

    if ( typeof imgId == "undefined" ) {
      imgId = this.state.imgId;
    }
		let qId = this.props.qId;
		let repeatNo = this.props.repeatNo;
    let sxnId= this.props.sxnId;

    if (typeof performResize == "undefined") {
      performResize = 1;
    }

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/images/confirm_upload/'  + this.props.custRef + "/" + performResize;
    var thus = this;

		let data = {
			imgId:imgId,
			qId:qId,
			repeatNo:repeatNo,
			sxnId:sxnId
		}

    fetch(fetchUrl, {
			credentials:"include" ,
			method: 'post',
			headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
			body: 'data='+JSON.stringify(data)  + '&' + thus.props.tokenKey + '=' + thus.props.token
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
      .then(
        function (data) {
			// throw new Error('Something went wrong');
          if (typeof data.rslt == "undefined" || data.rslt !== 'success') {

			  if(counter >= 1 ){
          const errorMessage = (!isImageTemplateToggle) ? 
          "Sorry but there was an error uploading your file. Please try again." : 
          "Sorry but there was a problem selecting this image.  Please try again";

				  alert(errorMessage);
	  			thus.setState({ fileId: "",imgUrl:'',imageUploading:false });
			  } else {
				  let callback = () => {
					  thus.confirmUpload ( performResize, imgId, tmbUrl,1 );
				  }
				 return thus.getTokens(false,false,false,callback);

			  }

            // TODO - catch errors better
          } else {
            // If not resizeing then
            if (performResize === 0) {
              // Upon success for simple updates - update the view
              thus.setState({ imgId: imgId, tmbUrl: tmbUrl });
            } else {
              thus.setState({ fileId: "",imgUrl:data.data.imgUrl,imageUploading:false });

              if (typeof thus.props.callbackFunc != 'undefined') {
                const newImageData = data.data;
                thus.props.callbackFunc(newImageData);
                if(typeof thus.props.toggleIsUploadingImage != 'undefined'){
                  thus.props.toggleIsUploadingImage();
                }
              }
            }

          }
          thus.getTokens();
      }
    ).catch(function (error) {

		if(counter >= 1){
			console.log('Request failed', error);
			thus.setState({ fileId: "",imgUrl:'',imageUploading:false });
		} else {
			let callback = () => {
				thus.confirmUpload ( performResize, imgId, tmbUrl,1 );
			}
		   return thus.getTokens(false,false,false,callback);
		}

	}); // TODO - catch errors!!!

		// Clear the file input
		document.getElementById("fileInput").value = "";

  }

  // Update a field's content using the auto-population select
  updateField ( content ) {
    this.setState({ textContent: content });
  }

  // For image fields we have to do a bit more in terms of restyling and saving the response
  updateImgField ( imgId, tmbUrl ) {
    this.setState({ imgId: imgId });

    const isImageTemplateToggle = true;
    // Update the database record of  the imgID
    this.confirmUpload(0, imgId, tmbUrl,0,isImageTemplateToggle);
    // As part of the success update the thumbnail
  }

  render () {

    const { hasError, loadingHistory, history, dateHolder, textContent, showHistoryLnk, imgId, tmbUrl, storeTemplate } = this.state;

    // Get an existing response
    var response = "";
    var sortOrder = false;
    var respFromLatest = false;
    var thumbnailUrl = tmbUrl;
    if (typeof this.props.responses != 'undefined') {
      for ( var i in this.props.responses ) {
        if (Number(this.props.responses[i].repeatNo) === Number(this.props.repeatNo)) {
          response = this.props.responses[i].content;
					if (this.props.responses[i].inLatest === true) {
						respFromLatest = true;
					}
					if(!thumbnailUrl && typeof this.props.responses[i].imgTmbUrl !== 'undefined' && this.props.responses[i].imgTmbUrl.length > 0 ){
						thumbnailUrl = this.props.responses[i].imgTmbUrl;
						// console.log(thumbnailUrl);
          }
          if (typeof this.props.responses[i].sortOrder != 'undefined' && this.props.responses[i].sortOrder !== null) {
            sortOrder = this.props.responses[i].sortOrder;
          }
        }
      }
    }

    var historyLnk = "";
    if (showHistoryLnk) {
      historyLnk = <Link to="#history" style={ styles.historyIcon } onClick={ (e) => this.displayHistory(e) }>History</Link>
    }

    var coverContent = "";
    if (loadingHistory !== false) {
      coverContent = <div className="cover">
        <div className='width-1 centred' styles={ styles.topMrgn }>
          <div className="loader whiteBg">Loading...</div>
        </div>
      </div>
    }
    if (typeof history != 'undefined' && history.length !== 0) {
      if (typeof history.rslt != 'undefined' && history.rslt === 'empty') {
        coverContent = <div className="cover">
          <a href="#close" onClick={ (e) => this.closeCover(e) }> </a>
          <div className="box big">
            <h5>Audit trail: "{ this.props.qContent }"</h5>
            <p className="smlr" style={ styles.err }>Unfortunately there is no history to display for this field.</p>
          </div>
        </div>
      } else {
        coverContent = <div className="cover">
          <a href="#close" onClick={ (e) => this.closeCover(e) }> </a>
          <div className="box big">
            <h5>Audit trail: "{ this.props.qContent }"</h5>
            <p className="smlr">Below you can see all the changes that have been made to this data over time and by whom.</p>

            <table className="divided">
              <thead>
                <tr>
                  <th>Entry</th>
                  <th>Entered by</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>

                
                { history.map( item => {

                  let auditText = "";

                  if(item.is_cash_calc_user){
                    auditText = 'Cash Calc'
                  } else if(item.is_outlook_user){
                    auditText = <span style={styles.syncFont}>Outlook Sync</span>
                  } else if(item.is_transact_user){
                    auditText = <span style={styles.syncFont}>Transact Sync</span>
                  } else {
                    auditText = item.modifier
                  }

                  return (
                    <tr key={ 'resp-' + item.rid }>
                      { this.props.inputType === "wysiwyg" || (this.props.inputType === "textarea" && Number(this.props.isSummary) === 1) ? (
                        <td dangerouslySetInnerHTML={{ __html: item.response }}></td>
                      ) : (
                        <td>{ item.response }</td>
                      )}
                      <td>{auditText }</td>
                      <td>{ item.modified }</td>
                    </tr>
                    )
                    }
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
    }

    if (this.props.inputType === 'spacer') {

      return (
        <div className='spacer' key={ this.props.qId }></div>
      );

  } else if (this.props.inputType === 'ruler') {

      return (
        <div className='spacer' key={ this.props.qId } style={{height:'10px'}}><hr style={{backgroundColor: '#c5c5c5', height: '1px', border: 0,marginBottom:"5px"}}/></div>
      );

    } else if(this.props.inputType === 'subhead') {

        return (
          <div style={ styles.clearer } key={ this.props.qId }>
            <h6 style={ styles.headSml }>{ this.props.qContent }</h6>
          </div>
        );

    } else if (this.props.inputType === 'sel-name') {

      if (response === "Client 1" && typeof this.props.names.client1 != 'undefined') {
        response = this.props.names.client1;
      }
      if (response === "Client 2" && typeof this.props.names.client2 != 'undefined') {
        response = this.props.names.client2;
      }
			var family = [];
			if (typeof this.props.family != 'undefined') {
				family = this.props.family;
			}

        return (
          <div className='question' key={ this.props.qId }>
            { historyLnk }
            { coverContent }
            <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
            <select readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } id={ this.props.inputName } onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false,false,false,this.props.resolveEditsSaved) }>
              <option></option>
              { typeof this.props.names != 'undefined' && typeof this.props.names.client1 != 'undefined' ? (
                <option value={ this.props.names.client1 }>{ this.props.names.client1 }</option>
              ) : (
                <option value="Client 1">Client 1</option>
              ) }
              { typeof this.props.names != 'undefined' && typeof this.props.names.client2 != 'undefined' ? (
                <option value={ this.props.names.client2 }>{ this.props.names.client2 }</option>
              ) : (
                <option value="Client 2">Client 2</option>
              ) }
							<option disabled={ true }>----------------</option>
							<option value="Joint">Jointly held</option>
							<option value="Trust">Trust</option>
							{ family.length > 0 ? (
								<option disabled={ true }>----------------</option>
							) : "" }
							{ family.map( member =>
								<option key={ member } value={ member }>{ member }</option>
							)}
            </select>
          </div>
        );

    } else if (this.props.inputType === 'select') {

      return (
        <div className='question' key={ this.props.qId }>
          { historyLnk }
          { coverContent }
          <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
          <select readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } style={ respFromLatest ? styles.latestHighlight : styles.none  } name={ this.props.inputName } defaultValue={ response } id={ this.props.inputName } onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, true, false,false,false,this.props.resolveEditsSaved) }>
            <option></option>
            { this.props.optionList.map( option =>
              <option key={ option.oid } value={ option.oid }>{ option.content }</option>
            )}
          </select>
        </div>
      );

    } else if (this.props.inputType === 'radio') {

      return (
        <div className='question radio' key={ this.props.qId }>
          { historyLnk }
          { coverContent }
          <label className={ this.props.isRequired === "1" ? 'required' : '' } style={ respFromLatest ? { ...styles.latestHighlightLabel, ...styles.strong } : styles.strong  } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
          <div style={ styles.radioGrp } className="radio-group">
            { this.props.optionList.map( option =>
              <div style={ styles.radio } key={ option.oid }>
                <input id={ "radio-" + option.oid }  style={ styles.radioInput } type='radio' value={ option.oid } defaultChecked={ response === option.oid ? "checked" : "" } name={ this.props.inputName } onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, true, true,false,false,this.props.resolveEditsSaved) } />
                <label htmlFor={ "radio-" + option.oid } style={ styles.radioLabel } dangerouslySetInnerHTML={{ __html: option.htmlContent }}></label>
              </div>
            )}
          </div>
        </div>
      );

    } else if (this.props.inputType === 'textarea' || this.props.inputType === 'wysiwyg') {

      response = response.replace(/<br\s*\/?>/gi, "\n");
      var classType = "agenda";
      if (Number(this.props.canTemplate) === 1) {
        classType = "wysiwyg";
      }

      return (
        <div className='question' key={ this.props.qId }>
          { historyLnk }
          { coverContent }
          { storeTemplate !== false || Number(this.props.canTemplate) === 1 ? (
            <FpiTemplates
              qid={this.props.qId}
              content={ textContent }
              updateContent={ this.handleEditorChange }
              templateType={ classType }
              templateClass={ classType }
            />
          ): "" }
          <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
					{ Number(this.props.isSummary) === 1  || this.props.inputType === 'wysiwyg' ? (

						<CKEditor
		          editor={ ClassicEditor }
		          data={ textContent }
		          onChange={ ( event, editor ) => {
		            const data = editor.getData();
		            this.handleEditorChange( data )
		          } }
		          onBlur={ ( event, editor ) => {
		            this.saveTextareaUpdate(this.props.qId, this.props.repeatNo);
		          } }
		          onFocus={ ( event, editor ) => {
		            console.log( 'Focus.', editor );
              } }
              config={{ toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'Indent','Outdent','insertTable','|','undo','redo' ] }}
            />
            
					) : (
						<textarea
							readOnly={ this.props.readOnly }
							className={ hasError === true ? "error" : "" }
							style={ respFromLatest ? styles.latestHighlight : styles.none  }
							name={ this.props.inputName } defaultValue={ response }
							type={ this.props.inputType } id={ this.props.inputName }
							onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) }>
						</textarea>
					) }

        </div>

      );
			// <textarea readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type={ this.props.inputType } id={ this.props.inputName } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) }></textarea>

    } else if (this.props.inputType === 'money') {

      return (
        <div className='question' key={ this.props.qId }>
          { historyLnk }
          { coverContent }
          <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
          <input readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type="text" id={ this.props.inputName } onChange={ (e) => this.handleCommas(e) } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) } style={ respFromLatest ? { ...styles.latestHighlight, ...styles.money } : styles.money  } />
        </div>
      );

    } else if (this.props.inputType === 'date') {

      // response = this.formatDateForDisplay(response);
      // <input readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type="text" id={ this.props.inputName } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) } />
	  if (dateHolder !== "") {
		  response = dateHolder;
		}
			  var rawResp = response;
		response = (response === "") ? null : new Date(response);
      var fieldInput = <DatePicker
        selected={ response }
        onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false, true,false,this.props.resolveEditsSaved) }
        dateFormat="d/M/yyyy"
				style={ respFromLatest ? styles.latestHighlight : styles.none  }
				className={ respFromLatest ? "new-update" : "" }
      />;
      if ( this.props.readOnly ) {
        fieldInput = <input readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } style={ respFromLatest ? styles.latestHighlight : styles.none  } name={ this.props.inputName } defaultValue={ rawResp } type="text" id={ this.props.inputName } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) } />;
      }

      return (
        <div className='question' key={ this.props.qId }>
          { historyLnk }
          { coverContent }
          <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
          { fieldInput }
        </div>
      );

  } else if (this.props.inputType === 'objective' || this.props.inputType === 'objective') {

		let bgCSS = {};
		let uploadImageText = 'Upload Image';

		if(thumbnailUrl){
			bgCSS.backgroundImage = ' url('+thumbnailUrl+')';
			uploadImageText = '';
		}

		if(typeof this.state.imgUrl !== 'undefined' && this.state.imgUrl.length > 0){
			bgCSS.backgroundImage = ' url('+this.state.imgUrl+') ';
			uploadImageText = '';
		}

		if(this.state.imageUploading){
			uploadImageText = <div style={styles.uploadMessage}>Uploading...</div>
		}

		let thumbnailStyle = bgCSS;

    response = response.replace(/<br\s*\/?>/gi, "\n");
    if (!sortOrder) {
      sortOrder = this.props.repeatNo;
    }

        return (
          <div className='question objective' key={this.props.qId }>

            <ObjectiveTemplates
              qid={ this.props.qId }
              content={ textContent }
              updateField={ this.updateField }
              type="objective"
            />

            { coverContent }
            <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
				    <div className='objective-container' style={styles.objectiveContainer}>

            <div className="img-uploader">
                <ObjectiveTemplates
                  qid={this.props.qId}
                  content={ imgId }
                  updateImgField={this.updateImgField}
                  type="objective-img"
                />
                <label htmlFor={'multi-' + this.props.qId +'-'+this.props.repeatNo} className="custom-file-upload" style={thumbnailStyle}>
                  { uploadImageText }
                  <input type='file' id={'multi-' + this.props.qId +'-'+this.props.repeatNo} onChange={ (e) => this.getFormDetails(e)} disabled={this.state.imageUploading} style={styles.fileUpload}/>
              </label>
            </div>

						<div style={styles.objectiveInputContainer} className='objective-input-container'>
	  					{ Number(this.props.isSummary) === 1  || this.props.inputType === 'objective' ? (
                <CKEditor
                    editor={ClassicEditor}
                    data={textContent}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.handleEditorChange(data)
                    }}
                    onBlur={(event, editor) => {
                      this.saveTextareaUpdate(this.props.qId, this.props.repeatNo);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                    config={{ toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'Indent', 'Outdent', '|', 'undo', 'redo'] }}
                />
	  					) : (
	  						<textarea
	  							readOnly={ this.props.readOnly }
	  							className={ hasError === true ? "error" : "" }
	  							style={ respFromLatest ? styles.latestHighlight : styles.none  }
	  							name={ this.props.inputName } defaultValue={ response }
	  							type={ this.props.inputType } id={ this.props.inputName }
	  							onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) }>
	  						</textarea>
	  					) }
					  </div>

            <div className="objective-so">
              <label htmlFor={"objso-" + this.props.qId + "-" + this.props.repeatNo }>Sort order</label>
                <input
                  id={"objso-" + this.props.qId + "-" + this.props.repeatNo}
                  type="number"
                  min="0"
                  max="100"
                  className="obj-so-input"
                  name="sort-order"
                  onBlur={(e) => this.saveSortOrder(e, this.props.qId, this.props.repeatNo) }
                  defaultValue={ sortOrder }
                />
            </div>
				  </div>
        </div>

        );

    } else if ( this.props.inputType === "fp-image" ) {

      return (
        <FullPageImg qid={ this.props.qId } repeatNo={ this.props.repeatNo } custRef={ this.props.custRef } response={ response } type={ this.props.pType } group={ this.props.group } />
      );

    } else if ( this.props.inputType === "age" ) { 

      var ages = [];
      for ( i=18;i<=100;i++ ) {
        ages.push(i);
      }

      return (
        <div className='question age' key={this.props.qId}>
          {historyLnk}
          {coverContent}
          <label className={this.props.isRequired === "1" ? 'required' : ''} htmlFor={this.props.inputName}>{this.props.qContent}</label>
          <select readOnly={this.props.readOnly} className={hasError === true ? "error" : ""} style={respFromLatest ? styles.latestHighlight : styles.none} name={this.props.inputName} defaultValue={response} id={this.props.inputName} onChange={(e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false, false, false, this.props.resolveEditsSaved)}>
            <option></option>
            {ages.map(opt =>
              <option key={ "age-" + opt } value={ opt }>{ opt } years old</option>
            )}
          </select>
        </div>
      );

    } else {

      return (
        <div className='question' key={ this.props.qId }>
          { historyLnk }
          { coverContent }
          <label className={ this.props.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.inputName }>{ this.props.qContent }</label>
          <input readOnly={ this.props.readOnly } style={ respFromLatest ? styles.latestHighlight : styles.none  } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type={ this.props.inputType } id={ this.props.inputName } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) } />
        </div>
      );

    }

  }

}

let styles = {

  objectiveInputContainer:{
	float:'left',
	width:'799px'

  },
  objectiveContainer:{
	  overflow:'auto'
  },
  uploadMessage:{
	height:'100%',
	width:'100%',
	background:'rgba(255,255,255,0.2)',
	display:'inline-block'
  },
  fileUpload:{
	  position:'absolute',
	  height:'100%',
	  width:'100%',
	  backgroundColor:'blue',
	  left:0,
	  opacity:0,
	  cursor:'pointer'
  },
  removeObjective:{
	  fontSize:'12px',
	  marginLeft:'5px'
  },
  historyIcon: {
    float: 'right',
    background: "url(" + historyImg + ") center center / 16px 16px no-repeat",
    fontSize: 0,
    width: 16,
    height: 16,
    marginTop: 5
  },
	latestHighlight: {
		border: "1px solid " + Colours.grn,
		padding: '8px 13px'
	},
	latestHighlightLabel: {
		borderBottom: "2px solid " + Colours.grn
	},
	none: {},
  err: {
    color: Colours.red,
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 35
  },
  clearer: {
    clear: 'both'
  },
  headSml: {
    color: Colours.grn,
    fontSize: 18,
    marginTop: 8,
    marginBottom: 5
  },
  money: {
    paddingLeft: 30,
    background: Colours.bgWhite + " url(" + pound + ") left 15px center / 8px auto no-repeat",
  },
  radio: {
    margin: "25px 0"
  },
  radioInput: {
    float: "left",
    margin: "5px 25px 5px 12px"
  },
  radioLabel: {
    marginLeft: 50,
    display: 'block'
  },
  strong: {
    fontWeight: 600
  },
  syncFont: {
    opacity: 0.5,
    fontStyle:"italic"
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash
  };
}

export default connect(mapStateToProps)(FlexiInput);
