import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';
import Btn from './../../common/btn.js';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';

class FileLink extends Component {

  constructor(props) {
    super(props);

    this.state = {
      custRef: this.props.match.params.custRef,
			customerName: "",
			selectedFiles: [],
      custFiles: [],
			genFiles: [],
      genLnk: '',
      genLoading: false,
      rdr: false,
			isLoadingCustomer: false,
      isLoadingGeneric: false,
      error: ""
    };

    this.getTokens = this.getTokens.bind(this);
    this.closeCover = this.closeCover.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
  }

	getCustomer () {

    // Check that we have a reference
    if (typeof this.state.custRef == 'undefined' || this.state.custRef === "") {
      this.setState({ rdr: true });
    }

    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customers/get/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.msg != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });
      } else {
        thus.setState({ customerName: data.name });
      }

    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

	getCustomerFiles () {
    var thus = this;
		let { custRef } = this.state;
		this.setState({ isLoadingCustomer: true });

    fetch(process.env.REACT_APP_API_BASE + '/admin/files/list/' + custRef, { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          console.log(data);
          // Check whether we have a failure
          if (typeof data.error == 'undefined') {
            // Redirect to the error page
            thus.setState({ custFiles: data, isLoadingCustomer: false });

          } else {
            // Process the data for display of the tabs
            thus.setState({ custFiles: [], isLoadingCustomer: false });
          }
        }
      );

  }

	getGenericFiles () {
    var thus = this;
		this.setState({ isLoadingGeneric: true });

    fetch(process.env.REACT_APP_API_BASE + '/admin/files/list/', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          console.log(data);
          // Check whether we have a failure
          if (typeof data.error == 'undefined') {
            // Redirect to the error page
            thus.setState({ genFiles: data, isLoadingGeneric: false });

          } else {
            // Process the data for display of the tabs
            thus.setState({ genFiles: [], isLoadingGeneric: false });
          }
        }
      );

  }

	selectFile (e, fileId) {

		var { selectedFiles } = this.state;

		if (e.target.checked) {
			// If the box has been checked then add it to the array
			if (!this.inArray(fileId, selectedFiles)) {
				selectedFiles.push(fileId);
			}
		} else {
			// Otherwise remove it from the array
			for (var i in selectedFiles) {
				if (selectedFiles[i] === fileId) {
					delete selectedFiles[i];
				}
			}
		}

		this.setState({ selectedFiles: selectedFiles });
	}

	generateLnk (e) {

		e.preventDefault();

		// Get the selected Files
		var { selectedFiles, custRef } = this.state;

		// Set the generating state (to display the loader)
		this.setState({ genLoading: true });

		// Run a query to generate the link and return it
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/files/share', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ files: selectedFiles, custRef: custRef }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

			// Upon success display the link and clear the selected fields
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
        // Set the updated state
        thus.setState({ genLoading: false, genLnk: data.url, selectedFiles: [],  });
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ error: "Sorry but there was an error generating the link. Please try again.", genLoading: false });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.setState({ error: "Sorry but there was an error generating the link. Please try again.", genLoading: false });
      thus.getTokens();
    });

	}

	inArray (needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] === needle) return true;
    }
    return false;
	}

  closeCover (e) {
    e.preventDefault();
    this.setState({ genLnk: "", genLoading: false, rdr: true });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {

    // Check for pagination
		this.getCustomer();
    this.getCustomerFiles();
    this.getGenericFiles();

    this.getTokens();

  }

  render() {

    const { isLoadingCustomer, isLoadingGeneric, custFiles, custRef, genLnk, genLoading, error, customerName, genFiles } = this.state;

    if (this.state.rdr) {
      return <Redirect to={"/admin/clients/details/" + custRef} />
    }

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    var coverContent = "";
    if (genLoading !== false) {
      coverContent = <div className="cover">
        <div className='width-1 centred' styles={ styles.topMrgn }>
          <div className="loader whiteBg">Loading...</div>
        </div>
      </div>
    }
    if (genLnk !== "") {
      coverContent = <div className="cover">
        <a href="#close" onClick={ (e) => this.closeCover(e) }> </a>
        <div className="box">
          <h5>Questionnaire Link</h5>
          <p className="smlr">Please copy the link below and send it to the client(s).</p>
          <p>{ process.env.REACT_APP_SITE_BASE }/download/{ this.state.genLnk }</p>
        </div>
      </div>
    }

    if (!isLoadingCustomer && !isLoadingGeneric) {
      content = <div style={ styles.form }>
									<div style={{ ...styles.bgGrey, ...styles.half }}>
										<h3>Customer Specific files</h3>
										{ custFiles.length > 0 ? (
											<div>
			                  { custFiles.map ( file =>
			                    <div key={file.id} style={ styles.half } className="half-width">
			                      <input name="fileName" type='checkbox' defaultChecked={ "" } onChange={ (e) => this.selectFile( e, file.id ) } id={ "input-" + file.id } value={ file.id } />
			                      <label style={ styles.label } htmlFor={ "input-" + file.id }>{ file.file_name } <span style={ styles.lighten }>({ file.createdFormatted })</span></label>
			                    </div>
			                  )}
											</div>
										) : (
											<p style={ styles.emptyMsg }>There are currently no client specific files uploaded.</p>
										) }
									</div>
									<div style={{ ...styles.bgGrey, ...styles.half }}>
										<h3>Generic files</h3>
										{ genFiles.length > 0 ? (
											<div>
			                  { genFiles.map ( file =>
			                    <div key={file.id} style={ styles.half } className="half-width">
			                      <input name="fileName" type='checkbox' defaultChecked={ "" } onChange={ (e) => this.selectFile( e, file.id ) } id={ "input-" + file.id } value={ file.id } />
			                      <label style={ styles.label } htmlFor={ "input-" + file.id }>{ file.file_name } <span style={ styles.lighten }>({ file.createdFormatted })</span></label>
			                    </div>
			                  )}
											</div>
										) : (
											<p style={ styles.emptyMsg }>There are currently no generic files uploaded.</p>
										) }
									</div>
                </div>;

    }

    var errorContent = "";
    if (error !== "") {
      errorContent = <div className='error mbtm' style={ styles.full }>
                      { error }
                    </div>
    }

    return (
      <div className='width-9 centred'>

        <Link style={{ ...styles.lnkRght, ...styles.grey }} to={ "/admin/clients/details/" + custRef }>Back</Link>
        <Heading mainTitle="Generate a File Download Link" subTitle={ customerName } />

        <FlashMsg />

        { coverContent }
        { errorContent }
        { content }

				<Btn title="Generate link" type="submit" handleSubmission={ (e) => this.generateLnk(e) } />

      </div>
    );
  }
}

var styles = {
  full: {
    width: '99%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
	grey: {
		background: Colours.txtGrey,
		fontSize: 14
	},
  form: {
    overflow: 'auto',
    display: 'block'
  },
  half: {
    width: "49%",
    float: "left",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: "0.5%",
    marginRight: "0.5%"
  },
  label: {
    marginLeft: 8
  },
	bgGrey: {
		background: Colours.bgGrey,
		padding: "12px 20px",
		boxSizing: "border-box"
	},
	lighten: {
		opacity: "0.6",
		fontSize: 14
	},
	emptyMsg: {
		fontStyle: "italic",
		opacity: "0.6",
		marginTop: 12
	}
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(FileLink);
