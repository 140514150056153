import React, { Component } from 'react';

import Colours from '../../../assets/colours.js';
import Fetching from '../../../functions/fetching.js';
import ExpContBlack from './../../../assets/img/expand-contract-black.png';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class EmailGroupsToggle extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      email_groups:[],
      customer_email_groups:[],
      is_collapsed:true,
      is_saving_data:false
    };
	  this.toggleEmailGroupPanelVisibility = this.toggleEmailGroupPanelVisibility.bind(this);
	  this.saveContent = this.saveContent.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
	  this.handleEmailGroupChange = this.handleEmailGroupChange.bind(this);
  }

  componentDidMount () {
    this.setState({email_groups:this.props.email_groups});
    this.setState({customer_email_groups:this.props.customer_email_groups});
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  

  componentDidUpdate(prevProps){
    if(prevProps.email_groups !== this.props.email_groups){
      this.setState({email_groups:this.props.email_groups});
    } 
    
    if(prevProps.customer_email_groups !== this.props.customer_email_groups){
      this.setState({customer_email_groups:this.props.customer_email_groups});
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({is_collapsed:true})
    }
  }

  toggleEmailGroupPanelVisibility(e){
    e.preventDefault();
	  this.setState(prevState => ({
		  is_collapsed: !prevState.is_collapsed
	  }));
  }

  getTokens () {
   fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	 .then(Fetching.statusCheck)
	 .then(Fetching.jsonExtract)
	 .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	 .catch(function (error) {
	   console.log('Request failed', error);
	 });
  }

  handleEmailGroupChange(e,id){

	  let customer_email_groups = [...this.state.customer_email_groups];

    if(e.target.checked){
      customer_email_groups.push(id)
    } else {
      for(let i = 0;i< customer_email_groups.length;i++){
        if(customer_email_groups[i] === id){
          customer_email_groups.splice(i,1);
        }
      }
    }
	 

	  this.setState({customer_email_groups}, () => {
		  this.saveContent();
	  })
  }



  // Check the tokens
  saveContent (e) {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/email_groups/update/' + this.props.custRef;
    var thus = this;

	  let saveData = this.state.customer_email_groups;

    fetch(fetchUrl, {
		credentials:"include",
		method: 'post',
		headers: {
			"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
		},
		body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
	})
      .then(response => response.json())
      .then(
        function (data) {
			thus.getTokens();
        }
      ).catch(function (error) {
 		  thus.getTokens();
 		  thus.setState({ success: "", error: "There was an error sending your message. Please check the content and try again." });
 	  });

  }


  render() {

    const {email_groups,customer_email_groups}  = this.state;

    let thus=this;

    const emailGroups = <div style={styles.email_group_popup} >
    
      {email_groups.map((email_group,i) => {

        let isChecked = false;

        customer_email_groups.forEach(ceg => {
          if(ceg === email_group.id){
            isChecked = true;
          }
        })

        return (
          <div  key={'qn_'+i}>
            <input style={{marginRight:5}} data-index={i} className="" id={email_group.name} name={email_group.name} onChange={(e) => this.handleEmailGroupChange(e,email_group.id)} type="checkbox" checked={isChecked} value={email_group.id} />
            <label htmlFor={email_group.name}  dangerouslySetInnerHTML={{ __html: email_group.name }}></label>

          </div>
        )
      })
    }
  </div>

	const visibilityCss = this.state.is_collapsed ? {display:'none'} : {display:'block'};
	// const filterState = this.state.is_collapsed ? 'closed' : 'open';
    return (

	  <>
    <Link style={ {...styles.fauxBtn, ...styles.export} } to={ `/`} onClick={(e) => thus.toggleEmailGroupPanelVisibility(e)}>Email Groups</Link>
      

	  <div style={visibilityCss} ref={this.wrapperRef}>
		  {emailGroups}
	  </div>
      {/* </div> */}
	  </>
    );
  }
}

var styles = {
  cfBlock: {
	  marginBottom: 25,
      background: Colours.bgGrey,
      padding: "12px 20px",
      boxSizing: "border-box",
      overflow: "auto"
  },

  mSml: {
    marginBottom: 15,
    borderBottomColor: Colours.txtGrey,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px top -38px",
    backgroundSize: "24px auto",
    backgroundImage: 'url(' + ExpContBlack + ')',
   },
  email_group_popup:{
    position:"absolute",
    backgroundColor: Colours.txtWhite,
    padding:"8px 24px 8px 8px",
    boxShadow: '1px 1px 5px rgba(0,0,0,0.15)',
    borderRadius:"4px"
  },
  save_btn:{
	  background: "rgb(78, 144, 67)",
	  color: "rgb(255, 255, 255)",
	  borderRadius: "20px",
	  textAlign: "center",
	  textDecoration: "none",
	  fontSize: '14px',
	  display: 'block',
	  padding: '5px 12px',
	  float:'right',
	  marginTop:0,
	  border:0,
	  cursor:'pointer',
	  marginRight:12
  },
  blackText:{
	  color:Colours.txtBlack
  },
  grey: {
    background: Colours.txtGrey,
    borderColor: Colours.txtGrey,
  },
  fauxBtn: {
    background: Colours.grn,
    color: Colours.txtWhite,
    borderRadius: 20,
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 14,
    display: 'block',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5
  },
  export: {
    color: Colours.txtWhite,
    background: Colours.txtGrey,
    marginTop: 2
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(EmailGroupsToggle);
