import React, { Component } from 'react';

import SxnList from './sxn-list.js'
import QuestionEdit from './q-edit.js'

class QuestionsList extends Component {
  render() {

    return (
      <div>
        <SxnList curSxn={ this.props.match.params.sxnSlug } />
        <QuestionEdit curSxn={ this.props.match.params.sxnSlug } />
      </div>
    );
  }
}

export default QuestionsList;
