import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import List from './list.js';
import Create from './create.js';
import Delete from './delete.js';

class CustomField extends Component {
  render() {

    return (
      <div className='content'>
        <Switch>
          <Route exact path='/admin/custom-fields' component={ List }/>
          <Route exact path='/admin/custom-fields/create' component={ Create }/>
          <Route exact path='/admin/custom-fields/create' component={ Create }/>
          <Route path='/admin/custom-fields/edit/:fieldId' component={ Create }/>
          <Route path='/admin/custom-fields/delete/:fieldId' component={ Delete }/>
        </Switch>
      </div>
    );
  }
}

export default CustomField;
