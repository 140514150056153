import React, { Component } from 'react';

class MicroThumb extends Component {

    render () {

        var styleDefinition = {};
        styleDefinition.backgroundImage = "url('" + this.props.url + "')";

        return <div className="micro-tmb">
            <a className="delete-img"
                href="#delete"
                onClick={(e) => this.props.deleteTemplate(e, this.props.tid )}
            >X</a >
            <a className="select-img"
                href="#select"
                onClick={(e) => this.props.updateImgField(e, this.props.tid, this.props.imgId, this.props.tmbUrl )}
                style={ styleDefinition }
            >Select</a >
        </div >


    }
}

export default MicroThumb;


