const Colours = {
  txtBlack: '#000',
  txtWhite: '#fff',
  txtGrey: '#8d8d8f',
  bgWhite: '#fff',
  bgWhite75: 'rgba(255,255,255,0.75)',
  bgGrey: '#f8f8f9',
  lightGrey: '#e2e0e0',
  lightishGrey: '#c2c0c0',
  grn: '#4e9043',
  lightGrn: '#a6c7a1',
  red: '#7D1717'
};

export default Colours;
