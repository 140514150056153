import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import Fetching from '../../../functions/fetching.js';

import Btn from '../../common/btn.js';
import PresentationSectionPanel from './presentation-section-panel';
import PresentationQuestionGroup from '../../questionnaire/presentation-question-group';
import moment from 'moment';
import TransactInvestments from './transact-investments.js';
import pie_one from '../../../assets/img/piecharts_legend/pie_chart_1-2.jpg'
import pie_two from '../../../assets/img/piecharts_legend/pie_chart_2-2.jpg'
import pie_three from '../../../assets/img/piecharts_legend/pie_chart_3-2.jpg'
import pie_four from '../../../assets/img/piecharts_legend/pie_chart_4-2.jpg'
import pie_five from '../../../assets/img/piecharts_legend/pie_chart_5-2.jpg'
import risk_bar from '../../../assets/img/graph-expectations-20230322.jpg'
import arrows from '../../../assets/img/arrows-down.png'

class Presentation extends Component {

  constructor(props) {
      super(props);

      this.state = {
        custRef: this.props.match.params.custRef,
        presentationSlug:this.props.match.params.presentationSlug,
        isFullscreen: false,
        rdr: false,
        errorFields: [],
        readOnly: false,
        names: false,
        family:[],
        groups:[],
        isLoadingData:true,
        viewType:'group',
        valuationData:[],
        riskData:[],
        assetFees:'',
        agenda:'',
        customerName:''
      };

      this.def = {
        'Defensive': {
          'desc': '<ul class="risk-desc"><li>I am looking for an investment where the long term return should be slightly better than that available from a high street deposit account and more or less in line with inflation.</li><li>Whilst I appreciate that there will be some fluctuations in the value of my investment, a fall of more than 12% during any 1 year period would make me feel uncomfortable.</li><li>I am happy to prioritise short term stability over long term growth and understand that this will limit long term returns.</li></ul>',
          'high': '43%',
          'low': '-25%',
          'long-term': '4%'
        },
        'Cautious': {
          'desc': '<ul class="risk-desc"><li>I am looking for an investment where the long term return should be better than that available from a high street deposit account and above inflation.</li><li>Whilst I appreciate that there will be some fluctuations in the value of my investment, a fall of more than 15% during any 1 year period would make me feel uncomfortable.</li><li>I wish to prioritise short term stability over long term growth and understand that this will somewhat restrict long term returns.</li></ul>',
          'high': '45%',
          'low': '-26%',
          'long-term': '5%'
        },
        'Balanced': {
          'desc': '<ul class="risk-desc"><li>I am looking for a balance between risk and reward, seeking higher returns than those available from a high street deposit account and above inflation.</li><li>Whilst I appreciate that there will be some fluctuations in the value of my investment, a fall of more than 20% during any 1 year period would make me feel uncomfortable.</li><li>I wish to have a balance between short term stability and long term growth.</li></ul>',
          'high': '49%',
          'low': '-29%',
          'long-term': '7%'
        },
        'Growth': {
          'desc': '<ul class="risk-desc"><li> I am willing to accept a higher level of risk on my investment in order to maximise potential returns.</li><li>I appreciate there could be significant fluctuations in the value of my investment and it could fall by more than 25% during any one year period, however, if funds were available I would look upon this as a buying opportunity.</li><li>I am happy to sacrifice short term stability in return for maximising potential long term returns.</li></ul>',
          'high': '53%',
          'low': '-32%',
          'long-term': '8%'
        },
        'Equity': {
          'desc': '<ul class="risk-desc"><li>I am willing to accept a high level of risk on my investment in order to maximise potential returns.</li><li>I appreciate there could be significant fluctuations in the value of my investment and it could fall by more than 30% during any one year period, however, if funds were available I would look upon this as a buying opportunity.</li><li>I am happy to fully sacrifice short term stability in return for maximising potential long term returns.</li></ul>',
          'high': '57%',
          'low': '-36%',
          'long-term': '9%'
        }
      };

      this.getTokens = this.getTokens.bind(this);
      this.getContent = this.getContent.bind(this);
      this.clearError = this.clearError.bind(this);
      this.loadGroupPage = this.loadGroupPage.bind(this);
      this.loadValuationData = this.loadValuationData.bind(this)
      this.loadRiskData = this.loadRiskData.bind(this)
      this.loadAssetFeesData = this.loadAssetFeesData.bind(this)
      this.loadAgendaData = this.loadAgendaData.bind(this)
      this.loadCustomerName = this.loadCustomerName.bind(this)
  }

  

    clearError (repNo, qid) {
      // Get the existing state
      var errorFields = this.state.errorFields;
      // Reset the state
      if (typeof errorFields[repNo + '-' + qid] != 'undefined') {
        delete errorFields[repNo + '-' + qid];
      }
      this.setState({ errorFields: errorFields });
    }

  getContent () {

    // Check that we have a reference
    if (typeof this.state.custRef == 'undefined' || this.state.custRef === "") {
      this.setState({ rdr: true });
    }

    if(this.state.presentationSlug === 'valuation_summary'){
      this.setState({viewType:'valuation'})
      this.loadValuationData();
    } else if (this.state.presentationSlug === 'investments'){
      this.setState({viewType:'investments',isLoadingData:false})
    } else if (this.state.presentationSlug === 'ongoing_advice'){
      this.loadAssetFeesData();
      this.setState({viewType:'ongoing-advice',isLoadingData:false})
    } else if (this.state.presentationSlug === 'risk_profile'){
      this.loadRiskData();
      this.setState({viewType:'risk-profile',isLoadingData:false})
    } else if (this.state.presentationSlug === 'agenda'){
        this.loadAgendaData();
        this.setState({viewType:'agenda',isLoadingData:false})
    } else{
      this.setState({viewType:'group'})
      this.loadGroupPage()
    }

  }

  loadGroupPage(){
    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_sections/presentation/' + this.state.custRef+'/'+this.state.presentationSlug, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.data != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });

      } else {
        console.log(data.groups);
        thus.setState({groups:data.groups,isLoadingData:false})

      }

    })
    .catch(function (error) {
      // thus.getTokens();
      console.log('Request failed', error);
    });
  }
  
  loadAgendaData(){
    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/agenda/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.data != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });

      } else {
        console.log(data);
        thus.setState({agenda:data.content,isLoadingData:false})

      }

    })
    .catch(function (error) {
      // thus.getTokens();
      console.log('Request failed', error);
    });
  
  
  }
  loadAssetFeesData(){
    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/assetFees/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.data != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });

      } else {
        console.log(data);
        thus.setState({assetFees:data.content,isLoadingData:false})

      }

    })
    .catch(function (error) {
      // thus.getTokens();
      console.log('Request failed', error);
    });
  
  }
  loadValuationData(){
    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/valuation/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.data != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });

      } else {
        console.log(data);
        thus.setState({valuationData:data,isLoadingData:false})

      }

    })
    .catch(function (error) {
      // thus.getTokens();
      console.log('Request failed', error);
    });
  }

  loadCustomerName(){
    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customers/name_details/' + this.state.custRef+'/1', { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if ( data.rslt != 'success' && data.data.length == 0) {
        // Set the updated state
        thus.setState({ rdr: true });

      } else {
        console.log(data.data[0].name);
        thus.setState({customerName:data.data[0].name})

      }

    })
    .catch(function (error) {
      // thus.getTokens();
      console.log('Request failed', error);
    });
  }

  loadRiskData(){
    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/risk/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.data != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });

      } else {
        console.log(data);
        thus.setState({riskData:data,isLoadingData:false})

      }

    })
    .catch(function (error) {
      // thus.getTokens();
      console.log('Request failed', error);
    });
  }

  componentDidMount() {
    // this.getTokens();
    this.getContent()
    this.loadCustomerName()

  }

  componentDidUpdate(){
    if(this.props.match.params.presentationSlug !== this.state.presentationSlug){
      this.setState({presentationSlug:this.props.match.params.presentationSlug,isLoadingData:true},() => {
        this.getContent();
      })
      
    }
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }


  // Switch the screen display to full screen
  triggerFullScreen (e) {
    e.preventDefault();
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
    this.setState({ isFullscreen: true });
    this.props.dispatch({ type: 'MINIMISE_HEADER' });

  }

  exitFullscreen(e) {
    e.preventDefault();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
    this.setState({ isFullscreen: false });
    this.props.dispatch({ type: 'MAXIMISE_HEADER' });

  }

  closeCover (e) {
    e.preventDefault();
    this.setState({ formAndHistory: [], loadingForm: false });
    this.getMapData("");
  }

  render() {

    let {  isFullscreen, rdr, groups,errorFields,readOnly,names,family,isLoadingData, custRef,viewType,valuationData,riskData,agenda, assetFees,customerName } = this.state;

   

    if (rdr) {
      return <Redirect to="/admin/clients" />
    }

    var fsBtn = <Btn type='button' title="Fullscreen" handleSubmission={ (e) => this.triggerFullScreen(e) } btnStyle={ styles.barBtn } btnStyleInner={ styles.barBtnInner } />
    if (isFullscreen) {
      fsBtn = <Btn type='button' title="Exit fullscreen" handleSubmission={ (e) => this.exitFullscreen(e) } btnStyle={ styles.barBtn } btnStyleInner={ styles.barBtnInner } />
    }

    let tabContent;

    if (isLoadingData) {
			tabContent =(
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
		} else if(viewType ==='group') {
      tabContent =  
      <Fragment>
      <h2>{this.state.presentationSlug.charAt(0).toUpperCase()+ this.state.presentationSlug.replace(/_/,' ').slice(1)}</h2>
      { groups.map( group =>

        <PresentationQuestionGroup
          performSaveCheck={this.state.performSaveCheck}
          responseChanges={this.state.responseChanges}
          resolveEditsSaved ={this.resolveEditsSaved}
          handleEditorChange={this.handleEditorChange}
          key={group.group.title}
          group={group.group}
          hideTabs={ this.props.hideTabs }
          errorFields={ errorFields }
          clearError={ this.clearError }
          custRef={ this.state.custRef }
          names={ names }
          family={ family }
          readOnly={ readOnly }
          sxnSlug={ this.props.sxnSlug }
          presentationSlug={this.state.presentationSlug}
        />

        )}
        </Fragment>
    } else if(viewType ==='investments') {
      tabContent =  
      <Fragment>
      <h2>{this.state.presentationSlug.charAt(0).toUpperCase()+ this.state.presentationSlug.replace(/_/,' ').slice(1)}</h2>
        <TransactInvestments custRef={custRef} embedded={true}/>
        </Fragment>
    
  } else if(viewType ==='agenda'){
    tabContent =  
      <Fragment>
      <h2>{this.state.presentationSlug.charAt(0).toUpperCase()+ this.state.presentationSlug.replace(/_/,' ').slice(1)}</h2>
      <div className='agenda-panel' dangerouslySetInnerHTML={{__html:agenda}}></div>
      </Fragment>
  } else if(viewType ==='ongoing-advice') {
      tabContent =  
      <Fragment>
      <h2>{this.state.presentationSlug.charAt(0).toUpperCase()+ this.state.presentationSlug.replace(/_/,' ').slice(1)}</h2>
        <div className='ongoing-advice-chart'>
          <div className='ongoing-advice-panel'>
          <h3>Ashworth Ongoing Advice Service</h3>
          </div>
        <img src={arrows} className='arrows-chart' alt='arrow-chart' />
        <div className='ongoing-advice-outcome' > 
          <div className='ongoing-advice-panel'>
            <h4>Portfolio value</h4>
            <p className='ongoing-advice-subheading'>...creating and maintaining the right investment portfolio through the right financial structure</p>
            <ul class="risk-graph-legend">
              <li>Taking the most appropriate level of investment risk.</li>
              <li>Being tax efficient</li>
              <li>Minimising the cost of investing.</li>
              <li>Managing risk through regular rebalancing.</li>
              <li>Individual fund manager selection and continual oversight.</li>
              <li>Using ISAs, Pensions, Bonds & Trusts etc where appropriate.</li>
              </ul>
            </div>
            <div className='ongoing-advice-panel'>
            <h4>Financial value</h4>
            <p className='ongoing-advice-subheading'>...achieving financial goals & getting organised</p>
            <ul class="risk-graph-legend">
              <li>Financial planning of future cash flows, future income and future expenditure, to ensure you can
maintain your lifestyle without running out of money.</li>
              <li>Minimising tax during life and on death.</li>
              <li>Legacy & Estate Planning.</li>
              <li>Insurance and risk management</li>
              <li>Behavioural coaching to help you make good decisions and avoid costly mistakes.</li>
              </ul>
          </div>
          <div className='ongoing-advice-panel'>
          <h4>Emotional value</h4>
          <p className='ongoing-advice-subheading'>...enabling genuine financial peace of mind</p>
          <ul class="risk-graph-legend">
              <li>Trust – in us and in investment markets.</li>
              <li>Success and sense of accomplishment.</li>
              <li>Confidence, clarity, feeling in control.</li>
              <li>Feeling a sense of financial freedom and independence.</li>
              <li>Discovering what’s truly important to you about money and the role it plays in your life.</li>
              <li>Having somebody to talk to about money.</li>
              <li>Knowing that, financially, everything is going to be alright.</li>
              </ul>
          </div>
        </div>
        </div>
        <div className='clear'></div>
        <h2>How we do it</h2>

        <table className='green-table'>
          <tbody>
            <tr>
              <td> Regular Meetings </td>
              <td>We will meet at least once per year to review progress and ensure everything remains on track for the coming years ahead.</td>
            </tr>
            <tr>
              <td>Additional Meetings</td>
              <td>We are available to meet in between our annual meetings whenever necessary. This will either be at your request or if we feel
there is an important issue that needs to be discussed.
</td>
            </tr>
            <tr>
              <td>Unlimited Telephone & Email Access To Your Adviser</td>
              <td>You are able to call or email us at anytime to ask questions and talk through anything you like.</td>
            </tr>
            <tr>
              <td>Cashflow Planning</td>
              <td>We use sophisticated financial planning software to show you what your financial future looks like and to help ensure you can
achieve and maintain you desired lifestyle.
</td>
            </tr>
            <tr>
              <td>Investment Management </td>
              <td>We make sure you are benefiting from the most appropriate investment strategy bearing in mind what you’re trying to achieve
and your thoughts/feelings about investment risk.

</td>
            </tr>
            <tr>
              <td>Investment Planning</td>
              <td>We make sure you are using the right method of investment (e.g. pension, ISA, Bond, Trust etc) at the right time for your
circumstances and to reflect what you’re trying to achieve.
</td>
            </tr>
            <tr>
              <td>Second Opinion</td>
              <td>We are available to talk through anything that might be happening in your life that has a financial implication.
</td>
            </tr>
            <tr>
              <td>Simplification</td>
              <td>The wording used by financial institutions can seem like a different language! We can translate what’s been sent to you and
identity what you need to keep and what can be safely destroyed. We can also carry out shredding for you.
</td>
            </tr>
            <tr>
              <td>Other Professionals</td>
              <td>We can keep your accountant and/or solicitor up to date with your financial arrangements.<br/>
We can provide your accountant with everything they might need to complete your tax return.<br/>
We can introduce you to other professionals should the need arise.
</td>
            </tr>
          </tbody>
        </table>
        <h2>Advice Fees</h2>
        <div className='advice-fees' dangerouslySetInnerHTML={{__html:assetFees}} ></div>
        </Fragment>
    } else if(viewType ==='risk-profile') {

      let yourRiskProfileContent = (
        <table className='plain risk-profile'>
          <tbody>

          {riskData.map((data) => {

            let pieChart = null
        switch(data.value.toLowerCase()){
          case 'defensive':
            pieChart = <img src={pie_one} alt='defensive_pie_chart'/>
            break;
          case 'cautious':
            pieChart = <img src={pie_two} alt='cautious_pie_chart' />
            break;
          case 'growth':
            pieChart = <img src={pie_four} alt='growth_pie_chart'/>
            break;
          case 'equity':
            pieChart = <img src={pie_five} alt='equity_pie_chart' />
            break;
          case 'balanced':
          default:
            pieChart = <img src={pie_three}  alt='balanced_pie_chart'  />
            break;
        }

        return (<tr>
          <td><span className='risk-title' >{data.fieldName}</span></td>
          <td><span className='risk-title' >{data.value}</span>{pieChart}</td>
          <td dangerouslySetInnerHTML={{__html:this.def[data.value]['desc']}}></td>
        </tr>
        )
      })}
            
              
          </tbody>
        </table>
      );


      

      tabContent =  
      <Fragment>
      <h2>{this.state.presentationSlug.charAt(0).toUpperCase()+ this.state.presentationSlug.replace(/_/,' ').slice(1)}</h2>
      <p >There are a wide range of options for how your money can be invested and in order to ensure
				any recommendation we make is suitable for you and that the outcome matches your
				expectations, we need to make sure they fit comfortably with your risk profile. Your risk
				profile is a combination of your attitude to risk and your capacity for loss. I have defined
				each of these below and then provided our assessment of your risk profile.</p>
			<p ><strong>Attitude to Risk:</strong></p>
			<p >An investor’s attitude to risk can be defined as their willingness to accept volatility and
				fluctuations in the value of their investments in return for the potential to achieve higher
				investment returns over a medium to long period of time (in excess of five years). Different
				investors are prepared to accept differing levels of volatility based on their overall
				circumstances and objectives. It is generally accepted that the higher the level of volatility
				an investor is prepared to accept, the higher the returns they can expect to achieve over the
				longer term, although this is not guaranteed. Conversely, if an investor is only prepared to
				accept a lower level of volatility, then they can expect to achieve lower returns (compared to
				a medium or high volatility investor) over the medium to longer term.</p>
			<p ><strong>Capacity for Loss:</strong></p>
			<p >An investor’s capacity for loss can be defined as their ability to accept the expected volatility
				associated with their attitude to risk and any permanent losses that may arise from this. The
				ability can be determined by the presence of, for example; other assets, an emergency fund,
				other sources of income or an anticipated inheritance.</p>
			<p ><strong>Your risk profile</strong></p>
			<p >Our understanding of your thoughts / feelings about investment risk:</p>
      {yourRiskProfileContent}
      <p>We discussed risk and return and you understand that the value of any investment will fluctuate over time. We agreed that a Balanced risk profile seems most appropriate
      for you as this will provide potential for future capital growth whilst maintaining some focus on short term stability.</p>
      <p>Some of the investments/pensions we have in place for you may have a lower or higher level of risk. Where this is the case the particular objective for that arrangement
      may be different to your general objectives/risk profile and/or the money may be earmarked for a particular person or moment in time.</p>
      <p>Enclosed with this report is full details of the 4 categories for risk profiles which we use. These being Defensive, Cautious, Balanced and Growth. If you feel we havenâ€™t
      assessed you accurately or if you feel one of the other risk profiles is more appropriate, please contact me immediately.</p>
      
  
      <h2>Risk Profile Definitions</h2>

      <table className='plain risk-profile'>
          <tbody>
          <tr>
            <td>
            <span className='risk-title'>Defensive</span><img src={pie_one}  alt='defensive_pie_chart' />
            </td>
            <td dangerouslySetInnerHTML={{__html:this.def['Defensive']['desc']}}></td>
          </tr>
          <tr>
            <td>
            <span className='risk-title' >Cautious</span><img src={pie_two}   alt='cautious_pie_chart'/>
            </td>
            <td dangerouslySetInnerHTML={{__html:this.def['Cautious']['desc']}}></td>
          </tr>
          <tr>
            <td>
            <span className='risk-title' >Balanced</span><img src={pie_three} alt='balanced_pie_chart'/>
            </td>
            <td dangerouslySetInnerHTML={{__html:this.def['Balanced']['desc']}}></td>
          </tr>
          <tr>
            <td>
            <span className='risk-title' >Growth</span><img src={pie_four} alt='growth_pie_chart' />
            </td>
            <td dangerouslySetInnerHTML={{__html:this.def['Growth']['desc']}}></td>
          </tr>
          <tr>
            <td>
            <span  className='risk-title' >Equity</span><img src={pie_five} alt='equity_pie_chart'/>
            </td>
            <td dangerouslySetInnerHTML={{__html:this.def['Equity']['desc']}}></td>
          </tr>
       
         
         
              
          </tbody>
        </table>

        <h2>Risk Profile Expectations</h2>
        <img src={risk_bar} style={{float:'left',width:'50%',height:'auto'}} alt='risk_bar_graph'/>
        <table class='plain' style={{float:'left',width:'25%',marginLeft:120,marginBottom:16}}>
          <thead>
            <tr style={{backgroundColor: "#f7f8f9"}}>
              <th>
            Risk Level
            </th>
            <th>Expected return</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Defensive
              </td>
              <td>CPI + 0%</td>
            </tr>
            <tr>
              <td>
                Cautious
              </td>
              <td>CPI + 1%</td>
            </tr>
            <tr>
              <td>
                Balanced
              </td>
              <td>CPI + 2%</td>
            </tr>
            <tr>
              <td>
                Growth
              </td>
              <td>CPI + 3%</td>
            </tr>
            <tr>
              <td>
                Equity
              </td>
              <td>CPI + 4%</td>
            </tr>
          </tbody>
          </table>
          <div class='clear' style={{marginBottom:16}}></div>
          <p><strong>Notes: </strong> Based on Sterling actual returns achieved since 31st December 1985 (source Waverton / Rimes - updated January 2019)</p>
          <p><strong>Risk warning: </strong> Past performance is no guarantee of future results and the value of such investments and their strategies may fall as well as rise. You may not get
back your initial investment. Capital security is not guaranteed. Changes in rates of exchange may have an adverse effect on the value, price or income of an
investment.
</p>

<ul class='risk-graph-legend'>
<li>The <strong>top line</strong> represents the best outcome from that risk profile</li>
<li>The <strong>bottom line</strong> represents the worst outcome from that risk profile</li>
<li>The <strong>top of the bar</strong> (green) is the average return in a positive year for returns</li>
<li>The <strong>bottom of the bar</strong> (brown) is the average decline in a negative year for returns</li>
</ul>
      </Fragment>

      

    } else {
      tabContent =  
      <Fragment>
      <h2>{this.state.presentationSlug.charAt(0).toUpperCase()+ this.state.presentationSlug.replace(/_/,' ').slice(1)}</h2>
      <table className='valuation-summary-table'>
        <thead>
          <tr>
            <th>Holder</th>
            <th>Provider</th>
            <th>Plan Type</th>
            <th>Start date</th>
            <th>Contributions</th>
            <th>Risk Level</th>
            <th>Current Value</th>
            <th>Withdrawals</th>
            <th>Additional Info</th>
          </tr>
        </thead>
        <tbody>
          {valuationData.map((data,i) => {
            
            if(valuationData.length - 1 > i){
              return (
                <tr key={'investment-'+i}>
                  <td>{data.Owner}</td>
                  <td>{data.Provider}</td>
                  <td>{data.type}</td>
                  <td>{moment(data['Start date']).format('D MMMM YYYY')}</td>
                  <td>{data['specials']['contributions']}</td>
                  <td>{data['specials']['risk-level']}</td>
                  <td>{data['specials']['approx-val']}</td>
                  <td>{data['specials']['withdrawals']}</td>
                  <td>{i+1}</td>
                </tr>
                )
            } else {
              return (
                <tr key={'investment-'+i}>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{data['contributions']}</td>
                  <td></td>
                  <td>{data['value']}</td>
                  <td>{data['withdrawals']}</td>
                  <td></td>
                </tr>
              )
            }
            
          })}
        </tbody>
      </table>
      <div style={{marginTop:16}}>
        <h4 style={{marginBottom:16}}>Additional information</h4>
      {valuationData.map((data,i) => {
        let footnote = null;
        if(data['Additional notes'] !== undefined){
          footnote = <p style={{paddingLeft:8}}>{(i+1) +': '+data['Additional notes'] }</p>
        }
        return footnote
      })}
      </div>
      </Fragment>
    }


    return (
      <div className={ this.props.minimise ? 'fullscreen-diagram pres-mode' : 'diagram' }>
        <div id='presentation-title'>
      <h1 className='presentation-customer-name'>{customerName !== "" ? customerName : ''}</h1>
      
        <div style={ styles.btnBar }>
        <Link style={{...styles.grnBtn} } to={ "/admin/clients/details/" + custRef }>Back</Link>
        { fsBtn }
       </div>
        </div>
   
        <div className='presentation-menu'>
        <PresentationSectionPanel presentationSlug={this.state.presentationSlug} custRef={ this.props.match.params.custRef } />
        </div>
      <div className='presentation-tab-content'>
        {tabContent}
       
          </div>

      </div>
    );
  }

}

// Styling information that is specific to this component
const styles = {
  level0:{
    font:{
      color:'white',
      size:18,
      face: "'Trebuchet', arial, sans-serif"
    }
  },
	level1:{
    font:{
      size:12,
      face: "'Trebuchet', arial, sans-serif"
    }
  },
  deep:{
    font:{
      color:'black',
      size:6,
      face: "'Trebuchet', arial, sans-serif"
    }
  },
  toggleBtn:{
    position:'absolute',
    right:'1rem',
    zIndex:99,
    width:'5rem'
  },
  btnBar: {
    float: 'right',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
  },
  barBtn: {
    float: 'left',
    marginRight: 12,
    marginTop: 0
  },
  barBtnInner: {
    borderRadius: "20px",
    marginTop: 12
  },
  italic: {
    fontWeight: "normal",
    fontStyle: "italic"
  },
  grnBtn:{
    marginLeft: "auto",
    display: "block",
    background: "rgb(78, 144, 67)",
    color:"rgb(255, 255, 255)",
    maxWidth: 200,
    textAlign: "center",
    padding: "5px 22px",
    boxSizing: "border-box",
    textDecoration: "none",
    fontWeight: 400,
    fontSize: 16,
    border:0,
    cursor: "pointer",
    outline: "none",
    float:'left',
    boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 5px",
    borderRadius: 20,
    marginTop: 12,
    marginRight:8
  }
}

function mapStateToProps(state) {
  return {
    minimise: state.minimalHeader
  };
}

export default connect(mapStateToProps)(Presentation);
