import React, { Component } from 'react';
import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';
import Btn from './../../common/btn.js';

import Upload from './upload.js';

class Files extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uid: (typeof this.props.custRef != 'undefined') ? this.props.custRef : "-",
      files: [],
			pageNo: 1
    };
  	this.loadFiles = this.loadFiles.bind(this);
	}

  // Fetch the user data
  componentDidMount () {
	this.loadFiles();
	}

	componentWillReceiveProps (newProps) {
		if (this.props.lastFileUpdate !== newProps.lastFileUpdate) {
			this.loadFiles();
		}
	}

	loadFiles () {

		if(typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ){
			
			let attachments = (typeof this.props.attachments !== 'undefined' && this.props.attachments.length > 0) ? this.props.attachments : [];
			this.setState({ files: attachments});
		} else {
			var thus = this;
			let { pageNo, uid } = this.state;

			fetch(process.env.REACT_APP_API_BASE + '/admin/files/list/' + uid + "/" + pageNo, { credentials:"include" })
			.then(Fetching.statusCheck)
			.then(Fetching.jsonExtract)
			.then(
				function (data) {

				// Check whether we have a failure
				if (typeof data.error == 'undefined') {
					// Redirect to the error page
					thus.setState({ files: data });

				} else {
					// Process the data for display of the tabs
					thus.setState({ files: [] });
				}
				}
			);
		}
    	

	}

	deleteFile ( e, fileId ) {

		if (window.confirm("Are you sure you wish to delete this file?")) {

			if(typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ){
				this.props.removeAttachment( fileId);
			} else {
				var thus = this;
				fetch(process.env.REACT_APP_API_BASE + '/admin/files/delete/' + fileId, { credentials:"include" })
				.then(Fetching.statusCheck)
				.then(Fetching.jsonExtract)
				.then(
					function (data) {

					// Check whether we have a failure
					if (typeof data.error == 'undefined') {
						// Redirect to the error page
						thus.loadFiles();
					} else {
						// Process the data for display of the tabs
						alert("Sorry but there was an error deleting the file. Please try again.");
					}
					}
				);
			}

			

		}

	}

  render() {

    const { files } = this.state;
	let blockStyle = {...styles.block};

	if(this.props.align !== undefined && this.props.align === 'right'){
		blockStyle.float = 'right';
	}

	let upload;
	if(typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ){
		upload =  <Upload isCommunication={true} custRef={ this.props.custRef } callbackFunc={ this.props.addAttachment } />
	} else {
		upload = <Upload custRef={ this.props.custRef } callbackFunc={ () => this.loadFiles() } />
	}

    return (
      <div className={ typeof this.props.title != 'undefined' ? "width-4" : "" } style={ blockStyle }>
				{ typeof this.props.title != 'undefined' ? (
					<h2>
						{ this.props.genLnk === true ? <Btn title="Cancel link" type="link" lnk={ "/admin/clients/cancel-link/" + this.props.custRef } wrapStyle={ styles.wrapper } btnStyle={{ ...styles.round, ...styles.grey,...styles.margin }} /> : "" }
						{ this.props.genLnk === true ? <Btn title="Generate link" type="link" lnk={ "/admin/clients/file-link/" + this.props.custRef } wrapStyle={ styles.wrapper } btnStyle={{ ...styles.round, ...styles.grey }} /> : "" }
						{ this.props.title }
					</h2>
				) : "" }
				{ typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ? (
					<h4>Attachments</h4>

				) : "" }

				<ul style={ styles.ul }>
	        		{ files.map( file =>
						<li style={{clear:'both'}} key={ file.id }>
							{typeof file.is_audio !== 'undefined' && file.is_audio ? (
								<div className='audio-player-container'>
									<audio
										className='communication-audio-player'
										controls
										src={ process.env.REACT_APP_API_BASE + "/admin/files/download/" + file.id }>
											Not supported
									</audio>
									<span className='right' style={ styles.sml }>Uploaded: { file.createdFormatted } (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)</span>
								</div>) : 
								(<span className='right' style={ styles.sml }>Uploaded: { file.createdFormatted } (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)</span>)
							}
							
							<a href={ process.env.REACT_APP_API_BASE + "/admin/files/download/" + file.id } target="_blank" rel="noopener noreferrer" style={ styles.a }>{ file.file_name }</a>
							
						</li>
					) }

					{upload}
				</ul>
      </div>
    );

  }
}

var styles = {
	block: {
		marginBottom: 25,
	    background: Colours.bgGrey,
	    padding: "12px 20px",
	    boxSizing: "border-box",
	    overflow: "auto"
	},
	sml: {
		opacity: '0.5',
		fontSize: 14,
		paddingTop: 2
	},
	ul: {
		listStyle: 'none'
	},
	a: {
		color: Colours.txtBlack
	},
	wrapper: {
		float: "right",
		marginTop: 0
	},
	round: {
		marginTop: 0,
		borderRadius: 25
	},
	grey: {
		backgroundColor: Colours.txtGrey,
		fontSize: 14
	},
	margin: {
		marginLeft:4
	}
};


export default Files;
