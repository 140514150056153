import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'

import Heading from './../heading.js';

import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';

class CustomerDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uid: this.props.match.params.custRef,
      usr: false,
      rdr: false,
      usrName: ""
    };
  }

  // Fetch the user data
  componentDidMount () {

    var thus = this;
    if (typeof this.props.match.params.custRef != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/customers/get/' + this.props.match.params.custRef + "/0", { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            console.log(data);
            // Check whether we have a failure
            if (typeof data.errors != 'undefined') {

              // Redirect to the error page
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but we could not find the client to delete.' });

            } else {
              // Process the data for display of the tabs
              thus.setState({ usr: data, usrName: data.name });
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the client.' });
        thus.setState({ rdr: true });
      }

  }

  handleDeletion () {

    var thus = this;
    if (typeof this.props.match.params.custRef != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/customers/delete/' + this.props.match.params.custRef, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            console.log(data);
            // Check whether we have a failure
            if (typeof data.errors != 'undefined') {

              // Redirect to the error page
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the client.' });
              return false;

            } else {
              // Process the data for display of the tabs
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: 'The client has been successfully deleted.' });
              return data;
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the client.' });
        thus.setState({ rdr: true });
      }

  }


  render() {

    const { usr, usrName, rdr } = this.state;

    if (rdr) {
      return (
        <Redirect to='/admin/clients' />
      );
    }

    if (usr) {

      return (
        <div className="width-6 centred">
          <Link style={{ ...styles.lnk, ...styles.lnkRght }} to={ `/admin/clients` }>Back</Link>
          <Heading mainTitle="Delete Client" subTitle={ usrName } />

          <p>Are you sure you wish to delete { usrName }? (N.B. Deletion removes all client data and it cannot be retrieved)</p>

          <Link style={{ ...styles.lnk, ...styles.lnkConfirm }} to={ `/admin/clients` } onClick={ (e) => this.handleDeletion(e) }>Yes, delete this client</Link>
          <Link style={{ ...styles.lnk, ...styles.lnkDeny }} to={ `/admin/clients` }>No, keep this client&rsquo;s data</Link>

        </div>
      );

    }

    return <div className='width-1 centred'>
            <div className="loader whiteBg">Loading...</div>
          </div>;
  }
}

var styles = {
  lnk: {
    display: "block",
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50
  },
  lnkDeny: {
    textAlign: 'center',
    maxWidth: 200,
    margin: "15px auto",
    background: Colours.red,
    border: '1px solid ' + Colours.red,
  },
  lnkConfirm: {
    textAlign: 'center',
    maxWidth: 200,
    margin: "30px auto 15px auto"
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CustomerDelete);
