import React, { Component } from 'react';
import { connect } from 'react-redux';

import Fetching from './../../../functions/fetching.js';

class ClientName extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
      custName: this.props.custName
    };

    this.saveField = this.saveField.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  componentWillReceiveProps ( newProps ) {

    if ( typeof newProps != 'undefined' && typeof newProps.errorFields != 'undefined' && typeof newProps.errorFields[newProps.repeatNo + '-' + newProps.qId] != 'undefined' ) {
      this.setState({ hasError: true });
    }

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  saveField (e, cfId, isSelect, isRadio) {

    // Figure out the response object
    var saveData = {
      name: encodeURIComponent(e.target.value),
    }

    // Run the save
    var thus = this;
    let fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/update_name/' + this.props.custRef;
    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if ( typeof data.rslt !== 'undefined' && data.rslt === 'success' ) {
        thus.setState({ hasError: false, custName: data.name });
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ hasError: true });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.getTokens();
      console.log('Request failed', error);
    });
  }

  render () {

    const { hasError, custName } = this.state;

    return (
      <div className='question' key="custName">
        <label className='required' htmlFor="custname">Customer name (as shown in listings)</label>
        <input className={ hasError === true ? "error" : "" } id="custname" name="custname" defaultValue={ custName } type="text" onBlur={ (e) => this.saveField(e) } />
      </div>
    );

  }

}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(ClientName);
