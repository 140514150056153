import React, { Component } from 'react';
import { connect } from 'react-redux';

import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';

class CFInput extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
      cfeId: ""
    };

    this.saveField = this.saveField.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  componentWillReceiveProps ( newProps ) {

    if ( typeof newProps != 'undefined' && typeof newProps.errorFields != 'undefined' && typeof newProps.errorFields[newProps.repeatNo + '-' + newProps.qId] != 'undefined' ) {
      this.setState({ hasError: true });
    }

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  saveField (e, cfId, isSelect, isRadio,attNo = 0) {

    if (!isRadio) {
      e.preventDefault();
    }

    if (this.props.cf.type === "linked") {
      return;
    }

    // Figure out the response object
    var saveData = {
      cfId: cfId,
      customerRef: this.props.custRef,
      entry: encodeURIComponent(e.target.value)
    }
    if (isSelect) {
      saveData.cfoId = saveData.entry;
    }
    if (this.state.cfeId !== "") {
      saveData.cfeId = this.state.cfeId;
    }

   

    // Run the save
    var thus = this;
    let fetchUrl = process.env.REACT_APP_API_BASE + '/admin/custom_field_entries/save/';
    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);
      
      if ( typeof data.rslt != 'undefined' && data.rslt === "secure-fail" &&  attNo < 10) {
        thus.getTokens();
        setTimeout(() => thus.saveField (e, cfId, isSelect, isRadio,++attNo),1000);
      } else if ( typeof data.rslt !== 'undefined' && data.rslt === 'success' && typeof data.entryId != 'undefined' ) {
        thus.setState({ hasError: false, cfeId: data.entryId });
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ hasError: true });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.getTokens();
      console.log('Request failed', error);
    });
  }

  render () {

    const { hasError } = this.state;

    if (this.props.cf.type === 'select') {

      return (
        <div className='question' key={ this.props.qid }>
          <label className={ this.props.cf.isRequired === "1" ? 'required' : '' } htmlFor={ "cf-" + this.props.cf.id }>{ this.props.cf.label }</label>
          <select className={ hasError === true ? "error" : "" } name={ "cf-" + this.props.cf.id } defaultValue={ typeof this.props.cf.entry != 'undefined' ? this.props.cf.entry : "" } id={ "cf-" + this.props.cf.id } onChange={ (e) => {e.persist(); this.saveField(e, this.props.cf.id, true, false)} }>
            <option></option>
            { this.props.cf.options.map( option =>
              <option key={ option.id } value={ option.id }>{ option.content }</option>
            )}
          </select>
        </div>
      );

    } else if (this.props.cf.type === 'radio') {

      return (
        <div className='question radio' key={ this.props.cf.id }>
          <label className={ this.props.cf.isRequired === "1" ? 'required' : '' } htmlFor={ "cf-" + this.props.cf.id } style={ styles.strong }>{ this.props.cf.label }</label>
          <div style={ styles.radioGrp } className="radio-group">
            { this.props.cf.options.map( option =>
              <div style={ styles.radio } key={ option.id }>
                <input id={ "cf-" + option.id }  style={ styles.radioInput } type='radio' value={ option.id } defaultChecked={ typeof this.props.cf.entry != 'undefined' && this.props.cf.entry === option.id ? "checked" : "" } name={ "cf-" + this.props.cf.id } onChange={ (e) => this.saveField(e, this.props.cf.id, true, true) } />
                <label htmlFor={ "cf-" + option.id } style={ styles.radioLabel } dangerouslySetInnerHTML={{ __html: option.content }}></label>
              </div>
            )}
          </div>
        </div>
      );

    } else if (this.props.cf.type === 'default') {

      return "";

    } else {

      return (
        <div className='question' key={ this.props.qid }>
          <label className={ this.props.cf.isRequired === "1" ? 'required' : '' } htmlFor={ this.props.cf.id }>{ this.props.cf.label }</label>
          <input readOnly={ this.props.cf.type === "linked" } className={ hasError === true ? "error" : "" } name={ this.props.cf.id } defaultValue={ typeof this.props.cf.entry != 'undefined' ? this.props.cf.entry : "" } type="text" id={ this.props.cf.id } onBlur={ (e) => this.saveField(e, this.props.cf.id, false, false) } />
        </div>
      );

    }

  }

}

const styles = {
  err: {
    color: Colours.red,
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 35
  },
  radio: {
    margin: "25px 0"
  },
  radioInput: {
    float: "left",
    margin: "5px 25px 5px 12px"
  },
  radioLabel: {
    marginLeft: 50,
    display: 'block'
  },
  strong: {
    fontWeight: 600
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(CFInput);
