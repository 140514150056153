import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import { Network, Node, Edge } from '@lifeomic/react-vis-network';
import options  from './map-components/graph-config';

import AgendaView from './agenda.js';
// import Toolkit from './../files/toolkit.js';

import TimeMachine from './time-machine.js';
import MapInput from './map-input.js';
import Fetching from './../../../functions/fetching.js';

import Btn from './../../common/btn.js';
import Colours from './../../../assets/colours.js';

class MapDetails extends Component {

  constructor(props) {
      super(props);

      this.networkComponent = React.createRef();

      // Set up the height option
      this.graphOptions = options;

      this.handleAllQuestionToggle = this.handleAllQuestionToggle.bind(this);

      this.state = {
        displayAllQuestions:false,
        nodes: [],
        links: [],
        isFullscreen: false,
        rdr: false,
        timeMachine: "",
        timeMachineTime: "",
        confirmedTimeMachine: "",
        loadingForm: false,
        formAndHistory: [],
        formQuestion: ""
      };

      this.updateTime = this.updateTime.bind(this);
      this.updateDate = this.updateDate.bind(this);
      this.confirmTimeMachine = this.confirmTimeMachine.bind(this);
      this.clearTimeMachine = this.clearTimeMachine.bind(this);
      this.getMapData = this.getMapData.bind(this);
  }

  updateTime (e) {

    var time = e.target.value;
    this.setState({ timeMachineTime: time });

  }

  updateDate (date) {
    this.setState({ timeMachine: date });
  }

  confirmTimeMachine ( e ) {

    e.preventDefault();

    let { timeMachine, timeMachineTime } = this.state;

    if (timeMachine !== "") {

      if (timeMachineTime === "") timeMachineTime = "00:00";

      var unixTime = timeMachine + ' ' + timeMachineTime;

      unixTime = new Date(unixTime).getTime() / 1000;

      this.setState({ confirmedTimeMachine: unixTime });
      this.getMapData( unixTime );

    } else {
      alert('Please select a date before activating the time machine.');
    }
  }

  clearTimeMachine ( e ) {

    e.preventDefault();

    let timeMachine = "";
    this.setState({ timeMachine: timeMachine, confirmedTimeMachine: timeMachine });
    this.getMapData( timeMachine );

  }

  //Implemented to fix positioning bug -  vis.js uses physics algorithm
  setAllCoordinatesToCentre(nodes,centre){
    nodes.map((node) => {
      node.x = centre.x;
      node.y = centre.y

      return node;
    })
  }

  // Generate the coordinates relative to the root node
  initialiseCoordinates(nodes,links){

    let centre = this.graphOptions.level.first.centre;

    this.setAllCoordinatesToCentre(nodes,centre)

    //Set up topic nodes
    let firstLevelNodes = nodes.filter(node => node.level === 1);

    firstLevelNodes = this.setCoordinates(centre,firstLevelNodes,225);

    //Check if there are questions associated
    for(let i = 0; i< firstLevelNodes.length;i++){
      let parentNode = firstLevelNodes[i];
      let childNodeIds  = [];
      links.forEach((link) => {
        if(link.from === firstLevelNodes[i].key){
          childNodeIds.push(link.to);
        }
      });

      if(childNodeIds.length > 0){
        //if so, set the coordinates of the children nodes
        let childNodes = nodes.filter(node => childNodeIds.indexOf(node.key) !== -1);
        let childCentre = {x:parentNode.x,y:parentNode.y};
        this.setCoordinates(childCentre,childNodes,150,parentNode.frax);

        // Now get the children of children
        for (var j in childNodes) {
          let qNode = childNodes[j];
          let respNodeIds  = [];
          links.forEach((link) => {
            if(link.from === qNode.key){
              respNodeIds.push(link.to);
            }
          });

          if(respNodeIds.length > 0){
            //if so, set the coordinates of the children nodes
            let respNodes = nodes.filter(node => respNodeIds.indexOf(node.key) !== -1);
            let respCentre = { x:qNode.x, y:qNode.y };
            this.setCoordinates(respCentre,respNodes,90,qNode.frax);

            for (var k in respNodes) {
              let rNode = respNodes[k];
              let respNodeIds  = [];
              links.forEach((link) => {
                if(link.from === rNode.key){
                  respNodeIds.push(link.to);
                }
              });

              if(respNodeIds.length > 0){
                //if so, set the coordinates of the children nodes
                let resp2Nodes = nodes.filter(node => respNodeIds.indexOf(node.key) !== -1);
                let resp2Centre = { x:rNode.x, y:rNode.y };
                this.setCoordinates(resp2Centre,resp2Nodes,80,rNode.frax);
              }
            }
          }
        }
      }

    }

  }

  setCoordinates(centre,nodes,radius,parentFrax){

    if(centre.x === undefined || centre.y === undefined){
      throw new Error('No centre point coordinates set')
    }

    let x0 = centre.x;
    let y0 = centre.y;
		var useRadius;
		var usableI;

    for(var i in nodes){

      var x = 0;
      var y = 0;
      var curFraction = 0;
      var radAngle = 0;
      // For level one nodes deal with them with a strighforward bit of geometry
      if (nodes[i].level === 1) {

        useRadius = (nodes[i].label === "Life goals") ? 1.6 * radius : radius ;
				usableI = i;
        curFraction = 2 * usableI / nodes.length - 0.5;
        x =  x0  + useRadius  * Math.cos(Math.PI * curFraction);
        y =  y0 + useRadius * Math.sin(Math.PI * curFraction);

      } else if (nodes[i].level === 2) {

        // For second level coordinates double the distance away from the centre
        // Place them on a quarter circle facing outwards
        if (nodes.length > 1) {
          var spread = this.getNodeSpread(nodes.length);
          radAngle = (parentFrax - (spread / 2)) + spread * (i / (nodes.length - 1));
          x = centre.x + radius * Math.cos(Math.PI * radAngle);
          y = centre.y + radius * Math.sin(Math.PI * radAngle);
          curFraction = radAngle;
        } else {
          x = centre.x + radius * Math.cos(Math.PI * parentFrax);
          y = centre.y + radius * Math.sin(Math.PI * parentFrax);
          curFraction = parentFrax;
        }

      } else if (nodes[i].level >= 3) {

        // For third level coordinates simply extend them beyond where the second level was
        // (As there will only ever be one of them)
        if (nodes.length > 1) {
          spread = this.getNodeSpread(nodes.length);
          radAngle = (parentFrax - (spread / 2)) + spread * (i / (nodes.length - 1));
          x = centre.x + radius * Math.cos(Math.PI * radAngle);
          y = centre.y + radius * Math.sin(Math.PI * radAngle);
          curFraction = radAngle;
        } else {
          x = centre.x + radius * Math.cos(Math.PI * parentFrax);
          y = centre.y + radius * Math.sin(Math.PI * parentFrax);
          curFraction = parentFrax;
        }

      }

      nodes[i].x = x;
      nodes[i].y = y;
      nodes[i].frax = curFraction;

    }

    return nodes;
  }

  setNodeStyling ( node, level ) {

    switch (level) {
      case 0:
        node.bg = Colours.grn;
        break;
      case 1:
        node.bg = Colours.lightGrn;

        break;
      case 2:
        node.bg = Colours.lightishGrey;
        break;
      case 3:
        node.bg = Colours.lightGrey;
        break;
      case 4:
        node.bg = Colours.bgWhite;
        break;
      default:
        node.bg = Colours.lightGrey;
        break;
    }

    return node;
  }

  getNodeSpread (noNodes) {
    if (noNodes < 4) return 0.35;
    if (noNodes < 7) return 0.75;
    return 1.5;
  }

  handleNodeClick (node) {
    this.toggleNodeVisibility(node)
  }

  hideAllQuestionNodes(){
    let { nodes } = this.state;

    nodes.map(node => {
      if(node.level > 2){
        node.hidden = true;
      }

      return node;
    });
  }

  showAllQuestionNodes(){
    let { nodes } = this.state;

    nodes.map(node => {
      if(node.level > 2){
        node.hidden = false;
      }

      return node;
    })
  }

  // Check whether the answers for a given node are currently open
  ansIsOpen (selectedNode, nodes, links) {

    // Sense check that this is a question
    if (typeof nodes[selectedNode].qid == "undefined" && typeof nodes[selectedNode].loadUrl == "undefined") return false;

    // Get links from the selected node
    for ( var i in links ) {
      if (links[i].from === selectedNode && nodes[links[i].to].hidden === true) {
        return false;
      }
    }

    return true;
  }

  toggleNodeVisibility (selectedNode) {
    let { nodes, links } = this.state;

    let selectedDetails = nodes[selectedNode];

    if ((typeof selectedDetails.qid == "undefined" && typeof selectedDetails.loadUrl == "undefined") || !this.ansIsOpen(selectedNode, nodes, links)) {

      let nodeIdsToToggleVisibililty = [];

      // Look up children node Ids from link array
      links.forEach((link) => {
        if(link.from === selectedNode /*|| nodeIdsToToggleVisibililty.indexOf(link.from) > -1*/){
          nodeIdsToToggleVisibililty.push(link.to);
        }
      });

      //Find the node objects from these children node ids that are questions (level 2)
      let nodesToToggle = nodes.filter(node => node.level > selectedDetails.level && nodeIdsToToggleVisibililty.indexOf(node.key) !== -1);

      if(nodesToToggle.length > 0){

        //find the current hidden state of the first child and toggle to get new state
        let newHiddenState = !nodesToToggle[0].hidden;

        //Apply new state to child nodes
        let newNodes = nodes.map((node) => {

          if(nodeIdsToToggleVisibililty.indexOf(node.key) !== -1){
            node.hidden = newHiddenState;
          } else {
            if(node.level > selectedDetails.level ){
              node.hidden = true;
            }
          }

          return node;
        });

        this.setState({ nodes: newNodes })
      }

    } else if (selectedDetails.level >= 2 && typeof selectedDetails.qid != 'undefined') {
			// Open up the question response element
			this.loadEditForm(selectedDetails);
    } else if (typeof selectedDetails.loadUrl != 'undefined') {
			// Link through to an external URL in a new tab
			window.open(selectedDetails.loadUrl, '_blank');
		}
  }

  loadEditForm (selectedNode) {

    // Run a fetch to get the history
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/history/' + this.props.match.params.custRef + "/" + selectedNode.qid + "/1/1", { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function(data){
        thus.setState({ formAndHistory: data, loadingForm: true, formQuestion: selectedNode });
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  componentDidMount() {

    //Due to the library limitations we need to set the click events via this way
    this.networkComponent.current.network.on("click", event => {

      if(event.nodes.length > 0){
        //clicked a node
        let selectedNode = event.nodes[0];
        this.handleNodeClick(selectedNode);
      }
    });

  }

  processSxn ( nodeGrp, curKey, curLevel, parentKey, nodeLst, lnkLst, isRepeater = false, repNo = 1, qContent = false, isSummary = 0 ) {

    var title = "";
    if (typeof nodeGrp.sxnName != 'undefined') {
      title = nodeGrp.sxnName;
    } else if (typeof nodeGrp.title != 'undefined') {
      title = nodeGrp.title;
    } else if (typeof nodeGrp.question != 'undefined') {
      title = nodeGrp.question;
			qContent = nodeGrp.question;
    } else if (typeof nodeGrp.content != 'undefined') {
      title = nodeGrp.content;
    } else if (isRepeater) {
      title = nodeGrp[isRepeater];
    }
    let isHidden = (!this.state.displayAllQuestions && curLevel > 1) ? true : false ;

    nodeLst[curKey] = { key: curKey, label: title, level: curLevel, hidden: isHidden };
		if (qContent) {
			nodeLst[curKey].qContent = qContent;
			nodeLst[curKey].isSummary = isSummary;
		}
    lnkLst.push({ from: parentKey, to: curKey });

    if (typeof nodeGrp.qType != 'undefined') nodeLst[curKey].qType = nodeGrp.qType;
    if (typeof nodeGrp.type != 'undefined') nodeLst[curKey].qType = nodeGrp.type;
    if (typeof nodeGrp.qid != 'undefined') nodeLst[curKey].qid = nodeGrp.qid;
    if (typeof nodeGrp.optionList != 'undefined') nodeLst[curKey].optionList = nodeGrp.optionList;
    if (typeof nodeGrp.options != 'undefined') nodeLst[curKey].optionList = nodeGrp.options;
    nodeLst[curKey].repNo =  repNo;

    return [ nodeLst, lnkLst ];

  }

  loadNewData (data) {

    // Set the customer name as the central node
    var i = 0;
    var nodes = [{ key: i, label: data.coreName, level:0 }];
    var links = [];

    // Set up variables to handle the ordering
    var loopVals = {
			curSxn: 0,
      curSxnSlug: "",
      curGrp: 0,
			curQuestion: 0,
			curQuestionContent: 0,
      curQuestionSummary: 0,
      level: 0,
      isValues: false,
      parentNode:0,
      curRepNo: 0
    }

    // Loop through sections
    if (typeof data.sxns != 'undefined') {
      for (var skey in data.sxns) {

				if (data.sxns[skey].sxnSlug === 'professionals') {
					// Add in a financials bubble manually (together with the children)
					i++;
					nodes.push({ key: i, label: "Financials", level: 1, hidden: false /*, loadUrl: process.env.REACT_APP_API_BASE + "/admin/progress_reviews/latest/" + this.props.match.params.custRef*/ });
          links.push({ from: 0, to: i });
          // N.B. The children are not intended to do anything, other than be a memory prompt
          let financeChildren = ["Income", "Expenditure", "Assets", "Liabilities"];
          let oldI = i;
          for (var x in financeChildren) {
            console.log(financeChildren[x]);
            i++;
            nodes.push({ key: i, label: financeChildren[x], level: 2, hidden: true });
            links.push({ from: oldI, to: i });
          }
				}

        i++;
				loopVals.curSxnSlug = data.sxns[skey].sxnSlug;

        loopVals.level = 1;
				if (data.sxns[skey].sxnName !== "Service Expectation" && data.sxns[skey].sxnName !== "First Question")
				{
					[nodes, links] = this.processSxn(data.sxns[skey], i, loopVals.level, 0, nodes, links);
				} else {
					i--;
				}
        loopVals.curSxn = i;

        // Loop through groups
        if (typeof data.sxns[skey].groups != 'undefined' && (typeof data.sxns[skey].type != 'undefined' || data.sxns[skey].type !== 'cf')) {
          for (var gkey in data.sxns[skey].groups) {

            i++;

            loopVals.isValues = (Number(data.sxns[skey].groups[gkey].group.is_mm_root) === 1) ? true : false ;
            loopVals.level = (loopVals.isValues) ? 1 : 2 ;
            loopVals.parentNode = (loopVals.isValues) ? 0 : loopVals.curSxn ;
            loopVals.curGrp = i;

            if (data.sxns[skey].groups[gkey].group.type === "repeat-fxd" && data.sxns[skey].groups[gkey].group.subtitle === "Client 1--Client 2") {

              // Handle the fixed repeaters to display both client data pieces
              i--;
              for (var j in data.names) {
                i++;
                loopVals.level = (loopVals.isValues) ? 1 : 2 ;
                loopVals.curGrp = i;
                loopVals.curRepNo = Number(j.replace('client', ''));

                [nodes, links] = this.processSxn(data.names, i, loopVals.level, loopVals.parentNode, nodes, links, j);

                // Now add in the questions for the group's questions (split this into its own function?!)
                if (typeof data.sxns[skey].groups[gkey].group.questions != 'undefined') {
                  for (var qkey in data.sxns[skey].groups[gkey].group.questions) {

                    loopVals.level = (loopVals.isValues) ? 2 : 3 ;
                    i++;
                    loopVals.curQuestion = i;
										loopVals.curQuestionContent = data.sxns[skey].groups[gkey].group.questions[qkey].question;
										loopVals.curQuestionSummary = data.sxns[skey].groups[gkey].group.questions[qkey].isSummary;
                    [nodes, links] = this.processSxn(data.sxns[skey].groups[gkey].group.questions[qkey], i, loopVals.level, loopVals.curGrp, nodes, links, false, loopVals.curRepNo, loopVals.curQuestionSummary);

                    // Loop through responses
                    if (typeof data.sxns[skey].groups[gkey].group.questions[qkey].responses != 'undefined') {
                      for ( var rkey in data.sxns[skey].groups[gkey].group.questions[qkey].responses ) {
                        if (typeof data.sxns[skey].groups[gkey].group.questions[qkey].responses[rkey].content != 'undefined' && "client" + data.sxns[skey].groups[gkey].group.questions[qkey].responses[rkey].repeatNo === j) {

                          loopVals.level = (loopVals.isValues) ? 3 : 4 ;
                          i++;
                          var valueEntered = this.getContent(rkey, data.sxns[skey].groups[gkey].group.questions[qkey]);
                          valueEntered.content = this.reduceTxt(valueEntered.content);

                          [nodes, links] = this.processSxn(valueEntered, i, loopVals.level, loopVals.curQuestion, nodes, links, false, loopVals.curRepNo, loopVals.curQuestionContent, loopVals.curQuestionSummary);

                        }
                      }
                    }

                  }
                }

              }
            } else {

							if (/*data.sxns[skey].groups[gkey].group.title !== 'Service Expectation' && */( data.sxns[skey].groups[gkey].group.type !== "repeat" || loopVals.curSxnSlug !== "professionals" )) {

              	[nodes, links] = this.processSxn(data.sxns[skey].groups[gkey].group, i, loopVals.level, loopVals.parentNode, nodes, links);

	              // Loop through questions
	              if (typeof data.sxns[skey].groups[gkey].group.questions != 'undefined') {
	                for (var qukey in data.sxns[skey].groups[gkey].group.questions) {

	                  loopVals.level = (loopVals.isValues) ? 2 : 3 ;
	                  i++;
										loopVals.curQuestion = i;
										loopVals.curQuestionContent = data.sxns[skey].groups[gkey].group.questions[qukey].question;
	                  loopVals.curQuestionSummary = data.sxns[skey].groups[gkey].group.questions[qukey].isSummary;
	                  [nodes, links] = this.processSxn(data.sxns[skey].groups[gkey].group.questions[qukey], i, loopVals.level, loopVals.curGrp, nodes, links, false, 1, loopVals.curQuestionContent, loopVals.curQuestionSummary);

	                  // Loop through responses
	                  if (typeof data.sxns[skey].groups[gkey].group.questions[qukey].responses != 'undefined') {
	                    for ( var rekey in data.sxns[skey].groups[gkey].group.questions[qukey].responses ) {
	                      if (typeof data.sxns[skey].groups[gkey].group.questions[qukey].responses[rekey].content != 'undefined') {

	                        loopVals.level = (loopVals.isValues) ? 3 : 4 ;
	                        i++;
	                        var valEntered = this.getContent(rekey, data.sxns[skey].groups[gkey].group.questions[qukey]);
	                        valEntered.content = this.reduceTxt(valEntered.content);

	                        [nodes, links] = this.processSxn(valEntered, i, loopVals.level, loopVals.curQuestion, nodes, links, false, 1, loopVals.curQuestionContent, loopVals.curQuestionSummary);

	                      }
	                    }
	                  }

	                }
	              }

              } else if ((data.sxns[skey].groups[gkey].group.type === "repeat" && loopVals.curSxnSlug === "professionals")/* || (data.sxns[skey].groups[gkey].group.title === "Service Expectation" && loopVals.curSxnSlug === "expectations")*/) {
                console.log(data.sxns[skey].groups[gkey].group);
                nodes[i] = { key: i, label: data.sxns[skey].groups[gkey].group.title, level: loopVals.level, hidden: true, loadUrl: "/admin/clients/details/" + this.props.match.params.custRef + "/" + loopVals.curSxnSlug };
								links.push({ from: loopVals.curSxn, to: i });
							} else {
								i--;
							}

            }

          }
        } else if (typeof data.sxns[skey].type != 'undefined' && data.sxns[skey].type === 'cf') {

					// Handle custom fields
					var lastI = 0;
					for (var rdkey in data.sxns[skey].riskData) {

						// Add in the name
            i++;
						nodes[i] = { key: i, label: data.names[data.sxns[skey].riskData[rdkey].fieldName], level: 2, hidden: true };
				    links.push({ from: loopVals.curSxn, to: i });
						lastI = i;

						// Now add in the risk level
						i++;
						nodes[i] = { key: i, label: data.sxns[skey].riskData[rdkey].value, level: 3, hidden: true, loadUrl: "/admin/clients/details/" + this.props.match.params.custRef + "/risk-profile" };
						links.push({ from: lastI, to: i });
					}
				}

      }
    }

		// Add in a financials bubble to open the progress review
		// i++;
		// nodes.push({ key: i, label: "Financials", level: 1, hidden: false, loadUrl: process.env.REACT_APP_API_BASE + "/admin/progress_reviews/latest/" + this.props.match.params.custRef });
		// links.push({ from: 0, to: i });

		// console.log(nodes);
		// console.log(links);
    // Set the initial positions for the nodes
    this.initialiseCoordinates(nodes,links);
    // Set the colours etc
    for (var k in nodes) {
      nodes[k] = this.setNodeStyling(nodes[k], nodes[k].level);
    }
    this.setState({ nodes: nodes, links: links });
  }

  reduceTxt (txt, limit = 150) {

		txt = txt.replace(/(<([^>]+)>)/ig," ");

    if (txt.length <= limit) return txt;

    txt = txt.substring(0,limit) + "...";
    return txt;

  }

  getContent(key, question) {

    // Get the straight content
    var value = { content: question.responses[key].content };

    // Check for options
    if (question.responses[key].type === "option") {
      for (var okey in question.options) {
        if (question.options[okey].oid === question.responses[key].content) {
          value.content = question.options[okey].content;
        }
      }
    }

    value.content = value.content.replace(/<br\s*[/]?>/gi, "\n");

    // Add in options and question types to trigger forms
    if (typeof question.options != 'undefined') value.optionList = question.options;
    if (typeof question.type != 'undefined') value.qType = question.type;
    if (typeof question.qid != 'undefined') value.qid = question.qid;

    return value;

  }

  getMapData ( confirmedTimeMachine ) {

    var custRef = this.props.match.params.custRef;
    var sxn = this.props.match.params.sxnSlug;

    if (typeof sxn == 'undefined' || typeof custRef == 'undefined') {

      this.setState({ rdr: true });

    } else {

      var thus = this;
      fetch(process.env.REACT_APP_API_BASE + '/questionnaire_sections/map/' + sxn + '/' + custRef + '/' + confirmedTimeMachine, { method: 'get', credentials: "include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function(data) {
          thus.loadNewData(data);
        }).catch(function(error) {
          console.log(error);
          console.log('There has been a problem with your fetch operation: ', error.message);
        });

    }

  }

  componentWillMount() {

    this.getMapData("");

  }

  handleAllQuestionToggle(e){
    this.setState((prevState) => {
      return {displayAllQuestions: !prevState.displayAllQuestions};
    })

    this.state.displayAllQuestions ? this.hideAllQuestionNodes() : this.showAllQuestionNodes();
  }

  // Switch the screen display to full screen
  triggerFullScreen (e) {
    e.preventDefault();
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
    this.setState({ isFullscreen: true });
    this.props.dispatch({ type: 'MINIMISE_HEADER' });

  }

  exitFullscreen(e) {
    e.preventDefault();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
    this.setState({ isFullscreen: false });
    this.props.dispatch({ type: 'MAXIMISE_HEADER' });

  }

  closeCover (e) {
    e.preventDefault();
    this.setState({ formAndHistory: [], loadingForm: false });
    this.getMapData("");
  }

  render() {

    let { nodes, links, isFullscreen, rdr, loadingForm, formAndHistory, formQuestion } = this.state;

    if (rdr) {
      return <Redirect to="/admin/clients" />
    }

    let nodeItems = nodes.map((node) =>
      <Node key={node.key} id={node.key} label={node.label} widthConstraint={ options.nodeSizes['level-' + node.level].widthConstraint } heightConstraint={ options.nodeSizes['level-' + node.level].heightConstraint } x={node.x} y={node.y} hidden={node.hidden || false}  color={ typeof node.bg != "undefined" ? node.bg : Colours.lightGrey } font={ node.level <= 1 ? styles['level' + node.level].font : styles.deep.font }/>
    )

    let linkItems = links.map((edge) =>
      <Edge id={edge.from+'-'+edge.to}  key={edge.from+'-'+edge.to} from={edge.from} to={edge.to} length={edge.length || 200} />
    )

    var fsBtn = <Btn type='button' title="Fullscreen" handleSubmission={ (e) => this.triggerFullScreen(e) } btnStyle={ styles.barBtn } btnStyleInner={ styles.barBtnInner } />
    if (isFullscreen) {
      fsBtn = <Btn type='button' title="Exit fullscreen" handleSubmission={ (e) => this.exitFullscreen(e) } btnStyle={ styles.barBtn } btnStyleInner={ styles.barBtnInner } />
    }

    var coverContent = "";
    if (loadingForm !== false) {
      coverContent = <div className="cover">
        <div className='width-1 centred' styles={ styles.topMrgn }>
          <div className="loader whiteBg">Loading...</div>
        </div>
      </div>
    }
    if (typeof formAndHistory != 'undefined' && formAndHistory.length !== 0) {
      if (typeof formAndHistory.rslt != 'undefined' && formAndHistory.rslt === 'empty') {
        coverContent = <div className="cover">
          <a href="#close" onClick={ (e) => this.closeCover(e) }> </a>
          <div className="box big">
            <h5>Update response: <span style={ styles.italic }>"{ formQuestion.qContent }"</span></h5>
            <MapInput qId={ formQuestion.qid } custRef={ this.props.match.params.custRef } repeatNo={ formQuestion.repNo } qContent={ formQuestion.label } inputType={ formQuestion.qType } isSummary={ formQuestion.isSummary } optionList={ typeof formQuestion.optionList ? formQuestion.optionList : [] } />
          </div>
        </div>
      } else {
        // console.log(formAndHistory[0]);
        coverContent = <div className="cover">
          <a href="#close" onClick={ (e) => this.closeCover(e) }> </a>
          <div className="box big">
            <h5>Update response: <span style={ styles.italic }>"{ formQuestion.qContent }"</span></h5>

            <MapInput qId={ formQuestion.qid } custRef={ this.props.match.params.custRef } repeatNo={ formQuestion.repNo } qContent={ formQuestion.label } inputType={ formQuestion.qType } optionList={ typeof formQuestion.optionList ? formQuestion.optionList : [] } isSummary={ formQuestion.isSummary } response={ typeof formAndHistory[0].response != "undefined" ? formAndHistory[0].response : "" } />

            <p className="smlr">Below you can see all the changes that have been made to this data over time and by whom.</p>

            <table>
              <thead>
                <tr>
                  <th>Entry</th>
                  <th>Entered by</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                { formAndHistory.map( item =>
                  <tr key={ item.rid }>
                    <td dangerouslySetInnerHTML={{__html: item.response}}></td>
                    <td>{ item.modifier }</td>
                    <td>{ item.modified }</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
    }

    return (
      <div className={ this.props.minimise ? 'fullscreen-diagram' : 'diagram' }>

        { coverContent }

        <div style={ styles.btnBar }>
          <Btn type='button' title={this.state.displayAllQuestions ? 'Hide All' : 'Show All'} handleSubmission={ (e) => this.handleAllQuestionToggle(e) } btnStyle={ styles.barBtn } btnStyleInner={ styles.barBtnInner } />
          { fsBtn }
        </div>

        <AgendaView custRef={ this.props.match.params.custRef } />

        <Network options={this.graphOptions.network} ref={this.networkComponent}>
          {nodeItems}
          {linkItems}
        </Network>

        <div className={ isFullscreen ? "time-machine fullscreen" : "time-machine" }>
          <TimeMachine updateDate={ this.updateDate } updateTime={ this.updateTime } confirmTimeMachine={ this.confirmTimeMachine } clearTimeMachine={ this.clearTimeMachine } custRef={ this.props.match.params.custRef } hideProgRev={ true } hideExport={ true } />
        </div>

				{/* <Toolkit /> */}
      </div>
    );
  }

}

// Styling information that is specific to this component
const styles = {
  level0:{
    font:{
      color:'white',
      size:18,
      face: "'Trebuchet', arial, sans-serif"
    }
  },
	level1:{
    font:{
      size:12,
      face: "'Trebuchet', arial, sans-serif"
    }
  },
  deep:{
    font:{
      color:'black',
      size:6,
      face: "'Trebuchet', arial, sans-serif"
    }
  },
  toggleBtn:{
    position:'absolute',
    right:'1rem',
    zIndex:99,
    width:'5rem'
  },
  btnBar: {
    float: 'right',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
  },
  barBtn: {
    float: 'left',
    marginRight: 12,
    marginTop: 0
  },
  barBtnInner: {
    borderRadius: "20px",
    marginTop: 12
  },
  italic: {
    fontWeight: "normal",
    fontStyle: "italic"
  }
}

function mapStateToProps(state) {
  return {
    minimise: state.minimalHeader
  };
}

export default connect(mapStateToProps)(MapDetails);
