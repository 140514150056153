const initialState = {
  sessionId: '',
  token: '',
  tokenKey: '',
	tokenIsUsed: true,
	tokenIsPending: false,
  customer: {
    token: '',
    tokenKey: '',
    mash: '',
    initName: ''
  },
  errors: {
    auth: '',
    adminSuccess: '',
    adminError: '',
  },
  sxns: [],
  minimalHeader: false
};

const Reducer = {

  init: function (state = initialState, action) {

    let updatedState = {...state};
    switch(action.type) {

      case 'SET_TOKENS':

				updatedState.tokenIsPending = false;
				updatedState.tokenIsUsed = false;
        updatedState.sessionId = action.sessionId;
        updatedState.token = action.token;
        updatedState.tokenKey = action.tokenKey;
        return updatedState;

      case 'SET_CUSTOMER_TOKENS':

        updatedState.customer.token = action.token;
        updatedState.customer.tokenKey = action.tokenKey;
        updatedState.customer.mash = action.mash;
        return updatedState;

      case 'SET_AUTH_ERROR':

        updatedState.errors.auth = action.error;
        return updatedState;

      case 'SET_ADMIN_ERROR':

        updatedState.errors.adminError = action.error;
        return updatedState;

      case 'SET_ADMIN_SUCCESS':

        updatedState.errors.adminSuccess = action.msg;
        return updatedState;

      case 'SET_NAME':

        updatedState.customer.initName = action.name;
        return updatedState;

      case 'SET_SXN_DATA':

        updatedState.sxns = action.sxns;
        return updatedState;

      case 'MINIMISE_HEADER':

        updatedState.minimalHeader = true;
        return updatedState;

      case 'MAXIMISE_HEADER':

        updatedState.minimalHeader = false;
        return updatedState;

      default:
        return state;

    }
  }

};

export default Reducer;
