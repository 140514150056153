import React, { Component } from 'react';

import Autocomplete from './../common/autocomplete.js';

class EmailFilter extends Component {

  render() {

    return (
			<div className="width-3 filter">
				<h3>Filter</h3>
				<div>
					<h4>Admin Users</h4>
					{ this.props.users.map( usr =>
						<div className="input checkbox" key={ 'usr-' + usr.id }>
							<input
								type='checkbox'
								id={ 'userCb' + usr.id }
								value={ "user-" + usr.id }
								defaultChecked={ usr.selected }
								onChange={ (e) => this.props.updateFilter(e) }
							/>
							<label htmlFor={ 'userCb' + usr.id }>{ usr.first } { usr.last } ({ usr.email })</label>
						</div>
					)}
				</div>
				<div>
					<h4>Communication Type</h4>
					<div className="input checkbox">
						
						<input
							type='checkbox'
							id='emailCB'
							value="mailbox-email"
							defaultChecked={ this.props.mailboxes.indexOf("email") > -1 ? true : false }
							onChange={ (e) => this.props.updateFilter(e) }
						/>
						<label htmlFor='emailCB'>Email</label>

						<div className='nested-checkbox-filter' style={this.props.showNestedEmailCheckbox === false ? {display:'none'} : {}} >
							<div className="input checkbox">
								<input
									type='checkbox'
									id='inboxCB'
									value="mailbox-inbox"
									defaultChecked={ this.props.mailboxes.indexOf("inbox") > -1 ? true : false }
									onChange={ (e) => this.props.updateFilter(e) }
								/>
								<label htmlFor='inboxCB'>Inbox</label>
							</div>
							<div className="input checkbox">
								<input
									type='checkbox'
									id='sentCB'
									value="mailbox-sent"
									onChange={ (e) => this.props.updateFilter(e) }
									defaultChecked={ this.props.mailboxes.indexOf("sent") > -1 ? true : false }
								/>
								<label htmlFor='sentCB'>Sent items</label>
							</div>
							<div className="input checkbox">
								<input
									type='checkbox'
									id='draftCB'
									value="mailbox-draft"
									onChange={ (e) => this.props.updateFilter(e) }
									defaultChecked={ this.props.mailboxes.indexOf("draft") > -1 ? true : false }
								/>
								<label htmlFor='draftCB'>Draft emails</label>
							</div>
						</div>
					</div>
					
					<div className="input checkbox">
						<input
							type='checkbox'
							id='phoneCB'
							value="mailbox-call"
							onChange={ (e) => this.props.updateFilter(e) }
							defaultChecked={ this.props.mailboxes.indexOf("call") > -1 ? true : false }
						/>
						<label htmlFor='phoneCB'>Telephone Call</label>
					</div>

					<div className="input checkbox">
						<input
							type='checkbox'
							id='meetCB'
							value="mailbox-meeting-notes"
							onChange={ (e) => this.props.updateFilter(e) }
							defaultChecked={ this.props.mailboxes.indexOf("meeting-notes") > -1 ? true : false }
						/>
						<label htmlFor='meetCB'>Meeting Notes</label>
					</div>
				</div>
				<div>
					<h4>Clients</h4>
					<Autocomplete
						id="2"
						label="Select the client(s) to filter by"
						suggestions={ this.props.customerSuggestions }
						selection={ this.props.updateCustomers }
						chosen={ this.props.customers }
						removal={ this.props.removeCustomers }
						dark={ true }
					/>
				</div>
				<div>
					<h4>Search</h4>
					<input type='text' id="search" onBlur={ (e) => this.props.updateFilter(e) } className='mtop no-mbtm' placeholder="Enter search word or phrase..." />
					<div className="input checkbox">
						<input
							type='checkbox'
							id='includeArchive'
							value="include-archive"
							onChange={ (e) => this.props.updateFilter(e) }
							defaultChecked={ this.props.includeArchived ? true : false }
						/>
						<label htmlFor='draftCB'>Include Archived Comunications</label>
					</div>
				</div>
			</div>
    );
  }
}

export default EmailFilter;
