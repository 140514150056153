import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import queryString from 'query-string';

import Btn from './../common/btn.js';
import FlashMsg from './../common/flash-msg.js';

import Colours from './../../assets/colours.js';
import Fetching from './../../functions/fetching.js';

import ssoOffice from './../../assets/img/azure-login.svg';

class Login extends Component {
  // Set the initial state for the form entry
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      rdr: false,
			authRdr: false,
			loading: true
    }

    this.handleEntry = this.handleEntry.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  handleEntry ( event ) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // Handle the login form submission
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;

    var email = encodeURIComponent(this.state.email);
    var pw = encodeURIComponent(this.state.password);

    fetch(process.env.REACT_APP_API_BASE + '/login', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ email: email, password: pw }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Redirect to the dashboard
        thus.setState({ rdr: '/admin/clients' });
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ error: data.msg });
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

	getSsoAuthUrl () {
		var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/sso/get_rdr_url', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function(data){
				if (typeof data.rdrUrl != 'undefined') {
					thus.setState({ authRdr: data.rdrUrl, loading: false });
				} else {
					thus.setState({ authRdr: false, loading: false });
				}
			})
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

	checkForSso () {
		// Get the query string from the URL
		var qsParams = this.props.location.search;
		qsParams = queryString.parse(qsParams);

		// Office 365 logn - If we have the appropriare params then handle the login redirect
		if ( typeof qsParams.code != 'undefined' ) {

			var provider = 'o365';
			if (typeof qsParams.state == 'undefined') {
				qsParams.state = "";
				provider = 'cashCalc';
			}

			var thus = this;
	    fetch(process.env.REACT_APP_API_BASE + '/sso/authorise/' + provider + '?code=' + qsParams.code + "&state=" + qsParams.state, { method: 'get', credentials:"include" })
	      .then(Fetching.statusCheck)
	      .then(Fetching.jsonExtract)
	      .then(function(data){
					if (typeof data.rslt != 'undefined' && data.rslt === "success") {
						if (typeof data.rdr != 'undefined') {
							thus.setState({ rdr: data.rdr });
						} else {
							thus.setState({ rdr: '/admin/clients' });
						}
					} else {
						if (typeof data.rdr != 'undefined' && provider === "cashCalc") {
							thus.setState({ rdr: data.rdr });
						} else {
							thus.setState({ authRdr: false, error: data.msg, loading: false });
						}
					}
				})
	      .catch(function (error) {
	        console.log('Request failed', error);
	      });

		} else {

			// Otherwise get the SSO redirection URL
			this.getSsoAuthUrl();

		}

	}

  checkStatus () {

		// Restrict this check to non-SSo redirects
		var qsParams = this.props.location.search;
		qsParams = queryString.parse(qsParams);

		// Check the status
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/login', { method: 'get', credentials: "include" })
    .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        if (typeof data.status != 'undefined' && data.status === 'authenticated') {
					// For SSO login prevent the redirect
					if ( typeof qsParams.code == 'undefined' ) {
          	thus.setState({ rdr: '/admin/clients' });
					}
        }
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });

  }

  // Before load of the login component run a check to see if we are already logged in
  componentWillMount () {
    // Check that they aren't aready logged in
    this.checkStatus();
    // Get the form tokens for submission
    this.getTokens();
		// Check whether this is an Sso redirection
		this.checkForSso();

    // Check for auth errors
    if (this.props.authError !== '') {
      this.setState({ error: this.props.authError });
      this.props.dispatch({ type: 'SET_AUTH_ERROR', error: '' });
    }

  }

	switchToStandard (e) {
		e.preventDefault();
		this.setState({ authRdr: false });
	}

	switchToSso (e) {
		e.preventDefault();
		this.getSsoAuthUrl();
	}

  render() {
		let { rdr } = this.state;

    if (rdr !== false) {
      return <Redirect to={ rdr } />
    }

		let { authRdr, loading } = this.state;

		if (loading) {
			return (
				<div className='content'>
					<div className='width-1 centred'>
						<div className="loader">Loading...</div>
					</div>
				</div>
			);
		}

    return (
      <div className='content'>
        <div className='width-1 spacer'>&nbsp;</div>
        <div className='width-4 ' style={ styles.container }>


          <div className='error'>{ this.state.error }</div>
          <FlashMsg />

					{ authRdr ? (
						<Fragment>
							<p className='intro'>Sign in using your Office 365 account to access the private
		          Ashworth admin area of the financial planning tool.</p>
							<a href={ authRdr } style={ styles.office }>
								<img src={ ssoOffice } alt="Sign in with Office 365" />
							</a>
							<a href="#standard" onClick={ (e) => this.switchToStandard(e) } style={ styles.sml }>Standard login</a>
						</Fragment>
					) : (
						<Fragment>
							<p className='intro'>Sign in using the form below to access the private
		          Ashworth admin area of the financial planning tool.</p>
							<form style={styles.form}>
		            <input name='email' placeholder='Email address' type='email' id='email' required='required' value={this.state.email} onChange={this.handleEntry} />
		            <input name='password' placeholder='Password' type='password' id='password' required='required' value={this.state.password} onChange={this.handleEntry} />
		            <Btn title="Sign in" lnk="/questionnaire" type="submit" handleSubmission={ this.handleSubmission } />
		          </form>

							<p className='smlrRght'>Forgotten your password? <Link to='/forgotten-password'>Reset it here</Link></p>
							<a href="#sso" onClick={ (e) => this.switchToSso(e) } style={ styles.smlNoMtop }>Office 365 login</a>
						</Fragment>
					)}

        </div>
      </div>
    );
  }
}

var styles = {
  container: {
    background: Colours.bgWhite75,
    marginTop: 145,
    marginBottom: 125,
    padding: 25
  },
  form: {
    marginTop: 32
  },
	office: {
		display: 'block',
		marginTop: 22,
		float: 'left'
	},
	sml: {
		float: 'right',
		fontSize: 12,
		marginTop: 45
	},
	smlNoMtop: {
		float: 'right',
		fontSize: 12,
		marginTop: 5
	}
};

function mapStateToProps(state) {
  return {
    sessionId: state.sessionId,
    token: state.token,
    tokenKey: state.tokenKey,
    authError: state.errors.auth,
  };
}

export default connect(mapStateToProps)(Login);
