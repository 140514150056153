import React, { Component } from 'react';
import Colours from './../../../assets/colours.js';
//import ProgressReviewIcon from './../../../assets/img/doc-icon.svg';
import Fetching from './../../../functions/fetching.js';

class FinancialPlanning extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isGeneratingFinancialPlanning:false,

    };


	this.generateFinancialPlanning = this.generateFinancialPlanning.bind(this);
  }


  generateFinancialPlanning () {

	  var thus = this;
	  this.props.handleDownloadPDFSubMenuButtonClick();

	  this.setState({isGeneratingFinancialPlanning:true})

	  fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/generateFinancialPlanning/' + this.props.custRef,{
		method: 'post',
		headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
		credentials:"include"
	  })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(function (data) {

		thus.props.updateFiles();
		window.open(process.env.REACT_APP_API_BASE+"/admin/files/download/"+data.file,'_blank');
		let isGeneratingFinancialPlanning = false;
		thus.setState({isGeneratingFinancialPlanning})

	  })
	  .catch(function (error) {
		console.log('Request failed', error);
		alert('Sorry but there was an error generating and saving the Progress Review. Please try again.');
	  });
  }



  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

 render(){
	 return (
		 <div>
		<span  className='download-title' >Financial Planning</span>
 		<button
 			onClick={this.generateFinancialPlanning}
 			style={{...styles.progressReview,opacity:this.state.isGeneratingFinancialPlanning ? 0.5: 1  }}
 			disabled={this.state.isGeneratingFinancialPlanning ? true: false }
 		 >
 		 Download
 		</button>
		</div>
 	)


 }

}


var styles = {
	progressReview: {
    marginLeft: 0,
	fontSize:'12px',
    background: Colours.txtGrey  ,
    color: Colours.txtWhite,
    textDecoration: 'none',
	padding: "3px 8px",
	cursor: 'pointer',
	border:'none',
	marginTop:0,
	borderRadius:25,
	float:'right'
  }
}

export default FinancialPlanning;
