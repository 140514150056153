import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DisplayComposeDropdown extends Component {

  constructor() {
    super();
    this.focusRef = React.createRef();
  }

  componentDidMount(){
    this.focusRef.current.focus();
  }



  render(){

    return (

      <div id='dropdown' className='compose-dropdown' style={styles.dropdown} tabIndex={1} ref={this.focusRef} onBlur={this.props.handleDropDownBlur}  >
        <div>
          <Link to="/admin/email/compose" >Compose Email</Link>
        </div>
        <div>
          <Link to="/admin/record_call/compose" >Telephone Call</Link>
        </div>
        <div>
        <Link to="/admin/meeting_notes/compose" >Meeting Notes</Link>
        </div>	      
      </div>

   );
  }

}

export default DisplayComposeDropdown;

var styles = {
  dropdown:{
    
  }
}
