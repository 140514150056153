import React, { Component } from 'react';
import { connect } from 'react-redux';
import Fetching from './../../../functions/fetching.js';

class CsvUpload extends Component {

	// Constructor to set up data and set init state
	constructor(props) {
		super(props);

		this.state = {
			fileId: "",
			formAction: "",
			filename: "",
			acl: "",
			cred: "",
			algo: "",
			lnkExpiry: "",
			policy: "",
			sig: "",
			processingUpload:false,
			formDisabled: true,
			dotIndex:0
		};

		this.getFormDetails = this.getFormDetails.bind(this);
		// this.confirmUpload = this.confirmUpload.bind(this);
		this.getTokens = this.getTokens.bind(this);
		this.getBase64 = this.getBase64.bind(this);
	}

	getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  getBase64(file) {
	return new Promise(function(resolve, reject) {
	  const reader = new FileReader();
	  reader.onload = function() {
		resolve(reader.result);
	  };
	  reader.onerror = reject;
	  reader.readAsDataURL(file);
	});
  }

	async getFormDetails (e) {

		// Disable the input submit while processing the update
		this.setState({ formDisabled: true });

		// Get the file name
		var fullPath = e.target.value;

		if (fullPath) {
			var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
			var filename = fullPath.substring(startIndex);
			if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
				filename = filename.substring(1);
			}

		}

		var thus = this;

		const formData = new FormData();

		formData.append('file', e.target.files[0]);
		formData.append(this.props.tokenKey,  this.props.token);

		this.setState({processingUpload:true});

		fetch(process.env.REACT_APP_API_BASE + '/admin/investments/csv', {
			method: 'post',
			// headers: {
			// 	"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			// },
			credentials:"include",
			body: formData
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			if ( typeof data.success != "undefined" ) {
				if(typeof thus.props.callback !== 'undefined'){
					thus.props.callback(data.slug);
				}
				// thus.setState({processingUpload:false});
				thus.getTokens();

			} else {
				alert("There was an error. Please check the content and try again.");
			}
		})
		.catch(function (error) {
			thus.getTokens();
			console.log('Request failed', error);
		});
	}


	componentWillMount () {
		this.getTokens();
		this.interval = setInterval(this.cycleDots, 500);
	}

	componentWillUnmount(){
		clearInterval(this.interval)
	}

	cycleDots = () => {
		const { dotIndex } = this.state;
		const dots = ['.', '..', '...'];
		const nextIndex = (dotIndex + 1) % dots.length;
		this.setState({ dotIndex: nextIndex })
	}

  render() {

	let { formAction,processingUpload,dotIndex } = this.state;

	const dots = ['.','..','...'];


	if(processingUpload){
		return 'Importing File'+dots[dotIndex];
	}

    return (
		  <form action={ formAction } method="post" encType="multipart/form-data" id="uploadForm">
		    <input type="file" name="file" id="fileInput" onChange={ (e) => this.getFormDetails(e) } className="uploader" />
		  </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(CsvUpload);
