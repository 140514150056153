import React, { Component } from 'react';
import { connect } from 'react-redux';

import Fetching from '../../../functions/fetching.js';
import Colours from '../../../assets/colours.js';

class CustomerCommunicationDropdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading:true,
      customers:[],
      
    };

    this.handleEntry = this.handleEntry.bind(this);
    this.loadCommunicationOnlyCustomers = this.loadCommunicationOnlyCustomers.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  handleEntry ( event ) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentDidMount(){
    this.loadCommunicationOnlyCustomers();
    this.getTokens();
  }

  loadCommunicationOnlyCustomers(){
    console.log('here');
    let thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customers/communication_only', { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(
      function (data) {
        // Check whether we have a failure
        if (typeof data.error !== 'undefined' && data.error === 'auth') {

          // Redirect to the error page
          thus.setState({ error: true });
          return false;

        } else {
          // Process the data for display of the users
          thus.setState({ customers: data.data, isLoading: false });
          return data;
        }
      }
    );

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Handle the submission of a user
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/create';
    var initName = encodeURIComponent(this.state.initName);
    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ initial_name: initName }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Set the updated state
        thus.setState({ rslt: 'The client account has been successfully created', rsltType: 'success' });
      } else {
        // Handle the failure by showing a failure message
        var msg = "<p>Sorry but there was an error creating the client's details. Please check the errors below and try again.</p><ul>";
        for ( var i in data.errors) {
          for (var j in data.errors[i]) {
            msg += "<li>" + data.errors[i][j].message + "</li>";
          }
        }
        msg += "</ul>";
        thus.setState({ rslt: msg, rsltType: 'error' });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }


  render() {

    let filteredCustomers = [];

    console.log(this.props);
    
    if(this.state.customers.length > 0  && typeof this.props.usrFirst !== 'undefined' && this.props.usrFirst.length > 0){
      filteredCustomers = this.state.customers.filter((customer) => {
        return customer.initial_name.toLowerCase().indexOf(this.props.usrFirst.toLowerCase()) !== -1;
      })
    } 

    // filteredCustomers = this.state.customers;

    let selectableClients = filteredCustomers.map((customer,i) => {

      let styleObj = {};
      if(typeof this.props.existingCustomer !== 'undefined'  && typeof this.props.existingCustomer.public_id !== 'undefined' ){
         if(this.props.existingCustomer.public_id === customer.public_id){
           styleObj = styles.selected
         }
      }
      return <div style={styleObj} onClick={() => this.props.handleExternalClientSelected(customer)} key={i}>{customer.initial_name}</div>
    })

    let title = null;
    var content = "";    
    if(filteredCustomers.length > 0){
      title = <h4>Client Communications Records</h4>
      content = <p>The following communications recordsmatch the client name that you have entered. If you would like to link these communications to your new client, then please select them from the list below.</p>
    }

    if(!this.props.canShowDropdown){
      return null
    }

    return (
      <div className="client-selector">
        { title }
        { content }
        { selectableClients }
      </div>
    );
  }
}

var styles = {
  selector: {
    position:'absolute',
    background: Colours.bgGrey,
    minWidth:'200px',
    padding:'0px 10px',
    cursor:'pointer',
    marginTop:'-18px',
    marginLeft:'5px'
  },
  selected:{
    background: Colours.lightGrey
  },
  full: {
    marginLeft: '0.5%',
    marginRight: '0.5%',
    width: '99%'
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(CustomerCommunicationDropdown);
