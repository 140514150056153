import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import Heading from '../heading.js';
import FlashMsg from '../../common/flash-msg.js';
import Btn from '../../common/btn.js';

import Fetching from '../../../functions/fetching.js';
import Colours from '../../../assets/colours.js';

class SectionsLink extends Component {

  constructor(props) {
    super(props);

    this.state = {
      customerName: "",
      custRef: this.props.match.params.custRef,
      sxns: [],
      genLnk: '',
      genLoading: false,
      rdr: false,
      isLoading: false,
      error: ""
    };

    this.getTokens = this.getTokens.bind(this);
    this.generateLnk = this.generateLnk.bind(this);
    this.closeCover = this.closeCover.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.getSxns = this.getSxns.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick ( e, sxnId ) {

    // Set the sections array
    var sxns = this.state.sxns;
    // Loop through the sections
    for (var i in sxns ) {
      if (sxnId === sxns[i].id) {
        sxns[i].isSelected = e.target.checked;
      }
    }
    // Update the state
    this.setState({ sxns: sxns });

  }

  getCustomer () {

    // Check that we have a reference
    if (typeof this.state.custRef == 'undefined' || this.state.custRef === "") {
      this.setState({ rdr: true });
    }

    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customers/get/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.msg != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });
      } else {
        thus.setState({ customerName: data.name });
      }

    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  getSxns () {

    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_sections/admin_list/', { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.msg != 'undefined') {
        this.setState({ rdr: true });
      } else {
        thus.setState({ sxns: data, isLoading: false });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  generateLnk ( e ) {

    e.preventDefault();

    this.setState({ genLoading: true });
    const usrRef = this.state.custRef;

    // Assemble the list of selected sxns
    var sxnList = [];
    let sxns = this.state.sxns;
    for ( var i in sxns ) {
      if (sxns[i].isSelected === true) {
        sxnList.push(sxns[i].id);
      }
    }

    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customer_submissions/create', {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({ pid: usrRef, sxns: sxnList }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
        // Set the updated state
        thus.setState({ genLoading: false, genLnk: data.slug });
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ error: "Sorry but there was an error generating the link. Please try again.", genLoading: false });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.setState({ error: "Sorry but there was an error generating the link. Please try again.", genLoading: false });
      thus.getTokens();
    });
  }

  closeCover (e) {
    e.preventDefault();
    this.setState({ genLnk: "", genLoading: false, rdr: true });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {

    // Check for pagination
    this.getCustomer();
    this.getSxns();

    this.getTokens();

  }

  render() {

    const { isLoading, genLnk, genLoading, sxns, error, custRef } = this.state;

    if (this.state.rdr) {
      return <Redirect to={"/admin/clients/details/" + custRef} />
    }

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    var coverContent = "";
    if (genLoading !== false) {
      coverContent = <div className="cover">
        <div className='width-1 centred' styles={ styles.topMrgn }>
          <div className="loader whiteBg">Loading...</div>
        </div>
      </div>
    }
    if (genLnk !== "") {
      coverContent = <div className="cover">
        <a href="#close" onClick={ (e) => this.closeCover(e) }> </a>
        <div className="box">
          <h5>Questionnaire Link</h5>
          <p className="smlr">Please copy the link below and send it to the client(s).</p>
          <p>{ process.env.REACT_APP_SITE_BASE }/welcome/{ this.state.genLnk }</p>
        </div>
      </div>
    }

    if (!isLoading) {
      content = <form>
                  <div style={ styles.form }>
                    { sxns.map ( sxn =>
                      <div key={sxn.id} style={ styles.half } className="half-width">
                        <input name="sectionName" type='checkbox' defaultChecked={ sxn.isSelected === true ? "checked":"" } onChange={ (e) => this.handleClick( e, sxn.id ) } id={ "input-" + sxn.id } value={ sxn.id } />
                        <label style={ styles.label } htmlFor={ "input-" + sxn.id }>{ sxn.title }</label>
                      </div>
                    )}
                  </div>
                  <Btn title="Generate link" lnk="/questionnaire" type="submit" handleSubmission={ (e) => this.generateLnk(e) } />
                </form>;

    }

    var errorContent = "";
    if (error !== "") {
      errorContent = <div className='error mbtm' style={ styles.full }>
                      { error }
                    </div>
    }

    return (
      <div className='width-9 centred'>

        <Link style={ styles.lnkRght } to={ `/admin/clients` }>Back</Link>
        <Heading mainTitle="Generate a Sections Link" subTitle={ this.state.customerName } />

        <FlashMsg />

        { coverContent }
        { errorContent }
        { content }

      </div>
    );
  }
}

var styles = {
  full: {
    width: '99%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  form: {
    overflow: 'auto',
    display: 'block'
  },
  half: {
    width: "49%",
    float: "left",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: "0.5%",
    marginRight: "0.5%"
  },
  label: {
    marginLeft: 8
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(SectionsLink);
