import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

import Fetching from './../../../functions/fetching.js';

import NumberFormat  from 'react-number-format';
import Paginate from './../../common/paginate.js';
import MIFilter from './../customers/mi-filter.js';
import { connect } from 'react-redux';
import Moment from 'moment';

class ManagementSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fees: 0,
      investment: 0,
      month_breakdown: [],
      month_review_total_count: 1,
      isLoading:true,
			isLoadingCustomers:true,
		  currentCustomerLoad:false,
		  pageNo:1,
		  customers:{},
		  paramList: {},
		  filterList: {},
		  sort:{}
    };

	this.updateFilter = this.updateFilter.bind(this);
	this.applySort = this.applySort.bind(this);
  }

  initialiseComponent () {
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/management-information', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {

          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {

            let newData = {...data.data};
            newData.isLoading = false;
            // Process the data for display of the users
            thus.setState(newData);
            return data;
          }
        }
      );
  }

  componentWillReceiveProps(nextProps){
	 //call your api and update state with new props

	 let pageNo = this.returnParam(nextProps.match.params.filterParams, "page");
	 this.initialiseCustomerComponent(pageNo, nextProps);
  }

  applySort(sortField){
	this.setState((prevState) => {

		let newState = {...prevState};

		if(typeof prevState.sort.field === "undefined"){
			newState.sort = {
				field:sortField,
				desc:true
			}
		} else if(prevState.sort.field !== sortField) {
			newState.sort.field = sortField;
			newState.sort.desc = true;	
		} else {
			newState.sort.desc = !prevState.sort.desc;
		}
		return newState;
	},()=> {
		var pageNo = this.returnParam(this.props.match.params.filterParams, "page");
		if (typeof pageNo == 'undefined') {
		pageNo = 1;
		}
		this.initialiseCustomerComponent(pageNo, this.props, true);;
	})
	
  }

  updateFilter(e, cfId, fieldType) {

	// Work out the filter value
	// N.B. This switch statement is pretty pointless at the moment, but would be useful if there was a checkbox style of field
	var fieldVal;
	switch (fieldType) {
	  case "radio":
		fieldVal = e.target.value;
		break;
	  case "select":
		fieldVal = e.target.value;
		break;
  case "cb":
	  fieldVal = e.target.checked;
	  break;
	  default:
		fieldVal = e.target.value;
		break;
	}

	// Update the filtered list - by adding hte params to POST request
	var filterList = this.state.filterList;

	if (fieldVal !== "") {
	  filterList[cfId] = fieldVal;
	} else {
	  if (typeof filterList[cfId] != 'undefined') {
		delete filterList[cfId];
	  }
	}

	this.setState({ filterList: filterList });

	var pageNo = this.returnParam(this.props.match.params.filterParams, "page");
	if (typeof pageNo == 'undefined') {
	  pageNo = 1;
	}
	this.initialiseCustomerComponent(pageNo, this.props, true);

  }

  initialiseCustomerComponent (pageNo, useProps, isParamsUpdate) {

		// First of all check whether we are already loading customers (to prevent this triggering lots of times all at once)
		let { currentCustomerLoad } = this.state;
		if (currentCustomerLoad) {
			return;
		}
		this.setState({ currentCustomerLoad: true });

    var thus = this;

	if (typeof isParamsUpdate == 'undefined') {
      isParamsUpdate = false;
    }

    if (typeof pageNo == 'undefined' || pageNo === "" || isParamsUpdate) pageNo = 1;

	// Check whether we have any parameters set
	let filterList = this.state.filterList;
	

// TODO - upon load of a new param reset the pageNo in URL etc to be page 1... how?!?!

    if (Object.keys(filterList).length === 0) {

		let url = process.env.REACT_APP_API_BASE + '/admin/management-information/customers?page=' + pageNo;
		if(typeof this.state.sort.field !== "undefined"){
			url += '&sort='+JSON.stringify(this.state.sort)
		}

	    fetch(url, { credentials:"include" })
	      .then(Fetching.statusCheck)
	      .then(Fetching.jsonExtract)
	      .then(
	        function (data) {

	          // Check whether we have a failure
	          if (typeof data.error !== 'undefined' && data.error === 'auth') {

	            // Redirect to the error page
	            thus.setState({ error: true, isLoadingCustomers: false, currentCustomerLoad: false });
	            return false;

	          } else {

	            let customers = {...data.data};
	            // Process the data for display of the users
	            thus.setState({customers: customers, isLoadingCustomers: false, currentCustomerLoad: false});
	            return data;
	          }
	        }
	      );
	  } else {

		let body =  'data=' + JSON.stringify(filterList) + '&' + this.props.tokenKey + '=' + this.props.token;
		if(typeof this.state.sort.field !== "undefined"){
			body += '&sort='+JSON.stringify(this.state.sort)
		}

		  fetch(process.env.REACT_APP_API_BASE + '/admin/management-information/customers?page=' + pageNo , {
			method: 'post',
			headers: {
			  "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body:body
		  })
			.then(Fetching.statusCheck)
			.then(Fetching.jsonExtract)
			.then(
			  function (data) {
					// Check whether we have a failure
					if (typeof data.error !== 'undefined' && data.error === 'auth') {

					  // Redirect to the error page
					  thus.setState({ error: true, isLoadingCustomers: false, currentCustomerLoad: false });
					  return false;

					} else {
						let customers = {...data.data};
	    	    // Process the data for display of the users
	    	    thus.setState({customers: customers,isLoadingCustomers: false, currentCustomerLoad: false});
	    	    return data;
					}
				}
			);

		}

  }

  getTokens () {
	fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
	  .catch(function (error) {
		console.log('Request failed', error);
	  });
  }

  extractParams ( paramList ) {

	var retLst = {};

	if (typeof paramList != 'undefined') {
	  paramList = paramList.split('&');

	  var curParam;
	  for (var i in paramList) {
		curParam = paramList[i].split(':');
		retLst[curParam[0]] = {
		  'pname': curParam[0],
		  'value': curParam[1]
		};
	  }
	}

	this.setState({ paramList: retLst });

	return retLst;
  }

  returnParam (filterParams, desired) {
		let paramsLst = this.extractParams(filterParams);
		return (typeof paramsLst[desired] != 'undefined' && typeof paramsLst[desired].value != 'undefined') ? paramsLst[desired].value : "" ;
  }


  componentDidMount () {

	  // Check for pagination
      var pageNo = this.returnParam(this.props.match.params.filterParams, "page");
      if (typeof pageNo == 'undefined') {
        pageNo = 1;
      }


    this.initialiseComponent();
	this.initialiseCustomerComponent(pageNo, this.props);
	this.getTokens();
  }

  // Render the view
  render() {

    const { isLoading,isLoadingCustomers,paramList,customers,sort} = this.state;

	if (!isLoadingCustomers) {

      let theBase = "/admin/management-summary/";
      var baseUrl = { "standard": theBase };
      for ( var key in paramList ) {
        baseUrl["minus-" + key] = theBase;
        for ( var dkey in paramList ) {
          baseUrl["standard"] += dkey + ":" + paramList[dkey].value + "&";
          if (key !== dkey) {
            baseUrl["minus-" + key] += dkey + ":" + paramList[dkey].value + "&";
          }
        }
        baseUrl["minus-" + key] += key + ":";
      }

  }

    let content = <div className='width-2 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

	let customerContent = <div className='width-2 centred'>
	              <div className="loader whiteBg">Loading...</div>
	            </div>;

    if (!isLoading && this.state.month_breakdown.length > 0) {

      content = <div>
                  <table className='management-summary-table'>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Reviews</th>
                        <th >Interim Reviews</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.month_breakdown.map((monthObj,i) => {

                        let newReviewTo = {
                          pathname: '/admin/clients' ,
                          review_month: monthObj.month,
                          filter_key:monthObj.month_custom_field_id,
                          filter_value:monthObj.month_field_id
                        }

                        let newInterimReviewTo = {
                          pathname: '/admin/clients',
                          interim_review_month: monthObj.month,
                          filter_key:monthObj.interim_month_custom_field_id,
                          filter_value:monthObj.interim_month_field_id
                        }

                        return (
                          <tr key={'month-'+i}>
                            <td>{monthObj.month}</td>
                            <td><Link className="link" to={newReviewTo}>{monthObj.review_count}</Link></td>
                            <td><Link className="link" to={newInterimReviewTo} >{monthObj.interim_review_count}</Link></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>

                </div>
    }

	if (!isLoadingCustomers && typeof customers.customers != "undefined" && customers.customers.length > 0) {

		let sortClassName = 'orderer';
		// if(typeof sort.field === 'undefined'){
		// 	sortClassName = ''
		// }

		if(typeof sort.desc !== 'undefined'){
			if( sort.desc === 0){
				sortClassName +=  ' asc '
			} else {
				sortClassName +=  ' desc '
			}
		}
		

      customerContent = <div>
                  <table >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>AUM</th>
                        <th >Ongoing Advice Fees</th>
						<th className={sortClassName} onClick={() => this.applySort('communication')} >Latest Communication</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.customers.customers.map((customerObj,i) => {

						let comm_date = customerObj.recent_communication.recent_communication_date;

						
						let link = <i>No Communication</i>;

						let urlLink = '';
						if(customerObj.recent_communication){

							let comm_type = '';
							switch(customerObj.recent_communication.recent_communication_type){
								case 'meeting-notes':
									comm_type = 'Meeting';
									break;
								case 'call':
									comm_type = 'Call';
									break;
								case 'sent-local':
								default:
									comm_type = 'Email';
									break;
							}
						

							switch(customerObj.recent_communication.recent_communication_type){
								case 'meeting-notes':
									urlLink = '/admin/meeting_notes/compose/'+customerObj.recent_communication.recent_communication_id;
									break;
								case 'call':
									urlLink = '/admin/record_call/compose/'+customerObj.recent_communication.recent_communication_id;
									break;
								default:
									urlLink = '/admin/email/details/'+customerObj.recent_communication.recent_communication_id;
									break;
							}
							link = <Link to={urlLink}>{comm_type + ': ' + customerObj.recent_communication.commsTimeAgo + ' ('+Moment(comm_date).format('DD/MM/YYYY')+')'}</Link>
						}

                        return (
                          <tr key={'month-'+i}>
                            <td>{customerObj.initial_name}</td>
                            <td>
								<NumberFormat
									value={ customerObj.investment_value }
									displayType={ 'text' }
									thousandSeparator={ true }
									prefix={ '£' }
									decimalSeparator={ '.' }
									decimalScale={ 2 }
									fixedDecimalScale={ true }
								/>
							</td>
                            <td>
								<NumberFormat
									value={customerObj.investment_fees}
									displayType={ 'text' }
									thousandSeparator={ true }
									prefix={ '£' }
									decimalSeparator={ '.' }
									decimalScale={ 2 }
									fixedDecimalScale={ true }
								/>
							</td>
							<td>
							{link}
							</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
				  <Paginate urlBase={ typeof baseUrl['minus-page'] != 'undefined' ? baseUrl['minus-page'] : baseUrl.standard + "page:" } totalPages={ customers.totalPages } currentPage={ customers.currentPage }  nextPage={ customers.nextPage } prevPage={ customers.prevPage } />
                </div>
    } else if (!isLoadingCustomers) {
		customerContent = <p>No customers found</p>
	}

    var averageFee = Math.floor( (this.state.fees * 100) / this.state.month_review_total_count) / 100;

    return (


      <div className='width-8 centred' style={{paddingBottom:'50px'}}>

        <Heading mainTitle="Management Information"  />

        <FlashMsg />

        <div className='summary-panel'>
          <div >
            <p className='heading'>Total assets under management</p>
            <span className='value'>
							<NumberFormat
								value={ this.state.investment }
								displayType={ 'text' }
								thousandSeparator={ true }
								prefix={ '£' }
								decimalSeparator={ '.' }
								decimalScale={ 2 }
								fixedDecimalScale={ true }
							/>
						</span>
          </div>

          <div >
            <p className='heading'>Total Ongoing advice fees</p>
            <span className='value'>
							<NumberFormat
								value={ this.state.fees }
								displayType={ 'text' }
								thousandSeparator={ true }
								prefix={ '£' }
								decimalSeparator={ '.' }
								decimalScale={ 2 }
								fixedDecimalScale={ true }
							/>
						</span>
          </div>

          <div>
			<div class='client-count-panel'>
				<p className='heading'>Number of clients</p>
				<span className='value'>{this.state.month_review_total_count}</span>
			</div>
			<div class='client-count-panel'>
				<div>
					<p>Prospects: <span>{this.state.month_review_total_prospect_count}</span></p>
					<p>Archived Clients: <span>{this.state.month_review_total_archive_count}</span></p>
				</div>
			</div>
          </div>

          <div >
            <p className='heading'>Average ongoing advice fee per client</p>
            <span className='value'>
							<NumberFormat
								value={ averageFee }
								displayType={ 'text' }
								thousandSeparator={ true }
								prefix={ '£' }
								decimalSeparator={ '.' }
								decimalScale={ 2 }
								fixedDecimalScale={ true }
							/>
						</span>
          </div>
        </div>


        <h2 className='subheading'>Client Reviews</h2>

        { content }



		<h2 className='subheading'><MIFilter updateFilter={ this.updateFilter } location={this.props.location} /> Clients </h2>


        { customerContent }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}


export default connect(mapStateToProps)(ManagementSummary);
