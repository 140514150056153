import React, { Component, Fragment } from 'react';
import Colours from './../../../../assets/colours.js';
import DisplayDropdown from './../content/display-dropdown.js'

class DisplayOptionDropdown extends Component {

  constructor(props){
    super(props);

    this.focusRef = React.createRef();
  }




  render() {

    const dropdown = this.props.isDropdownOpen ? <DisplayDropdown handleDropDownBlur={this.props.handleDropDownBlur} {...this.props} /> : null;

    return (
      <Fragment  >
      <div style={styles.container}>
        <button
          style={{ ...styles.round, ...styles.displayOptions}}
          onClick={(e) => this.props.toggleDropdownDisplay()}
          >
          + Display Options
        </button>

      </div>

      {dropdown}

      </Fragment>
    );
  }
}

export default DisplayOptionDropdown;

var styles = {
  container:{
    position:"relative",
    overflowY:"visible",
    display:'inline-block',
    zIndex:'999',
    paddingTop:'0'
  },
  round: {
    borderRadius: 25,
    display: 'inline-block',
    margin: "0 0 0 18px",
    padding: "5px 12px",
    fontSize: 13,
		border: 'none'
  },
	displayOptions: {
    marginLeft: 10,
    background: Colours.txtGrey ,
    color: Colours.txtWhite,
    textDecoration: 'none',
    paddingRight: 15,
    paddingLeft: 15,
		cursor: 'pointer'
  }
};
