import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Heading from './../heading.js';
import Btn from './../../common/btn.js';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';

class Create extends Component {

  constructor(props) {
    super(props);

    this.state = {
      qList: { qgrps: [] },
      cfId: this.props.match.params.fieldId,
      label: "",
      type: "text",
      isRequired: 0,
      inTable: 0,
      options: "",
      qqId: "",
      repNo: "",
      selLnk: {},
      rdr: false
    };

    this.handleEntry = this.handleEntry.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  setLnked (qqId) {

    let qList = this.state.qList;

    for ( var i in qList.qgrps ) {
      if (qList.qgrps[i].id === qqId && typeof qList.qgrps[i].group == "undefined") {
        this.setState({ selLnk: qList.qgrps[i] })
        console.log(qList.qgrps[i]);
        return;
      }
    }

  }

  handleEntry ( event ) {
    if (event.target.name === "isRequired" || event.target.name === "inTable") {
      this.setState({ [event.target.name]: (event.target.checked)?"1":"0" });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "qqId") {
        this.setLnked(event.target.value);
      }
    }
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Fetch the custom field data
  componentDidMount () {

    this.getQuestionList();
    if (typeof this.props.match.params.fieldId != 'undefined') {
      this.loadDetails(this.props.match.params.fieldId);
    }
    this.getTokens();

  }

  // Return a list of all the questions that can be used to populate a field
  getQuestionList () {
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_sections/listall', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          thus.setState({ qList: data });
        }
      )
      .catch(function (error) {
        console.log('Request failed', error);
      });

  }

  loadDetails (cfId) {

    var thus = this;
    if (typeof this.props.match.params.fieldId != 'undefined') {

      fetch(process.env.REACT_APP_API_BASE + '/admin/custom_fields/get/' + this.props.match.params.fieldId, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {

            // Check whether we have a failure
            if (typeof data.errors != 'undefined') {

              // Redirect to the error page
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but we could not find the custom field to edit.' });

            } else {
              // Process the data for display of the tabs
              thus.setState({ label: data.cf.label, type: data.cf.type, isRequired: data.cf.is_required, options: data.cf.options, inTable: data.cf.in_table, qqId: data.cf.qqId, repNo: data.cf.repNo });
              setTimeout(function(){
                thus.setLnked(data.cf.qqId);
              }, 1000);
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error editing the custom field.' });
        thus.setState({ rdr: true });
      }

  }

  // Handle the submission of a user
  handleSubmission ( event ) {

    event.preventDefault();

    var thus = this;
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/custom_fields/save';

    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify({
        cfId: this.state.cfId,
        label: encodeURIComponent(this.state.label),
        type: this.state.type,
        is_required: this.state.isRequired,
        in_table: this.state.inTable,
        qqId: this.state.qqId,
        repNo: this.state.repNo,
        optionStr: this.state.options
      }) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {

        // Set the updated state
        thus.setState({ rdr: true });
        thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: 'The custom field has been successfully saved.' });

      } else {
        // Handle the failure by showing a failure message
        var msg = "<p>Sorry but there was an error creating the custom field. Please check the errors below and try again.</p><ul>";
        for ( var i in data.errors) {
          for (var j in data.errors[i]) {
            msg += "<li>" + data.errors[i][j].message + "</li>";
          }
        }
        msg += "</ul>";
        thus.setState({ rslt: msg, rsltType: 'error' });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  render() {

    const { label, type, isRequired, inTable, options, rdr, qList, qqId, selLnk, repNo } = this.state;

    if (rdr) {
      return (
        <Redirect to='/admin/custom-fields' />
      );
    }

    var title = "Create Custom Field";
    var subtitle = "";
    if (label !== "") {
      subtitle = label;
      title = "Edit Custom Field";
    }

    var optionsInput = "";
    if (type !== "text" && type !== "linked" && type !== "default") {
      optionsInput = <div className='full-input' style={ styles.mtop }>
          <label htmlFor="options">Enter the text for the { type === "select" ? "select menu":"radio button" } options below (separated by a { type === "select" ? "line break":'"---"' })</label>
          <textarea style={ styles.radioOptInput } value={ options } name="options" id="options" onChange={ (e) => this.handleEntry(e) }></textarea>
        </div>;
    } else if (type === "linked") {
      optionsInput = (
        <div className='full-input' style={ styles.mtop }>
          <label htmlFor="options">Select the linked field</label>
          <select id="qqId" name="qqId" onChange={ this.handleEntry } value={ qqId }>
            <option value=""></option>
            { qList.qgrps.map( q =>
              <option key={ typeof q.group != "undefined" ? "grp-" + q.id : q.id } value={ q.id } disabled={ typeof q.group != "undefined" ? true : false }>{ typeof q.group != "undefined" ? q.group : q.content }</option>
            )}
          </select>
        </div>
      );
    }

    var repStr = "";
    if (type === "linked" && selLnk.gtype === "repeat-fxd") {
      repStr = (
        <div className='full-input' style={ styles.mtop }>
          <label htmlFor="options">Which repeating element of the linked field should be used?</label>
          <select id="repNo" name="repNo" onChange={ this.handleEntry } value={ repNo }>
            { selLnk.gopts.map ( opt =>
              <option key={ opt.repNo } value={ opt.repNo }>{ opt.value }</option>
            )}
          </select>
        </div>
      );
    }

    return (
      <div className='width-9 centred'>
        <Link style={ styles.lnkRght } to={ `/admin/custom-fields` }>Back</Link>
        <Heading mainTitle={ title } subTitle={ subtitle } />

        <div className={ "mbtm " + this.state.rsltType } style={ styles.full } dangerouslySetInnerHTML={{ __html: this.state.rslt }}></div>

        <form>

          { type !== "default" ?
            (
              <div>
                <div className='full-input'>
                  <label htmlFor="label" style={ styles.full }>Custom field name</label>
                  <input type="text" name="label" id="label" defaultValue={ label } onChange={ this.handleEntry } style={ styles.full } />
                </div>

                <div className='full-input'>
                  <label htmlFor="type" style={ styles.full }>Custom field type</label>
                  <select id="type" name="type" onChange={ this.handleEntry } value={ type }>
                    <option value="text">Plain text input</option>
                    <option value="select">Select (Dropdown menu)</option>
                    <option value="radio">Radio button group</option>
                    <option value="linked">Linked field</option>
                  </select>
                </div>
              </div>
            ) : ( "" )
          }

          { optionsInput }
          { repStr }

          <div className='full-input'>
            <input type="checkbox" name="inTable" id="inTable" checked={ inTable === "1" ? "checked":"" } onChange={ this.handleEntry } style={ styles.cb } />
            <label htmlFor="inTable" style={ styles.full }>Should this custom field be displayed in the customers table?</label>
          </div>

          { type !== "default" ?
            (
              <div className='full-input'>
                <input type="checkbox" name="isRequired" id="isRequired" checked={ isRequired === "1" ? "checked":"" } onChange={ this.handleEntry } style={ styles.cb } />
                <label htmlFor="isRequired" style={ styles.full }>Should this custom field be compulsory?</label>
              </div>
            ) : ( "" )
          }

          <Btn title="Save" lnk="/questionnaire" type="submit" handleSubmission={ this.handleSubmission } btnStyle={ styles.mbtm } />
        </form>

      </div>
    );
  }
}

var styles = {
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
  full: {
    marginLeft: '0.5%',
    marginRight: '0.5%',
    width: '99%'
  },
  cb: {
    float: 'left',
    marginRight: 15,
    marginTop: 8
  },
  radioOptInput: {
    minHeight: 250,
    width: "98%",
    marginLeft: "0.5%"
  },
  mtop: {
    marginTop: 25
  },
	mbtm: {
		marginBottom: 60
	}
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(Create);
