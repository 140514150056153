import React, { Component,Fragment } from 'react';
import SideBar from './planning-components/side-bar.js';
import Graph from './planning-components/graph.js'
import Fetching from './../../../functions/fetching.js';
import { connect } from 'react-redux';
import AgendaView from './agenda.js'

class Planning extends Component {


  constructor(props){
    super(props);

    let d = new Date();
    let currentDateTime = d.toString();

    this.typingTimeout = null;

    this.state = {
      isFullScreen:false,
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
      customer_age:35,
	  dob_found:false,
      investment_age:32,
      savings:{},
      calculators:{
        display_values:[],
        settings:[],
        openCalculator:0,
        lastOpenCalculator:0,
        lastUpdate:currentDateTime,
      },
      typingTimeout: 0,
      displayNoCalculatorPage:true,
      existing_contributions:[],
      existing_withdrawals:[],
      imageURI:'',
      lastUpdate:'',
      agendaMinimised:true,
      graphKey:0,
	  isLoading:true,
	  isGeneratingValuationSummary:false,
      planDisplayDetails:{
        startAgeCrop:35,
        endAgeCrop:100,
        holdingStartAgeCrop:35,
        holdingEndAgeCrop:100,
        graphToggle:'both',
        showContributions:true,
        showWithdrawals:true,
        showExpectedReturn:true,
        showUpperReturn:true,
        showLowerReturn:true,
        showTarget:true,
        enable_inflation:true,
        displayPotentialValue:true,
        displayCurrentSavings:true,
        displayOriginalForecast:false
      }
    }

    this.handleExpandToggle = this.handleExpandToggle.bind(this);
    this.handleInvestmentReturnInput = this.handleInvestmentReturnInput.bind(this);
    this.handleInflationRateInput = this.handleInflationRateInput.bind(this);
    this.handleStartingWithdrawalRateInput = this.handleStartingWithdrawalRateInput.bind(this);
    this.handleCurrentValueInput = this.handleCurrentValueInput.bind(this);
    this.handleMonthlyContributionInput = this.handleMonthlyContributionInput.bind(this);
    this.handleAgeInput = this.handleAgeInput.bind(this);
    this.handleRetirementAgeInput = this.handleRetirementAgeInput.bind(this);
    this.handleTargetInput = this.handleTargetInput.bind(this);
    this.createOption = this.createOption.bind(this);
    this.handleTargetTypeToggle = this.handleTargetTypeToggle.bind(this);
    this.handleAssumedRateHighInput =this.handleAssumedRateHighInput.bind(this);
    this.handleAssumedRateLowInput =this.handleAssumedRateLowInput.bind(this);

    this.handleWithdrawalTypeChange = this.handleWithdrawalTypeChange.bind(this);
    this.handleWithdrawalFieldInputChange = this.handleWithdrawalFieldInputChange.bind(this);
    this.handleWithdrawalFieldRemove = this.handleWithdrawalFieldRemove.bind(this);
    this.handleWithdrawalFieldAdd = this.handleWithdrawalFieldAdd.bind(this);

    this.handleContributionTypeChange = this.handleContributionTypeChange.bind(this);
    this.handleContributionFieldInputChange = this.handleContributionFieldInputChange.bind(this);
    this.handleContributionFieldRemove = this.handleContributionFieldRemove.bind(this);
    this.handleContributionFieldAdd = this.handleContributionFieldAdd.bind(this);

    this.handleStartingWithdrawalFieldAdd = this.handleStartingWithdrawalFieldAdd.bind(this);
    this.handleStartingWithdrawalFieldRemove = this.handleStartingWithdrawalFieldRemove.bind(this);

    this.toggleCurrentSavings = this.toggleCurrentSavings.bind(this);
    this.togglePotentialValueSavings = this.togglePotentialValueSavings.bind(this);
    this.toggleOriginalForecastDisplay = this.toggleOriginalForecastDisplay.bind(this);

    this.handleTargetValueToggle = this.handleTargetValueToggle.bind(this);
    this.handleInvestmentReturnToggle = this.handleInvestmentReturnToggle.bind(this);
    this.handleInflationRateToggle = this.handleInflationRateToggle.bind(this);
    this.handleStartingWithdrawalRateToggle = this.handleStartingWithdrawalRateToggle.bind(this);
    this.handleAgeToggle = this.handleAgeToggle.bind(this);
    this.handleRetirementAgeToggle = this.handleRetirementAgeToggle.bind(this);
    this.handleAssumedRateHighToggle =this.handleAssumedRateHighToggle.bind(this);
    this.handleAssumedRateLowToggle =this.handleAssumedRateLowToggle.bind(this);

    this.saveCalculator = this.saveCalculator.bind(this);
    this.softDeleteCalculator = this.softDeleteCalculator.bind(this);
    this.handleSnapshot = this.handleSnapshot.bind(this);

    this.handleCalculatorTitleEdit = this.handleCalculatorTitleEdit.bind(this);

    this.loadCalculators = this.loadCalculators.bind(this);
    this.downloadAsPDF = this.downloadAsPDF.bind(this);

    this.saveImageURI= this.saveImageURI.bind(this);

    this.handleInvestmentReturnType = this.handleInvestmentReturnType.bind(this);
    this.handleAssumedRateLowType = this.handleAssumedRateLowType.bind(this);
    this.handleAssumedRateHighType = this.handleAssumedRateHighType.bind(this);
    this.handleInflationRateType = this.handleInflationRateType.bind(this);
    this.handleRetirementAgeType = this.handleRetirementAgeType.bind(this);
    this.handleAgetype = this.handleAgetype.bind(this);
    this.handleStartingWithdrawalRateType = this.handleStartingWithdrawalRateType.bind(this);
    this.handleTargetType = this.handleTargetType.bind(this);
    this.handleStartingWithdrawalRateTypeFromBox = this.handleStartingWithdrawalRateTypeFromBox.bind(this);

    this.triggerFullScreen = this.triggerFullScreen.bind(this);
    this.exitFullscreen = this.exitFullscreen.bind(this);

	this.handleNotesChange = this.handleNotesChange.bind(this);

    this.handleContributionInflationChange = this.handleContributionInflationChange.bind(this);
    this.handleWithdrawalInflationChange = this.handleWithdrawalInflationChange.bind(this);

    this.handleGlobalInflationToggle = this.handleGlobalInflationToggle.bind(this);

    this.toggleContributionDisplay = this.toggleContributionDisplay.bind(this);
    this.toggleWithdrawalDisplay = this.toggleWithdrawalDisplay.bind(this);

    this.toggleExpectedReturnDisplay = this.toggleExpectedReturnDisplay.bind(this);
    this.toggleUpperReturnDisplay = this.toggleUpperReturnDisplay.bind(this);
    this.toggleLowerReturnDisplay = this.toggleLowerReturnDisplay.bind(this);
    this.toggleTargetDisplay = this.toggleTargetDisplay.bind(this);

    this.toggleAgendaMinimised = this.toggleAgendaMinimised.bind(this);

    this.toggleEndAgeCrop = this.toggleEndAgeCrop.bind(this);
    this.toggleStartAgeCrop = this.toggleStartAgeCrop.bind(this);

    this.handleGraphToggle = this.handleGraphToggle.bind(this);

    this.loadDisplaySettings = this.loadDisplaySettings.bind(this);

	this.generateValuationSummary = this.generateValuationSummary.bind(this);
  }

  componentDidMount() {
    this.getTokens();
	this.loadCalculators()

  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  generateValuationSummary () {

	  var thus = this;

	  this.setState({isGeneratingValuationSummary:true})

	  fetch(process.env.REACT_APP_API_BASE + '/admin/progress_reviews/generateValuationSummary/' + this.props.match.params.custRef,{
		method: 'post',
		headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
		credentials:"include"
	  })
	  .then(Fetching.statusCheck)
	  .then(Fetching.jsonExtract)
	  .then(function (data) {

		window.open(process.env.REACT_APP_API_BASE+"/admin/files/download/"+data.file,'_blank');
		let isGeneratingValuationSummary = false;
		thus.setState({isGeneratingValuationSummary})

	  })
	  .catch(function (error) {
		console.log('Request failed', error);
		alert('Sorry but there was an error generating and saving the Progress Review. Please try again.');
	  });
  }

  saveCalculatorDisplaySettingsAction(activeCalculatorIndex = -1){
    let thus = this;

	let customerRef = this.props.match.params.custRef;

	const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/saveCalculatorDisplaySettings/"+customerRef;

    let data = {};

    data.data = this.state.planDisplayDetails;

	data.activeCalculator = -1;

	if(activeCalculatorIndex !== -1){
		data.activeCalculator = activeCalculatorIndex;
	} else {
		if(typeof this.state.calculators.settings[this.state.calculators.openCalculator] !== 'undefined'){
			data.activeCalculator = this.state.calculators.settings[this.state.calculators.openCalculator].id;
		}
	}

    fetch(apiRoute, {
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include",
      body:'data=' + JSON.stringify(data) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      console.log(data);

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

	handleNotesChange ( content ) {

		var { calculators } = this.state;

		if ( typeof calculators.settings[calculators.openCalculator] != 'undefined' ) {
			calculators.settings[calculators.openCalculator].notes = content;
		}

		this.setState({ calculators: calculators });

	}

  handleGraphToggle(e){
    let graphToggle = e.target.value;
    let startAgeCrop, endAgeCrop,holdingStartAgeCrop,holdingEndAgeCrop;

    let planDisplayDetails = {...this.state.planDisplayDetails};

    switch(graphToggle){
      case 'both':
        startAgeCrop = this.state.investment_age;
        holdingStartAgeCrop = this.state.investment_age;
        endAgeCrop = 100;
        holdingEndAgeCrop = 100;
        break;
      case 'progress':
        startAgeCrop = this.state.investment_age;
        endAgeCrop = this.state.customer_age;
        holdingStartAgeCrop = this.state.investment_age;
        holdingEndAgeCrop = this.state.customer_age;
        break;
      case 'projections':
        startAgeCrop = this.state.customer_age;
        holdingStartAgeCrop = this.state.customer_age;
        endAgeCrop = 100;
        holdingEndAgeCrop = 100;
        break;
	default:
		startAgeCrop = this.state.customer_age;
		holdingStartAgeCrop = this.state.customer_age;
		endAgeCrop = 100;
		holdingEndAgeCrop = 100;
		break;
    }


    planDisplayDetails.graphToggle  = graphToggle ;
    planDisplayDetails.startAgeCrop = startAgeCrop;
    planDisplayDetails.endAgeCrop = endAgeCrop;
    planDisplayDetails.holdingStartAgeCrop = holdingStartAgeCrop;
    planDisplayDetails.holdingEndAgeCrop = holdingEndAgeCrop;

	this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());

  }

  handleGlobalInflationToggle ( content ) {

	let enable_inflation = this.state.planDisplayDetails.enable_inflation;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.enable_inflation = !enable_inflation;

	this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());

	}

  // Switch the screen display to full screen
  triggerFullScreen (e) {
    e.preventDefault();
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
    this.setState({ isFullScreen: true });
    this.props.dispatch({ type: 'MINIMISE_HEADER' });

  }

  exitFullscreen(e) {
    e.preventDefault();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
    this.setState({ isFullScreen: false });
    this.props.dispatch({ type: 'MAXIMISE_HEADER' });

  }

  downloadAsPDF(e,i){
    let thus = this;

    let customerRef = this.props.match.params.custRef;

    const apiRoute = process.env.REACT_APP_API_BASE+"/ProgressReviews/generatePDF/"+customerRef;
    let data = this.state.calculators.settings[i];
    let imageURI = this.state.imageURI;
    imageURI = encodeURIComponent( imageURI);

    // Run the login request
    fetch(apiRoute, {
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include",
      body:'data=' + JSON.stringify(data) + '&imageURI=' + JSON.stringify(imageURI)  + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        window.open(process.env.REACT_APP_API_BASE+"/admin/files/download/"+data.file,'_blank');
      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

  loadDisplaySettings(){

		let thus = this;

		let customerRef = this.props.match.params.custRef;

		const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/loadCalculatorDisplaySettings/"+customerRef;

		let data = {};

		const calculators = {...this.state.calculators}

		if(typeof calculators.settings[calculators.openCalculator] !== 'undefined'){
			data.activeCalculatorId = calculators.settings[calculators.openCalculator].id;
		}

		// Run the login request
    fetch(apiRoute, {
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
	  	body:'data=' + JSON.stringify(data) + '&' + this.props.tokenKey + '=' + this.props.token,
      credentials:"include"
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
	  	let isLoading = false;
      if(data.data.length > 0){
        let planDisplayDetails = data.data[0];
				let isLoading = false;
        thus.setState({planDisplayDetails,isLoading});
	} else {
		thus.setState({isLoading});
	}


    })

  }

  loadCalculators(){

	let thus = this;

	let customerRef = this.props.match.params.custRef;

	const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/index/"+customerRef;

		// Run the login request
    fetch(apiRoute, {
      method: 'get',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include"
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {


        let calculators= data.data.calculators;
        let savings = data.data.savings;
        let customer_age = data.data.customer_age;
        let existing_contributions = data.data.existing_contributions;
        let existing_withdrawals = data.data.existing_withdrawals;
        let displayNoCalculatorPage = (data.data.calculators.length === 0) ? true :false
        const lastUpdate = thus.getCurrentDateTime();
		let dob_found = false;

		if(customer_age !== 0){
			calculators = thus.mapSavedGraphsToCustomerAge(calculators,customer_age);
			dob_found = true;
		} else {
			customer_age = 35;
		}

        thus.setState({dob_found,calculators,savings,lastUpdate,customer_age,displayNoCalculatorPage,existing_contributions,existing_withdrawals },() => thus.loadDisplaySettings());
      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

  // In the instance where a customer dob is entered after a graph has been saved where this wasn't found,
  // we map all the saved values to the customer age before presenting the graph.
  mapSavedGraphsToCustomerAge(calculators,customer_age){

	let new_settings = calculators.settings;
	let new_display_values = calculators.display_values;

	new_settings = new_settings.map((setting) => {
		setting.age = customer_age;

		return setting;
	});

	new_display_values = new_display_values.map((display_value) => {
		display_value.age = customer_age;

		return display_value;
	});

	calculators.settings = new_settings;
	calculators.display_values = new_display_values;

	return calculators;
  }

  saveCalculator(i,callback){

	let thus = this;

	let customerRef = this.props.match.params.custRef;

	const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/save/"+customerRef;
    let saveData = this.state.calculators.settings[i];
    let validateData = {};
    validateData['savings'] = this.state.savings;
    validateData['customer_age'] =  this.state.customer_age;
    validateData['investment_age'] =  this.state.investment_age;
    let index = i;
		// Run the login request
    fetch(apiRoute, {
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include",
      body:'data=' + JSON.stringify(saveData) + '&validate_data=' + JSON.stringify(validateData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Redirect to the dashboard
        let calculators = {...thus.state.calculators};
        calculators.settings[index].id = parseInt(data.data.id);
        calculators.display_values[index].id = parseInt(data.data.id);

        calculators.settings[index].instance_id = parseInt(data.data.instance_id);
        calculators.display_values[index].instance_id = parseInt(data.data.instance_id);
        const lastUpdate = thus.getCurrentDateTime();
        thus.setState({calculators,lastUpdate});

        if(typeof callback !== 'undefined'){
          callback();
        }

				//thus.setState({similarProducts:data.data})
      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

  softDeleteCalculator(e,i){

	let thus = this;
    let index = i;

    let doDelete = window.confirm('Do you wish to delete this calculator?');
    if(!doDelete){
      return;
    }

	let customerRef = this.props.match.params.custRef;

	const apiRoute = process.env.REACT_APP_API_BASE+"/calculator/delete/"+customerRef;
    let deleteData = {
      customer_plan_id:this.state.calculators.settings[index].id
    }

		// Run the login request
    fetch(apiRoute, {
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      credentials:"include",
      body:'data=' + JSON.stringify(deleteData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

      if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
        // Redirect to the dashboard
        let calculators = {...thus.state.calculators};
        calculators.settings.splice(index,1);
        calculators.display_values.splice(index,1);

        calculators.openCalculator = 0;
        const lastUpdate = thus.getCurrentDateTime();

        let displayNoCalculatorPage = false;

        if(calculators.length === 0){
          displayNoCalculatorPage = true;
        } else {
          calculators.openCalculator = 0;
        }

        thus.setState({calculators,lastUpdate,displayNoCalculatorPage});
        alert('Calculator Deleted');
				//thus.setState({similarProducts:data.data})
      } else {
        console.log('Request failed', data);
      }

      thus.getTokens();

    })
    .catch(function (error) {
      console.log('Request failed', error);
      thus.getTokens();
    });
  }

  handleSnapshot(data,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].snapshot = data;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleExpandToggle(e,index){


    let calculators = {...this.state.calculators};
    calculators.lastOpenCalculator = calculators.openCalculator;
    calculators.openCalculator = index;
	let isLoading = true;

    this.setState({calculators,isLoading},() => {
      this.saveCalculator(calculators.lastOpenCalculator);
	  this.loadDisplaySettings();
  });
  }

  handleInvestmentReturnInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].annual_investment_return = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleInvestmentReturnType(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].annual_investment_return = e.floatValue;
      calculators.settings[index].annual_investment_return = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleInvestmentReturnToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].annual_investment_return = e;
    this.setState({calculators});
  }

  handleInflationRateInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].inflation_rate = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleInflationRateType(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].inflation_rate = e.floatValue;
      calculators.settings[index].inflation_rate = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleInflationRateToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].inflation_rate = e;
    this.setState({calculators});
  }

  handleStartingWithdrawalRateInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].withdrawal_rate = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleStartingWithdrawalRateToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].withdrawal_rate = e;
    this.setState({calculators});
  }

  handleStartingWithdrawalRateType(e,index){

    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].withdrawal_rate = e.floatValue;
      calculators.settings[index].withdrawal_rate = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleStartingWithdrawalRateTypeFromBox(e,index){

    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    let value = e.target.value;

    calculators.display_values[index].withdrawal_rate = parseFloat(value);
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators, lastUpdate},()=>console.log(this.state));

  }

  handleMonthlyContributionInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].monthly_contribution = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleCurrentValueInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].current_value = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleAgeInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].age = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleAgetype(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].age = e.floatValue;
      calculators.settings[index].age = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleAgeToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].age = e;
    this.setState({calculators});
  }

  handleRetirementAgeInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].retirement_age = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleRetirementAgeToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].retirement_age = e;
    this.setState({calculators});
  }

  handleRetirementAgeType(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].retirement_age = e.floatValue;
      calculators.settings[index].retirement_age = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }


  handleAssumedRateHighInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].assumed_rate_high = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleAssumedRateHighType(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].assumed_rate_high = e.floatValue;
      calculators.settings[index].assumed_rate_high = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);

  }

  handleAssumedRateHighToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].assumed_rate_high = e;
    this.setState({calculators});
  }

  handleAssumedRateLowInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].assumed_rate_low = e;
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleAssumedRateLowType(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].assumed_rate_low = e.floatValue;
      calculators.settings[index].assumed_rate_low = e.floatValue;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleAssumedRateLowToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].assumed_rate_low = e;
    this.setState({calculators});
  }

  handleTargetTypeToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].is_specific_amount = parseInt(e.target.value);
    const lastUpdate = this.getCurrentDateTime();

    let renderCallback = (calculators.settings[index].is_specific_amount === 0) ? this.handleStartingWithdrawalFieldAdd : this.handleStartingWithdrawalFieldRemove;

    this.setState({calculators,lastUpdate}, () => renderCallback(e,index));
  }

  handleTargetInput(e,index){
    let calculators = {...this.state.calculators};
    calculators.settings[index].target = e;
    calculators.display_values[index].target = e;
    if(calculators.settings[index].is_specific_amount === 0){
      for(var i = 0 ; i< calculators.settings[index].withdrawals.length;i++){
        if(calculators.settings[index].withdrawals[i].type === 'starting_amount'){
          calculators.settings[index].withdrawals[i].value = e;
          calculators.display_values[index].withdrawals[i].value = e;
        }
      }

    }
    // const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators});
  }

  handleTargetType(e,index){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout( () => {
      calculators.display_values[index].target = e.floatValue;
      calculators.settings[index].target = e.floatValue;
      if(calculators.settings[index].is_specific_amount === 0){
        for(var i = 0 ; i< calculators.settings[index].withdrawals.length;i++){
          if(calculators.settings[index].withdrawals[i].type === 'starting_amount'){
            calculators.settings[index].withdrawals[i].value = e.floatValue;
            calculators.display_values[index].withdrawals[i].value = e.floatValue;
          }
        }
      }
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleTargetValueToggle(e,index){
    let calculators = {...this.state.calculators};
    calculators.display_values[index].target = e;
    this.setState({calculators});
  }

  handleWithdrawalInflationChange(e,index,wIndex){
    let calculators = {...this.state.calculators};
    if(  parseInt(calculators.settings[index].withdrawals[wIndex].has_inflation_applied) === 1){
      calculators.settings[index].withdrawals[wIndex].has_inflation_applied = 0;
    } else {
      calculators.settings[index].withdrawals[wIndex].has_inflation_applied = 1;
    }
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleWithdrawalTypeChange(e,index,wIndex){
    let calculators = {...this.state.calculators};
    calculators.settings[index].withdrawals[wIndex].type = e.target.value;
    if(e.target.value === 'lump_sum'){
      calculators.settings[index].withdrawals[wIndex].end_age = ''
    }
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleWithdrawalFieldInputChange(e,index,wIndex){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    let displayWithdrawals = calculators.display_values[index].withdrawals.slice();

    displayWithdrawals[wIndex][e.target.name] = e.target.value;
    calculators.display_values[index].withdrawals = displayWithdrawals;


    this.setState({calculators});
    let thus = this;
    let propertyName = e.target.name;

    this.typingTimeout = setTimeout( () => {

      let calculators = {...thus.state.calculators};

      let settingsWithdrawals = calculators.settings[index].withdrawals.slice();
      settingsWithdrawals[wIndex][propertyName] = calculators.display_values[index].withdrawals[wIndex][ propertyName];
      calculators.settings[index].withdrawals = settingsWithdrawals;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);


  }

  handleWithdrawalFieldRemove(e,index,wIndex){
    let doDelete = window.confirm('Do you wish to delete this withdrawal?');

    if(doDelete){
      let calculators = {...this.state.calculators};

      let settings = calculators.settings[index].withdrawals.slice();
      let display_values = calculators.display_values[index].withdrawals.slice();
      settings.splice(wIndex,1);
      display_values.splice(wIndex,1);


      calculators.settings[index].withdrawals.splice(wIndex,1);
      calculators.display_values[index].withdrawals.splice(wIndex,1);
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators,lastUpdate});
    }

  }

  handleWithdrawalFieldAdd(e,index){
    let withdrawalObj = {
      id:-1,
      type:'lump_sum',
      value:'',
      start_age:'',
      end_age:'',
      can_edit:1,
      has_inflation_applied:0
    }

    let calculators = {...this.state.calculators};
    let displayWithdrawals = calculators.display_values[index].withdrawals.slice()

    displayWithdrawals.push(withdrawalObj);
    let settingWithdrawals = calculators.settings[index].withdrawals.slice()
    settingWithdrawals.push(withdrawalObj);

    calculators.display_values[index].withdrawals =   displayWithdrawals
    calculators.settings[index].withdrawals = settingWithdrawals
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleStartingWithdrawalFieldAdd(e,index){


    let calculators = {...this.state.calculators};
    // let withdrawalRate = calculators.display_values[index].withdrawal_rate
    let retirementAge = calculators.display_values[index].retirement_age;
    let target = calculators.settings[index].target;
    //console.log(calculators.settings[index].target);

    let withdrawalObj = {
      id:-1,
      type:'starting_amount',
      value:target,
      start_age:retirementAge,
      end_age:100,
      can_edit:0,
      has_inflation_applied:1,
      startingWithdrawalField:true
    }

    let displayWithdrawals = calculators.display_values[index].withdrawals.slice()

    displayWithdrawals.unshift(withdrawalObj);
    let settingWithdrawals = calculators.settings[index].withdrawals.slice()
    settingWithdrawals.unshift(withdrawalObj);

    calculators.display_values[index].withdrawals =   displayWithdrawals
    calculators.settings[index].withdrawals = settingWithdrawals

    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleStartingWithdrawalFieldRemove(e,index){
    let indexToRemove = 0;

    let calculators = {...this.state.calculators};
    calculators.settings[index].withdrawals.forEach((contribution,i) => {
      if(contribution.startingWithdrawalField){
        indexToRemove = i;
      }
    })

    calculators.settings[index].withdrawals.splice(indexToRemove,1);
    calculators.display_values[index].withdrawals.splice(indexToRemove,1);

    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleContributionTypeChange(e,index,wIndex){
    let calculators = {...this.state.calculators};
    calculators.settings[index].contributions[wIndex].type = e.target.value;
    if(e.target.value === 'lump_sum'){
      calculators.settings[index].contributions[wIndex].end_age = ''
    }
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }

  handleContributionInflationChange(e,index,wIndex){
    let calculators = {...this.state.calculators};
    if(  parseInt(calculators.settings[index].contributions[wIndex].has_inflation_applied) === 1){
      calculators.settings[index].contributions[wIndex].has_inflation_applied = 0;
    } else {
      calculators.settings[index].contributions[wIndex].has_inflation_applied = 1;
    }
    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate},()=> console.log(this.state));
  }

  handleContributionFieldInputChange(e,index,wIndex){
    let calculators = {...this.state.calculators};

    clearTimeout(this.typingTimeout);

    let displayContributions = calculators.display_values[index].contributions.slice();
    displayContributions[wIndex][e.target.name] = e.target.value;
    calculators.display_values[index].contributions = displayContributions;


    this.setState({calculators});
    let thus = this;
    let propertyName = e.target.name;

    this.typingTimeout = setTimeout( () => {

      let calculators = {...thus.state.calculators};
      let settingContributions = calculators.settings[index].contributions.slice();

      settingContributions[wIndex][propertyName] = displayContributions[wIndex][ propertyName];
      calculators.settings[index].contributions = settingContributions;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators, lastUpdate});
    },1000);
  }

  handleContributionFieldRemove(e,index,wIndex){
    let doDelete = window.confirm('Do you wish to delete this contribution?');

    if(doDelete){
      let calculators = {...this.state.calculators};

      let settings = calculators.settings[index].contributions.slice();
      let display_values = calculators.display_values[index].contributions.slice();
      settings.splice(wIndex,1);
      display_values.splice(wIndex,1);

      calculators.settings[index].contributions = settings;
      calculators.display_values[index].contributions = display_values;

      const lastUpdate = this.getCurrentDateTime();
      this.setState({calculators,lastUpdate});
    }

  }

  handleContributionFieldAdd(e,index){


    let contributionObj = {
      id:-1,
      type:'lump_sum',
      value:'',
      start_age:'',
      end_age:'',
      can_edit:1,
      has_inflation_applied:0
    }

    let calculators = {...this.state.calculators};

    let displayContributions = calculators.display_values[index].contributions.slice()

    displayContributions.push(contributionObj);
    let settingContributions = calculators.settings[index].contributions.slice()
    settingContributions.push(contributionObj);

    calculators.display_values[index].contributions =   displayContributions
    calculators.settings[index].contributions = settingContributions

    const lastUpdate = this.getCurrentDateTime();
    this.setState({calculators,lastUpdate});
  }


  getCurrentDateTime(){
    let d = new Date();
    let currentDateTime = d.toString();

    return currentDateTime
  }

  toggleCurrentSavings(){
    let displayCurrentSavings = this.state.displayCurrentSavings

    const lastUpdate = this.getCurrentDateTime();
    this.setState({
      displayCurrentSavings:!displayCurrentSavings,
      lastUpdate
    })
  }

  togglePotentialValueSavings(){
    let displayPotentialValue = this.state.displayPotentialValue
    const lastUpdate = this.getCurrentDateTime();
    this.setState({
      displayPotentialValue:!displayPotentialValue,
      lastUpdate
    })
  }

  toggleOriginalForecastDisplay(){
    let displayOriginalForecast = this.state.planDisplayDetails.displayOriginalForecast;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.displayOriginalForecast = !displayOriginalForecast;

		this.setState({planDisplayDetails});
  }

  saveImageURI(imageURI){

    this.setState({
      imageURI
    });
  }

  handleCalculatorTitleEdit(e,index){

    let calculators = {...this.state.calculators};

    calculators.settings[index].title = e.target.value;
    this.setState({calculators});
  }

  toggleStartAgeCrop(e,index){

    let startAgeCrop = e.target.value;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    clearTimeout(this.typingTimeout);

    planDisplayDetails.holdingStartAgeCrop = startAgeCrop;
    this.setState({planDisplayDetails});

    this.typingTimeout = setTimeout( () => {
      planDisplayDetails.startAgeCrop = planDisplayDetails.holdingStartAgeCrop ;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({planDisplayDetails,lastUpdate},() => this.saveCalculatorDisplaySettingsAction());
    },500);


  }

  toggleEndAgeCrop(e,index){

    let endAgeCrop = e.target.value;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    clearTimeout(this.typingTimeout);

    planDisplayDetails.holdingEndAgeCrop = endAgeCrop;
    this.setState({planDisplayDetails});

    this.typingTimeout = setTimeout( () => {
      planDisplayDetails.endAgeCrop = planDisplayDetails.holdingEndAgeCrop ;
      const lastUpdate = this.getCurrentDateTime();
      this.setState({planDisplayDetails,lastUpdate},() => this.saveCalculatorDisplaySettingsAction());
    },500);

  }

  createOption(e){

    let title = '';
    let calculators = {};

    if(this.state.calculators.settings.length > 0){
      calculators = {...this.state.calculators};
      const index = calculators.settings.length ;
      const optionNo = index +  1;

      title = 'Option '+ optionNo + ': ';

      let newCalculatorSettings = {...calculators.settings[this.state.calculators.openCalculator]};
      let newCalculatorDisplayValues = {...calculators.display_values[this.state.calculators.openCalculator]};

      //deep clone settings
      newCalculatorSettings.contributions = [];
      newCalculatorSettings.withdrawals = [];

      calculators.settings[this.state.calculators.openCalculator].contributions.forEach((contribution) => {
        const clone = {...contribution};
        newCalculatorSettings.contributions.push(clone);
      })

      calculators.settings[this.state.calculators.openCalculator].withdrawals.forEach((withdrawal) => {
        const clone = {...withdrawal};
        newCalculatorSettings.withdrawals.push(clone);
      })

      //deep clone display_values
      newCalculatorDisplayValues.contributions = [];
      newCalculatorDisplayValues.withdrawals = [];

      calculators.display_values[this.state.calculators.openCalculator].contributions.forEach((contribution) => {
        const clone = {...contribution};
        newCalculatorDisplayValues.contributions.push(clone);
      })

      calculators.display_values[this.state.calculators.openCalculator].withdrawals.forEach((withdrawal) => {
        const clone = {...withdrawal};
        newCalculatorDisplayValues.withdrawals.push(clone);
      })

      newCalculatorSettings.title = title;
      newCalculatorSettings.id = -1;
      newCalculatorSettings.instance_id = -1;

      newCalculatorDisplayValues.id = -1;
      newCalculatorDisplayValues.instance_id = -1;

      calculators.display_values.push(newCalculatorDisplayValues);
      calculators.settings.push(newCalculatorSettings);

      calculators.openCalculator= calculators.settings.length -1;

    } else {
      let newDisplayValueObject = {
        age:this.state.customer_age,
        annual_investment_return:0,
        assumed_rate_high:0,
        assumed_rate_low:0,
        inflation_rate:0,
        instance_id:-1,
        id:-1,
        is_specific_amount:1,
        retirement_age:65,
        target:100000,
        contributions:this.state.existing_contributions,
        withdrawals:this.state.existing_withdrawals,
        withdrawal_rate:0,

      };

    title = 'Option 1: ';

    let newSettingsObject = {
      age:this.state.customer_age,
      annual_investment_return:0,
      assumed_rate_high:0,
      assumed_rate_low:0,
      inflation_rate:0,
      instance_id:-1,
      id:-1,
      is_specific_amount:1,
      retirement_age:65,
      target:100000,
      contributions:this.state.existing_contributions,
      withdrawals:this.state.existing_withdrawals,
      withdrawal_rate:0,
      title:title,
      snapshot:{},

    };

    calculators = {...this.state.calculators};
    calculators.settings.push(newSettingsObject);
    calculators.display_values.push(newDisplayValueObject);
    calculators.lastOpenCalculator = 0;
  }
  let displayNoCalculatorPage = false;

  this.setState({calculators,displayNoCalculatorPage},() => {
    this.saveCalculator(calculators.lastOpenCalculator)
  });

}

  toggleContributionDisplay(){

    let showContributions = this.state.planDisplayDetails.showContributions;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.showContributions = !showContributions;

		this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());
  }

  toggleWithdrawalDisplay(){

    let showWithdrawals = this.state.planDisplayDetails.showWithdrawals;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.showWithdrawals = !showWithdrawals;

		this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());
  }


  toggleExpectedReturnDisplay(){

    let showExpectedReturn = this.state.planDisplayDetails.showExpectedReturn;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.showExpectedReturn = !showExpectedReturn;

		this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());

  }

  toggleUpperReturnDisplay(){

    let showUpperReturn = this.state.planDisplayDetails.showUpperReturn;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.showUpperReturn = !showUpperReturn;

		this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());

  }

  toggleLowerReturnDisplay(){

    let showLowerReturn = this.state.planDisplayDetails.showLowerReturn;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.showLowerReturn = !showLowerReturn;

		this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());
  }

  toggleTargetDisplay(){

    let showTarget = this.state.planDisplayDetails.showTarget;
    let planDisplayDetails = {...this.state.planDisplayDetails};

    planDisplayDetails.showTarget = !showTarget;

	this.setState({planDisplayDetails},() => this.saveCalculatorDisplaySettingsAction());

  }

  toggleAgendaMinimised(agendaMinimised){
    let newGraphKey = this.state.graphKey;
    newGraphKey++;

    this.setState({
      agendaMinimised: agendaMinimised,
      graphKey:newGraphKey

    });
  }


  render() {

    const openCalculator = this.state.calculators.openCalculator;
    const handleExpandToggle = this.handleExpandToggle;
    const calculators = this.state.calculators.settings;
    const displayValues = this.state.calculators.display_values;
    const savings = this.state.savings;
    const createOption = this.createOption;

    let calculatorInputCallbacks = {};

    calculatorInputCallbacks.handleInvestmentReturnInput = this.handleInvestmentReturnInput;
    calculatorInputCallbacks.handleInflationRateInput = this.handleInflationRateInput;
    calculatorInputCallbacks.handleStartingWithdrawalRateInput = this.handleStartingWithdrawalRateInput;
    calculatorInputCallbacks.handleMonthlyContributionInput = this.handleMonthlyContributionInput;
    calculatorInputCallbacks.handleCurrentValueInput = this.handleCurrentValueInput;
    calculatorInputCallbacks.handleAgeInput = this.handleAgeInput;
    calculatorInputCallbacks.handleRetirementAgeInput = this.handleRetirementAgeInput;
    calculatorInputCallbacks.handleTargetInput = this.handleTargetInput;
    calculatorInputCallbacks.handleTargetTypeToggle = this.handleTargetTypeToggle;
    calculatorInputCallbacks.handleAssumedRateHighInput =this.handleAssumedRateHighInput;
    calculatorInputCallbacks.handleAssumedRateLowInput =this.handleAssumedRateLowInput;

    calculatorInputCallbacks.handleTargetValueToggle = this.handleTargetValueToggle;
    calculatorInputCallbacks.handleInvestmentReturnToggle = this.handleInvestmentReturnToggle;
    calculatorInputCallbacks.handleInflationRateToggle = this.handleInflationRateToggle;
    calculatorInputCallbacks.handleStartingWithdrawalRateToggle= this.handleStartingWithdrawalRateToggle;
    calculatorInputCallbacks.handleAgeToggle = this.handleAgeToggle;
    calculatorInputCallbacks.handleRetirementAgeToggle = this.handleRetirementAgeToggle;
    calculatorInputCallbacks.handleAssumedRateHighToggle =this.handleAssumedRateHighToggle;
    calculatorInputCallbacks.handleAssumedRateLowToggle =this.handleAssumedRateLowToggle;

    calculatorInputCallbacks.handleInvestmentReturnType = this.handleInvestmentReturnType;
    calculatorInputCallbacks.handleAssumedRateLowType = this.handleAssumedRateLowType;
    calculatorInputCallbacks.handleAssumedRateHighType = this.handleAssumedRateHighType;
    calculatorInputCallbacks.handleInflationRateType = this.handleInflationRateType;
    calculatorInputCallbacks.handleAgetype = this.handleAgetype;
    calculatorInputCallbacks.handleRetirementAgeType = this.handleRetirementAgeType;
    calculatorInputCallbacks.handleStartingWithdrawalRateType = this.handleStartingWithdrawalRateType;
    calculatorInputCallbacks.handleTargetType = this.handleTargetType;
    calculatorInputCallbacks.handleStartingWithdrawalRateTypeFromBox = this.handleStartingWithdrawalRateTypeFromBox;

    calculatorInputCallbacks.withdrawals = {};
    calculatorInputCallbacks.withdrawals.handleWithdrawalTypeChange = this.handleWithdrawalTypeChange;
    calculatorInputCallbacks.withdrawals.handleWithdrawalFieldInputChange= this.handleWithdrawalFieldInputChange;
    calculatorInputCallbacks.withdrawals.handleWithdrawalFieldRemove= this.handleWithdrawalFieldRemove ;
    calculatorInputCallbacks.withdrawals.handleWithdrawalFieldAdd= this.handleWithdrawalFieldAdd ;
    calculatorInputCallbacks.withdrawals.handleWithdrawalInflationChange= this.handleWithdrawalInflationChange;

    calculatorInputCallbacks.contributions = {};
    calculatorInputCallbacks.contributions.handleContributionTypeChange = this.handleContributionTypeChange;
    calculatorInputCallbacks.contributions.handleContributionFieldInputChange= this.handleContributionFieldInputChange;
    calculatorInputCallbacks.contributions.handleContributionFieldRemove= this.handleContributionFieldRemove ;
    calculatorInputCallbacks.contributions.handleContributionFieldAdd= this.handleContributionFieldAdd ;
    calculatorInputCallbacks.contributions.handleContributionInflationChange= this.handleContributionInflationChange ;


    const saveCalculator = this.saveCalculator;
    const softDeleteCalculator = this.softDeleteCalculator;
    const handleSnapshot = this.handleSnapshot;
    const handleCalculatorTitleEdit = this.handleCalculatorTitleEdit;
    const downloadAsPDF = this.downloadAsPDF;
    const handleGlobalInflationToggle = this.handleGlobalInflationToggle;

    const toggleCurrentSavings = this.toggleCurrentSavings;
    const togglePotentialValueSavings = this.togglePotentialValueSavings;
    const toggleOriginalForecastDisplay = this.toggleOriginalForecastDisplay
    const lastUpdate = this.state.lastUpdate;


    const customerAge = this.state.customer_age;
    const saveImageURI = this.saveImageURI;

    const exitFullscreen = this.exitFullscreen;
    const triggerFullScreen = this.triggerFullScreen;
    const isFullScreen = this.state.isFullScreen;
    const hasGlobalInflationSet = this.state.enable_inflation;
	const dobFound = this.state.dob_found;

    let graph;
    let sideBar;

    if(savings.length === 0){
      savings[customerAge] = 0;
    }
    sideBar = (
      <SideBar
        openCalculator={openCalculator}
        createOption={createOption}
        handleExpandToggle={handleExpandToggle}
        calculators={calculators}
        displayValues ={displayValues}
        calculatorInputCallbacks={calculatorInputCallbacks}
        saveCalculator={saveCalculator}
        softDeleteCalculator={softDeleteCalculator}
        customerAge = {customerAge}
        handleCalculatorTitleEdit={handleCalculatorTitleEdit}
        downloadAsPDF={downloadAsPDF}
        isFullScreen={isFullScreen}
        custRef={this.props.match.params.custRef}
		handleNotesChange={ this.handleNotesChange }
        hasGlobalInflationSet={hasGlobalInflationSet}
        savings={savings}
		dobFound={dobFound}
      />
    );

    if (calculators.length === 0 ) {
      graph = 'Please create a new Calculator to display the graph';
	} else if(this.state.isLoading){
      graph = <div></div>
	}else {

      graph = (
        <Graph
          toggleStartAgeCrop={this.toggleStartAgeCrop}
          toggleEndAgeCrop={this.toggleEndAgeCrop}
          openCalculator={openCalculator}
          saveCalculator={saveCalculator}
          calculators={calculators}
          toggleCurrentSavings={toggleCurrentSavings}
          togglePotentialValueSavings={togglePotentialValueSavings}
          toggleOriginalForecastDisplay={toggleOriginalForecastDisplay}
          lastUpdate={lastUpdate}
          savings={savings}
          handleSnapshot={handleSnapshot}
          saveImageURI={saveImageURI}
          custRef={this.props.match.params.custRef}
          exitFullscreen={exitFullscreen}
          triggerFullScreen={triggerFullScreen}
          isFullScreen={isFullScreen}
          key={this.state.graphKey}
          handleGlobalInflationToggle={handleGlobalInflationToggle}
          toggleWithdrawalDisplay ={this.toggleWithdrawalDisplay}
          toggleContributionDisplay ={this.toggleContributionDisplay}
          toggleExpectedReturnDisplay={this.toggleExpectedReturnDisplay}
          toggleUpperReturnDisplay={this.toggleUpperReturnDisplay}
          toggleLowerReturnDisplay={this.toggleLowerReturnDisplay}
          toggleTargetDisplay = {this.toggleTargetDisplay}
          graphToggle={this.state.graphToggle}
          handleGraphToggle={this.handleGraphToggle}
          planDisplayDetails={this.state.planDisplayDetails}
		  generateValuationSummary={this.generateValuationSummary}
		  isGeneratingValuationSummary={this.state.isGeneratingValuationSummary}
        />);
    }

    // let marginLeft = (this.state.agendaMinimised) ? 0 : '200px'
    // let nudgeCSS = (this.state.agendaMinimised) ? {}: {position:'absolute',left:'750px'}

    let expandedClass = (this.state.agendaMinimised) ?  '' : 'expanded-agenda'

    return (
      <Fragment>

      <AgendaView custRef = {this.props.match.params.custRef} fixedLayout={true} notifyParentExpandedState={this.toggleAgendaMinimised} />
      <div style={{marginLeft:'50px'}} className={expandedClass + ' planning-content'}>

        <div className='width-3'>
          { sideBar }
        </div>
        <div  className='width-9'>
          { graph }

        </div>
      </div>

      </Fragment>
    );
  }
}



function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(Planning);
