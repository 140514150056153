
// Check the status of a fetch request
const Fetching = {
  statusCheck: function (response) {
    if ((response.status >= 200 && response.status < 300) || (response.status === 0 && response.type === "opaque")) {
      return Promise.resolve(response)
    } else {
      return Promise.reject()
    }
  },

  // Extract JSOn from fetch stream
  jsonExtract: function (response) {
    return response.json()
  }

}

export default Fetching;
