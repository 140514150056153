import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';

import menuIcon from './../../../assets/img/menu-icon.svg';

class SxnList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sxns: [],
      isLoading: false,
      menuStatus: "closed"
    };

    this.getSxns = this.getSxns.bind(this);
  }

  getSxns (current) {

    // Get the sections
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_sections/admin_list/', { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.msg != 'undefined') {
        this.setState({ rdr: true });
      } else {
        if (current === false) {
          var k = 0;
          for (var i in data) {
            data[i].isCurrent = (k === 0)?true:false;
            k++;
          }
        } else {
          for (var j in data) {
            data[j].isCurrent = (current === data[j].slug)?true:false;
          }
        }
        thus.setState({ sxns: data, isLoading: false });
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  toggleMenu ( e, shutIt ) {
    if (e !== 1) {
      e.preventDefault();
    }
    var { menuStatus } = this.state;
    if (typeof shutIt == 'undefined') {
      menuStatus = (menuStatus === 'open')?'closed':'open';
    } else {
      menuStatus = shutIt;
    }
    this.setState({ menuStatus: menuStatus });
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {
    // Check whether a section is selected
    var current = false;
    if (typeof this.props.curSxn != 'undefined') {
      current = this.props.curSxn;
    }
    this.getSxns(current);
  }

  componentWillReceiveProps(nextProps){
    // Check whether a section is selected
    var current = false;
    if (typeof nextProps.curSxn != 'undefined') {
      current = nextProps.curSxn;
    }
    this.getSxns(current);
    this.toggleMenu(1,"closed");
  }

  render() {

    const { sxns, isLoading, menuStatus } = this.state;

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if (!isLoading) {

      content = <ul style={ styles.bigMTop } className={ 'tabs ' + menuStatus }>
                  <li className="switch"><a href="#open" className={ "sxnsMenu " + menuStatus } style={ styles.menu } onClick={ (e) => this.toggleMenu(e) }>Menu</a></li>
                  { sxns.map( sxn =>
                    <li key={ sxn.id } style={ styles.li }>
                      { sxn.isCurrent ? (
                        <Link style={{ ...styles.tab, ...styles.active }} to={ `/admin/questions/${ sxn.slug }` } onClick={this.handleChangeLocal}>{ sxn.title }</Link>
                      ) : (
                        <Link style={ styles.tab } to={ `/admin/questions/${ sxn.slug }` } onClick={this.handleChangeLocal}>{ sxn.title }</Link>
                      )}
                    </li>
                  )}
                </ul>;

    }

    return (
      <div className="width-3">
        { content }
      </div>
    );
  }
}

const styles = {
  bigMTop: {
    marginTop: 75
  },
  li:{
    listStyle: 'none',
    marginTop: 5
  },
  tab: {
    display: 'block',
    border: '1px solid ' + Colours.txtGrey,
    padding: '8px 15px',
    boxSizing: 'border-box',
    color: Colours.txtGrey,
    textDecoration: 'none',
    fontSize: 18,
    background: Colours.bgGrey
  },
  active: {
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
  },
  menu: {
    background: Colours.grn + " url(" + menuIcon + ") center right 20px / 16px auto no-repeat"
  }
};

export default SxnList;
