import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Heading from './../heading.js';
import FlashMsg from './../../common/flash-msg.js';

import Fetching from './../../../functions/fetching.js';
import Colours from './../../../assets/colours.js';
import Paginate from './../../common/paginate.js';

class UserList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      usrs: [],
      isLoading: true,
			ccLoginUrl: "",
			ssoFlash: ""
    };
  }

  initialiseComponent (pageNo) {
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/users?page=' + pageNo, { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          // Check whether we have a failure
					thus.populateCcLogin();
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {
            // Process the data for display of the users
            thus.setState({ usrs: data, isLoading: false });
            return data;
          }
				}
      );
		this.checkSsoFlash(this.props);
  }

  componentWillReceiveProps(nextProps){
     //call your api and update state with new props
     this.initialiseComponent(nextProps.match.params.pageNo);
		 this.checkSsoFlash(nextProps);
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {

    // Check for pagination
    var pageNo = this.props.match.params.pageNo;

    if (typeof pageNo == 'undefined') {
      pageNo = 1;
    }
    this.initialiseComponent(pageNo);

  }

	populateCcLogin () {
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/sso/get_rdr_url/cashcalc', { credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          // Check whether we have a failure
          if (typeof data.rslt != 'undefined' && data.rslt === "success") {

            // Redirect to the error page
            thus.setState({ ccLoginUrl: data.rdrUrl });

          }
        }
      );
	}

	checkSsoFlash () {
		var paramFlash = this.props.match.params.ssoStatus;
		if (typeof paramFlash != "undefined") {
			this.setState({ ssoFlash: paramFlash });
		}
	}

  // Render the view
  render() {

    const { usrs, isLoading, ssoFlash } = this.state;

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if (!isLoading) {

      content = <div>
                  <table>
                    <thead>
                      <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th className="hide3">Email</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      { usrs.usrs.map( usr =>
                        <tr key={ usr.id } className={ usr.is_archived === "1" ? "faded":"" }>
                          <td>{ usr.first_name }</td>
                          <td>{ usr.last_name }</td>
                          <td className="hide3">{ usr.email }</td>
                          <td>
                            <Link style={ styles.lnk } to={ `/admin/users/edit/` + usr.id }>Edit</Link>
                            <Link style={ styles.lnk } to={ `/admin/users/archive/` + usr.id }>{ usr.is_archived === "1" ? "Unarchive":"Archive" }</Link>
                            <Link style={ styles.lnk } to={ `/admin/users/delete/` + usr.id }>Delete</Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Paginate urlBase="/admin/users/page/" totalPages={ usrs.totalPages } currentPage={ usrs.currentPage }  nextPage={ usrs.nextPage } prevPage={ usrs.prevPage } />
                </div>
    }

    return (
      <div className='width-9 centred'>

				{/* { (ccLoginUrl && ccLoginUrl !== "") ? (
					<a style={{ ...styles.lnkRght, ...styles.lnkGrey }} href={ ccLoginUrl }>Link My CashCalc Account</a>
				) : "" } */}
        <Link style={ styles.lnkRght } to={ `/admin/users/create` }>Create Admin</Link>
        <Heading mainTitle="Manage Site Admins" subTitle="View, create and edit the admin users" />

				{ (ssoFlash === "success") ? (
					<div className='success mbtm' style={ styles.smlr }>Your CashCalc account has been successfully linked.</div>
				) : "" }

				{ (ssoFlash === "fail") ? (
					<div className='error mbtm' style={ styles.smlr }>Sorry but there was an error linking your account. Please try again.</div>
				) : "" }

				<FlashMsg />

        { content }

      </div>
    );
  }
}

var styles = {
  lnk: {
    color: Colours.txtBlack,
    display: 'inline-block',
    marginLeft: 5
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
	lnkGrey: {
		background: Colours.lightGrey,
		color: Colours.txtBlack,
		border: "1px solid " + Colours.lightGrey
	},
	smlr: {
    width: '99%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
  }
};

export default UserList;
