import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Fetching from './../../../functions/fetching.js';

import Btn from './../../common/btn.js';

class CustomerContent extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      content: "",
			custRef: this.props.match.params.custRef,
			contentType: this.props.match.params.type,
			error: "",
			success: ""
    };

		this.loadContent = this.loadContent.bind(this);
		this.saveContent = this.saveContent.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  componentWillMount () {
    this.loadContent();
    this.getTokens();
  }

  // Update state based on the content
  handleEditorChange (content) {
    this.setState({ content: content, error: "", success: "" });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // On bur of the text editor save the notes content to the database
  saveContent (  ) {

    let { content, custRef, contentType } = this.state;

    let saveData = {
      content: content
    };

    let thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/admin/contents/create/' + contentType + '/' + custRef, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if (typeof data.rslt != "undefined" && data.rslt === 'success') {
        console.log('Request succeeded with JSON response', data);
				thus.setState({ success: "The content has been saved successfully.", error: "" });
      } else {
				thus.setState({ error: "There was an error updating the Services Provided content. Please check the content and try again.", success: "" });
      }
			thus.getTokens();
    })
    .catch(function (error) {
      thus.getTokens();
			thus.setState({ error: "Sorry but there was an error updating the Services Provided content. Please check the content and try again.", success: "" });
      console.log('Request failed', error);
    });

  }

  // Load up the agenda content
  loadContent () {

		let { custRef, contentType } = this.state;

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/contents/return/' + contentType + "/" + custRef;
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          if (typeof data.content != "undefined" && data.content !== false) {
            thus.setState({
              content: data.content
            });
          }

        }
      );

  }


  render() {

    const { content, error, success } = this.state;

		var errContent = "";
		if (error) {
			errContent = <div className='error mbtm' style={ styles.smlr }>{ error }</div>
		}

		if (success) {
			errContent = <div className='success mbtm' style={ styles.smlr }>{ success }</div>
		}

    return (
      <div className="width-8 centred lrg-txt" onBlur={this.saveUpdate}>
        <h1>Manage Content</h1>
				<h2>Add content for the &quot;Services Provided&quot; page of the Progress Review</h2>

				{ errContent }

        <ReactQuill value={ content } onChange={ this.handleEditorChange } onBlur={this.saveUpdate} modules={ modules } />

				<Btn
					handleSubmission={ this.saveContent }
					title="Save content"
					type="button"
				/>
			</div>
    );

  }
}

const styles = {
	smlr: {
		width: '99%',
		marginLeft: '0.5%',
		marginRight: '0.5%',
	}
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'strike'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
  ],
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(CustomerContent);
