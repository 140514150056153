import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import mail from './../../../assets/img/mail.png';
import meeting from './../../../assets/img/meeting.png';
import phone from './../../../assets/img/phone.png';
import phoneInbound from './../../../assets/img/phone-inbound.png';
import phoneOutbound from './../../../assets/img/phone-outbound.png';
import phoneVoicemail from './../../../assets/img/phone-voicemail.png';

import Paginate from './../../common/paginate.js';

class EmailList extends Component {

  render() {

		if (this.props.emailsLoading) {
			return (
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
		}

		if (this.props.emails.length === 0) {
			return (
				<div style={ styles.mbtm }>
					<p style={ styles.centre }>Sorry but there are no communications to display at the moment.</p>
				</div>
			);
		}

		let emailList = null;

		emailList=  this.props.emails.map ( (email,index) => {

			let link = '';
			switch(email.type){
				case 'call':
				case 'call-inbound':
				case 'call-outbound':
				case 'call-voicemail':
					link = '/admin/record_call/compose/'+ email.id;
					break;
				case 'meeting-notes':
					link = '/admin/meeting_notes/compose/'+ email.id;
					break;
				case 'draft':
					link = '/admin/email/compose/' + email.id;
					break;
				default:
					link = '/admin/email/details/'+ email.id;
					break;
							
			}

			let emailType = 'Send Item';
			let bgColorClass = 'comm-row white';
			let imgIcon = <img  style={styles.icon } src={mail} alt="Email" />

			if(email.type === 'call'){
				emailType = 'Telephone Call';
				bgColorClass = 'comm-row comm-row-green';
				imgIcon = <img style={styles.icon } src={phone} alt="Phone" />
			} else if (email.type === "call-inbound"){
				emailType = 'Telephone Call - Inbound';
				bgColorClass = 'comm-row comm-row-green';
				imgIcon = <img style={styles.icon } src={phoneInbound} alt="Phone Inbound" />

			} else if (email.type === "call-outbound"){
				emailType = 'Telephone Call - Outbound';
				bgColorClass = 'comm-row comm-row-green';
				imgIcon = <img style={styles.icon } src={phoneOutbound} alt="Phone Outbound" />
			} else if (email.type === "call-voicemail"){
				emailType = 'Telephone Call - Voicemail';
				bgColorClass = 'comm-row comm-row-green';
				imgIcon = <img style={styles.icon } src={phoneVoicemail} alt="Phone Voicemail" />
			} else if (email.type === "meeting-notes"){
				emailType = "Meeting Notes";
				bgColorClass = 'comm-row comm-row-grey';
				imgIcon = <img style={styles.icon } src={meeting} alt="Meeting" />
			} else if (email.type === "inbox" || email.type === "draft") {
				emailType = email.type.charAt(0).toUpperCase() + email.type.slice(1).replace('-',' ')
			}

			return (<tr key={ "email-" + email.id } className={bgColorClass}>
				<td style={{padding:'0 12px'}}>{imgIcon}</td>
				<td >
					
					<h6>
						<Link to={link }>{ email.subject }</Link>
					</h6>
					<p>{ email.bodyPreview }</p>
				</td>
				<td className='minor hide3'>
					{ email.usr }
				</td>
				<td className='minor hide2'>
					{ emailType}
				</td>
				<td className='minor hide2'>
					{ email.date }<br />
					{ email.time }
				</td>
				<td className='minor'>
					<Link to={link }>View/Edit</Link>
				</td>
				<td className='minor hide3'>
					<button style={styles.btn} onClick={(e) => this.props.toggleArchiveEmail(index)}>
						{!email.is_archived ? 'Archive' : 'Unarchive'}
					</button>

				</td>
			</tr>);
			}
		)

    return (
			<div style={ styles.mbtm }>

				<table className="emails">
					<thead>
						<tr>
							<th></th>
							<th>Message</th>
							<th className="hide3">User Account</th>
							<th className="hide2">Communication Type</th>
							<th className="hide2">Date</th>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{emailList}
						

					</tbody>
				</table>

				<Paginate
					urlBase={ "/admin/emails/" }
					{ ...this.props }
				/>

			</div>
    );
  }
}

const styles = {
	mbtm: {
		marginBottom: 65,
		overflow: 'auto'
	},
	centre: {
		textAlign: 'center',
		fontStyle: 'italic'
	},
	icon:{
		height:'36px',
		width:'36px',
		float:'left'
	},
	btn:{
		marginLeft:'auto',
		marginRight: 'auto',
		display: 'block',
		background: 'rgb(78, 144, 67)',
		color: 'rgb(255, 255, 255)',
		maxWidth: '200px',
		textAlign: 'center',
		padding: '5px 22px',
		boxSizing: 'border-box',
		textDecoration: 'none',
		// fontWeight: '400',
		fontSize: '12px',
		border: '0px',
		cursor: 'pointer',
		outline: 'none',
		boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 5px',
		borderRadius: '20px',
		marginTop:0
	}
}

export default EmailList;
