import React, { Component } from 'react';

import TimeMachine from './time-machine.js';
import Heading from './../heading.js';
import Tab from './../../questionnaire/tab.js';
import TabContent from './../../questionnaire/tab-content.js';
import CustomFieldData from './custom-fields.js';
import Files from './../files/files.js';
import EmailGroupsPanel from './email-groups-panel.js';

import Colours from './../../../assets/colours.js';
import menuIcon from './../../../assets/img/menu-icon.svg';

class CustomerDetails extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      custRef: this.props.match.params.custRef,
      currentSxn: "",
      mindmapSxns: [],
      sxns: [],
      error: false,
      isLoading: true,
      firstSxn: '',
      menuStatus: "closed",
      tabsZClass: "",
      timeMachine: "",
      timeMachineTime: "",
      confirmedTimeMachine: "",
      fileUpdate: false,
      reloadTab: false,
      pdfGenerating:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.hideTabs = this.hideTabs.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.confirmTimeMachine = this.confirmTimeMachine.bind(this);
	  this.clearTimeMachine = this.clearTimeMachine.bind(this);
    this.updateFiles = this.updateFiles.bind(this);
    this.reloadTab = this.reloadTab.bind(this);
    this.tabLoaded = this.tabLoaded.bind(this);
    this.togglePDFGeneration= this.togglePDFGeneration.bind(this)
  }

  // Trigger the reloading of the tab content
  reloadTab () {
    this.setState({ reloadTab: true });
  }
  tabLoaded () {
    this.setState({ reloadTab: false });
  }
  
  // Check the tokens
  loadContent () {

    // Get the sxnSlug
    let sxnSlug = (typeof this.props.match.params.sxnSlug != 'undefined')?this.props.match.params.sxnSlug:"personal-details";
    this.setState({ currentSxn: sxnSlug });
// console.log(sxnSlug);
    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/questionnaire_sections/admin_list/';
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {
            // Process the data for display of the tabs
            var firstRequired = '';
            var mindmapSxns = [];
            for (var i in data) {
              data[i].activeState = (data[i].slug === sxnSlug)?'active':'';
              data[i].disabledClass = '';
              if (data[i].isRequired !== 0 && firstRequired === '') {
                firstRequired = data[i].slug;
              }
              if (data[i].canMindmap === "1") {
                mindmapSxns.push(data[i].slug);
              }
            }

            // Final check to make sure there is a section included
            if (firstRequired === '') {
              firstRequired = 'personal-details';
            }

            thus.setState({ sxns: data, isLoading: false, firstSxn: firstRequired, currentSxn: sxnSlug, mindmapSxns: mindmapSxns });
            return data;
          }
        }
      );

  }

  updateTime (e) {

    var time = e.target.value;
    this.setState({ timeMachineTime: time });

  }

  updateDate (date) {

    this.setState({ timeMachine: date });

  }

	updateFiles () {
		var newVal = Math.round(Math.random() * 1000) + 1;
		this.setState({ fileUpdate: newVal ,pdfGenerating:false});
	}

	togglePDFGeneration(){
		this.setState({
			pdfGenerating:true
		})
	}

  confirmTimeMachine ( e ) {

    e.preventDefault();

    let { timeMachine, timeMachineTime } = this.state;

    if (timeMachine !== "") {

      if (timeMachineTime === "") timeMachineTime = "00:00";

      var unixTime = timeMachine + ' ' + timeMachineTime;

      unixTime = new Date(unixTime).getTime() / 1000;

      this.setState({ confirmedTimeMachine: unixTime });

    } else {
      alert('Please select a date before activating the time machine.');
    }
  }

  clearTimeMachine ( e ) {

    e.preventDefault();

    let timeMachine = "";
    this.setState({ timeMachine: timeMachine, confirmedTimeMachine: timeMachine });

  }

  // componentDidMount - used to trigger the data fetch
  componentWillMount() {
    this.loadContent();
  }

  // Handle the display of the correct tab as active
  handleChange ( chosenTab ) {

    // console.log(chosenTab);
    const { sxns } = this.state;
    for (var i in sxns) {
      if ( sxns[i].slug === chosenTab ) {
        sxns[i].activeState = 'active';
      } else {
        sxns[i].activeState = '';
      }
    }
    this.setState({ sxns: sxns, isLoading: false, currentSxn: chosenTab });
    this.toggleMenu(1, "closed");

  }

  toggleMenu ( e, shutIt ) {
    if (e !== 1) {
      e.preventDefault();
    }
    var { menuStatus } = this.state;
    if (typeof shutIt == 'undefined') {
      menuStatus = (menuStatus === 'open')?'closed':'open';
    } else {
      menuStatus = shutIt;
    }
    this.setState({ menuStatus: menuStatus });
  }

  // Shunt the tabs back a bit
  hideTabs ( isHide ) {
    if (isHide === true) {
      this.setState({ tabsZClass: "knocked-back" });
    } else {
      this.setState({ tabsZClass: "" });
    }
  }


  render() {

    const { sxns, isLoading, custRef, currentSxn, menuStatus, tabsZClass, confirmedTimeMachine, fileUpdate, reloadTab } = this.state;

    if (isLoading) {
      return (
        <div className='content'>
          <div className='width-1 centred'>
            <div className="loader">Loading...</div>
          </div>
        </div>
      );
    }

    return (
      <div style={ styles.coreContent } className="usr-details">

        <TimeMachine
					updateDate={ this.updateDate }
					updateTime={ this.updateTime }
					updateFiles={ (e) => this.updateFiles(e) }
					pdfGenerating={this.state.pdfGenerating}
					togglePDFGeneration={this.togglePDFGeneration}
					confirmTimeMachine={ this.confirmTimeMachine }
					clearTimeMachine={ this.clearTimeMachine }
          custRef={ custRef }
          loadTab={ (e) => this.reloadTab(e) }
				/>

        <Heading mainTitle="Manage Client Details" subTitle={ this.props.initName } hClass="width-12" />

        <CustomFieldData custRef={ custRef } custName={ this.props.initName } />

        <Files custRef={ custRef } title="Client Files" genLnk={ true } lastFileUpdate={ fileUpdate } align='right' />

        <EmailGroupsPanel custRef={ custRef } custName={this.props.initName  } />

        <div className={ 'width-3 clear menu-wrapper ' + tabsZClass } style={ styles.container }>
          <ul className={ 'tabs ' + menuStatus }>
            <li className="switch"><a href="#open" className={ "sxnsMenu " + menuStatus } style={ styles.menu } onClick={ (e) => this.toggleMenu(e) }>Menu</a></li>
            { sxns.map(sxn =>
              <Tab
                key={ sxn.slug }
                title={ sxn.title }
                lnkBase={ "/admin/clients/details/" + custRef + "/" }
                lnk={ sxn.slug }
                handleChange={ this.handleChange }
                activeClass={ sxn.activeState }
                disabledClass={sxn.disabledClass}
                isHidden={ sxn.isHidden }
              />
            )}
          </ul>
        </div>

        <TabContent 
          sxnSlug={ currentSxn } 
          hideTabs={ this.hideTabs } 
          timeMachine={ confirmedTimeMachine } 
          isAdmin={ true } 
          custRef={ custRef } 
          canMindmap={ true }
          reloadTab={reloadTab}
          tabLoaded={ this.tabLoaded }
        />
      </div>
    );
  }
}

var styles = {
  container: {
    marginTop: 35,
    zIndex: 2,
    position: 'relative',
    marginBottom:70
  },
  coreContent: {
    marginBottom: 75
  },
  menu: {
    background: Colours.grn + " url(" + menuIcon + ") center right 20px / 16px auto no-repeat"
  }
};

export default CustomerDetails;
