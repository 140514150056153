import React, { Component } from 'react';

class Heading extends Component {
  render() {

    if (typeof this.props.hClass != "undefined") {
      return (
        <div className={ this.props.hClass }>
          <h1>{ this.props.mainTitle }</h1>
          <h2>{ this.props.subTitle }</h2>
        </div>
      );
    } else {
      return (
        <div style={ styles.fw }>
          <h1>{ this.props.mainTitle }</h1>
          <h2>{ this.props.subTitle }</h2>
        </div>
      );
    }
  }
}

const styles = {
  // fw: {
  //   width: '98%',
  //   margin: "auto 1%"
  // }
};

export default Heading;
