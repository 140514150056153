import React, { Component } from 'react';
import CommentBubble from './comment-bubble';
import Fetching from '../../../functions/fetching';
import { connect } from 'react-redux';

class CommentGroup extends Component {

	constructor(props){
		super(props);
		this.state = {
			comments:[],
			user:{},
			newCommentContent:''
		}
		this.getComments = this.getComments.bind(this);
		this.saveComment = this.saveComment.bind(this);
		this.getTokens = this.getTokens.bind(this);
		this.updateCommentField = this.updateCommentField.bind(this);
	}

	componentDidMount(){
		this.getComments();
		this.getTokens();
	}

	getComments () {
		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/user_emails/comments/'+this.props.communicationId;
		var thus = this;
		fetch(fetchUrl, { credentials:"include" })
			.then(response => response.json())
			.then(
				function (data) {

					if (typeof data.rslt != "undefined" && data.rslt === 'success') {
						thus.setState({
							comments: data.data,
							user:data.user
						});
					}

					thus.getTokens();

				}
			);
	}

	updateCommentField(e){
		this.setState(
			this.setState({newCommentContent: e.target.value})
		)
	}

	saveComment(e){
		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/user_emails/save_comment/'+this.props.communicationId;
		var thus = this;

		let data = {
			'comment':this.state.newCommentContent
		};
		fetch(fetchUrl, { 
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body: 'data=' + JSON.stringify(data) + '&' + this.props.tokenKey + '=' + this.props.token
		 }).then(response => response.json())
			.then(
				function (data) {

					if (typeof data.rslt != "undefined" && data.rslt === 'success') {
						thus.setState({newCommentContent:''})
						thus.getComments();
						thus.getTokens();
					}

				}
			);
	}

	getTokens () {
		fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
		  .then(Fetching.statusCheck)
		  .then(Fetching.jsonExtract)
		  .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
		  .catch(function (error) {
			console.log('Request failed', error);
		  });
	}

	render(){

		let comments  =  this.state.comments;

		let commentsSet = comments.map((comment,i) => {
			return <CommentBubble key={'comment-'+i} {...comment} />
		})


		return (
			<div className='bubble-comment-group'>
				<h2>Comments / Additional notes</h2>
				{commentsSet}
				<CommentBubble 
					isNew={true} 
					user={this.state.user} 
					saveComment={this.saveComment} 
					updateCommentField={this.updateCommentField}
					newCommentContent={this.state.newCommentContent}
				/>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
	  token: state.token,
	  tokenKey: state.tokenKey,
	};
  }
  
  export default connect(mapStateToProps)(CommentGroup);
  
