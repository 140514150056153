import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom'

import Colours from '../../assets/colours.js';
import Fetching from '../../functions/fetching.js';
import removeIcon from './../../assets/img/remove-btn.png'
import FpiTemplates from './../admin/questionnaire/fpi-templates.js'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import pound from './../../assets/img/pound.svg'

class FullPageImg extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      isDeleted: false,
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
      fpiId: (typeof this.props.response.fpiId != "undefined") ? this.props.response.fpiId : false,
      selectedDoc: (typeof this.props.response.document_type != "undefined") ? this.props.response.document_type : "pr",
      selectedSxn: (typeof this.props.response.position_after != "undefined") ? this.props.response.position_after : "",
      title: (typeof this.props.response.title != "undefined") ? this.props.response.title : "",
      sortOrder: (typeof this.props.response.sortOrder != "undefined") ? this.props.response.sortOrder : 1,
      content: (typeof this.props.response.content != "undefined") ? this.props.response.content : "",
      imageUploading: false,
      uploadingLabel: "Upload image",
      formDisabled: false,
      imgId: (typeof this.props.response.imgId != "undefined") ? this.props.response.imgId : 0,
      tmbUrl: (typeof this.props.response.tmbUrl != "undefined" && this.props.response.tmbUrl !== "") ? this.props.response.tmbUrl : false,
      assets:(typeof this.props.response.assets != "undefined") ? this.props.response.assets :0,
      fees:(typeof this.props.response.fees != "undefined") ? this.props.response.fees :0
    };

    this.updateContent = this.updateContent.bind(this);

  }

  getTokens() {
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials: "include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        thus.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session });
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Initialise the data 
  componentWillMount () {
    // TODO 
    // console.log(this.props.response);
  }

  // Update the selected document
  // Save it to the db and 
  toggleSelectedDoc (e) {
    this.setState({ selectedDoc: e.target.value });

    // Trigger a save field
    this.storeToDb();
  }

  // Toggle the state to include latest updates 
  saveField ( e, triggerStore = false ) {

    // First of all check out which field is being updated
    var fieldName = e.target.name;
    var fieldValue = e.target.value;
    this.setState({ [fieldName] : fieldValue });

    if (triggerStore) {
      var thus = this;
      setTimeout(function(){
        thus.storeToDb();
      }, 250);
    }
  }

  storeToDb (  ) {

      // Set up the save data 
      let { fpiId, selectedDoc, selectedSxn, imgId, title, sortOrder, content,assets,fees } = this.state;
      var saveData = {
        fpiId: fpiId,
        imgId: imgId,
        title: title,
        assets:assets,
        fees:fees,
        sort_order: sortOrder,
        docType: selectedDoc,
        content: encodeURIComponent(content.replace(/"/g, '\\"')),
        pa: selectedSxn,
        pageType: ((typeof this.props.group.isText != "undefined" && this.props.group.isText) || (typeof this.props.response.isText != "undefined" && this.props.response.isText)) ? "text" : "image"
      };

      // Run the save
      var thus = this;
      var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/full_page_images/save/' + this.props.custRef;
      fetch(fetchUrl, {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        credentials: "include",
        body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
      })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
          console.log('Request succeeded with JSON response', data);
          if (typeof data.rslt == "undefined" || data.rslt !== 'success') {
            alert('Sorry but there was an error saving your input. Please try again.');
          } else {
            thus.setState({ fpiId: data.fpiId });
          }
          // setTimeout(function(){
            thus.getTokens(); // WHAT IS GOING ON HERE THEN?!
          // }, 2500);
        })
        .catch(function (error) {
          thus.getTokens();
          alert('Sorry but there was an error saving your entries. Please try again.');
          console.log('Request failed', error);
        });

  }

  uploadImg ( e ) {
 
    // Disable the input submit while processing the update
    this.setState({ formDisabled: true, imageUploading: true, uploadingLabel: "Uploading..." });

    // Get the file name
    var fullPath = e.target.value;
    if (fullPath) {
      var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      var filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }
    }

    var postData = { filename: filename };

    if (typeof this.state.imgId !== 'undefined' && this.state.imgId > 0) {
      postData.imgId = this.state.imgId;
    }

    var fileData = { filename: filename, fileContent: e.target.files[0] };
    var thus = this;

    fetch(process.env.REACT_APP_API_BASE + '/admin/images/post_image/fpi/' + this.props.custRef, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials: "include",
      body: 'data=' + JSON.stringify(postData) + '&' + thus.props.tokenKey + '=' + thus.props.token
    })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {

        if (typeof data.rslt != "undefined" && data.rslt === 'success') {

          console.log('Request succeeded with JSON response', data);
          thus.setState({
            imgId: data.data.imgId,
            formAction: data.data.attributes.action,
            filename: data.data.subName, // TODO - remove this!!!
            acl: data.data.inputs.acl,
            cred: data.data.inputs['X-Amz-Credential'],
            algo: data.data.inputs['X-Amz-Algorithm'],
            lnkExpiry: data.data.inputs['X-Amz-Date'],
            policy: data.data.inputs['Policy'],
            sig: data.data.inputs['X-Amz-Signature'],
            formDisabled: false
          });
          thus.getTokens();
          thus.doFetchSubmit(fileData, data.data.imgId);

        } else {
          alert("There was an error uploading your image. Please check the content and try again.");
        }
      })
      .catch(function (error) {
        thus.getTokens();
      });
  }

  doFetchSubmit(fileData, imgId) {

    // Assemble the core data for form submission
    let { formAction, filename, acl, cred, algo, lnkExpiry, policy, sig } = this.state;

    var submitData = new FormData();
    submitData.append("key", filename);
    submitData.append("acl", acl);
    submitData.append("X-Amz-Credential", cred);
    submitData.append("X-Amz-Algorithm", algo);
    submitData.append("X-Amz-Date", lnkExpiry);
    submitData.append("Policy", policy);
    submitData.append("X-Amz-Signature", sig);
    submitData.append("file", fileData.fileContent);

    var thus = this;

    fetch(formAction, {
      method: 'post',
      body: submitData,
      mode: "no-cors"
    })
      .then(Fetching.statusCheck)
      .then(function (data) {
        console.log('Upload succeeded. Handle confirmation.');
        thus.confirmUpload(imgId);
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  confirmUpload(imgId, tmbUrl, counter = 0) {

    if (typeof imgId == "undefined") {
      imgId = this.state.imgId;
    }

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/images/confirm_upload/' + this.props.custRef + "/1/fpi";
    var thus = this;

    let { fpiId } = this.state;

    let data = {
      imgId: imgId,
      fpiId: fpiId,
      type: "fpi"
    }

    fetch(fetchUrl, {
      credentials: "include",
      method: 'post',
      headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: 'data=' + JSON.stringify(data) + '&' + thus.props.tokenKey + '=' + thus.props.token
    })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(
        function (data) {
          // throw new Error('Something went wrong');
          if (typeof data.rslt == "undefined" || data.rslt !== 'success') {

            if (counter >= 1) {
              alert("Sorry but there was an error uploading your file. Please try again.");
              thus.setState({ fileId: "", imgUrl: '', imageUploading: false });
            } else {
              let callback = () => {
                thus.confirmUpload(imgId, tmbUrl, 1);
              }
              return thus.getTokens(false, false, false, callback);

            }

          } else {
            if (typeof data.fpiId != 'undefined') {
              thus.setState({ tmbUrl: data.data.imgUrl, fpiId: data.fpiId, imgId: data.data.imgId, uploadingLabel: "Upload image", formDisabled: false });
            } else {
              thus.setState({ tmbUrl: data.data.imgUrl, imgId: data.data.imgId, uploadingLabel: "Upload image", formDisabled: false });
            }
          }
          thus.getTokens();
        }
      ).catch(function (error) {

        if (counter >= 1) {
          console.log('Request failed', error);
          thus.setState({ fileId: "", imgUrl: '', imageUploading: false });
        } else {
          let callback = () => {
            thus.confirmUpload(imgId, tmbUrl, 1);
          }
          return thus.getTokens(false, false, false, callback);
        }

      }); // TODO - catch errors!!!

    // Clear the file input
    document.getElementById("imgUpload" + this.props.repeatNo).value = "";

  }

  removeItem ( e ) {
    e.preventDefault();

    let { fpiId } = this.state;

    if ( !fpiId ) {

      this.setState({ isDeleted: true });

    } else {

      // Run a query to delete the entry
      var thus = this;
      fetch(process.env.REACT_APP_API_BASE + '/full_page_images/delete/' + fpiId + "/" + this.props.custRef, { method: 'get', credentials: "include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
          if ( typeof data.rslt != 'undefined' && data.rslt === "success" ) {
            thus.setState({ isDeleted: true });
          } else {
            alert('Sorry but there was an issue deleting the full page image.');
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });

    }

  }

  updateContent ( content, runSave ) {
    this.setState({ content: content });
    if (typeof runSave != 'undefined' && runSave === true) {
      var thus = this;
      setTimeout(function(){
        thus.storeToDb();
      }, 100);
    }
  }


  render () {

    const { hasError, selectedDoc, selectedSxn, title, uploadingLabel, tmbUrl, isDeleted, sortOrder, content,assets,fees } = this.state;

    if ( isDeleted ) {
      return <Fragment></Fragment>
    }

    // Set up the data for the second select menu
    let sxnOpts = docOptions[selectedDoc];
    
    var tmbStyles = { ...styles.quarteredSml };
    var tmbClass = "img-upload";
    if (tmbUrl) {
      tmbStyles.backgroundImage = "url('" + tmbUrl + "')";
      tmbStyles.backgroundPosition = "center center";
      tmbStyles.backgroundSize = "cover";
      tmbStyles.backgroundRepeat = "no-repeat";
      tmbClass += " filled";
    }

    // Check whether this is an image or text page
    var isText = false;
    if (typeof this.props.response.isText == "undefined" && typeof this.props.group.isText != "undefined" && this.props.group.isText) isText = true;
    if (typeof this.props.response.isText != "undefined" && this.props.response.isText) isText = true;

    return (
        <div className='question' style={ styles.fullWidth } key={ this.props.qid }>

          <button style={ styles.remove } onClick={ (e) => this.removeItem(e) }>Remove</button>

          { !isText ? (
            <div className={ tmbClass } style={ tmbStyles }>
              <label className="required" htmlFor={"imgUpload" + this.props.repeatNo}>{ uploadingLabel }</label>
              <input type="file" id={"imgUpload" + this.props.repeatNo} onChange={(e) => this.uploadImg(e)} />
            </div>
          ) : "" }
          
          <div className="img-title" style={styles.quartered}>
            <label className="required" htmlFor={ "docTitle" + this.props.repeatNo }>Page title?</label>
            <input type="text" name="title" defaultValue={ title } id={"docTitle" + this.props.repeatNo} onChange={(e) => this.saveField(e)} onBlur={(e) => this.storeToDb(e) } />
          </div>

          <div className="select" style={styles.quartered}>
            <label className='required' htmlFor={ "docSel" + this.props.repeatNo }>Which PDF should this appear in?</label>
            <select className={hasError === true ? "error" : ""} style={styles.none} name="selectedDoc" defaultValue={selectedDoc} id={ "docSel" + this.props.repeatNo} onChange={(e) => this.toggleSelectedDoc(e) }>
              <option value="fpr">Financial Planning report</option>
              <option value="pr">Progress Review</option>
            </select>
          </div>

          <div className="select" style={styles.quartered}>
            <label className='required' htmlFor={ "sxnSel" + this.props.repeatNo }>Where should the image appear?</label>
            <select className={hasError === true ? "error" : ""} style={styles.none} name="selectedSxn" defaultValue={selectedSxn} id={ "sxnSel" + this.props.repeatNo } onChange={(e) => this.saveField(e, true)}>
              <option></option>
              { sxnOpts.map ( (opt, i) => 
                <option key={ 'sxn-opt-' + this.props.repeatNo + "-" + i } value={ opt.value }>{ opt.label }</option>
              )}
            </select>
          </div>

          <div className="img-so" style={{ ...styles.quartered, ...styles.so }}>
            <label className="required" htmlFor={"docSo" + this.props.repeatNo}>Sort order</label>
            <input type="number" name="sortOrder" defaultValue={ sortOrder } id={"docSo" + this.props.repeatNo} onChange={(e) => this.saveField(e)} onBlur={(e) => this.storeToDb(e)} />
          </div>

        { isText ? (
          <Fragment>
            <div className="img-assets" style={styles.quartered}>
              <label className="required" htmlFor={ "docTitle" + this.props.repeatNo }>Assets Under Management - {'{AUM}'}</label>
              <input type="number" name="assets" defaultValue={ assets } id={"docAssets" + this.props.repeatNo} onChange={(e) => this.saveField(e)} onBlur={(e) => this.storeToDb(e) } style={styles.money}/>
            </div>

            <div className="img-fees" style={styles.quartered}>
              <label className="required" htmlFor={ "docTitle" + this.props.repeatNo }>Advice Fees - {'{AUMfees}'}</label>
              <input type="number" name="fees" defaultValue={ fees } id={"docFees" + this.props.repeatNo} onChange={(e) => this.saveField(e)} onBlur={(e) => this.storeToDb(e) } style={styles.money}/>
            </div>

            <div className="text-editor" style={ styles.txtArea } id={ "stuff-" + this.props.qid }>
              <FpiTemplates 
                qid={this.props.qid}
                content={ content }
                updateContent={ this.updateContent }
                templateType="fpi-text"
                templateClass=""
              />
              <label className="required" style={ styles.mBtm } htmlFor={"txt-" + this.props.repeatNo}>Page content</label>
              <CKEditor
                editor={ ClassicEditor }
                data={ content }
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.updateContent(data)
                }}
                onBlur={(event, editor) => {
                  this.storeToDb();
                }}
                config={{ toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'Indent', 'Outdent', 'insertTable', '|', 'undo', 'redo'] }}
              />
            </div>
          </Fragment>
          ) : ""}
        </div>
      );


  }

}

let styles = {
  fullWidth: {
    width: "98%",
    overflow: "auto"
  },
  quarteredSml: {
    float: 'left',
    width: '11%',
    marginRight: "2%"
  },
  quartered:{
    float:'left',
    width:'23.5%',
    marginRight: "2%",
    clear: "right"
  },
  so: {
    float: 'left',
    width: '8%',
    marginRight: "0",
    clear: "right"
  },
  remove: {
    float: "right",
    display: "right",
    margin: "0 12px 5px 0",
    border: "none",
    textDecoration: "underline",
    fontSize: "14px",
    background: 'url(' + removeIcon + ') left center / 12px 12px no-repeat',
    paddingLeft: 18,
    cursor: "pointer"
  },
  txtArea: {
    clear: "both",
    marginBottom: 14,
    overflow: "auto",
    width: "99%"
  },
  objectiveContainer:{
	  overflow:'auto'
  },
  uploadMessage:{
    height:'100%',
    width:'100%',
    background:'rgba(255,255,255,0.2)',
    display:'inline-block'
  },
  fileUpload:{
	  position:'absolute',
	  height:'100%',
	  width:'100%',
	  backgroundColor:'blue',
	  left:0,
	  opacity:0,
	  cursor:'pointer'
  },
	latestHighlight: {
		border: "1px solid " + Colours.grn,
		padding: '8px 13px'
	},
	latestHighlightLabel: {
		borderBottom: "2px solid " + Colours.grn
	},
	none: {},
  err: {
    color: Colours.red,
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 35
  },
  clearer: {
    clear: 'both'
  },
  headSml: {
    color: Colours.grn,
    fontSize: 18,
    marginTop: 8,
    marginBottom: 5
  },
  strong: {
    fontWeight: 600
  },
  money: {
    paddingLeft: 30,
    background: Colours.bgWhite + " url(" + pound + ") left 15px center / 8px auto no-repeat",
  },
}

const docOptions = {
  fpr: [
    {
      value: "cover",
      label: "After the cover page"
    },
    {
      value: "objectives",
      label: "After the objectives page"
    },
    {
      value: "recommendations",
      label: "After the recommendations page"
    },
    {
      value: "disadvantages",
      label: "After the possible disadvantages page"
    },
    {
      value: "risk",
      label: "After the risk profile page"
    },
    {
      value: "fees",
      label: "After the fees and charges page"
    },
    {
      value: "next-steps",
      label: "After the next steps page"
    },
    {
      value: "appendices",
      label: "After the appendices"
    },
    {
      value: "risk-definitions",
      label: "After the risk definitions page"
    },
    {
      value: "risk-expectations",
      label: "After the risk expectations page"
    },
    {
      value: "oas",
      label: "After the 'what we do' page"
    },
    {
      value: "tangible",
      label: "After the 'how we do it' page"
    },
    {
      value: "warnings",
      label: "After the risk warnings page"
    },
    {
      value: "glossary",
      label: "After the glossary"
    },
  ],
  pr: [
    {
      value: "cover",
      label: "After the cover page"
    },
    {
      value: "objectives",
      label: "After the objectives page"
    },
    {
      value: "valuations",
      label: "After the valuations page"
    },
    {
      value: "protections",
      label: "After the protections page"
    },
    {
      value: "recommendations",
      label: "After the recommendations page"
    },
    {
      value: "services",
      label: "After the services provided page"
    },
    {
      value: "risk-profile",
      label: "After the risk profile page"
    },
    {
      value: "oas",
      label: "After the 'what we do' page"
    },
    {
      value: "tangible",
      label: "After the 'how we do it' page"
    },
    {
      value: "risk-warnings",
      label: "After the risk warnings page"
    },
    {
      value: "glossary-pr",
      label: "After the glossary"
    },
    {
      value: "forecast",
      label: "After the forecast graphs"
    }
  ]
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(FullPageImg);
