import React, { Component } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Contributions from './contributions.js'
import Withdrawals from './withdrawals.js'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import NumberFormat from 'react-number-format';
import {  getTargetValue,_doRound,getInflationRateAmount,numberWithCommas} from './data-generator.js'

class Calculator extends Component {

  importTargetValue(calculator,displayValue,hasGlobalInflationSet){

    const DECIMAL_POINT_COUNT = 2;

    let dataOptions = {
      enableInflation:true
    }

    if(!this.props.hasGlobalInflationSet){
      dataOptions.enableInflation = false
    }
    let targetYearDifference = displayValue.retirement_age - displayValue.age;
    let inflationRateAmount = getInflationRateAmount(dataOptions,calculator,targetYearDifference);

    let target = getTargetValue(calculator);
    let targetEntry = target * inflationRateAmount;
    return '£'+numberWithCommas(_doRound(targetEntry,DECIMAL_POINT_COUNT));

  }

  getInitialSavings(savings){

    const DECIMAL_POINT_COUNT = 2;

    let initialSavingsAge= 100;
    let hasSavings = false;

    for(var savingAge in savings){
      hasSavings = true;
      if(savingAge < initialSavingsAge){
        initialSavingsAge = savingAge;
      }
    }

    if(!hasSavings){
      return 0;
    } else {
      return numberWithCommas(_doRound(savings[initialSavingsAge],DECIMAL_POINT_COUNT));
    }


  }

  render() {

	const dobFound = this.props.dobFound;

    const calculator = this.props.calculator;
    const displayValue = this.props.displayValue;
    const calculatorInputCallbacks= this.props.calculatorInputCallbacks;
    const withdrawalCallbacks = this.props.calculatorInputCallbacks.withdrawals;
    const contributionCallbacks = this.props.calculatorInputCallbacks.contributions;

    let maxIncomeSlider = 5000000;

    if(calculator.is_specific_amount === 0){
      maxIncomeSlider = 250000;
    }

    const saveCalculatorCallback = () =>{
      alert('Calculator Saved');
    }

	const yourAgeSlider = (!dobFound) ? (
		<div style={ styles.wrapper }>
		 <p>Your Age</p>
		 <Slider min={this.props.customerAge} max={80} value={this.props.customerAge} onChange={(e) => calculatorInputCallbacks.handleAgeToggle(e,this.props.index)} onAfterChange={(e) => calculatorInputCallbacks.handleAgeInput(e,this.props.index)}  />
		 <NumberFormat onValueChange={(e) => calculatorInputCallbacks.handleAgetype(e,this.props.index)} type='text' value={this.props.customerAge}/>
		</div>
	) : null;

    let startValue = this.getInitialSavings(this.props.savings);

    return (
      <div className={ this.props.isExpanded ? 'calculator' : 'calculator closed' }>
        <div onClick={(e) => this.props.handleExpandToggle(e,this.props.index)} className='header'>
          <div className="btns">

            <button onClick={(e) => this.props.saveCalculator(this.props.index,saveCalculatorCallback)} >Save</button>
            <button onClick={(e) => this.props.softDeleteCalculator(e,this.props.index)}>Delete</button>
            <button onClick={(e) => this.props.downloadAsPDF(e,this.props.index)} >PDF</button>
          </div>
          <input onChange={(e) => this.props.handleCalculatorTitleEdit(e,this.props.index)} className='title-input' type="text" value={calculator.title} />

        </div>
        <div className='calculator-content'
          style={{
            display:(this.props.isExpanded) ? 'block' : 'none'
          }}
        >
          <div style={ styles.wrapper }>
           <p>Assumed annual investment return</p>
           <Slider min={-30} step={0.5} max={30} value={displayValue.annual_investment_return} onChange={(e) => calculatorInputCallbacks.handleInvestmentReturnToggle(e,this.props.index)} onAfterChange={(e) => calculatorInputCallbacks.handleInvestmentReturnInput(e,this.props.index)} />
           <NumberFormat  onValueChange={(e) => calculatorInputCallbacks.handleInvestmentReturnType(e,this.props.index)} suffix={'%'} value={displayValue.annual_investment_return}/>
          </div>
          <div style={ styles.wrapper }>
           <p>Highest expected 1 year return</p>
           <Slider  min={-30} step={0.5} max={30} value={displayValue.assumed_rate_high} onChange={(e) => calculatorInputCallbacks.handleAssumedRateHighToggle(e,this.props.index)} onAfterChange={(e) => calculatorInputCallbacks.handleAssumedRateHighInput(e,this.props.index)} />
           <NumberFormat suffix={'%'} onValueChange={(e) => calculatorInputCallbacks.handleAssumedRateHighType(e,this.props.index)}   value={displayValue.assumed_rate_high}/>
          </div>
          <div style={ styles.wrapper }>
           <p>Lowest expected 1 year return</p>
           <Slider  min={-30} step={0.5} max={30} value={displayValue.assumed_rate_low} onChange={(e) => calculatorInputCallbacks.handleAssumedRateLowToggle(e,this.props.index)} onAfterChange={(e) => calculatorInputCallbacks.handleAssumedRateLowInput(e,this.props.index)} />
           <NumberFormat suffix={'%'} onValueChange={(e) => calculatorInputCallbacks.handleAssumedRateLowType(e,this.props.index)}  value={displayValue.assumed_rate_low}/>
          </div>
          <div style={ styles.wrapper }>
            <p>Assumed rate of inflation</p>
            <Slider min={0} step={0.5} max={20}  value={displayValue.inflation_rate} onChange={(e) => calculatorInputCallbacks.handleInflationRateToggle(e,this.props.index)} onAfterChange={(e) => calculatorInputCallbacks.handleInflationRateInput(e,this.props.index)}/>
            <NumberFormat  onValueChange={(e) => calculatorInputCallbacks.handleInflationRateType(e,this.props.index)} suffix={'%'} value={displayValue.inflation_rate}/>
          </div>


          {yourAgeSlider}

          <div style={ styles.wrapper }>
           <p>Ideal retirement Age</p>
           <Slider min={0} max={80} value={displayValue.retirement_age} onChange={(e) => calculatorInputCallbacks.handleRetirementAgeToggle(e,this.props.index)}  onAfterChange={(e) => calculatorInputCallbacks.handleRetirementAgeInput(e,this.props.index)}  />
           <NumberFormat onValueChange={(e) => calculatorInputCallbacks.handleRetirementAgeType(e,this.props.index)} type='text' value={displayValue.retirement_age}/>
          </div>

          <div style={ styles.wrapper }>
          <h6>
            Target
          <span className='target-value' style={{display: (calculator.is_specific_amount === 0) ? 'inline-block' : 'none' }}>
            {this.importTargetValue(calculator,displayValue,this.props.hasGlobalInflationSet)}
          </span>
          </h6>
           <input type="radio" name={'target_type_amount_'+this.props.index} id={'target_amount_'+this.props.index} value='1'  checked={calculator.is_specific_amount === 1} onChange={(e) => calculatorInputCallbacks.handleTargetTypeToggle(e,this.props.index)}/>
           <label htmlFor={'target_amount_'+this.props.index}  >Specific Amount</label>
           <input type="radio" name={'target_type_amount_'+this.props.index} id={'target_income_'+this.props.index}  value='0'  checked={calculator.is_specific_amount === 0} onChange={(e) => calculatorInputCallbacks.handleTargetTypeToggle(e,this.props.index)}/>
           <label htmlFor={'target_income_'+this.props.index} >Yearly Income</label>
          </div>

          <div style={{display: (calculator.is_specific_amount === 0) ? 'block' : 'none' }}>
            <div style={ styles.wrapper }>
              <p>Assumed starting withdrawal rate</p>
              <Slider  min={0} step={0.5} max={20} value={displayValue.withdrawal_rate} onChange={(e) => calculatorInputCallbacks.handleStartingWithdrawalRateToggle(e,this.props.index)} onAfterChange={(e) => calculatorInputCallbacks.handleStartingWithdrawalRateInput(e,this.props.index)} />
              <NumberFormat onValueChange={(e) => calculatorInputCallbacks.handleStartingWithdrawalRateType(e,this.props.index)}suffix={'%'}  value={displayValue.withdrawal_rate}/>
            </div>
          </div>


          <div className='target-container' style={ styles.wrapper }>
           <p>Target</p>
           <Slider min={0} max={maxIncomeSlider} step={1000} value={displayValue.target} onChange={(e) => calculatorInputCallbacks.handleTargetValueToggle(e,this.props.index)}
           onAfterChange={(e) => calculatorInputCallbacks.handleTargetInput(e,this.props.index)}/>
           <NumberFormat  onValueChange={(e) => calculatorInputCallbacks.handleTargetType(e,this.props.index)}  prefix={'£'} thousandSeparator={true} value={displayValue.target}/>
          </div>

          <div style={ styles.wrapper }>
          	<h6>Contributions</h6>
          	<Contributions startValue={startValue} contributions={calculator.contributions} contribution_displays={displayValue.contributions} index={this.props.index} contributionCallbacks={contributionCallbacks} />
					</div>

					<div style={ styles.wrapper }>
          	<h6>Withdrawals</h6>
          	<Withdrawals calculatorInputCallbacks={calculatorInputCallbacks} target={displayValue.target}startingWithdrawalRate = {displayValue.withdrawal_rate} withdrawals={calculator.withdrawals} withdrawal_displays={displayValue.withdrawals} index={this.props.index} withdrawalCallbacks={withdrawalCallbacks}/>
					</div>

					<div style={ styles.wysiwyg } className="calc-quill">
						<h6>Calculator notes</h6>
						<ReactQuill
							defaultValue={ typeof this.props.calculator.notes != 'undefined' ? this.props.calculator.notes : "" }
							onChange={ this.props.handleNotesChange } modules={ modules }
						/>
					</div>

        </div>
      </div>
    );
  }
}

const styles = {
	wrapper: {
		margin: 15,
		marginTop: 6,
		marginBottom: 6
	},
	wysiwyg: {
		clear: 'both',
		margin: "22px 15px 15px 15px"
	}
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'strike'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
  ],
};

export default Calculator;
