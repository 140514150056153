import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import logo from './../../assets/img/ashworth-logo.jpg';
import Colours from './../../assets/colours.js';

import AdminMenu from './../admin/admin-menu.js';
import ProgressBar from './../questionnaire/progress-bar.js';

class Header extends Component {

  render() {

    let menu = <ProgressBar />;
    if (typeof this.props.isAdmin != 'undefined') {
      menu = <AdminMenu />;
    }

    return (
      <header style={ styles.header } className={ this.props.minimise ? "sml-head" : "" }>
        <div className='content'>
          <div className='width-3'>
            <Link style={ styles.lnk } to={ `/` }>
              <img src={ logo } className="logo" style={ styles.logo } alt="Ashworth Financial Planning logo" />
            </Link>
          </div>
          <div className='width-9 right menu'>
            { menu }
          </div>
        </div>
      </header>
    );
  }
}

var styles = {
  header: {
    paddingTop: 20,
    paddingBottom: 20,
    background: Colours.bgWhite,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.1)'
  },

  lnk: {
    border: 0,
    outline: 'none'
  },
  logo: {
    width: '100%',
    maxWidth: 155
  }
};

function mapStateToProps(state) {
  return {
    minimise: state.minimalHeader
  };
}

export default connect(mapStateToProps)(Header);
