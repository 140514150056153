import React, { Component } from 'react';

import Colours from './../../../assets/colours.js';
import ExpCont from './../../../assets/img/expand-contract.png';

class MIFilter extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      cfs: [],
      filterState: "closed"
    };

    this.loadContent = this.loadContent.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.initialiseDefaultSearch = this.initialiseDefaultSearch.bind(this);
  }

  componentWillMount () {
    this.loadContent();
  }

  // Check the tokens
  loadContent () {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/custom_fields/get_filter';
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          thus.setState({ cfs: data.cfs });

        }
      );

  }

  updateFilter(e, cfId, fieldType) {

    // Pass it up the chain to the filter
    this.props.updateFilter(e, cfId, fieldType);

  }

  // Toiggle the open / closed status of the filter
  toggleFilter (e) {

    e.preventDefault();

    var fltrState = this.state.filterState;
    fltrState = (fltrState === "closed") ? "open" : "closed";
    this.setState({ filterState: fltrState });

  }

  //if props.location have specific filter settings attached
  initialiseDefaultSearch(cfs,propsLocation){
    for(var i = 0 ; i <cfs.length;i++){

      //like review month....
      if(propsLocation.hasOwnProperty('review_month') && propsLocation.filter_key === cfs[i].id){
        cfs[i].entry = propsLocation.filter_value;
      }

      //..or interim review month
      if(propsLocation.hasOwnProperty('interim_review_month') && propsLocation.filter_key === cfs[i].id){
        cfs[i].entry = propsLocation.filter_value;
      }
    }

    return cfs;
  }


  render() {
    let { cfs, filterState } = this.state;

    cfs = this.initialiseDefaultSearch(cfs,this.props.location);

    if (cfs.length === 0) {
      return (
        <div style={ styles.cfBlock } className={ "cf-block " + filterState }>

          <h2 className={ filterState }>Filter Clients</h2>

          <div className='cf-content width-1 centred'>
            <div className="loader sml">Loading...</div>
          </div>

        </div>

      );
    }

    return (


        <div className='cf-content'>
          { cfs.map( cf =>{

			  if(cf.label === 'Client name'){
				  return (<div className='question' style={{float:'right',marginRight: '0.5%'}} key='search-box'>
					<input style={{fontSize:'14px'}} name={ 'name' } defaultValue={ cf.entry } type="text" id={'name' } onBlur={ (e) => this.updateFilter(e, 'name', "text") } placeholder='Search by name or email address...'/>
				  </div>);
				} else {
					return null;
				}
			})}
        </div>
    );
  }
}

var styles = {
  cfBlock: {
    marginBottom: 35,
    background: Colours.bgGrey,
    padding: "12px 20px",
    boxSizing: "border-box",
    overflow: "auto"
  },
  mSml: {
    marginBottom: 15,
    borderBottomColor: Colours.grn,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px top -38px",
    backgroundSize: "24px auto",
    backgroundImage: 'url(' + ExpCont + ')',
  },
	label: {
		marginLeft: 15
	}
};

export default MIFilter;
