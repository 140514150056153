const CONFIGURATION  = {
  series:{
    both:{
      0:{type:'area',color: 'grey',lineWidth: 1},
      1:{color: '#696969'},
      2:{color: '#51A9D6'},
      3: { type: 'line',color: 'blue',lineWidth: 1, },
      4: { type: 'line',color: '#A26E3F' ,lineWidth: 1, },
      5: { type: 'line',color: '#4E9043' ,lineWidth: 2, },
      6: { type: 'line',color: '#8d8d8f' ,lineWidth: 1,},
      7: { type: 'line',color: 'grey',lineDashStyle:[4,4],lineWidth: 1}
    },
    both_without_original:{
      0:{type:'area',color: 'grey',lineWidth: 1},
      1:{color: '#696969'},
      2:{color: '#51A9D6'},
      3: { type: 'line',color: '#A26E3F' ,lineWidth: 1, },
      4: { type: 'line',color: '#4E9043' ,lineWidth: 2, },
      5: { type: 'line',color: '#8d8d8f' ,lineWidth: 1,},
      6: { type: 'line',color: 'grey',lineDashStyle:[4,4],lineWidth: 1}
    },
    savings:{
      0:{type:'area',color: 'grey',lineWidth: 1},
      1:{color: '#696969'},
      2:{color: '#51A9D6'},
      3:{ type: 'line',color: 'grey',lineDashStyle:[4,4],lineWidth: 1},
    },
    predictions:{
      0: { type: 'line',color: 'blue',lineWidth: 1, },
      1: { type: 'line',color: '#A26E3F' ,lineWidth: 1, },
      2: { type: 'line',color: '#4E9043' ,lineWidth: 2, },
      3: { type: 'line',color: '#8d8d8f' ,lineWidth: 1,},
      4: { type: 'line',color: 'grey',lineDashStyle:[4,4],lineWidth: 1}
    },
    predictions_without_original:{
      0: { type: 'line',color: 'blue',lineWidth: 1, },
      1: { type: 'line',color: '#4E9043' ,lineWidth: 2, },
      2: { type: 'line',color: '#8d8d8f' ,lineWidth: 1,},
      3: { type: 'line',color: 'grey',lineDashStyle:[4,4],lineWidth: 1}
    },
    target_only:{
      0: { type: 'line',color: 'grey',lineDashStyle:[4,4],lineWidth: 1}
    }
  }
}

export default CONFIGURATION
