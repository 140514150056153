import React, { Component } from 'react';
import { connect } from 'react-redux';

import Heading from './../heading.js'
import FlashMsg from './../../common/flash-msg.js'
import Btn from './../../common/btn.js'
import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';

class QuestionEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sxnTitle: "",
      questions: [],
      isLoading: false,
      rslt: "",
      rsltType: ""
    };

    this.getQuestions = this.getQuestions.bind(this);
    this.handleEntryChange = this.handleEntryChange.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  getQuestions ( sxnSlug ) {

    if (!sxnSlug) {
      sxnSlug = 'personal-details';
    }

    // Get the sections
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_sections/edit/' + sxnSlug, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.msg != 'undefined') {
        this.setState({ rdr: true });
      } else {
        for (var i in data.groups) {
          console.log(data.groups[i]);
          data.groups[i].group.gKey = i;
          for (var j in data.groups[i].group.questions) {
            data.groups[i].group.questions[j].gKey = i;
            data.groups[i].group.questions[j].qKey = j;
          }
        }
        thus.setState({ questions: data.groups, isLoading: false, sxnTitle: (typeof data[0] != 'undefined')?data[0].group.title:"" });
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  handleEntryChange (e) {
    // Get the input name
    var inputDetails = e.target.name;
    var [ field, qid, gKey, qKey ] = inputDetails.split('-');

    // Get the state that is to be updated
    var questions = this.state.questions;

    // Handle the entries and use them to update the state
    if (qid === questions[gKey].group.questions[qKey].qid) {
      switch ( field ) {
        case "qType":
          questions[gKey].group.questions[qKey].type = e.target.value;
          break;
        case "qContent":
          questions[gKey].group.questions[qKey].question = e.target.value;
          break;
        case "qOptions":
          questions[gKey].group.questions[qKey].optionStr = e.target.value;
          break;
        case "qSo":
          questions[gKey].group.questions[qKey].sortOrder = e.target.value;
          break;
        case "qRequired":
          questions[gKey].group.questions[qKey].isRequired = (e.target.checked)?"1":"0";
          break;
				case "qHidden":
	        questions[gKey].group.questions[qKey].isHidden = (e.target.checked)?"1":"0";
	        break;
				case "qSummary":
		      questions[gKey].group.questions[qKey].isSummary = (e.target.checked)?"1":"0";
		      break;
        default:
          break;
      }
    }

    this.setState({ questions: questions });
  }

  addQuestion(e, gid, gKey) {

    e.preventDefault();

    // Sense check that the GID is as expected
    var questions = this.state.questions;
    if (questions[gKey].group.gid === gid) {
      // Figure out the sort order of the last item in the list
      var lastSo = (questions[gKey].group.questions.length > 0)?questions[gKey].group.questions[questions[gKey].group.questions.length - 1].sortOrder:0;
      // As we have the correct item append a new (empty) question to the array
      var blankQuestion = {
        qid: "new",
        sxnId: questions[gKey].group.sxnId,
        groupId: questions[gKey].group.gid,
        question: "",
        type: "text",
				isRequired: "0",
				isHidden: "0",
        isSummary: "0",
        gKey: gKey,
        qKey: questions[gKey].group.questions.length,
        sortOrder: Number(lastSo) + 1
      };
      questions[gKey].group.questions.push(blankQuestion);
      this.setState({ questions: questions });
    } else {
      alert("Sorry but there was an error adding another field. Please refresh the page and try again.");
    }
  }

  saveQuestion (e, qid, gKey, qKey) {

    // Sense check that the qid is as expected
    this.setState({ rslt: '', rsltType: '' });

    var questions = this.state.questions;
    if (qid === questions[gKey].group.questions[qKey].qid) {
      // We have the correct details so run a save on this part of the questions state
      console.log('Running a save on the question:');
      console.log(questions[gKey].group.questions[qKey]);
      var saveQData = questions[gKey].group.questions[qKey];
      saveQData.question = encodeURIComponent(saveQData.question);
      // questions[gKey].group.questions[qKey].question = encodeURIComponent(questions[gKey].group.questions[qKey].question);

      // Run the save
      var thus = this;
      var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/questionnaire_questions/save';
      fetch(fetchUrl, {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        credentials:"include",
        body: 'data=' + JSON.stringify(questions[gKey].group.questions[qKey]) + '&' + this.props.tokenKey + '=' + this.props.token
      })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);

        if (typeof data.rslt !== 'undefined' && data.rslt === 'success') {
          // Update the QID
          questions[gKey].group.questions[qKey].qid = data.qid;
          // Set the updated state
          thus.setState({ rslt: 'The question has been successfully created', rsltType: 'success' });
          setTimeout(function(){
            thus.setState({ rslt: '', rsltType: '' });
          }, 6500);
        } else {
          // Handle the failure by showing a failure message
          var msg = "<p>Sorry but there was an error saving the question. Please check the errors below and try again.</p><ul>";
          for ( var i in data.errors) {
            for (var j in data.errors[i]) {
              msg += "<li>" + data.errors[i][j].message + "</li>";
            }
          }
          msg += "</ul>";
          thus.setState({ rslt: msg, rsltType: 'error' });
        }
        thus.getTokens();
      })
      .catch(function (error) {
        thus.getTokens();
        console.log('Request failed', error);
      });

    } else {
      alert("Sorry but there was an error saving your question. Please refresh the page and try again.");
    }

  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {
    // Check whether a section is selected
    var current = false;
    if (typeof this.props.curSxn != 'undefined') {
      current = this.props.curSxn;
    }
    this.getQuestions(current);

    this.getTokens();
  }

  componentWillReceiveProps(nextProps){
    // Check whether a section is selected
    var current = false;
    if (typeof nextProps.curSxn != 'undefined') {
      current = nextProps.curSxn;
    }
    this.getQuestions(current);
  }

  render() {

    const { questions, isLoading, sxnTitle, rslt, rsltType } = this.state;

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    if (!isLoading && questions.length !== 0) {

      content = (
        <div style={ styles.mBtm }>
          { questions.map( group =>
            <div key={ group.group.title + group.group.subtitle } style={ styles.grpDiv }>
              <h3 style={ styles.title }>{ group.group.title } { group.group.type === 'repeat-fxd' ? "(" + group.group.subtitle.replace(/--/g, ", ") + ")" : "" }</h3>
              <div className={ "mbtm mtop " + rsltType } dangerouslySetInnerHTML={{ __html: rslt }}></div>
              { group.group.questions.map( question =>
                <div key={ question.qid + '-' + question.gKey + '-' + question.qKey } style={ styles.clearance }>
                  <div style={ styles.qInput }>
                    <label htmlFor={ 'qContent-' + question.qid }>Field label</label>
                    <input name={ "qContent-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ 'qContent-' + question.qid } defaultValue={ question.question } type="text" style={ styles.qInputInput } onChange={ (e) => this.handleEntryChange(e) } />
                  </div>
                  <div style={ styles.qInput }>
                    <label htmlFor={ 'qType-' + question.qid }>Field type</label>
                    <select name={ "qType-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ 'qType-' + question.qid } defaultValue={ question.type } style={ styles.qInputInput } onChange={ (e) => this.handleEntryChange(e) }>
                      <option value="text">Single line text</option>
											<option value="textarea">Multiple line text</option>
                      <option value="wysiwyg">Multiple line text (WYSIWYG)</option>
                      <option value="select">Select (dropdown) menu</option>
                      <option value="sel-name">Select a client (dropdown)</option>
                      <option value="radio">Radio button group</option>
                      <option value="money">Money amount</option>
                      <option value="age">Age</option>
                      <option value="date">Date</option>
                      <option value="subhead">Sub-heading</option>
                      <option value="spacer">Spacer (empty element used for layout)</option>
                      <option value="ruler">Ruler (empty element w. l/break used for layout)</option>
                      <option value="objective">Objective</option>
                      <option value="fp-image">Full page image upload</option>
                    </select>
                  </div>
                  <div style={ styles.cb }>
                    <label htmlFor={ 'qSo-' + question.qid } styles={ styles.cbLabel }>Sort order</label>
                    <input style={ styles.noInput } name={ "qSo-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ 'qSo-' + question.qid } defaultValue={ question.sortOrder } type="number" onChange={ (e) => this.handleEntryChange(e) } />
                  </div>
                  <div style={ styles.cb }>
                    <label htmlFor={ 'qRequired-' + question.qid } styles={ styles.cbLabel }>Is required?</label>
                    <input style={ styles.cbInput } name={ "qRequired-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ 'qRequired-' + question.qid } defaultChecked={ question.isRequired === "1" ? "checked":"" } type="checkbox" onChange={ (e) => this.handleEntryChange(e) } />
                  </div>
									<div style={ styles.cb }>
                    <label htmlFor={ 'qHidden-' + question.qid + '-' + question.gKey + '-' + question.qKey } styles={ styles.cbLabel }>Is hidden?</label>
                    <input style={ styles.cbInput } name={ "qHidden-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ 'qHidden-' + question.qid + '-' + question.gKey + '-' + question.qKey } defaultChecked={ question.isHidden === "1" ? "checked":"" } type="checkbox" onChange={ (e) => this.handleEntryChange(e) } />
                  </div>
									<div style={ styles.cb }>
                    <label htmlFor={ 'qSummary-' + question.qid + '-' + question.gKey + '-' + question.qKey } styles={ styles.cbLabel }>Summary?</label>
                    <input style={ styles.cbInput } name={ "qSummary-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ 'qSummary-' + question.qid + '-' + question.gKey + '-' + question.qKey } defaultChecked={ question.isSummary === "1" ? "checked":"" } type="checkbox" onChange={ (e) => this.handleEntryChange(e) } />
                  </div>
                  <Btn title="Delete" btnStyle={{ ...styles.smlRounded, ...styles.del }} type="lnk" lnk={ '/admin/questions/delete/' + question.qid } />
									<Btn title="Update" btnStyle={{ ...styles.smlRounded, ...styles.updateBtn }} type="submit" handleSubmission={ (e) => this.saveQuestion(e, question.qid, question.gKey, question.qKey) } />
                  {
                    question.type === "select" ? (
                      <div style={{ ...styles.qInput, ...styles.options }}>
                        <label htmlFor={ "qOptions-" + question.qid }>Enter the options for the select menu below (one per line)</label>
                        <textarea style={ styles.optionInput } name={ "qOptions-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ "qOptions-" + question.qid } defaultValue={ question.optionStr } onChange={ (e) => this.handleEntryChange(e) }></textarea>
                      </div>
                    ) : ""
                  }
                  {
                    question.type === "radio" ? (
                      <div style={{ ...styles.qInput, ...styles.options, ...styles.radio }}>
                        <label htmlFor={ "qOptions-" + question.qid }>Enter the text for the radio button options below (separated by "---")</label>
                        <textarea style={{ ...styles.optionInput, ...styles.radioOptInput }} name={ "qOptions-" + question.qid + '-' + question.gKey + '-' + question.qKey } id={ "qOptions-" + question.qid } defaultValue={ question.optionStr } onChange={ (e) => this.handleEntryChange(e) }></textarea>
                      </div>
                    ) : ""
                  }
                </div>
              )}
              <Btn title="+ Add another field" btnStyle={{ ...styles.smlRounded, ...styles.greyBtn }} type="submit" handleSubmission={ (e) => this.addQuestion(e, group.group.gid, group.group.gKey) } />
            </div>
          )}
        </div>
      );

    }

    return (
      <div className='width-9'>
        <Heading mainTitle="Update Questionnaire" subTitle={ sxnTitle } />

        <FlashMsg />

        { content }

      </div>
    );
  }
}

const styles = {
  mBtm: {
    marginBottom: 95,
  },
  grpDiv: {
    overflow: 'auto',
    marginBottom: 25
  },
  title: {
    color: Colours.grn,
    marginTop: 10,
    borderBottom: "1px solid " + Colours.lightGrey
  },
  clearance: {
    clear: 'left',
    borderBottom: "1px solid " + Colours.lightGrey,
    overflow: 'auto',
    marginBottom: 5,
    padding: "8px 15px"
  },
  qInput: {
    float: 'left',
    width: '27.5%',
    marginRight: '2.5%'
  },
  qInputInput: {
    width: '98%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  smlRounded: {
    borderRadius: '20px',
    fontSize: 14,
    float: 'right',
    marginTop: 15,
  },
	updateBtn: {
		clear: 'left'
	},
  options: {
    clear: 'left'
  },
  optionInput: {
    minHeight: 95
  },
  radio: {
    width: "98%",
    minWidth: 300
  },
  radioOptInput: {
    minHeight: 250,
  },
  greyBtn: {
    background: Colours.txtGrey,
    float: 'right',
    clear: 'left',
    marginTop: 0
  },
  del: {
    background: Colours.red,
    marginLeft: 10,
		clear: 'left'
  },
  cb: {
    textAlign: 'center',
    width: '7%',
    float: 'left',
    marginTop: 5,
    marginRight: '2.5%'
  },
  cbInput: {
    margin: "15px auto",
    display: 'block',
    width: 'auto'
  },
  noInput: {
    maxWidth: 75,
    width: "80%",
    minWidth: 45,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(QuestionEdit);
