import React, { Component } from 'react';
import { connect } from 'react-redux';

import Colours from './../../assets/colours.js';
import Download from './../../assets/img/download.svg';

class ProgressBar extends Component {

  render() {

    let sxns = this.props.sxns;
    if ( sxns.length === 0 ) {
      return (
        <nav></nav>
      );
    }

    return (
      <nav style={ styles.mainMenu }>
        <a href={ process.env.REACT_APP_API_BASE + "/questionnaire_sections/generate_client_pdf/" + this.props.customerToken + '/' + this.props.customerMash + '/' + this.props.customerTokenKey } style={{ ...styles.round, ...styles.download }} target="_blank" rel="noopener noreferrer">Export</a>

        <div>
          <h3 style={ styles.startedLnk }>Get Started</h3>
          <ul style={styles.progressBar}>
            { sxns.map( sxn =>
              <span key={ sxn.slug }>
                { sxn.isAddressed === "1" ? (
                  <li style={{ ...styles.progressBlock,...styles.grnProgress }}></li>
                ) : (
                  <span>
                    { sxn.isRequired === 1 ? (
                      <li style={ styles.progressBlock }></li>
                    ) : (
                      <li style={{ ...styles.progressBlock, ...styles.unreq }}></li>
                    )}
                  </span>
                )}
              </span>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

var styles = {
  mainMenu: {
    marginTop: 25
  },
  progressBar: {
    float: 'right'
  },
  progressBlock: {
    display: 'block',
    float: 'left',
    height: 4,
    width: 8,
    background: Colours.lightGrey,
    marginLeft: 2
  },
  grnProgress: {
    background: Colours.grn
  },
  unreq: {
    opacity: '0.35'
  },
  startedLnk: {
    color: Colours.grn,
    fontWeight: 400,
    fontSize: 22
  },
  round: {
    borderRadius: 25,
    display: 'inline-block',
    margin: "0 0 0 18px",
    padding: "5px 12px",
    fontSize: 13
  },
  download: {
    marginLeft: 25,
    marginTop: 5,
    marginBottom: 5,
    background: Colours.lightishGrey + " url('" + Download + "') right 12px center / 16px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
    paddingRight: 42,
    paddingLeft: 15,
    float: 'right'
  }
};

function mapStateToProps(state) {
  return {
    sxns: state.sxns,
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash
  };
}

export default connect(mapStateToProps)(ProgressBar);
