import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';

import Heading from '../heading.js';
import FlashMsg from '../../common/flash-msg.js';
import Btn from '../../common/btn.js';

import Fetching from '../../../functions/fetching.js';
import Colours from '../../../assets/colours.js';

class CancelLink extends Component {

  constructor(props) {
    super(props);

    this.state = {
      custRef: this.props.match.params.custRef,
			links:[],
      rdr: false,
			isLoadingCustomerLinks: false,
      isLoadingGeneric: false,
      error: ""
    };

    this.getTokens = this.getTokens.bind(this);
    this.closeCover = this.closeCover.bind(this);
    this.getCustomerLinks = this.getCustomerLinks.bind(this);
    this.cancelLnk = this.cancelLnk.bind(this);
  }

	getCustomerLinks () {

    // Check that we have a reference
    if (typeof this.state.custRef == 'undefined' || this.state.custRef === "") {
      this.setState({ rdr: true });
    }

    // Get the customer details
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/admin/customer_sends/list/' + this.state.custRef, { credentials:"include" })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.error != 'undefined' && typeof data.msg != 'undefined') {
        // Set the updated state
        this.setState({ rdr: true });
      } else {
        thus.setState({ links: data.customer_links,isLoadingCustomerLinks:false});
      }

    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

	cancelLnk (e,url) {

		e.preventDefault();

    var doCancel = window.confirm('This will cancel the link. Do you wish to continue?');

    if(!doCancel){
      return false;
    }

		// // Get the selected Files
		// var { selectedFiles, custRef } = this.state;

		// Set the generating state (to display the loader)
		this.setState({ genLoading: true });

		// Run a query to generate the link and return it
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/admin/customer_sends/cancel/'+url, {
      method: 'get',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

			// Upon success display the link and clear the selected fields
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
        // Set the updated state
        thus.getCustomerLinks()
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ error: "Sorry but there was an error cancelling the link. Please try again.", genLoading: false });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.setState({ error: "Sorry but there was an error cancelling the link. Please try again.", genLoading: false });
      thus.getTokens();
    });

	}

	inArray (needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] === needle) return true;
    }
    return false;
	}

  closeCover (e) {
    e.preventDefault();
    this.setState({ genLnk: "", genLoading: false, rdr: true });
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  componentDidMount () {

    // Check for pagination
		this.getCustomerLinks();
 

    this.getTokens();

  }

  render() {

    const { isLoadingCustomerLinks, links, custRef,  error, customerName } = this.state;

    if (this.state.rdr) {
      return <Redirect to={"/admin/clients/details/" + custRef} />
    }

    var content = <div className='width-1 centred'>
                    <div className="loader whiteBg">Loading...</div>
                  </div>;

    var coverContent = "";
    if (isLoadingCustomerLinks !== false) {
      coverContent = <div className="cover">
        <div className='width-1 centred' styles={ styles.topMrgn }>
          <div className="loader whiteBg">Loading...</div>
        </div>
      </div>
    }

    if (!isLoadingCustomerLinks ) {
      content = <div style={ styles.form }>
									<div style={{ ...styles.bgGrey}}>
										{ links.length > 0 ? (
											<div>
			                  { links.map ( (link,i) =>
			                    <div key={'link-'+i}  className="spaced-item">
                            <h4>{ moment(link.created).format("HH:mm, DD/MM/YYYY") }: { link.sender }</h4>
                            <div style={ styles.fileLink }><a href={process.env.REACT_APP_SITE_BASE + '/download/'+link.url}  rel="noopener noreferrer" target="_blank">{process.env.REACT_APP_SITE_BASE + '/download/'+ link.url}</a> <Btn title="Cancel" type="button" btnStyle={{float:'right',marginTop:-5}} btnStyleInner={{marginTop:0,fontSize:14,borderRadius:20}} handleSubmission={ (e) => this.cancelLnk(e,link.url) } /></div> 
                            <p style={ styles.fileTitle }>Files:</p>
                            <ul style={styles.fileList}>
                            {link.files.map( (file,j) => (
                              <li key={"file-"+i+j} style={styles.file} >{file.name}</li>
                            ))}
                            </ul>
			                    </div>
			                  )}
											</div>
										) : (
											<p style={ styles.emptyMsg }>There are currently no customer links created.</p>
										) }
									</div>
                </div>;

    }

    var errorContent = "";
    if (error !== "") {
      errorContent = <div className='error mbtm' style={ styles.full }>
                      { error }
                    </div>
    }

    return (
      <div className='width-9 centred'>

        <Link style={{ ...styles.lnkRght, ...styles.grey }} to={ "/admin/clients/details/" + custRef }>Back</Link>
        <Heading mainTitle="Cancel a File Download Link" subTitle={ customerName } />

        <FlashMsg />

        { coverContent }
        { errorContent }
        { content }

      </div>
    );
  }
}

var styles = {
  full: {
    width: '99%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
  },
  lnkRght: {
    float: 'right',
    marginLeft: 5,
    marginTop: 50,
    display: 'block',
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 18,
    paddingRight: 18,
    background: Colours.grn,
    border: '1px solid ' + Colours.grn,
    color: Colours.txtWhite,
    textDecoration: 'none'
  },
	grey: {
		background: Colours.txtGrey,
		fontSize: 14
	},
  form: {
    overflow: 'auto',
    display: 'block'
  },
  half: {
    width: "49%",
    float: "left",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: "0.5%",
    marginRight: "0.5%"
  },
  label: {
    marginLeft: 8
  },
	bgGrey: {
		background: Colours.bgGrey,
		padding: "12px 20px",
		boxSizing: "border-box"
	},
	lighten: {
		opacity: "0.6",
		fontSize: 14
	},
	emptyMsg: {
		fontStyle: "italic",
		opacity: "0.6",
		marginTop: 12
	},
  fileList:{
    fontSize:14,
    marginLeft:14,
    marginBottom:14
  },
  file:{
    fontSize:14,
    marginLeft:14
  },
  fileLink:{
    marginBottom:0
  },
  fileTitle:{
    marginBottom:0,
    fontSize:14,
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(CancelLink);
