import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Fetching from '../../../functions/fetching.js';
import Colours from '../../../assets/colours.js';
import Autocomplete from '../common/autocomplete.js';

import Heading from '../heading.js';

import Btn from '../../common/btn.js';
import DatePicker from 'react-datepicker';

import "./../../../assets/css/datepicker.css";
import CommentGroup from './comment-group.js';
import Files from '../files/files.js';

import Moment from 'moment';

class CommunicationCompose extends Component {

	constructor(props) {
    super(props);

    this.state = {
			communicationId: false,
			editorReady: false,
			subject: "",
			sender: false,
			recipients: [],
			attachments: [],
			// defaultAttachments: [],
			msg: "",
			rdr: false,
			loading: false,
			fileOptions: [],
			senderSuggestions: [],
			recipientSuggestions: [],
			error: "",
			success: '',
			communicationDate:new Date(),
			lastFileUpdate:new Date(),
			isMeeting:null,
			newClientName:'',
			communicationType:''
    };

		this.selectRecipient = this.selectRecipient.bind(this);

		this.removeRecipient = this.removeRecipient.bind(this);


		this.saveCommunication = this.saveCommunication.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.setCommunicationDate = this.setCommunicationDate.bind(this);
		this.addAttachment = this.addAttachment.bind(this);
		this.storeNewName = this.storeNewName.bind(this);
		this.removeAttachment = this.removeAttachment.bind(this);
	}

	// On component mount load up the users, which triggers email loading
	componentWillMount () {
		this.getTokens();
		this.loadCommunication();
		this.getCustomers();
	}

	componentDidMount(){
		if(this.props.location.pathname.indexOf("record_call") !== -1 || (typeof this.props.isCallLog !== 'undefined'  && this.props.isCallLog)){
			this.setState({isMeeting:false});
		} else {
			this.setState({isMeeting:true})
		}
	}

	getTokens () {
		fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
		.catch(function (error) {
			console.log('Request failed', error);
		});
	}


	// Get a list of potential recipients + use it to populate the recipients autocomplete
	getCustomers () {
		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customers/emails_list/0';
		var thus = this;
		fetch(fetchUrl, { credentials:"include" })
			.then(response => response.json())
			.then(
				function (data) {

					if (typeof data.rslt != "undefined" && data.rslt === 'success') {
						thus.setState({
							recipientSuggestions: data.data
						});
					}

				}
			);
	}

	selectRecipient ( id, name ) {


		var newRecipients = [];
		var isIncluded = false;

		// If the recipient is not in the recipients array then add it
		if (!isIncluded) {
			newRecipients.push({ id: id, name: name });
		}

		this.setState({ recipients: newRecipients });
	}


	addAttachment(file){
		file.createdFormatted = Moment().format('DD/MM/YYYY');

		let fileArray = file.file_name.split('/');
		file.file_name = fileArray[fileArray.length - 1];
		let attachments = [...this.state.attachments];
		attachments.push(file);
		this.setState({attachments},() => this.setState({lastFileUpdate:new Date()}));
	}

	storeNewName(e){
		this.setState({newClientName:e.target.value})
	}

	removeRecipient ( e ) {

		e.preventDefault();

		// Get the recipient ID
		let custId = e.target.getAttribute('data-id');

		// Get the recipients list
		let { recipients } = this.state;
		var newRecipients = [];

		// Check whether the existing is already in the recipients array
		for ( var i in recipients ) {
			if ( custId !== recipients[i].id ) {
				newRecipients.push(recipients[i]);
			}
		}

		this.setState({ recipients: newRecipients });
	}

	// Handle the update of the editor
	handleEditorChange ( content ) {
		this.setState({ msg: content });
	}

	// Update subject in state
	updateSubject (e) {
		this.setState({ subject: e.target.value });
	}

	formatForFetch ( usrList ) {

		var newLst = [];
		for ( var i in usrList ) {
			newLst.push({
				id: usrList[i].id,
				name: encodeURIComponent(usrList[i].name)
			});
		}

		return newLst;

	}

	saveCommunication (e) {

		e.preventDefault();

		const isCallLog = this.props.isCallLog;

		// Set up the data for the submission
		let { communicationId, subject, msg, sender, recipients, attachments, newClientName, communicationDate,communicationType } = this.state;

		let newRecipients = this.formatForFetch(recipients);
		let newSender = this.formatForFetch(sender);

		let email_type = communicationType;

		var saveData = {
			communicationId: communicationId,
			sender: newSender,
			recipients: newRecipients,
			subject: encodeURIComponent(subject),
			msg: encodeURIComponent(msg.replace(/(?:\r\n|\r|\n)/g, "<br />")),
			attachments: attachments,
			email_type:email_type,
			commDate: communicationDate
		}

		if(recipients.length === 0) {
			saveData.newClientName = newClientName;
		}

		var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/user_emails/save_communication';

		if(this.props.isCallLog){
			fetchUrl += '?slug='+this.props.match.params.slug+'&hash='+this.props.match.params.hash
		}

		// Run the save enquiry
		let thus = this;
		fetch(fetchUrl, {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			credentials:"include",
			body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
		})
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      if (typeof data.eid != "undefined") {
		let message = '';
		  if(email_type ==='meeting-notes'){
			message = 'Your meeting notes have been saved';
		  } else {
			message = 'Your call has been saved';
		  }
		  if(!isCallLog){
				thus.setState({rdr:{pathname:'/admin/emails',state:{ 'success': message}}});
			} else {
				thus.setState({ 'success': message});
			}
      } else {
		thus.setState({ success: "", error: "There was an error saving. Please check the content and try again." });
		
	  }
	  thus.getTokens();
		window.scrollTo(0, 0);
			
    })
    .catch(function (error) {
      	thus.getTokens();
			thus.setState({ success: "", error: "There was an error saving. Please check the content and try again." });
			window.scrollTo(0, 0);
	  });

	}

	// Load up draft on page load (if necessary)
	loadCommunication () {

		var commId = -1

		 if ( typeof this.props.match.params.communicationId != 'undefined' ) {
			commId = this.props.match.params.communicationId;
		} else if (this.props.communicationId !== 'undefined'){
			commId = this.props.communicationId;
		}

		// Check whether we are viewing a draft
		if ( commId !== -1 ) {

			// Set the email ID in the state
			this.setState({ communicationId: commId });

			// Fetch the email details
			var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/user_emails/get/' + commId;

			if(this.props.isCallLog){
				fetchUrl += '?slug='+this.props.match.params.slug+'&hash='+this.props.match.params.hash
			}
				
			var thus = this;
			fetch(fetchUrl, { credentials:"include" })
				.then(response => response.json())
				.then(
					function (data) {

						if (typeof data.rslt != "undefined" && data.rslt === 'success') {
							// Set the state
							thus.setState({
								communicationId: commId,
								subject: data.data.subject,
								sender: data.data.sender,
								recipients: data.data.recipients,
								attachments: data.data.attachmentsRaw,
								msg: data.data.body,
								communicationDate:new Date(data.data.communication_date),
								communicationType:data.data.communication_type
								
							},()=> thus.setState({lastFileUpdate:new Date(),editorReady: true}));
						} else {
							thus.setState({ communicationId: false, editorReady: true });
						}

					}
				);

		} else {
			this.setState({ editorReady: true });
		}

	}


	// Update the meeting date in the state with input
	setCommunicationDate( communicationDate ) {
		// communicationDate = this.formatDate(communicationDate);
		this.setState({ communicationDate: communicationDate, communicationDateFormatted: communicationDate });
	}

	formatDate ( date ) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
	
		return [year, month, day].join('-');
	  }

	removeAttachment(fileId){
		let attachments =[]
		let currentAttachments = [...this.state.attachments];

		for(let i = 0; i< currentAttachments.length;i++){
			console.log(parseInt(currentAttachments[i]['id']));
			if(parseInt(currentAttachments[i]['id']) !== parseInt(fileId)){
				attachments.push(currentAttachments[i]);
			}
		}

		this.setState({attachments},() => this.setState({lastFileUpdate:new Date()}))
	}

	// Render the view
  render() {

		let { rdr, loading, msg, communicationDate,recipientSuggestions, recipients, error, success, subject, editorReady } = this.state;

		if ( rdr ) {
			return (
				<Redirect to={ rdr } />
			);
		}

		if ( loading ) {
			return (
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
		}

		var editor = "";
		if ( editorReady ) {
			editor = <CKEditor
						editor={ClassicEditor}
						data={msg}
						onChange={(event, editor) => {
						const data = editor.getData();
							this.handleEditorChange(data)
						}}
						config={{ toolbar: ['bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'Indent', 'Outdent', '|', 'undo', 'redo'] }}
					/>
		}

		var flashContent = "";
		if ( success !== "" ) {
			flashContent = <div className='success mbtm'>{ success }</div>
		}
		if ( error !== "" ) {
			flashContent = <div className='error mbtm'>{ error }</div>
		}

		recipientSuggestions = recipientSuggestions.map((recipient) => {
			if(recipient.is_email_group === true){
				recipient.style = {fontWeight:'bold',fontStyle:'italic'}
			}

			return recipient;
		})

		let title = (this.state.isMeeting) ? 'Compose Meeting Notes' : 'Compose Call Notes';

		let commentContent = null;

		if ( typeof this.props.match.params.communicationId != 'undefined' ) {
			commentContent = <CommentGroup communicationId={this.props.match.params.communicationId} />
		}

    return (
      <div className='content'>

		<div className="width-6 centred email-compose lrg-txt">
			<Heading mainTitle={title} />

			{ flashContent }

			<Autocomplete
				id="2"
				label="Client"
				suggestions={ recipientSuggestions }
				selection={ this.selectRecipient }
				chosen={ recipients }
				removal={ this.removeRecipient }
				onBlurCallbackHandler={this.storeNewName}
			/>

			<div style={ styles.mrgn }>
				<label htmlFor="subject">Date</label>
				<DatePicker
					selected={ new Date(communicationDate) }
					onChange={ this.setCommunicationDate }
					dateFormat="d/M/yyyy h:mm aa"
					showTimeSelect
					timeFormat="p"
					timeIntervals={15}

				/>
			</div>

			<div style={ styles.mrgn }>
				<label htmlFor="subject">Subject</label>
				<input type="text" id='subject' defaultValue={ subject } onChange={ (e) => this.updateSubject(e) } style={ styles.pad } />
			</div>

			<div style={ styles.mrgn } className='bulk-email-container'>
				<label>Notes</label>
				{ editor }
			</div>
			{typeof this.props.isNotLoggedIn !== 'undefined' &&  this.props.isNotLoggedIn  ? null :
			( <div style={ styles.mrgn }>
				
				<Files 
					isCommunication={true} 
					lastFileUpdate={this.state.lastFileUpdate} 
					attachments={this.state.attachments} 
					addAttachment={this.addAttachment} 
					removeAttachment={this.removeAttachment}
				/>
			</div>)
  			}
			

			<Btn
				type='button'
				title="Save"
				handleSubmission={ (e) => this.saveCommunication(e) }
				btnStyleInner={{ ...styles.rounded }}
				btnStyle={ styles.wrap }
			/>
			

			<div style={{clear:'both'}} ></div>

			<div style={ styles.mrgn }>
				{commentContent}
			</div>

		</div>

      </div>
    );
  }
}



const styles = {
	mrgn: {
		marginLeft: "1%",
		marginRight: "1%",
		width: '98%'
	},
	pad: {
		padding: '5px 8px',
		fontSize: '0.9em'
	},
	wrap: {
		float: 'right',
		marginLeft: 12,
		marginTop: 0
	},
	greyBtn: {
		backgroundColor: Colours.txtGrey
	},
	smlBtn: {
		fontSize: 12,
		float: 'right',
		padding: '2px 12px',
		marginTop: 12
	},
	rounded: {
		borderRadius: 25,
	}
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(CommunicationCompose);
