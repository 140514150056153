import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Fetching from './../../functions/fetching.js';
import Colours from './../../assets/colours.js';
import Download from './../../assets/img/download.svg';

class Confirm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      cbStatus: false,
      isConfirmed: false
    };

    this.handleSubmission = this.handleSubmission.bind(this);
    this.getTokens = this.getTokens.bind(this);
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  handleCheck (e) {

    let status = e.target.checked;
    this.setState({ cbStatus: status });

  }

  // Handle the submission of a user
  handleSubmission ( event ) {

    event.preventDefault();

    let { cbStatus } = this.state;

    if (!cbStatus) {
      alert('Sorry, but before you submit your data you must confirm you have completed the form by ticking the checkbox.');
    } else {

      var thus = this;
      var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/customer_submissions/submit/' + this.props.customerToken + "/" + this.props.customerMash + "/" + this.props.customerTokenKey;
      fetch(fetchUrl, {
        method: 'get',
        credentials:"include"
      })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        console.log('Request succeeded with JSON response', data);
        if (typeof data.rslt != 'undefined' && data.rslt === 'success') {
          thus.setState({ isConfirmed: true });
        } else {
          alert("We're really sorry but there was an error submitting your response. Please check that you have completed all the sections and try again.");
        }

        thus.getTokens();
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });

    }

  }

  // Fetch the user data
  componentDidMount () {
    this.getTokens();
  }

  render() {

    const { isConfirmed } = this.state;

    if (isConfirmed) {
      return (
        <Redirect to="/thanks" />
      );
    }

    return (
      <div className="width-9 tab-body" style={ styles.width }>

        <h2 style={ styles.head2 }>Thank you</h2>
        <p style={ styles.intro }>Thank you for completing our form. The
        information you have provided will help us provide the best advice for
        you and your family.</p>
        <p>If you are happy that you have provided all the information you can,
        please confirm this by ticking the checkbox below and select the &quot;submit&quot;
        button to send your data to our team. If
        you wish to make changes you can edit any of the sections
        using the tabs on the left hand side.</p>
        <p>Please note: Once submitted you will not be able to view your questionnaire
        via your current link. We recommend you review each of the tabs on the left
        which show as incomplete to make sure you have provided as much information
        as you can.</p>

        <div style={ styles.cb }>
          <input type="checkbox" value="1" defaultChecked="" id="confirmBox" onChange={ (e) => this.handleCheck(e) } />
          <label htmlFor="confirmBox" style={ styles.label }>I confirm that I have completed the form and it is accurate
            to the best of my knowledge</label>
        </div>

        <p>If you wish to retain a copy of the data you have entered, you can download and save a PDF version using the &quot;Export&quot; button below.</p>

        <div style={ styles.centred }>
          <a href={process.env.REACT_APP_API_BASE + "/questionnaire_sections/generate_client_pdf/" + this.props.customerToken + '/' + this.props.customerMash + '/' + this.props.customerTokenKey} style={ styles.download } target="_blank" rel="noopener noreferrer">Export</a>
          <button lnk="/questionnaire" onClick={ this.handleSubmission } style={ styles.btn }>Submit</button>
        </div>

      </div>
    );
  }
}

var styles = {
  width: {
    paddingRight: 28,
    boxSizing: 'border-box'
  },
  head2: {
    color: Colours.grn,
    marginTop: 16,
    boxSizing: 'border-box',
    marginBottom: 22
  },
  intro: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '28px'
  },
  label: {
    marginLeft: 15
  },
  cb: {
    margin: 35,
    fontStyle: 'italic'
  },
  centred: {
    textAlign: 'center'
  },
  btn: {
    background: Colours.grn,
    padding: '5px 22px',
    display: 'inline-block',
    marginLeft: 8,
    border: 'none',
    outline: 'none',
    color: Colours.txtWhite,
    fontSize: 16,
    cursor: "pointer"
  },
  download: {
    margin: '0',
    background: Colours.lightishGrey + " url('" + Download + "') right 12px center / 16px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
    padding: '5px 52px 5px 22px',
    display: 'inline-block'
  }
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash
  };
}

export default connect(mapStateToProps)(Confirm);
