import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

import Tab from './tab.js';
import TabContent from './tab-content.js';

import Heading from './heading.js';

import Colours from './../../assets/colours.js';
import menuIcon from './../../assets/img/menu-icon.svg';

class TabBlock extends Component {
  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      sxns: [],
      error: false,
      isLoading: true,
      canAccess: false,
      firstSxn: '',
      menuStatus: "closed",
      showConfirm: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.checkCompleteness = this.checkCompleteness.bind(this);
  }

  // Check the tokens
  loadContent () {
    console.log('LOADING CONTENT');
    var { token, mash, tokenKey } = this.props.match.params;

    // If we have a token mash and key then store them to redux + possibly to cookie?!
    if (typeof token !== 'undefined' && typeof mash !== 'undefined' && typeof tokenKey !== 'undefined' && token !== '' && mash !== '' && tokenKey !== '') {
      this.props.dispatch({ type: 'SET_CUSTOMER_TOKENS', token: token, tokenKey: tokenKey, mash: mash });
      // Store the data to a cookie
      const { cookies } = this.props;
      var tokenData = { token: token, tokenKey: tokenKey, mash: mash };
      cookies.set('AshCli', tokenData, { path: '/'});

    } else if (this.props.customerToken !== '' && this.props.customerTokenKey === '' && this.props.customerMash === '') {
      token = this.props.customerToken;
      tokenKey = this.props.customerTokenKey;
      mash = this.props.customerMash;
    } else {

      // We do not have anything from the URL and there is nothing in the state
      // So check for cookies
      const { cookies } = this.props;
      var cookieData = cookies.get('AshCli');
      if (typeof cookieData !== 'undefined' && typeof cookieData.token !== 'undefined' && typeof cookieData.mash !== 'undefined' && typeof cookieData.tokenKey !== 'undefined' && cookieData.token !== '' && cookieData.mash !== '' && cookieData.tokenKey !== '') {
        this.props.dispatch({ type: 'SET_CUSTOMER_TOKENS', token: cookieData.token, tokenKey: cookieData.tokenKey, mash: cookieData.mash });
        token = cookieData.token;
        tokenKey = cookieData.tokenKey;
        mash = cookieData.mash;
      } else {
        this.setState({ isLoading: false, error: true });
      }

    }

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/questionnaire_sections/list/' + token + '/' + mash + '/' + tokenKey + '/';
    var pathname = this.props.location.pathname;
    var thus = this;
    fetch(fetchUrl)
      .then(response => response.json())
      .then(
        function (data) {

          // Check whether we have a failure
          if (typeof data.error !== 'undefined' && data.error === 'auth') {

            // Redirect to the error page
            thus.setState({ error: true });
            return false;

          } else {
            // Process the data for display of the tabs
            var firstRequired = '';
            for (var i in data) {
              data[i].activeState = (pathname === '/questionnaire/' + data[i].slug)?'active':'';
              data[i].disabledClass = (data[i].isRequired === 0)?'disabled':'';
              if (data[i].isRequired !== 0 && firstRequired === '') {
                firstRequired = data[i].slug;
                if (pathname === '/questionnaire' || pathname === '/questionnaire/') {
                  data[i].activeState = 'active';
                }
              }
            }

            // Final check to make sure there is a section included
            if (firstRequired === '') {
              firstRequired = 'personal-details';
            }

            thus.setState({ sxns: data, isLoading: false, firstSxn: firstRequired });
            thus.props.dispatch({ type: 'SET_SXN_DATA', sxns: data });
            return data;
          }
        }
      );

  }

  checkCompleteness () {

    let { sxns } = this.state;

    var unaddressed = 0;
    for (var i in sxns) {
      if (sxns[i].isAddressed !== 1 && sxns[i].isAddressed !== "1" && sxns[i].isRequired === 1) {
        unaddressed++;
      }
    }

    if (unaddressed === 0) {
      this.setState({ showConfirm: true });
    }

  }


  // componentDidMount - used to trigger the data fetch
  componentDidMount() {
    this.loadContent();
  }

  componentWillReceiveProps (newProps) {
    // var sxns = newProps.sxns;
    // this.setState({ sxns: sxns });
  }

  // Handle the display of the correct tab as active
  handleChange ( chosenTab, completeSxnSlug ) {
console.log("Change active to: " + chosenTab);
    var { sxns } = this.state;
    for (var i in sxns) {
      if ( sxns[i].slug === chosenTab ) {
        sxns[i].activeState = 'active';
      } else {
        sxns[i].activeState = '';
      }
      // If we have a just completed section slug then handle it
      if (typeof completeSxnSlug != 'undefined' && sxns[i].slug === completeSxnSlug) {
        sxns[i].isAddressed = "1";
      }
    }
    this.setState({ sxns: sxns, isLoading: false });
    this.toggleMenu(1, "closed");
    console.log("HANDLING CHANGE");
    console.log(sxns);
  }

  toggleMenu ( e, shutIt ) {
    if (e !== 1) {
      e.preventDefault();
    }
    var { menuStatus } = this.state;
    if (typeof shutIt == 'undefined') {
      menuStatus = (menuStatus === 'open')?'closed':'open';
    } else {
      menuStatus = shutIt;
    }
    this.setState({ menuStatus: menuStatus });
  }

  render() {
    // If there is an error screen then redirect to the error screen
    if (this.state.error !== false) {
      return <Redirect to={ '/error' } />
    }

    const { isLoading, menuStatus, showConfirm, sxns } = this.state;

    var pathname = this.props.location.pathname;
    if (showConfirm === true && pathname !== "/questionnaire/confirm") {
      return <Redirect to={ '/questionnaire/confirm' } />
    }

    if (isLoading) {
      return (
        <div className='content'>
          <div className='width-1 centred'>
            <div className="loader">Loading...</div>
          </div>
        </div>
      );
    }

    const TabContentWithProps = (props) => {
      return (
        <TabContent
          handleChange={ this.handleChange }
          showConfirm={ showConfirm }
          checkCompleteness={ this.checkCompleteness }
          {...props}
        />
      );
    }

    return (
      <div className='content' style={ styles.coreContent }>
        <Heading mainTitle="Update Your Details" subTitle={ this.props.initName } />

        <div className='width-3 menu-wrapper' style={ styles.container }>
          <ul className={ 'tabs ' + menuStatus }>
            <li className="switch"><a href="#open" className={ "sxnsMenu " + menuStatus } style={ styles.menu } onClick={ (e) => this.toggleMenu(e) }>Menu</a></li>
            { sxns.map(sxn =>
              <Tab 
                key={ sxn.slug } 
                title={ sxn.title } 
                lnk={ sxn.slug } 
                isComplete={ sxn.isAddressed } 
                handleChange={this.handleChange} 
                activeClass={ sxn.activeState } 
                disabledClass={ sxn.disabledClass } 
              />
            )}
            <Tab key="confirm" title="Confirm & Submit" lnk="confirm" isComplete="0" handleChange={this.handleChange} isConfirm={ true } activeClass={ pathname.indexOf("/questionnaire/confirm") > -1 ? "active":"" } disabledClass="" />
          </ul>
        </div>

        <Switch>
          <Route exact path='/questionnaire' render={(props) => <TabContent firstSxn={this.state.firstSxn} checkCompleteness={ this.checkCompleteness } handleChange={ this.handleChange } {...props} />} />
          <Route exact path='/questionnaire/confirm' render={(props) => <TabContent firstSxn={this.state.firstSxn} handleChange={ this.handleChange } showConfirm="true" {...props} />} />
          <Route path='/questionnaire/:tabno' component={ TabContentWithProps } />
        </Switch>

      </div>
    );
  }
}

var styles = {
  container: {
    marginTop: 35,
    zIndex: 2,
    position: 'relative'
  },
  coreContent: {
    marginBottom: 75
  },
  menu: {
    background: Colours.grn + " url(" + menuIcon + ") center right 20px / 16px auto no-repeat"
  }
};

function mapStateToProps(state) {
  return {
    customerToken: state.customer.token,
    customerTokenKey: state.customer.tokenKey,
    customerMash: state.customer.mash,
    initName: state.customer.initName,
    sxns: state.sxns
  };
}

export default withCookies(connect(mapStateToProps)(TabBlock));
