const options = {
  network: {
    autoResize: true,
    height: "100%",
		interaction: {
			dragNodes: false,
		},
    nodes: {
      widthConstraint: 55,
      heightConstraint: 20
    },
    physics: false,
    layout: {
      improvedLayout: true,
    }
  },
  level:{
    first:
      {
        centre:{x:50,y:20},
      },
  },
	nodeSizes: {
		'level-0': {
			widthConstraint: 200,
      heightConstraint: 52
		},
		'level-1': {
			widthConstraint: 100,
      heightConstraint: 38
		},
		'level-2': {
			widthConstraint: 70,
      heightConstraint: 20
		},
		'level-3': {
			widthConstraint: 55,
      heightConstraint: 20
		},
		'level-4': {
			widthConstraint: 55,
      heightConstraint: 20
		},
	}
}

export default options;
