import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import Fetching from './../../../functions/fetching.js';


class QuestionDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      qid: this.props.match.params.qid,
      rdr: false
    };
  }

  // Fetch the user data
  componentDidMount () {
    var thus = this;
    if (typeof this.props.match.params.qid != 'undefined') {
      fetch(process.env.REACT_APP_API_BASE + '/admin/questionnaire_questions/delete/' + this.props.match.params.qid, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
          function (data) {
            console.log(data);
            // Check whether we have a failure
            if (typeof data.error != 'undefined') {

              // Redirect to the error page
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: data.msg });
              return false;

            } else {
              // Process the data for display of the tabs
              thus.setState({ rdr: true });
              thus.props.dispatch({ type: 'SET_ADMIN_SUCCESS', msg: 'The question has been successfully deleted.' });
              return data;
            }
          }
        );

      } else {
        // Do a redirect
        thus.props.dispatch({ type: 'SET_ADMIN_ERROR', error: 'Sorry but there was an error deleting the question. Please try again.' });
        thus.setState({ rdr: true });
      }

  }

  render() {

    if (this.state.rdr === true) {
      return (
        <Redirect to='/admin/questions' />
      );
    }

    return <div className='width-1 centred'>
            <div className="loader whiteBg">Loading...</div>
          </div>;

  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(QuestionDelete);
