import React, { Component } from 'react';

import Colours from './../../../assets/colours.js';
import CFInput from './../custom-fields/input.js';
import ClientName from './../custom-fields/client-name.js';

import LinkIcon from './../../../assets/img/link-icon.svg';

import GenerateLink from './generate-link.js';

class CustomFieldData extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      cfs: [],
      custName: ""
    };

    this.loadContent = this.loadContent.bind(this);
  }

  componentWillMount () {
    this.loadContent();
  }

  // Check the tokens
  loadContent () {

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/admin/custom_fields/get_user_info/' + this.props.custRef;
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {

          thus.setState({ cfs: data.cfs, custName: data.name });

        }
      );

  }


  render() {

    const { cfs, custName } = this.state;

    if (cfs.length === 0) {
      return (
        <div className='width-1 centred'>
          <div className="loader">Loading...</div>
        </div>
      );
    }

    return (
      <div style={ styles.cfBlock } className="cf-block width-8">

        <GenerateLink 
          styles={{ ...styles.btn, ...styles.fullBtn }}
          lightStyles={ styles.btn }
          custRef={ this.props.custRef }
        />

        <h2>Core Client Information</h2>

        <ClientName custName={ custName } custRef={ this.props.custRef } />

        { cfs.map( cf =>
          <CFInput cf={ cf } custRef={ this.props.custRef } key={ cf.id } />
        )}


      </div>


    );
  }
}

var styles = {
  cfBlock: {
    marginBottom: 25,
    background: Colours.bgGrey,
    padding: "12px 20px",
    boxSizing: "border-box",
    overflow: "auto"
  },
  btn: {
    backgroundColor: Colours.lightishGrey,
    backgroundImage: 'url("' + LinkIcon + '")',
    backgroundSize: "24px auto",
    backgroundPosition: "left 12px center",
    backgroundRepeat: "no-repeat",
    float: "right",
    display: "block",
    padding: "5px 15px 5px 42px",
    color: Colours.txtWhite,
    textDecoration: "none",
    fontSize: "14px",
    borderRadius: "25px",
    boxShadow: "",
    border: "none",
    cursor: "pointer"
  },
  fullBtn: {
    backgroundColor: Colours.txtGrey,
    marginTop: 0,
    marginRight: 12
  }
};

export default CustomFieldData;
