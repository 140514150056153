import React, { Component } from 'react';

class FilterInput extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {

    };

    this.updateFilter = this.updateFilter.bind(this);
  }

  componentWillReceiveProps ( newProps ) {

    if ( typeof newProps != 'undefined' && typeof newProps.errorFields != 'undefined' && typeof newProps.errorFields[newProps.repeatNo + '-' + newProps.qId] != 'undefined' ) {
      this.setState({ hasError: true });
    }

  }

  updateFilter (e, cfId, fieldType) {
    if (fieldType !== "radio") {
      e.preventDefault();
    }
    this.props.updateFilter(e, cfId, fieldType);
  }

  render () {

    if (this.props.cf.type === 'select'  ) {

      return (
        <div className='question' key={ this.props.qid }>
          <label className={ this.props.cf.isRequired === "1" ? 'required' : '' } htmlFor={ "cf-" + this.props.cf.id }>{ this.props.cf.label }</label>
          <select style={ styles.selMBtm } name={ "cf-" + this.props.cf.id } defaultValue={ typeof this.props.cf.entry != 'undefined' ? this.props.cf.entry : "" } id={ "cf-" + this.props.cf.id } onChange={ (e) => this.updateFilter(e, this.props.cf.id, this.props.cf.type) }>
            <option></option>
              { this.props.cf.options.map( option =>
                <option key={ option.id } value={ option.id }>{ option.content }</option>
              )}
            </select>
        </div>
      );

    } else if (this.props.cf.type === 'radio') {

      return (
        <div className='question' key={ this.props.qid }>
          <label className={ this.props.cf.isRequired === "1" ? 'required' : '' } htmlFor={ "cf-" + this.props.cf.id }>{ this.props.cf.label }</label>

          <div style={ styles.radioGrp } className="radio-group">
            { this.props.cf.options.map( option =>
              <div style={ styles.radio } key={ option.id }>
                <input id={ "cf-" + option.id }  style={ styles.radioInput } type='radio' value={ option.id } defaultChecked={ typeof this.props.cf.entry != 'undefined' && this.props.cf.entry === option.id ? "checked" : "" } name={ "cf-" + this.props.cf.id } onChange={ (e) => this.updateFilter(e, this.props.cf.id, this.props.cf.type) } />
                <label htmlFor={ "cf-" + option.id } style={ styles.radioLabel } dangerouslySetInnerHTML={{ __html: option.content }}></label>
              </div>
            )}
          </div>
        </div>
      );

    } else if (this.props.cf.type === 'text' || (this.props.cf.type === 'default' && this.props.cf.label !== "Progress" && this.props.cf.label !== "Created")) {

      var cfId;
      if (this.props.cf.type === 'default') {
        cfId = (this.props.cf.label === 'Reference')?'reference':'name';
      } else {
        cfId = this.props.cf.id;
      }

      return (
        <div className='question' key={ this.props.qid }>
          <label className={ this.props.cf.isRequired === "1" ? 'required' : '' } htmlFor={ "cf-" + this.props.cf.id }>{ this.props.cf.label }</label>
          <input name={ this.props.cf.id } defaultValue={ typeof this.props.cf.entry != 'undefined' ? this.props.cf.entry : "" } type="text" id={ this.props.cf.id } onBlur={ (e) => this.updateFilter(e, cfId, "text") } />
        </div>
      );

    } else {

      return "";

    }

  }

}

const styles = {
  radio: {
    margin: "25px 0"
  },
  radioInput: {
    float: "left",
    margin: "5px 25px 5px 12px"
  },
  radioLabel: {
    marginLeft: 50,
    display: 'block'
  },
  strong: {
    fontWeight: 600
  },
  selMBtm: {
    marginBottom: 25
  }
}

export default FilterInput;
