import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'

import Colours from './../../assets/colours.js';

class Paginate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curPage: this.props.currentPage
    };
  }

  handleClick = (newPage) => {
    this.setState({ curPage: newPage });
  }

  // Render the view
  render() {

    var pageNos = [];
		if (this.props.totalPages <= 10) {
	    for (var i=1; i <= this.props.totalPages; i++) {
	      pageNos.push(i);
	    }
		} else {
			if ( this.props.nextPage <= 9 ) {
				pageNos = [1,2,3,4,5,6,7,8,9,10];
			} else if (this.props.totalPages - this.props.prevPage <= 2) {
				for (var j=this.props.totalPages - 10; j <= this.props.totalPages; j++) {
		      pageNos.push(j);
		    }
			} else {
				for (var k=Number(this.props.currentPage) - 7; k <= Number(this.props.currentPage) +2; k++) {
		      pageNos.push(k);
		    }
			}
		}

    if (this.props.totalPages > 1) {

      return (
        <div>
          <p style={ styles.para }>Page { this.state.curPage } of { this.props.totalPages }</p>
          <p style={{ ...styles.para, ...styles.rght }}>
						{ Number(this.props.currentPage) !== 1 ? (
							<Fragment>
		            <Link style={ styles.lnk } to={ this.props.urlBase + 1 } onClick={ (e) => this.handleClick(1) }>&lt;&lt;</Link>
		            <Link style={ styles.lnk } to={this.props.urlBase + this.props.prevPage} onClick={ (e) => this.handleClick(this.props.prevPage) }>&lt;</Link>
							</Fragment>
						) : "" }
            { pageNos.map( no =>
							<Fragment key={ no }>
								{ no !== Number(this.props.currentPage) ? (
              		<Link style={ styles.lnk } to={ this.props.urlBase + no } onClick={ (e) => this.handleClick(no) }>{ no }</Link>
								) : (
									<span style={ styles.span }>{ no }</span>
								)}
							</Fragment>
            )}
						{ Number(this.props.currentPage) !== Number(this.props.totalPages) ? (
							<Fragment>
		            <Link style={ styles.lnk } to={ this.props.urlBase + this.props.nextPage} onClick={ (e) => this.handleClick(this.props.nextPage) }>&gt;</Link>
		            <Link style={ styles.lnk } to={ this.props.urlBase + this.props.totalPages } onClick={ (e) => this.handleClick(this.props.totalPages) }>&gt;&gt;</Link>
							</Fragment>
						) : "" }
          </p>
        </div>
      );

    } else {
      return <div></div>;
    }
  }
}

const styles = {
  para: {
    fontSize: 14,
    marginTop: 22,
    float: 'left'
  },
  rght: {
    float: 'right'
  },
  lnk: {
    color: Colours.txtBlack,
    display: 'inline-block',
    marginLeft: 4
  },
	span: {
    color: Colours.txtBlack,
    display: 'inline-block',
    marginLeft: 4
  }
};

export default Paginate;
