import React, { Component } from 'react';

class Heading extends Component {
  render() {
    return (
      <div className='width-12'>
        <h1>{ this.props.mainTitle }</h1>
        <h2>{ this.props.subTitle }</h2>
      </div>
    );
  }
}

export default Heading;
