import React, { Component } from 'react';
import Moment from 'moment';
import Btn from '../../common/btn';


class CommentBubble extends Component {

  // Render the view
  render() {

	let newCommentContent = this.props.newCommentContent;

	if(typeof this.props.isNew !== 'undefined'  && this.props.isNew === true){
		return (
			<div className='comment-bubble faded'>
				<h3>Add a comment</h3>
				<h4>
					{this.props.user.first_name +' '+this.props.user.last_name}
					<span className='right comment-bubble-date'>{Moment().format('H:mma, Do MMMM YYYY ')}</span>
				</h4>
				<textarea
					name={'newCommentContent'}
					value={newCommentContent }
					onChange={ (e) => this.props.updateCommentField(e) }
					style={{resize:'none'}} >
				</textarea>
				<Btn
					type='button'
					title="Save Comment"
					handleSubmission={ (e) => this.props.saveComment(e) }
					btnStyleInner={{...styles.smlBtn}}
					btnStyle={ styles.wrap }
				/>
			</div>
		)
	} else {
		return (
			<div className='comment-bubble'>
				<h4>
					{this.props.user}
					<span className='right comment-bubble-date'>{Moment(this.props.comment.created).format('H:mm, Do MMMM YYYY ')}</span>
				</h4>
				<p>{this.props.comment.content}</p>
			</div>
		)
	}
  }
}


const styles = {

	wrap: {
		float: 'right',
		marginLeft: 12,
		marginTop: 0,
		marginBottom: 0
	},

	smlBtn: {
		fontSize: 14,
		float: 'right',
		padding: '2px 12px',
		marginTop: 0,
		borderRadius: 10,
	},

};


export default CommentBubble;
