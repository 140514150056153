import React, { Component } from 'react';
import Header from '../common/header.js';

import Footer from '../common/footer.js';
import Communication from './emails/communication.js';
import Fetching from '../../functions/fetching.js';


class CallLogTemplate extends Component {


  constructor(props){
    super(props);
    this.state = {
      loading:true,
      isValidUrl:false,
      isLoggedIn:false,
      communicationId:false,
      errorMessage:false
    }

    this.checkValidUrl = this.checkValidUrl.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  componentWillMount () {
		this.checkValidUrl();
	}

  checkValidUrl(){
    var fetchUrl = process.env.REACT_APP_API_BASE + '/call_log/verify_hash/' + this.props.match.params.slug+'/'+ this.props.match.params.hash;
    var thus = this;
    fetch(fetchUrl, { credentials:"include" })
      .then(response => response.json())
      .then(
        function (data) {
          if(data.rslt === 'success'){
            thus.setState({communicationId:data.communication_id,isValidUrl:true})
            thus.checkStatus();
          } else {
            thus.setState({
              errorMessage:'This link is not valid',
              loading:false
            })
          }
        }
      );
  }
  // Fetching

  checkStatus () {
    // TODO - Fix this one up...
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/login', { method: 'get', credentials: "include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {

        var isLoggedIn = (data.status === 'authenticated') ? true : false;

        thus.setState({isLoggedIn:isLoggedIn,loading:false})
        
      })
      .catch(function (error) {
        thus.setState({ auth: false });
        console.log('Request failed', error);
      });
    

  }



  render() {

    const {loading,communicationId,isLoggedIn,errorMessage} = this.state;

    if ( loading ) {
			return (
				<div className='width-1 centred'>
					<div className="loader whiteBg">Loading...</div>
				</div>
			);
    }
    


    return (
      <div >
        {isLoggedIn ? <Header isAdmin={ true }/> : <Header/> }

        {errorMessage ? <p>{errorMessage}</p> : (
        
        <Communication isCallLog={true} isNotLoggedIn={!isLoggedIn} communicationId={communicationId} {...this.props} />
        )}

        <Footer />
      </div>
    );
  }
}

export default CallLogTemplate;
