import React, { Component } from 'react';

import Colours from './../../../../assets/colours.js';
import pound from './../../../../assets/img/pound.svg';
import perc from './../../../../assets/img/perc.svg';
// import {_doRound} from './data-generator.js'

class Withdrawals extends Component {

  renderStartingWithdrawalAmount(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay,startingWithdrawalRate){
    //console.log(withdrawal);
    return (
      <div key={'wd-'+calculatorIndex+'-'+i} className="adjustments" >

      <div className="inf-container">
        <label htmlFor={ "inflation-" + i }>Inf</label>
        <input id={ "inflation-" + i } type='checkbox' defaultChecked={parseInt(withdrawal.has_inflation_applied) === 1} name="inflation" onChange={(e) => callbacks.handleWithdrawalInflationChange(e,calculatorIndex,i)} />
      </div>
			<div className="amt">
				<label htmlFor={ "val-" + i }>Value</label>
      	<input id={ "val-" + i } type='text' value={withdrawal.value}  name="value"  onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} style={ styles.money } />
			</div>

			<div className="age-container">
				<label htmlFor={ "age-" + i }>Start</label>
      	<input id={ "age-" + i } type='text' className='narrow' name="start_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} value={withdrawalDisplay.start_age} />
			</div>

			<div className="age-container">
				<label htmlFor={ "endage-" + i }>End</label>
      	<input type='text' className='narrow' name="end_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} value={withdrawalDisplay.end_age}/>
			</div>


    </div>
    )
  }

  renderPercentage(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay){

    return (
      <div key={'wd-'+calculatorIndex+'-'+i} className="adjustments">
      <div className="inf-container">
        <label htmlFor={ "inflation-" + i }>Inf</label>
        <input id={ "inflation-" + i } type='checkbox' defaultChecked={parseInt(withdrawal.has_inflation_applied) === 1} name="inflation"  onChange={(e) => callbacks.handleWithdrawalInflationChange(e,calculatorIndex,i)} />
      </div>
			<div className="type">
				<label htmlFor={ "amount-" + i }>Type</label>
	      <select value='percentage' onChange={(e) => callbacks.handleWithdrawalTypeChange(e,calculatorIndex,i)}>
	        <option value='lump_sum'>Lump Sum</option>
	        <option value='percentage'>Percentage (%)</option>
	        <option value='amount'>Monthly Amount (£)</option>
	      </select>
			</div>

			<div className="amt">
				<label htmlFor={ "val-" + i }>Value</label>
      	<input id={ "val-" + i } type='text' value={ withdrawalDisplay.value }  name="value"  onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} style={ styles.perc } />
			</div>

			<div className="age-container">
				<label htmlFor={ "age-" + i }>Start</label>
      	<input id={ "age-" + i } type='text' className='narrow' name="start_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} value={withdrawalDisplay.start_age} />
			</div>

			<div className="age-container">
				<label htmlFor={ "endage-" + i }>End</label>
      	<input type='text' className='narrow' name="end_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} value={withdrawalDisplay.end_age}/>
			</div>

      <span onClick={(e) => callbacks.handleWithdrawalFieldRemove(e,calculatorIndex,i)} className='close-btn' >x</span>
    </div>
    )
  }

  renderLumpSum(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay){

    return (
      <div key={'wd-'+calculatorIndex+'-'+i} className="adjustments">
        <div className="inf-container">
          <label htmlFor={ "inflation-" + i }>Inf</label>
          <input id={ "inflation-" + i } type='checkbox' defaultChecked={parseInt(withdrawal.has_inflation_applied) === 1} name="inflation"   onChange={(e) => callbacks.handleWithdrawalInflationChange(e,calculatorIndex,i)} />
        </div>
				<div className="type">
					<label htmlFor={ "amount-" + i }>Type</label>
	        <select value='lump_sum' onChange={(e) => callbacks.handleWithdrawalTypeChange(e,calculatorIndex,i)}>
	          <option value='lump_sum'>Lump Sum</option>
	          <option value='percentage'>Percentage (%)</option>
	          <option value='amount' >Monthly Amount (£)</option>
	        </select>
				</div>

				<div className="amt">
					<label htmlFor={ "val-" + i }>Value</label>
					<input id={ "val-" + i } type='text' value={ withdrawalDisplay.value } name="value"  onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} style={ styles.money } />
				</div>

				<div className="age-container">
					<label htmlFor={ "age-" + i }>Age</label>
        	<input id={ "age-" + i } type='text' className='narrow'  value={withdrawalDisplay.start_age} name="start_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} />
				</div>

        <span onClick={(e) => callbacks.handleWithdrawalFieldRemove(e,calculatorIndex,i)} className='close-btn'>x</span>
      </div>
    );
  }

  renderAmount(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay){

    return (
      <div key={'wd-'+calculatorIndex+'-'+i} className="adjustments">
        <div className="inf-container">
          <label htmlFor={ "inflation-" + i }>Inf</label>
          <input id={ "inflation-" + i } type='checkbox' defaultChecked={parseInt(withdrawal.has_inflation_applied) === 1} name="inflation"  onChange={(e) => callbacks.handleWithdrawalInflationChange(e,calculatorIndex,i)} />
        </div>
				<div className="type">
					<label htmlFor={ "amount-" + i }>Type</label>
	        <select value='amount' onChange={(e) => callbacks.handleWithdrawalTypeChange(e,calculatorIndex,i)}>
	          <option value='lump_sum'>Lump Sum</option>
	          <option value='percentage'>Percentage (%)</option>
	          <option value='amount'>Monthly Amount (£)</option>
	        </select>
				</div>

				<div className="amt">
					<label htmlFor={ "val-" + i }>Value</label>
					<input id={ "val-" + i } type='text' value={withdrawalDisplay.value}  name="value"  onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} style={ styles.money } />
				</div>

				<div className="age-container">
					<label htmlFor={ "age-" + i }>Start</label>
        	<input id={ "age-" + i } type='text' className='narrow'  value={ withdrawalDisplay.start_age } name="start_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} />
				</div>

				<div className="age-container">
					<label htmlFor={ "endage-" + i }>End</label>
        	<input id={ "endage-" + i } type='text' className='narrow'  value={ withdrawalDisplay.end_age } name="end_age" onChange={(e) => callbacks.handleWithdrawalFieldInputChange(e,calculatorIndex,i)} />
				</div>

         <span onClick={(e) => callbacks.handleWithdrawalFieldRemove(e,calculatorIndex,i)} className='close-btn'>x</span>
      </div>
    );
  }

  render() {

   const calculatorIndex = this.props.index;
   const callbacks = this.props.withdrawalCallbacks;
   const startingWithdrawalRate = this.props.startingWithdrawalRate
   // const target = this.props.target
   // const calculatorInputCallbacks = this.props.calculatorInputCallbacks
   // console.log( this.props.withdrawals);

   const withdrawals = this.props.withdrawals.map((withdrawal,i) => {
     if(withdrawal.can_edit === 1){
       const withdrawalDisplay = this.props.withdrawal_displays[i];

       switch(withdrawal.type){
         case 'lump_sum':
         default:
          return this.renderLumpSum(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay);
         case 'amount':
          return this.renderAmount(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay);
         case 'percentage':
          return this.renderPercentage(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay);
        case 'starting_amount':
          return this.renderStartingWithdrawalAmount(withdrawal,calculatorIndex,i,callbacks,withdrawalDisplay,startingWithdrawalRate);

       }
     } else {
       return null;
     }

   })

    return (
      <div>

	      { withdrawals }

	      <button onClick = {(e) => callbacks.handleWithdrawalFieldAdd(e,calculatorIndex) } className="addn">Add</button>

      </div>
    );
  }
}

const styles = {
	money: {
    paddingLeft: 14,
    background: Colours.bgWhite + " url(" + pound + ") left 6px center / 5px auto no-repeat",
  },
	perc: {
		paddingRight: 14,
    background: Colours.bgWhite + " url(" + perc + ") right 6px center / 8px auto no-repeat",
	}
};

export default Withdrawals;
