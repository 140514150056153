import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import QuestionsList from './q-list.js';
import QuestionDelete from './q-delete.js';

import CustomerContent from './customer-content.js';

class Content extends Component {
  render() {

    return (
      <div className='content'>
        <Switch>
          <Route path='/admin/questions/delete/:qid' component={ QuestionDelete }/>
          <Route path='/admin/questions/:sxnSlug' component={ QuestionsList }/>
					<Route exact path='/admin/questions' component={ QuestionsList }/>
          <Route path='/admin/contents/:type/:custRef' component={ CustomerContent }/>
        </Switch>
      </div>
    );
  }
}

export default Content;
