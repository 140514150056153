import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';

import pound from './../../../assets/img/pound.svg'

class MapInput extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      token: this.props.formToken,
      tokenKey: this.props.formTokenKey,
			textContent: (typeof this.props.response != "undefined") ? this.props.response : ""
    };

    this.saveField = this.saveField.bind(this);
    this.getTokens = this.getTokens.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.saveTextareaUpdate = this.saveTextareaUpdate.bind(this);
  }

  componentWillReceiveProps ( newProps ) {

    if ( typeof newProps != 'undefined' && typeof newProps.errorFields != 'undefined' && typeof newProps.errorFields[newProps.repeatNo + '-' + newProps.qId] != 'undefined' ) {
      this.setState({ hasError: true });
    }

  }

  componentDidMount() {
    this.getTokens();
  }

  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  // Add in commas to the numeric values (if required)
  handleCommas (e) {

    var enteredVal = e.target.value;

    enteredVal = enteredVal.replace(/[^0-9,.]/g, "");

    // Strip out commas
    enteredVal = enteredVal.replace(/,/g, "");

    // Work out how many charaters before any decimal points
    enteredVal = enteredVal.split(".");
    let length = enteredVal[0].length;

    if (length > 3) {
      var mod = length % 3;
      if (mod === 0) mod = 3;
      enteredVal[0] = enteredVal[0].slice(0,mod) + "," + enteredVal[0].slice(mod) ;

      let commaNo = length / 3 - 1;

      // loop through and add in the commas
      for ( var i = 1; i < commaNo; i++ ) {
        enteredVal[0] = enteredVal[0].slice(0, ((Number(i)*3) + Number(i) + mod)) + "," + enteredVal[0].slice(((Number(i)*3) + Number(i) + mod));
      }
    }

    // Bring the string back together
    enteredVal = enteredVal.join(".");

    e.target.value = enteredVal;

  }

  saveField (e, qId, repeatNo, isSelect, isRadio, respContent) {

    if (!isRadio && typeof respContent == "undefined") {
      e.preventDefault();
    }

		if (typeof respContent == "undefined") {
			respContent = e.target.value;
		}

    if (this.props.readOnly) {
      return;
    }

    // Figure out the response object
    var saveData = {
      qid: qId,
      repeatNo: this.props.repeatNo,
      response: encodeURIComponent(respContent.replace(/(?:\r\n|\r|\n)/g, "<br />"))
    }
    if (isSelect) {
      saveData.aid = saveData.response;
    }

    // Run the save
    var thus = this;
    var fetchUrl;
    fetchUrl = process.env.REACT_APP_API_BASE + '/admin/questionnaire_responses/update/' + this.props.custRef;

    fetch(fetchUrl, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      credentials:"include",
      body: 'data=' + JSON.stringify(saveData) + '&' + this.props.tokenKey + '=' + this.props.token
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      console.log('Request succeeded with JSON response', data);

      if (typeof data.rslt !== 'undefined' && (data.rslt === 'success' || data.rslt === 'empty-success')) {
        thus.setState({ hasError: false });
        // thus.props.clearError(saveData.repeatNo, saveData.qid);
        // thus.props.refreshFunc();
      } else {
        // Handle the failure by showing a failure message
        thus.setState({ hasError: true });
      }
      thus.getTokens();
    })
    .catch(function (error) {
      thus.getTokens();
      console.log('Request failed', error);
    });
  }

	// Update state based on the content
  handleEditorChange (content) {
    this.setState({ textContent: content });
  }

	saveTextareaUpdate (qId, repeatNo) {
		let { textContent } = this.state;
		this.saveField(false, qId, repeatNo, false, false, textContent);
	}

  render () {

    const { hasError, textContent } = this.state;

    // Get an existing response
    var response = (typeof this.props.response != "undefined") ? this.props.response : "";

    if (this.props.inputType === 'sel-name') {

      if (response === "Client 1" && typeof this.props.names.client1 != 'undefined') {
        response = this.props.names.client1;
      }
      if (response === "Client 2" && typeof this.props.names.client2 != 'undefined') {
        response = this.props.names.client2;
      }

      return (
        <div className='question-full' key={ this.props.qid }>
          <select readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } id={ this.props.inputName } onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) }>
            <option></option>
            { typeof this.props.names != 'undefined' && typeof this.props.names.client1 != 'undefined' ? (
              <option value={ this.props.names.client1 }>{ this.props.names.client1 }</option>
            ) : (
              <option value="Client 1">Client 1</option>
            ) }
            { typeof this.props.names != 'undefined' && typeof this.props.names.client2 != 'undefined' ? (
              <option value={ this.props.names.client2 }>{ this.props.names.client2 }</option>
            ) : (
              <option value="Client 2">Client 2</option>
            ) }
            <option value="Joint">Jointly held</option>
          </select>
        </div>
      );

    } else if (this.props.inputType === 'select') {

      var rid = "";
      for ( var i in this.props.optionList ) {
        if (this.props.optionList[i].content === response) {
          rid = this.props.optionList[i].oid;
        }
      }

      return (
        <div className='question-full' key={ this.props.qid }>
          <select readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ rid } id={ this.props.inputName } onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, true, false) }>
          <option></option>
            { this.props.optionList.map( option =>
              <option key={ option.oid } value={ option.oid }>{ option.content }</option>
            )}
          </select>
        </div>
      );

    } else if (this.props.inputType === 'radio') {

      return (
        <div className='question-full radio' key={ this.props.qid }>
          <div style={ styles.radioGrp } className="radio-group">
            { this.props.optionList.map( option =>
              <div style={ styles.radio } key={ option.oid }>
                <input id={ "radio-" + option.oid }  style={ styles.radioInput } type='radio' value={ option.oid } defaultChecked={ response === option.oid ? "checked" : "" } name={ this.props.inputName } onChange={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, true, true) } />
                <label htmlFor={ "radio-" + option.oid } style={ styles.radioLabel } dangerouslySetInnerHTML={{ __html: option.htmlContent }}></label>
              </div>
            )}
          </div>
        </div>
      );

    } else if (this.props.inputType === 'textarea') {

      response = response.replace(/<br\s*\/?>/gi, "\n");
      return (
        <div className='question-full' key={ this.props.qid }>
					{ Number(this.props.isSummary) === 1 ? (
						<ReactQuill value={ textContent } onChange={ this.handleEditorChange } onBlur={ (e) => this.saveTextareaUpdate(this.props.qId, this.props.repeatNo) } modules={ modules } />
					) : (
						<textarea readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type={ this.props.inputType } id={ this.props.inputName } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) }></textarea>
					) }
        </div>
      );

    } else if (this.props.inputType === 'money') {

      return (
        <div className='question-full' key={ this.props.qid }>
          <input readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type="text" id={ this.props.inputName } onChange={ (e) => this.handleCommas(e) } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) } style={ styles.money } />
        </div>
      );

    } else {

      return (
        <div className='question-full' key={ this.props.qid }>
          <input readOnly={ this.props.readOnly } className={ hasError === true ? "error" : "" } name={ this.props.inputName } defaultValue={ response } type="text" id={ this.props.inputName } onBlur={ (e) => this.saveField(e, this.props.qId, this.props.repeatNo, false, false) } />
        </div>
      );

    }

  }

}

const styles = {
  err: {
    color: Colours.red,
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 35
  },
  money: {
    paddingLeft: 30,
    background: Colours.bgWhite + " url(" + pound + ") left 15px center / 8px auto no-repeat",
  },
  radio: {
    margin: "25px 0"
  },
  radioInput: {
    float: "left",
    margin: "5px 25px 5px 12px"
  },
  radioLabel: {
    marginLeft: 50,
    display: 'block'
  },
  strong: {
    fontWeight: 600
  }
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'strike'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
  ],
};

function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey
  };
}

export default connect(mapStateToProps)(MapInput);
